import { ExpertDocumentsService } from "src/app/expert/documents/expert-documents.service";

export abstract class IExpertDocumentItemParent {
    public documentsService: ExpertDocumentsService;

	public isSuperExpert: boolean;
	public hasDeleteConfirmationRight: boolean;
	public hasMarkNotUsableRight: boolean;
	public hasExpertOpsRight: boolean;
	public hasAdnotateRight: boolean;
	public documentId: string;

    // public getRouteComponent(): string {
	// 	return null;
	// }

    // public initLazy(): void {
	// 	return null;
	// }

	public onScrollUp(): void {
		return null;
	}

	public unhook(): void {
		
	}
}