<kendo-dialog *ngIf="showConfirmationDialog" title="Confirmare" [width]="450" (close)="closeConfirmationDialog()">
    <h5> {{ getMP2024FormConclusion() }} </h5>
    <h5> {{ getMP2024ProcentVenitConclusion() }} </h5>
    <div class="col-24 d-flex justify-content-around mt-4">
        <button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="saveMicroProfitForm()">
            <i class="mdi-content-send mr-2"></i>
            <span i18n="@@Actions$Save"> Trimite</span>
        </button>
        <button class="btn waves-effect waves-light" type="button" name="action" (click)="cancelMicroProfitForm()">
            <i class="mdi-content-clear mr-2"></i>
            <span i18n="@@Actions$Cancel">Renunță</span>
        </button>
    </div>
</kendo-dialog>

<section *ngIf="mp2024FormModel && hasViewRight && !isLoading" class="d-flex flex-column mp2024-form">
    <form #mp2024Form="ngForm" class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-start">
					<div class="col d-flex">
						<div class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{mp2024FormModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2">{{mp2024FormModel.formData.description}}</h5>
                            <h5 class="col-24 mt-2">Completați cu datele corespunzătoare companiei dumneavoastră.</h5>
                            <h5 class="col-24 mt-2 pink-text text-accent-2 font-weight-bold">{{mp2024FormModel.formData.companyName}}</h5>
                            <label *ngIf="mp2024FormModel.formData.status == 'Submitted'" class="col-24 mt-2 font-size-1-1 pink-text text-accent-2">
                                Răspunsurile au fost trimise.
                            </label>
						</div>
					</div>		
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5" title="Închide" (click)="this.close.emit()"></i>
				</div>
			</div>
		</div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <!-- Intrebari -->
        <div class="d-flex flex-wrap mb-2">
            <div *ngIf="isP2M" class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23">
                        Doriți ca firma dumneavoastră, în cazul în care îndeplinește toate condițiile, să treacă începand cu 01.01.2024 de la Impozit pe Profit la Microîntreprindere - plătitoare de impozit pe venit?
                    </h5>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesDoresteMicro" [value]="true" #yesDoresteMicro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.doresteMicro" [disabled]="isReadOnly"
                            (change)="onDoresteMicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesDoresteMicro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noDoresteMicro" [value]="false" #noDoresteMicro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.doresteMicro" [disabled]="isReadOnly"
                            (change)="onDoresteMicroChange()"/>
                        <label class="k-radio-label" [for]="noDoresteMicro">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <div class="col-23">
                        <h5 class="col-24 px-0" [ngClass]="{'disabled-header': !isReadOnly && are1FTEDisabled}">
                            La data de {{mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'dd.MM.yyyy'}}, aveți salariați care au norma însumată de cel puțin 8 ore pe zi sau contracte de mandat cu minim salariul minim pe economie?
                        </h5>
                        <div *ngIf="isM2P" class="col-24 px-0" >Dacă la {{mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'dd MMMM'}} sunteți în primele 30 de zile de la înființare și intenționați să angajați înainte de expirarea termenului de 30 de zile, răspundeți DA la această întrebare.</div>
                    </div>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre1FTEHelp()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre1FTE" [value]="true" #yesAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.are1FTE" [disabled]="isReadOnly || are1FTEDisabled"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre1FTE">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre1FTE" [value]="false" #noAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.are1FTE" [disabled]="isReadOnly || are1FTEDisabled"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label" [for]="noAre1FTE">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && areConsultanta80Disabled}">
                        În perioada ianuarie - {{ mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'MMMM' }} {{ mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'yyyy' }}, 
                        ați realizat un procent mai mare de 20% din totalul cifrei de afaceri din activitate de consultanță?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAreConsultanta80()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAreConsultanta80" [value]="true" #yesAreConsultanta80 size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.areConsultanta80" [disabled]="isReadOnly || areConsultanta80Disabled"
                            (change)="onAreConsultanta80Change()"/>
                        <label class="k-radio-label pr-3" [for]="yesAreConsultanta80">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAreConsultanta80" [value]="false" #noAreConsultanta80 size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.areConsultanta80" [disabled]="isReadOnly || areConsultanta80Disabled"
                            (change)="onAreConsultanta80Change()"/>
                        <label class="k-radio-label" [for]="noAreConsultanta80">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && caSub500kDisabled}">
                        În perioada ianuarie - {{ mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'MMMM' }} {{ mp2024FormModel.formDataParameters.lastDayOfMonth | date: 'yyyy' }}, 
                        cifra de afaceri însumată de toate întreprinderile legate din care face parte această companie, a fost mai mică de 500 000 Euro?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onCaSub500k()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesCaSub500k" [value]="true" #yesCaSub500k size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.caSub500k" [disabled]="isReadOnly || caSub500kDisabled"
                            (change)="onCaSub500kChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesCaSub500k">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noCaSub500k" [value]="false" #noCaSub500k size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.caSub500k" [disabled]="isReadOnly || caSub500kDisabled"
                            (change)="onCaSub500kChange()"/>
                        <label class="k-radio-label" [for]="noCaSub500k">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && are253MicroDisabled}">
                        Există cel puțin un acționar care deține (direct sau indirect) mai mult de 25% din capitalul social al acestei companii și care mai deține (direct sau indirect) aceeași procent și la minim o altă microîntreprindere?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre253Micro()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre253Micro" [value]="true" #yesAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre253Micro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre253Micro" [value]="false" #noAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label" [for]="noAre253Micro">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && pastreazaFirmaMicroDisabled}">
                        Doriți ca această firmă să {{isP2M?"devină":"rămână"}} microîntreprindere? Atenție: numărul de microîntreprinderi în care dețineți, direct sau indirect, mai mult de 25% din capitalul social poate fi MAXIM 1.
                    </h5>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesPastreazaFirmaMicro" [value]="true" #yesPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesPastreazaFirmaMicro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noPastreazaFirmaMicro" [value]="false" #noPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label" [for]="noPastreazaFirmaMicro">nu</label>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Concluzie 1 -->
        <div *ngIf="getMP2024FormConclusion() != null" class="col-24 px-0 mb-3 d-flex flex-wrap conclusion-card">
            <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                <h3 class="col-23 my-3"> {{ getMP2024FormConclusion() }} </h3>
                <i *ngIf="mp2024FormModel.data.ramaneMicro || mp2024FormModel.data.devineMicro" class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onRamaneMicroHelp()"></i>
            </div>
        </div>

        <!-- Intrebare aditionala -->
        <div class="d-flex flex-wrap mb-2">
            <div *ngIf="showCAENQuestion && (mp2024FormModel.data.ramaneMicro || mp2024FormModel.data.devineMicro)" class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-auto">
                        În perioada ianuarie - {{ mp2024FormModel.formDataParameters.lunaMicroAsDate | date: 'MMMM' }} {{ mp2024FormModel.formDataParameters.lunaMicroAsDate | date: 'yyyy' }}
                        firma a desfăşurat activităţi, principale sau secundare, corespunzătoare codurilor CAEN afișate în detalii?
                    </h5>
                    <div #caenCodesAnchor class="col-auto mb-2 cursor-pointer" (click)="showCaenCodes()">
                        <a class="see-caen-codes">[Vezi coduri CAEN]</a>
                    </div>
                    <kendo-popup #caenCodesPopup *ngIf="caenCodesPopupVisible" class="caen-codes-popup"
                        [anchor]="{ nativeElement: caenCodesAnchor }" 
                        [popupAlign]="caenCodesPopupAlign" [anchorAlign]="caedCodesAnchorAlign">
                        <div class="px-2 d-flex max-width-500">
                            5821 - Activităţi de editare a jocurilor de calculator<br>
                            5829 - Activităţi de editare a altor produse software<br> 
                            6201 - Activităţi de realizare a soft-ului la comandă (software orientat client)<br> 
                            6209 - Alte activităţi de servicii privind tehnologia informaţiei<br>
                            5510 - Hoteluri şi alte facilităţi de cazare similare<br>
                            5520 - Facilităţi de cazare pentru vacanţe şi perioade de scurtă durată<br>
                            5530 - Parcuri pentru rulote, campinguri şi tabere<br>
                            5590 - Alte servicii de cazare<br>
                            5610 - Restaurante<br>
                            5621 - Activităţi de alimentaţie (catering) pentru evenimente<br>
                            5629 - Alte servicii de alimentaţie n.c.a.<br>
                            5630 - Baruri şi alte activităţi de servire a băuturilor<br>
                            6910 - Activităţi juridice - numai pentru societăţile cu personalitate juridică care nu sunt entităţi transparente fiscal, constituite de avocaţi potrivit legii<br>
                            8621 - Activităţi de asistenţă medicală generală<br>
                            8622 -Activităţi de asistenţă medicală specializată<br>
                            8623 - Activităţi de asistenţă stomatologică<br>
                            8690 - Alte activităţi referitoare la sănătatea umană 
                        </div>
                    </kendo-popup>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAreCodCaen" [value]="true" #yesAreCodCaen size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.hasCaenCode" [disabled]="isReadOnly"
                            (change)="onAreCodCaenChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAreCodCaen">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAreCodCaen" [value]="false" #noAreCodCaen size="large" kendoRadioButton
                            [(ngModel)]="mp2024FormModel.data.hasCaenCode" [disabled]="isReadOnly"
                            (change)="onAreCodCaenChange()"/>
                        <label class="k-radio-label" [for]="noAreCodCaen">nu</label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Concluzie 2 -->
        <div *ngIf="getMP2024ProcentVenitConclusion() != null" class="col-24 px-0 mb-3 d-flex flex-wrap conclusion-card">
            <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                <h3 class="col-23 my-3"> {{ getMP2024ProcentVenitConclusion() }} </h3>
            </div>
        </div>

        <div class="col-24 d-flex justify-content-around mt-2">
            <button *ngIf="!isReadOnly" type="submit" class="col-auto btn waves-effect waves-light mx-1" [disabled]="saveDisabled"
                (click)="openConfirmationDialog()">
                <i class="mdi-content-send mr-2"></i>
                <span i18n="@@Actions$Save">Trimite</span>
            </button>
        </div>
    </form>
</section>

<section *ngIf="!hasViewRight && !isLoading">
    <div class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-center">
					<div class="col d-flex">
						<div *ngIf="mp2024FormModel" class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{mp2024FormModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2"> {{mp2024FormModel.formData.description}} </h5>
						</div>
					</div>
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                </div>
            </div>
        </div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <div class="d-flex flex-wrap">
            <h5>Nu aveți drepturi de vizualizare.</h5>
        </div>
    </div>
</section>