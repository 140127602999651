import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";

import { SalienteLibrarySharedModule } from '@saliente/library';

import { ChatComponent } from './chat.component';
import { ChatMarkAnsweredDialogComponent } from './chat-mark-answered-dialog.component';
import { DocumentDisplaySharedModule } from '../document-display';
import { MicroProfitSharedModule } from 'src/app/client-forms/micro-profit-form/micro-profit-form-shared.module';
import { DuPFAKeezSharedModule } from 'src/app/client-forms/du-pfa-keez-form/du-pfa-form-shared.module';
import { TimeSlotsDialog } from './meetings/time-slots-dialog';
import { MeetingRequestDialog } from './meetings/meeting-request-dialog';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MP2024FormSharedModule } from 'src/app/client-forms/mp2024-form/mp2024-form-shared.module';


const COMPONENTS = [ChatComponent, ChatMarkAnsweredDialogComponent, TimeSlotsDialog, MeetingRequestDialog];

@NgModule({
	imports: [
		CommonModule, FormsModule, RouterModule, ScrollingModule, SalienteLibrarySharedModule, DocumentDisplaySharedModule,
		MicroProfitSharedModule, DuPFAKeezSharedModule, MP2024FormSharedModule,
		DateInputsModule, PerfectScrollbarModule
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class CommonNotificationsSharedModule {
}