import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@saliente/common-auth';
import { HR_EXPERT_ROUTES } from './hrexpert.routes.constants';

const routes: Routes = [
	{
		path: HR_EXPERT_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: HR_EXPERT_ROUTES.DOCUMENTS,
				loadChildren: () => import('./documents/hrexpert-documents.module').then(m => m.HrExpertDocumentsModule)
				// const modules: any = require('./documents/hrexpert-documents.module');
				// 	return modules.HrExpertDocumentsModule;
				// }
			},
			{
				path: HR_EXPERT_ROUTES.STATUTORY_REPORTS,
				loadChildren: () => import('./documents-summary/statutory-reports-processors/hr-expert-statutory-reports-processors.module').then(m => m.HrExpertStatutoryReportsProcessorsModule)
				// 	const modules: any = require('./documents-summary/statutory-reports-processors/hr-expert-statutory-reports-processors.module');
				// 	return modules.HrExpertStatutoryReportsProcessorsModule;
				// }
			},
			{
				path: HR_EXPERT_ROUTES.STATUTORY_REPORTS_DASHBOARD,
				data:{
				role:'HR_EXPERT'
				},
				loadChildren: () => import('../expert/statutory-reports-dashboard/statutory-reports-dashboard.module').then(m => m.StatutoryReportsDashboardModule)
				// const modules: any = require('../expert/statutory-reports-dashboard/statutory-reports-dashboard.module');
				//   return modules.StatutoryReportsDashboardModule;
				// }
			},
			{
				path: HR_EXPERT_ROUTES.GLOBAL_PARAMETERS,
				loadChildren: () => import('../payroll/global-parameters/global-parameters.module').then(m => m.GlobalParametersModule)
			},
		]
	}
];

// @dynamic
@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
	exports: [RouterModule]
})
export class HrExpertRouterModule {

}
