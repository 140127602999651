import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subscription, } from 'rxjs';
import { timeout, map, switchMap, catchError, filter, } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import gql from 'graphql-tag';

import { SpinnerService } from '@saliente/library';
import { ConfigService, RestService, DownloadService, UploadService, } from '@saliente/common';
import { AuthService, PredefinedUserZone, } from '@saliente/common-auth';

import { ClientFinancialReportHeaderModel, ClientFinancialReportDetail, ClientFinancialReportDetailActionsModel, Reports_ForcedRunByClientAndMonthUsingPostMutationVariables, Reports_ForcedRunByClientAndMonthUsingPostMutation, MenuBadgeNumbersModel, } from './financial-reports.models';

import { PerformActionUsingPostMutation } from './financial-report-graphql-models';
import { ApolloResult } from 'src/app/common/general/gql-codegen.types';
import { CheckBatchStatusGQL, RunUploadGQL } from '../adapters/reports.generated';
import { KMutationOptions } from 'src/app/common/general/rest.service';
import { Reports_BatchExecutionRepresentation } from 'src/graphql';


const financialReportsQueryText = gql`
query financialReports($clientEid: String!, $reportName: String!, $filters: String){
	reports{
		getFinancialReportUsingGET(clientEId:$clientEid, reportName:$reportName, renderType:"RENDER_DEFAULT", filters: $filters){
			value
		}
	}
}
`;

const financialReportDetailsQueryText = gql`
query financialReportDetails($clientEid: String!, $reportName: String!, $filters: String){
	reports{
		getFinancialReportUsingGET(clientEId:$clientEid, reportName:$reportName, filters:$filters, renderType:"RENDER_DEFAULT"){
			value
		}
	}
  }
`;

const performActionMutationText = gql`
mutation performAction($request: subs_PerformActionRequestRepresentationInput!){
	subs{
		performActionUsingPOST(request:$request){
      actionResult
      errorMessages
      fileInfo {
        content
        contentType
        fileName
      }
      navigateURL
      resultOk
      resultOkMessage
		}
	}
  }
`;
const Reports_ForcedRunByClientAndMonthUsingPostDocument = gql`
    mutation reports_forcedRunByClientAndMonthUsingPOST($clientCode: String, $forMonthYYYYMM: Int) {
  reports {
    forcedRunByClientAndMonthUsingPOST(
      clientCode: $clientCode
      forMonthYYYYMM: $forMonthYYYYMM
    ) {
      Message
    }
  }
}
`;

const menuBadgeNumbersQuery = gql`
query menuBadgeNumbersUsingGET($clientEid: String!, $type: String) {
	subs {
		getMenuBadgeNumbersUsingGET(clientEid: $clientEid, type: $type) {
			clarificationCount
      cardClarificationCount
      reconciliationCount
      missingDocsCount
		}
	}
}
`;

/* required in expert.closing-audit */
@Injectable({
  providedIn: 'root',
})
export class ClientFinancialReportsService {
  private model: ClientFinancialReportDetail;

  public docsCount: BehaviorSubject<MenuBadgeNumbersModel>;

  constructor(private restService: RestService, private authService: AuthService, private configService: ConfigService,
    private downloadService: DownloadService, private uploadService: UploadService, private spinner: SpinnerService,
    private toastr: ToastrService,) {
      this.docsCount = new BehaviorSubject<MenuBadgeNumbersModel>(null);
    }

  public fetchFinancialReports(source: string, reportName: string): Observable<ClientFinancialReportHeaderModel[]> {
    if (this.authService.isInRole('DASHBOARD_VIEW')) {
      return this.authService.getSelectedCompanyId()
        .pipe(
          switchMap((companyId) => {
            if (companyId) {
              return this.restService
                .query({
                  query: financialReportsQueryText,
                  variables: {
                    clientEid: companyId,
                    reportName: reportName,
                    filters: JSON.stringify({
                      username: this.authService.user.email,
                      section: source,
                      zonecode: this.authService.selectedZoneCode
                    })
                  },
                  fetchPolicy: 'network-only'
                })
                .pipe(
                  timeout(60000),
                  map((res: any) => {
                    try {
                      let data = JSON.parse(res.data.reports.getFinancialReportUsingGET.value || '[]');
                      let result = plainToClass<ClientFinancialReportHeaderModel, object>(ClientFinancialReportHeaderModel, data).filter((r) => r.displayWeb);
                      result = result.filter((r) => !r.permission || this.authService.isInRole(r.permission));
                      if (this.authService.selectedZoneCode != PredefinedUserZone.Expert) {
                        result = result.filter((r) => !r.type.startsWith('xp'));
                      }
                      return result;

                    } catch (err) {
                      console.log(err.stack);
                    }
                    return null;
                  }),
                  catchError((err) => {
                    if (err.name == 'TimeoutError') {
                      this.toastr.error('Ups! Se pare că serverului îi ia ceva timp să răspundă. Te rog sa încerci mai târziu.');
                    } else {
                      this.toastr.error('Am întâmpinat o eroare necunoscută. Te rog sa încerci din nou sau să ne contactezi.');
                    }
                    return of(null);
                  })
                );
            }
            return of(null);
          })
        );
    } else {
      return of(null);
    }
  }

  public fetch(type: string, filters: string = null, showSpinner: boolean = true): Observable<ClientFinancialReportDetail> {
    return this.authService.getSelectedCompanyId()
      .pipe(
        switchMap((companyId) => {
          return this.restService
            .query({
              query: financialReportDetailsQueryText,
              fetchPolicy: 'network-only',
              variables: { clientEid: companyId, reportName: type, filters }
            }, { spinner: showSpinner })
            .pipe(
              map((res: any) => {
                let financialReports = res.data.reports.getFinancialReportUsingGET;
                try {
                  financialReports = JSON.parse(res.data.reports.getFinancialReportUsingGET.value || '[]');
                  if (financialReports.length) {
                    this.model = plainToClass(ClientFinancialReportDetail, financialReports[0]);
                    this.model.tryConvertValues();
                  } else {
                    this.model = null;
                  }
                } catch {
                  this.model = null;
                }
                return this.model;
              })
            );
        })
      );
  }

  public downloadReport(type: string, report: ClientFinancialReportDetail, filters: string = null) {
    return this.configService
      .getReportsDownloadUrl()
      .pipe(
        switchMap((url) => {
          url = url + `/financial/cielreports/${type}?clientEId=${encodeURIComponent(this.authService.user.selectedCompanyId)}&reportName=${encodeURIComponent(report.type)}&filters=${encodeURIComponent(filters)}&attachment=true`;
          return this.downloadService.download(url);
          //url = url + `/financial/cielreports/${type}?clientEId=${this.authService.user.selectedCompanyId}&reportName=${report.type}&filters=${filters}&attachment=true`;
          //return this.downloadService.download(encodeURI(url));
        })
      );
  }

  public uploadFile(file: any, meta: any) {
    this.spinner.show();
    return this.configService
      .getDocumentsUploadUrl()
      .pipe(
        switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
        filter((data) => {
            return !!data.done;
        })
      );
  }

  public async runReportAsync(documentEid: string, type: string)  {
    let qry = new RunUploadGQL(this.restService);
		let result = await qry.mutate({
			data: {
        clientEid: this.authService.user.selectedCompany.externalId,
        documentEid: documentEid,
        type: type,
      },
		}, { error: false } as KMutationOptions).toPromise();

		if (result) {
			return result.data.reports.runUploadUsingPOST.externalId;
		}
		
		return null;
  }

  
  public async checkBatchStatus(batchExternalId: string) : Promise<Reports_BatchExecutionRepresentation> {
    let qry = new CheckBatchStatusGQL(this.restService);
		let result = await qry.fetch({ batchExternalId }, { error: false } as KMutationOptions).toPromise();
		if (result) {
      return result.data.reports.checkBatchStatusUsingGET;
		}

    return null;
  }


  public executeActionStream(action: ClientFinancialReportDetailActionsModel, dataItem: any):Observable<PerformActionUsingPostMutation> {
    return this.restService
      .mutate({
        mutation: performActionMutationText,
        variables: {
          request: {
            clientCode: this.authService.user.selectedCompany.fiscalNumber,
            code: action.code,
            context: JSON.stringify(dataItem),
            report: this.model.type,
          },
        }
      })
      .pipe(
        map((response: {
          data: PerformActionUsingPostMutation;
        }) => {
          if (response) {
            const ret = response.data;
            // const data = response.data.subs.performActionUsingPOST as PerformActionUsingPostMutation;
            return ret;
            // return $.extend(true, {}, response.data.subs.performActionUsingPOST);
          }
          return null;
        })
      );
  }

  public executeHeaderActionStream(action: ClientFinancialReportDetailActionsModel, filters: any):Observable<PerformActionUsingPostMutation> {
    return this.restService
      .mutate({
        mutation: performActionMutationText,
        variables: {
          request: {
            clientCode: this.authService.user.selectedCompany.fiscalNumber,
            code: action.code,
            context: JSON.stringify({
              "filters": filters,
              "zone": this.authService.selectedZoneCode,
              "user": this.authService.userName
            }),
            report: this.model.type,
          },
        }
      })
      .pipe(
        map((response: {
          data: PerformActionUsingPostMutation;
        }) => {
          if (response) {
            const ret = response.data;
            // const data = response.data.subs.performActionUsingPOST as PerformActionUsingPostMutation;
            return ret;
            // return $.extend(true, {}, response.data.subs.performActionUsingPOST);
          }
          return null;
        })
      );
  }
  
  public forcedRunByClientAndMonth(variables: Reports_ForcedRunByClientAndMonthUsingPostMutationVariables): Observable<Reports_ForcedRunByClientAndMonthUsingPostMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Reports_ForcedRunByClientAndMonthUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Reports_ForcedRunByClientAndMonthUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

  public getDocumentsCounts(type: string): Observable<MenuBadgeNumbersModel> {
    const clientEid = this.authService.user.selectedCompanyId;
    // return this.authService.getSelectedCompanyId()
    //   .pipe(
    //     switchMap((companyId) => {
          return this.restService
            .query({
              query: menuBadgeNumbersQuery,
              fetchPolicy: 'network-only',
              variables: { clientEid: clientEid, type: type }
            }, { spinner: false })
            .pipe(
              map((res: any) => {
                try {
                  const docsCountModel: MenuBadgeNumbersModel = plainToClass(MenuBadgeNumbersModel, res.data.subs.getMenuBadgeNumbersUsingGET);
                  //console.log(docsCountModel);
                  
                  return docsCountModel;
                } catch {
                  return null;
                }
              })
            );
        //})
      //);
  }

  public computeDocsSubscription: Subscription;
  public computeDocsCount() {
    if (this.computeDocsSubscription) {
			this.computeDocsSubscription.unsubscribe();
			this.computeDocsSubscription = null;
		}

    if (this.authService.selectedZoneCode != undefined) {
      if (this.authService.selectedZoneCode == PredefinedUserZone.Administrator) {
        this.computeDocsSubscription = this.getDocumentsCounts("clientMenu").subscribe((count) => {
          if (count != null) {
            this.docsCount.next(count);
          }
        });
      }
      else if (this.authService.selectedZoneCode == PredefinedUserZone.Expert) {
        this.computeDocsSubscription = this.getDocumentsCounts("expertMenu").subscribe((count) => {
          if (count != null) {
            this.docsCount.next(count);
          }
        });
      }
    }
  }

  public get missingDocumentsCount(): number {
    if (this.docsCount != null && this.docsCount.value != null) {
      return this.docsCount.value.missingDocsCount;
    }
  }

  public get clarificationCount(): number {
    if (this.docsCount != null && this.docsCount.value != null) {
      return this.docsCount.value.clarificationCount;
    }
  }
  
  public get cardClarificationCount(): number {
    if (this.docsCount != null && this.docsCount.value != null) {
      return this.docsCount.value.cardClarificationCount;
    }
  }

  public get reconciliationCount(): number {
    if (this.docsCount != null && this.docsCount.value != null) {
      return this.docsCount.value.reconciliationCount;
    }
  }
}
