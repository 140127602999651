<section class="row m-0">
	<form #bookDocumentForm="ngForm" class="col-24 px-0">
		<inputfield id="txtReference" name="reference" class="col-24" i18n-label="@@BookDocument$Reference" label="Referință" (keyPress)="referenceKeyPress($event)" required
			[(ngModel)]="model.reference">
		</inputfield>
		<!-- AM. 03.11.2021. task 5364. comment. datePicker removed -->
		<!-- <kee-datepicker name="documentDateEx" class="col-24" i18n-label="@@BookDocument$Date" label="Data document" [labelOnTop]=true placeholder=""
			[(ngModel)]="model.documentDateEx" [required]="dateIsRequired">
		</kee-datepicker> -->
		<kee-textarea id="txtComment" name="comment" class="col-24" i18n-label="@@BookDocument$Comment" label="Notă" [(ngModel)]="model.comment"></kee-textarea>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" i18n="@@Actions$Save" (click)="this.save.emit(bookDocumentForm)">
				<i class="mdi-content-save"></i>
				Salvează
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" i18n="@@Actions$Cancel" (click)="this.close.emit(bookDocumentForm)">
				<i class="mdi-content-clear"></i>
				Renunță
			</button>
		</div>
	</form>
</section>