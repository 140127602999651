export class PAYROLL_ROUTES {
	static ROOT: string = '/payroll';
	static ROOT_1: string = 'payroll';

	static CALCULATION_ROOT: string = 'calculation';

    static TIMESHEET: string = 'timesheet';

	static EMPLOYEES = 'employees';
	static SICK_LEAVE = 'sickleave';

}