import { Input, Directive } from '@angular/core';

import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnComponent, BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { localizeOperators } from '../utils';

const stringOperators = localizeOperators({
	"filterEqOperator": "eq",
	"filterNotEqOperator": "neq",
	// eslint-disable-next-line
	"filterContainsOperator": "contains",
	"filterNotContainsOperator": "doesnotcontain",
	"filterStartsWithOperator": "startswith",
	"filterEndsWithOperator": "endswith",
	"filterIsNullOperator": "isnull",
	"filterIsNotNullOperator": "isnotnull",
	"filterIsEmptyOperator": "isempty",
	"filterIsNotEmptyOperator": "isnotempty"
});

@Directive()
export abstract class DropdownFilterComponent extends BaseFilterCellComponent {

	@Input() public column: ColumnComponent;
	@Input() public filter: CompositeFilterDescriptor;
	@Input() public operator: string;


    /**
     * The current filter for the associated column field.
     * @readonly
     * @type {FilterDescriptor}
     */
	get currentFilter(): FilterDescriptor {
		return this.filterByField((this.column || (<any>{})).field);
	}

    /**
     * The current filter operator for the associated column field.
     * @readonly
     * @type {string}
     */
	get currentOperator(): string {
		return this.currentFilter ? (<string>this.currentFilter.operator) : this.operator;
	}

	protected defaultOperators: Array<{
		text: string;
		value: string;
	}>;

	constructor(filterService: FilterService, protected localization: LocalizationService) {
		super(filterService);
		this.operator = "contains";
		this.defaultOperators = stringOperators(this.localization);
	}
}