import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import gql from 'graphql-tag';

import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, shareReplay, first } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { dateToInt } from '@saliente/library';
import { RestService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import {
	AccountingPeriodService,
	AccountingFiscalSheetModel,
} from '@saliente/common-accounting';

import { ExpertDocumentsAccuracyService } from '../documents-accuracy/expert-documents-accuracy.service';
import { ExpertStatutoryReportsProcessorsService } from '../statutory-reports-processors/expert-statutory-reports-processors.service';
import { ExpertDocumentsSummaryModel } from './expert-documents-summary.models';

import { EXPERT_ROUTES } from '../../expert.routes.constants';

const companyInfoQueryText = gql`
	query companyInfo($clientEid: String!) {
		accounts {
			GetClientUsingGET(clientEid: $clientEid) {
				externalId
				fiscalNumber
				expertComments
				serviceStartDate
				sysCaenCode {
					code
					description
				}
			}
			fiscalSheet: getFisaRolUsingGET(clientEid: $clientEid) {
				found
				data: lista {
					fiscalNumber
					clientName: numeClient
					toDate: panaLaData
					sections: sectiuni {
						creditedDifference: creditatDiferenta
						creditedAmountIncome: creditatIncasariSuma
						creditedAmountRefund: creditatSumeDeRambursat
						interestCalculatedNotEstablished: dobandaCalculataSiNeinstituita
						isSummary: esteSumar
						sectionName: numeSectie
						unpaidPromiseOfPayment: obligatieDePlataNeachitata
						unpaid: neachitate
						totalPromiseOfPayment: obligatieDePlataSuma
						supersolvency: suprasolvire
						penalties: penalizari
						unallocated: nealocate
						sortOrder
					}
				}
			}
      getClientExtraInfoUsingGET(clientEid: $clientEid) {
        hasOSS
        hasOSSStartingWithDate
      }
		}
	}
`;

const statutoryReportsQueryText = gql`
	query statutoryReports($clientEid: String!, $date: Int!) {
		accounts {
			getStatutoryReportDefinitionUsingGET(clientEid: $clientEid, forMonth: $date) {
				definitions {
					endDate
					externalId
					periodicity
					startDate
					statutoryReportTypeRepresentation {
						code
						description
					}
					isActiveForSelectedMonth
				}
				lastImportDate
			}
		}
	}
`;

@Injectable()
export class ExpertDocumentsSummaryService {
	public readonly summaryTypes = {
		documentsAccuracy: {
			name: 'documentsAccuracy',
			displayName: 'Corespondența cu sistemul contabil',
			goto: (detail: any): void => {
				if (detail.counter) {
					const date = this.date;
					const	startDate = new Date(date);
					const	endDate = new Date(new Date(date).setMonth(date.getMonth() + 1));
					this.router.navigate([EXPERT_ROUTES.ROOT, EXPERT_ROUTES.DOCUMENTS_ACCURACY, detail.code, dateToInt(startDate), dateToInt(endDate)]);
				}
			},
		},
	};

	public _date: BehaviorSubject<Date> = new BehaviorSubject(null);
	public get date(): Date {
		return this._date.value;
	}
	public set date(value) {
		const oldValue = this._date.value;
		value = this.firstDayOfMonth(value);
		if (value && oldValue) {
			if (value.getTime() !== oldValue.getTime()) {
				this._date.next(value);
			}
		} else {
			this._date.next(value);
		}
	}

	private summaryStream: Observable<ExpertDocumentsSummaryModel>;

	constructor(
		private documentsAccuracyService: ExpertDocumentsAccuracyService,
		private authService: AuthService,
		private restService: RestService,
		private accountingPeriodService: AccountingPeriodService,
		private statutoryReportsService: ExpertStatutoryReportsProcessorsService,
		private router: Router
	) {}

	private firstDayOfMonth(date: Date): Date {
		if (date && date.getTime) {
			return new Date(date.getFullYear(), date.getMonth(), 1);
		}
		return null;
	}

	private summaryEntry(model: ExpertDocumentsSummaryModel, summaryType: any, values: any[]): void {
		const entryIndex = model.summary.findIndex((s) => s.name === summaryType.name);
		if (entryIndex !== -1) {
			model.summary.splice(entryIndex, 1);
		}
		const modelEntry: any = {
			...summaryType,
			data: values,
		};
		model.summary.push(modelEntry);
	}

	public fetch(): Observable<ExpertDocumentsSummaryModel> {
		if (!this.summaryStream) {
			this.summaryStream = this.authService.getSelectedCompanyId().pipe(
				switchMap((clientEid) => {
					(this._date as any)._value = null;
					return this.restService
						.query({
							query: companyInfoQueryText,
							fetchPolicy: 'network-only',
							variables: {
								clientEid: clientEid,
							},
						})
						.pipe(
							map((response: any) => {
								if (response) {
									const clientInfo = response.data.accounts.GetClientUsingGET;
									const	fiscalSheet = response.data.accounts.fiscalSheet;
                  const oss=response.data.accounts.getClientExtraInfoUsingGET;
									const model: ExpertDocumentsSummaryModel = plainToClass(ExpertDocumentsSummaryModel, clientInfo);
									const caen = clientInfo.sysCaenCode;
									if (caen) {
										model.caenCode = caen.code;
										model.caenDescription = caen.description;
									}
									if (fiscalSheet) {
										model.fiscalSheet = plainToClass(AccountingFiscalSheetModel, fiscalSheet);
									}
                  model.hasOSS=oss.hasOSS;
                  model.hasOSSStartingWithDate=oss.hasOSSStartingWithDate;
									return model;
								}
							})
						);
				}),
				switchMap((model: ExpertDocumentsSummaryModel) => {
					if (!this._date.value) {
						const startDate = this.firstDayOfMonth(new Date());
						if (model.serviceStartDateEx < startDate) {
							startDate.setMonth(startDate.getMonth() - 1);
						}
						this._date.next(startDate);
					}
					return this._date.pipe(
						switchMap((date) => {
							const startDate = new Date(date);
							const	endDate = new Date(new Date(date).setMonth(date.getMonth() + 1));
							return combineLatest([
								this.accountingPeriodService.accountingPeriodStream(model.clientEid, date),
								//this.documentsAccuracyService.countersStream(model.clientEid, startDate, endDate),
								// this.statutoryReports(model.clientEid, date),
							]);
						}),
						map(([accountingPeriod, /*documentsAccuracy, statutoryReports*/]) => {
							model.accountingPeriod = accountingPeriod;
							model.summary = [];
							this.summaryEntry(model, this.summaryTypes.documentsAccuracy, []);
							return model;
						})
					);
				}),
				shareReplay(1)
			);
		}
		return this.summaryStream;
	}

	public documentsAccuracyStream(model: ExpertDocumentsSummaryModel): Observable<ExpertDocumentsSummaryModel> {
		const date = this._date.value;
		const	startDate = new Date(date);
		const	endDate = new Date(new Date(date).setMonth(date.getMonth() + 1));
		return this.documentsAccuracyService.countersStream(model.clientEid, startDate, endDate).pipe(
			map((documentsAccuracy) => {
				this.summaryEntry(model, this.summaryTypes.documentsAccuracy, documentsAccuracy);
				return model;
			}),
			first()
		);
	}

	// public closeOpenMonth(model: ExpertDocumentsSummaryModel) {
	// 	const subscription = this.authService.getSelectedCompanyId()
	// 		.pipe(
	// 			switchMap((clientEid: string) => {
	// 				return this.accountingPeriodService.accountingPeriodToggleStatusStream(clientEid, this.date);
	// 			})
	// 		)
	// 		.subscribe((accountingPeriod: AccountingPeriodModel) => {
	// 			subscription.unsubscribe();
	// 			if (accountingPeriod) {
	// 				model.accountingPeriod = accountingPeriod;
	// 			}
	// 		});
	// }
}
