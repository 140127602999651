import { Input, Output, EventEmitter, Injector, Directive } from '@angular/core';

import { Observable } from 'rxjs';

import { Control } from '../control';

let nextUniqueId = 0;

@Directive()
export class LabeledControl<T> extends Control<T> {

    //#region readonly / disabled

    private _readonly: boolean = false;
    @Input()
    get readonly(): boolean {
        return this._readonly;
    }

    set readonly(value) {
        this._readonly = this.coerceBooleanProperty(value);
    }

    private _disabled: boolean = false;
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = this.coerceBooleanProperty(value);
    }

    //#endregion

    //#region control

    // @Input() public placeholder: string = null;
    public _placeholder: string = null;
	@Input() get placeholder(): string {
		return this._placeholder;
	}
	set placeholder(value) {
		this._placeholder = value;
	}


    @Input() public id: string = `labeledcontrol-${nextUniqueId++}`;
    get controlId() {
        return `__${this.id}__`;
    }

    @Input() public controlClass: string = '';
    @Input() public wrapperClass: string = '';

    //#endregion

    //#region label

    private labelClass: string = '';

    @Input() public label: string = '';

    private _labelOnTop: boolean = false;
    @Input()
    get labelOnTop(): boolean {
        return this._labelOnTop;
    }

    set labelOnTop(value) {
        this._labelOnTop = this.coerceBooleanProperty(value);
    }

    //#endregion

    //#region focused

    private _focused: boolean = false;
    get focused(): boolean {
        return this._focused;
    }

    private _blurEmitter: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output('blur')
    get onBlur(): Observable<FocusEvent> {
        return this._blurEmitter.asObservable();
    }

    private _focusEmitter: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output('focus')
    get onFocus(): Observable<FocusEvent> {
        return this._focusEmitter.asObservable();
    }
    
    //#endregion

    //#region (pre)suffix

    @Input() public prefix: string;
    @Output() public prefixClick = new EventEmitter<boolean>();
    @Input() public suffix: string;
    @Output() public suffixClick = new EventEmitter<boolean>();

    //#endregion

    //#region

    get behaviourClasses(): string {
        let result: string = '';
        if (this.focused) {
            result += ' input-focused';
        }
        if (this.readonly) {
            result += ' input-readonly';
        }
        if (this.disabled) {
            result += ' input-disabled';
        }
        if (this.touched) {
            if (this.invalid) {
                result += ' invalid';
            } else {
                result += ' valid';
            }
        }
        if (this._labelOnTop || this.focused || (this.value !== null && this.value !== undefined) || (this.placeholder && this.placeholder.trim())) {
            result += ' input-active';
        }
        return result.trim();
    }

    //#endregion

	/**
	 * Constructor
	 */
    public constructor(injector: Injector, ) {
        super(injector);
    }

    public _handleFocus(event: FocusEvent) {
        this._focused = true;
        this._focusEmitter.emit(event);
    }

    public _handleBlur(event: FocusEvent) {
        this._focused = false;
        this.touch();
        this._blurEmitter.emit(event);
    }

    public _handleChange(value: T) {
        this.value = value;
        this.touch();
    }

    public _handleEnterEvent() {
        this.touch();
        this._blurEmitter.emit();
    }
}