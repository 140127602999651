import { Directive, ElementRef, AfterViewInit } from '@angular/core';

declare var WOW: any;

@Directive({
	selector: '[wow-container]',
	exportAs: 'wowContainer'
})
export class WowContainerDirective implements AfterViewInit {
	private wow: any;

	constructor(private el: ElementRef) {
		require('imports-loader?this=>window!wowjs');
		new URL('animate.css', import.meta.url);
		// require('animate.css');
		this.wow = new WOW();
	}

	public ngAfterViewInit() {
		this.wow.element = this.el.nativeElement;
		this.wow.start();
	}

	private tryReset() {
		if (this.wow.stopped === false) {
			this.wow.stop();
			this.wow.start();
		}
	}
}
