import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { DuChiriiModel, SistemImpozitareChirii } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";

@Component({
	selector: 'chirie',
	templateUrl: 'chirie.component.html',
    styleUrls: ['chirie.component.scss']
})
export class ChirieComponent implements OnInit {
    @Input() public index: number;
    @Input() public model: DuChiriiModel;
    @Input() public type: number; // 0 - realizat, 1 - estimat
    @Input() public year: number;
    @Input() public isReadOnly: boolean;

    @Output() public delete = new EventEmitter();

    // view child
	@ViewChild('chirieForm') public chirieForm: NgForm;

    //subscription
    private isFormTouchedSubscription: Subscription;
    
    //model
    public sistemImpozitareList: any[];
    public sistemImpozitareRealizatList: any[] = [
        { value: SistemImpozitareChirii.SistemReal, name: this.clientFormsService.sistemImpozitareChiriiDisplay(SistemImpozitareChirii.SistemReal) },
        { value: SistemImpozitareChirii.CoteForfetare, name: this.clientFormsService.sistemImpozitareChiriiDisplay(SistemImpozitareChirii.CoteForfetare) }];
    public sistemImpozitareEstimatList: any[] = [
        { value: SistemImpozitareChirii.SistemReal, name: this.clientFormsService.sistemImpozitareChiriiDisplay(SistemImpozitareChirii.SistemReal) }];
    
    constructor(private clientFormsService: ClientFormsService) { }
    
    ngOnInit(): void {
        if (this.type == 1) {
            this.sistemImpozitareList = this.sistemImpozitareEstimatList;
        }
        else {
            this.sistemImpozitareList = this.sistemImpozitareRealizatList;
        }

        if (this.year == 2023) {
            this.sistemImpozitareList = this.sistemImpozitareList.filter((s) => s.value == SistemImpozitareChirii.SistemReal);
        }

        this.isFormTouchedSubscription = this.clientFormsService.isFormTouched.subscribe(this.onFormTouched.bind(this));
    }

    ngOnDestroy(): void {
        if (this.isFormTouchedSubscription) { this.isFormTouchedSubscription.unsubscribe(); this.isFormTouchedSubscription = null; }
    }

    private onFormTouched() {
        if (this.chirieForm != undefined && this.chirieForm.form != undefined) {
            this.chirieForm.form.markAllAsTouched();
        }
    }

    public get isRealizat(): boolean {
        return this.type == 0;
    }

    public get isSistemReal(): boolean {
        return this.model.sistemImpozitare == undefined || this.model.sistemImpozitare == SistemImpozitareChirii.SistemReal;
    }

    public getMinDate(): Date {
        return new Date(this.year, 0, 1);
    }

    public getMaxDate(): Date {
        return new Date(this.year, 11, 31);
    }
    
    public validateDataActivitate(value: Date) {
		if (value && (value < this.getMinDate() || value > this.getMaxDate())) {
			return `Data trebuie să fie în interiorul anului de referință: ${this.year}`;
		}

		return true;
	}
}