import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { SalienteLibrarySharedModule } from '@saliente/library';

import { AccountingFiscalSheetComponent } from './accounting-fiscal-sheet.component';
import { AccountingStatutoryReportsComponent } from './accounting-statutory-reports.component';
import { CompanyFiscalSummaryExComponent } from './company-fiscal-summary/company-fiscal-summary.component-ex';
import { CompanyFiscalSummaryService } from './company-fiscal-summary/company-fiscal-summary.service';
const COMPONENTS = [AccountingFiscalSheetComponent, AccountingStatutoryReportsComponent, CompanyFiscalSummaryExComponent];
const SERVICES = [CompanyFiscalSummaryService];

@NgModule({
	imports: [CommonModule, FormsModule, RouterModule, SalienteLibrarySharedModule],
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
	providers: [...SERVICES],
})
export class CommonAccountingSharedModule {}
