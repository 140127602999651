import { Directive, Input, Renderer2, ElementRef, AfterViewInit } from '@angular/core';

import $ from '../jquery.plugins';

@Directive({ selector: '[owl-carousel]' })
export class OwlCarouselDirective implements AfterViewInit {

	@Input('owl-carousel') private owlCarousel: any;

	constructor(private el: ElementRef, private renderer: Renderer2) {
		require('owl.carousel');
		new URL('owl.carousel/dist/assets/owl.carousel.css', import.meta.url)
		new URL('owl.carousel/dist/assets/owl.theme.default.css', import.meta.url)
		// require('owl.carousel/dist/assets/owl.carousel.css');
		// require('owl.carousel/dist/assets/owl.theme.default.css');
		//this.patchOwl();

		this.renderer.addClass(this.el.nativeElement, 'owl-carousel');
		this.renderer.addClass(this.el.nativeElement, 'owl-theme');
	}

	private patchOwl() {
		const autoplay = $.fn.owlCarousel.Constructor.Plugins.autoplay.prototype;
		const autoplayPlayFn = autoplay.play;

		if (!autoplayPlayFn.__patched__) {
			/* eslint-disable @typescript-eslint/no-var-requires, space-before-function-paren */
			autoplay.play = function () {
				autoplayPlayFn.apply(this, arguments);
				if (this._timeout) {
					window.clearTimeout(this._timeout);
				}
				this._setAutoPlayInterval();
			};
			autoplay.play.__patched__ = true;
		}
	}

	public ngAfterViewInit() {
		$(this.el.nativeElement).owlCarousel(this.owlCarousel || {});
	}
}
