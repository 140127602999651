import { ɵisObservable, ɵisPromise } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { Observable, from, of } from 'rxjs';
import * as lodash from 'lodash-es';

import { FilterDescriptor, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { HttpParams } from '@angular/common/http';
import * as MarkdownIt from 'markdown-it';

const parametersRegexp = /\@([^\!\"\#\$\%\&\'\(\)\*\+\,\.\/\:\;\<\=\>\?\@\^\`\{\|\}\-\~\s\']+)/g;

export function coerceBooleanProperty(value: any): boolean {
	return value != null && `${value}` !== 'false';
}

export function coerceNumericProperty(value: any): number {
	value = parseFloat(value).toFixed(4);
	return value * 1;
}

export function toObservable(value: any): Observable<any> {
	if (ɵisObservable(value)) {
		return value;
	}
	if (ɵisPromise(value)) {
		return from(value);
	}
	return of(value);
}

export function copyFormControls(sourceForm: NgForm, destFrom: NgForm) {
	const sourceControls = sourceForm.controls;
	const destControls: any = destFrom.controls;

	const destFromAsAny: any = destFrom;
	let forms: Map<NgForm, string[]> = destFromAsAny.forms;
	if (forms) {
		forms = destFromAsAny.forms;
		if (forms.has(sourceForm)) {
			removeFormControls(sourceForm, destFrom);
			forms.delete(sourceForm);
		}
	} else {
		forms = destFromAsAny.forms = destFromAsAny.forms || new Map();
	}
	const formControls: string[] = [];
	destFromAsAny.forms.set(sourceForm, formControls);

	for (var controlName in sourceControls) {
		destControls[controlName] = sourceControls[controlName];
		formControls.push(controlName);
	}
}

export function removeFormControls(sourceForm: NgForm, destFrom: NgForm) {
	const sourceControls = sourceForm.controls;
	const destControls: any = destFrom.controls;

	const destFromAsAny: any = destFrom;
	const forms: Map<NgForm, string[]> = destFromAsAny.forms;
	if (forms && forms.has(sourceForm)) {
		const formControls: string[] = forms.get(sourceForm);
		formControls.forEach((controlName: string) => {
			delete destControls[controlName];
		});
	} else {
		for (var controlName in sourceControls) {
			delete destControls[controlName];
		}
	}
}

export function getRandomInt(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const precisionRoundEx = (number: number, precision: number) => {
	/*
	var factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;*/
	if (!('' + number).includes('e')) {
		const result: any = number + 'e+' + precision;
		return +(Math.round(result) + 'e-' + precision);
	} else {
		var arr = ('' + number).split('e');
		var sig = '';
		if (+arr[1] + precision > 0) {
			sig = '+';
		}
		const result: any = +arr[0] + 'e' + sig + (+arr[1] + precision);
		return +(Math.round(result) + 'e-' + precision);
	}
};
export function precisionRound(number: number, precision: number) {
	if ((number || 0) != 0) {
		if (precision < 0) {
			return number;
		}
		//return precisionRoundEx(precisionRoundEx(number, precision + 10), precision);
		return precisionRoundEx(number, precision);
	}
	return 0;
}

export function accPrecisionRound(number: number, precision: number) {
	if (number != 0) {
		if (precision < 0) {
			return number;
		}
		const sign = number < 0 ? -1 : 1;
		//return sign * precisionRoundEx(precisionRoundEx(Math.abs(number), precision + 10), precision);
		return sign * precisionRoundEx(precisionRoundEx(Math.abs(number), 10), precision);
	}
	return 0;
}

export function processCurrencyFormula(value: string): number {
	if (value) {
		let floatValue;
		if (value.startsWith('=')) {
			let evaledValue;
			try {
				evaledValue = eval(value.substring(1));
			} catch {}
			if (evaledValue) {
				floatValue = parseFloat(evaledValue);
			} else {
				floatValue = NaN;
			}
		} else {
			floatValue = parseFloat(value);
		}
		if (!isNaN(floatValue)) {
			return precisionRound(floatValue, 4);
		}
	}
	return null;
}

export function UID(): string {
	return UUID.UUID().replace(new RegExp('-', 'g'), '');
}

export function DateToInt(date: Date): number {
	if (date) {
		return date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
	}
	return null;
}

function kendoLogicOperator(operator: string): string {
	/*if(operator === 'or'){
		return ' OR ';
	}*/
	return ' AND ';
}
function kendoFilterOperatorNValue(filter: FilterDescriptor): string {
	let filterValue: any = filter.value;
	if (Object.prototype.toString.call(filterValue) === '[object Date]') {
		filterValue = DateToInt(filterValue);
	}

	switch (<string>filter.operator) {
		case 'neq':
			return '[]:' + filterValue;
		case 'contains':
			return '[like]:' + filterValue;
		case 'doesnotcontain':
			return '[]:';
		case 'startswith':
			return '[sw]:' + filterValue;
		case 'endswith':
			return '[]:' + filterValue;
		case 'isnull':
			return '[]:';
		case 'isnotnull':
			return '[]:';
		case 'isempty':
			return '[]:';
		case 'isnotempty':
			return '[]:';
		case 'gte':
			return '[gte]:' + filterValue;
		case 'gt':
			return '[gt]:' + filterValue;
		case 'lte':
			return '[lte]:' + filterValue;
		case 'lt':
			return '[lt]:' + filterValue;
		default:
			return '[eq]:' + filterValue;
	}
}

function kendoFilter(filter: FilterDescriptor, fieldResolver: (fieldName: any) => any): string {
	const field = fieldResolver(filter.field);
	if (Array.isArray(field)) {
		let fields = field,
			separator = '',
			result = '';
		fields.forEach((field) => {
			result += separator + field + kendoFilterOperatorNValue(filter);
			separator = ' OR ';
		});
		return '(' + result + ')';
	}
	return fieldResolver(filter.field) + kendoFilterOperatorNValue(filter);
}

export function flattenCompositeFilterDescriptor(rootFilter: CompositeFilterDescriptor, fieldResolver?: (fieldName: any) => any): string {
	if (rootFilter) {
		const filters = rootFilter.filters;
		fieldResolver = fieldResolver || ((fieldName: string) => fieldName);
		if (filters) {
			let result = '';
			let separator = '';
			filters.forEach((filter: any) => {
				if (filter.filters) {
					result += separator + flattenCompositeFilterDescriptor(filter, fieldResolver);
				} else {
					result += separator + kendoFilter(filter, fieldResolver);
				}
				separator = kendoLogicOperator(rootFilter.logic);
			});
			return result;
		}
	}
	return null;
}

export function visitFilter(rootFilter: CompositeFilterDescriptor, action: (filter: FilterDescriptor, parent: CompositeFilterDescriptor, idx: number) => void): void {
	if (rootFilter) {
		const filters = rootFilter.filters;
		if (filters) {
			filters.forEach((filter: any, index: number) => {
				if (filter.filters) {
					visitFilter(filter, action);
				} else {
					action(filter, rootFilter, index);
				}
			});
		}
	}
}

export function flattenSortDescriptor(sort: SortDescriptor[], fieldResolver?: (fieldName: string) => string): string {
	let result: string = '';
	let separator: string = '';
	fieldResolver = fieldResolver || ((fieldName: string) => fieldName);
	if (sort && sort.length) {
		sort.forEach((sortDesc: SortDescriptor) => {
			if (sortDesc.dir) {
				result += separator + fieldResolver(sortDesc.field) + (sortDesc.dir === 'desc' ? ' desc' : '');
				separator = ' AND ';
			}
		});
	}
	return result;
}

export function monthDiff(d1: Date, d2: Date): number {
	let months;
	months = (d1.getFullYear() - d2.getFullYear()) * 12;
	months -= d2.getMonth();
	months += d1.getMonth();
	return months <= 0 ? 0 : months;
}
export function daysDiff(d1: Date, d2: Date): number {
	const difference = d1.getTime() - d2.getTime();
	const days = Math.floor(difference / (1000 * 3600 * 24)); //7.23=>7 days as 7.99
	return days;
}

export function dateToInt(value: Date): number {
	if (value && value.getTime && !isNaN(value.getTime())) {
		return value.getFullYear() * 10000 + (value.getMonth() + 1) * 100 + value.getDate();
	}
	return null;
}
export function dateToYYYYMM(value: Date): number {
	if (value && value.getTime && !isNaN(value.getTime())) {
		return value.getFullYear() * 100 + (value.getMonth() + 1);
	}
	return null;
}
export function dateToYYYYMM01(value: Date): number {
	if (value && value.getTime && !isNaN(value.getTime())) {
		return value.getFullYear() * 10000 + (value.getMonth() + 1) * 100 + 1;
	}
	return null;
}
export function dateToYYYYMMDD(value: Date): number {
	if (value && value.getTime && !isNaN(value.getTime())) {
		return value.getFullYear() * 10000 + (value.getMonth() + 1) * 100 + value.getDate();
	}
	return null;
}
export function YYYYMMToDate(yearMonth: number): Date {
	const month = yearMonth % 100;
	const year = (yearMonth - month) / 100;
	return new Date(year, month - 1, 1);
}

export function intYYYYMMToString(yyyymm: number, locale: string): string {
	const date = intToDate(yyyymm * 100 + 1);
	const month = date.toLocaleDateString(locale, { month: 'long' });
	const uMonth = month.charAt(0).toUpperCase() + month.slice(1); /*- aprilie -> Aprilie */
	return uMonth + ' ' + date.getFullYear().toString();
}
export function intYYYYMMDDToString(yyyymmdd: number, locale: string): string {
	const date = intToDate(yyyymmdd);
	const month = date.toLocaleDateString(locale, { month: 'long' });
	const uMonth = month.charAt(0).toUpperCase() + month.slice(1); /*- aprilie -> Aprilie */
	const day = date.toLocaleDateString(locale, { day: '2-digit' });
	return day + ' ' + uMonth + ' ' + date.getFullYear().toString();
}
export function intYYYYMMDDToSimpleString(yyyymmdd: number): string {
	const nf = new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2
	});
	const date = intToDate(yyyymmdd);
	return date.getFullYear().toString() + '.' + nf.format(date.getMonth() + 1) + '.' + nf.format(date.getDate());
}
export function intYYYYMMDDToSimpleMMYYYYString(yyyymmdd: number): string {
  const str=intYYYYMMDDToSimpleString(yyyymmdd);
	return str.substring(0,7);
}

export function dateToDDMMMMYYYYHHmmString(date: Date, locale: string): string {
	const ret = intYYYYMMDDToString(dateToYYYYMMDD(date), locale) + ' ' + date.toLocaleTimeString('ro-ro', { hour: '2-digit', minute: '2-digit' });
	return ret;
}

export function addMonthsToYYYYYMM(yyyymm: number, monthsToAdd: number): number {
	let date = intToDate(yyyymm * 100 + 1);
	/* see addMonths*/
	date = addMonths(date, monthsToAdd);
	//date.setMonth(date.getMonth()+monthsToAdd);
	return dateToYYYYMM(date);
}

export const addMonths = (date: Date, numMonthsToAdd: number): Date => {
	const ret = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	ret.setMonth(date.getMonth() + numMonthsToAdd);
	if (ret.getDate() !== date.getDate()) {
		/*2022.05.31 ->crtDate.setMonth(crtDate.getMonth() + 1); will lead to 1 of july instead of 30th of june*/
		const computedMonth = ret.getMonth();
		while (ret.getMonth() === computedMonth) {
			ret.setDate(ret.getDate() - 1);
		}
	}
	return ret;
};

export function intToDate(value: number): Date {
	if (value) {
		const day = value % 100;
		const monthYear = (value - day) / 100;
		const month = (monthYear % 100) - 1;
		const year = (monthYear - month - 1) / 100;
		return new Date(year, month, day);
	}
	return null;
}

export function getCurrentAccountingMonth(): Date {
	const ret = new Date();
	let refDay = 25;
	/* let refDay = 31; */
	if (ret.getMonth() === 11) {
		refDay = 21;
	}
	if (ret.getDate() <= refDay) {
		ret.setMonth(ret.getMonth() - 1);
	}
	return ret;
}
export function getCurrentHRMonth(): Date {
	const ret = new Date();
	let refDay = 24;
	if (ret.getMonth() === 11) {
		refDay = 21;
	}
	if (ret.getDate() <= refDay) {
		ret.setMonth(ret.getMonth() - 1);
	}
	return ret;
}
export const intDateToString = (intDate: number): string => {
	if ((intDate ?? 0) === 0) {
		return '';
	}
	/* due to Polaris...*/
	const smonth = (intDate ?? 0).toString();
	return smonth.substring(0, 4) + '.' + smonth.substring(4, 6) + '.' + smonth.substring(6, 8);
};
export const intMonthToString = (intMonth: number): string => {
	if ((intMonth ?? 0) === 0) {
		return '';
	}
	/* due to Polaris...*/
	const smonth = (intMonth ?? 0).toString();
	return smonth.substring(0, 4) + '.' + smonth.substring(4, 6);
};

export function isDefined(value: any) {
	return typeof value !== 'undefined' && value !== void 0 && value !== null;
}

export function isString(value: any) {
	return typeof value === 'string';
}

export function formatText(text: string, params: any) {
	if (text) {
		return text.replace(parametersRegexp, function (match: string) {
			return params[match.substring(1)];
		});
	}
	return text;
}

export function formatTextParams(text: string) {
	if (text) {
		return text.match(parametersRegexp).map((match: string) => match.substring(1));
	}
	return [];
}
export function cloneDeep(source: any): any {
	return lodash.cloneDeep(source);
}
/* https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574 */
/* should be encapsulated in a class to have this initialized, see original implementation */
// export function deepCopyObsolete<T>(source: T | any): T {
//   return Array.isArray(source)
//     ? source.map((item) => this.deepCopy(item))
//     : source instanceof Date
//     ? new Date(source.getTime())
//     : source && typeof source === "object"
//     ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
//         Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
//         o[prop] = this.deepCopy(source[prop]);
//         return o;
//       }, Object.create(Object.getPrototypeOf(source)))
//     : (source as T);
// }

export function buildQueryParams(source: any): HttpParams {
	let target: HttpParams = new HttpParams();
	Object.keys(source).forEach((key) => {
		let value: any = source[key];
		if (lodash.isNil(value)) {
			return;
		}
		if (lodash.isPlainObject(value)) {
			value = JSON.stringify(value);
		} else {
			value = value.toString();
		}
		target = target.append(key, value);
	});
	return target;
}
export function removeProperty(obj: any, toDelete: string): void {
	for (const prop in obj) {
		if (prop === toDelete) {
			delete obj[prop];
			return;
		}
	}
}
export function removePropertyRecursively(obj: any, toDelete: string): void {
	for (const prop in obj) {
		if (prop === toDelete) {
			delete obj[prop];
		} else {
			if (typeof obj[prop] === 'object') {
				removePropertyRecursively(obj[prop], toDelete);
			}
		}
	}
}
export function removeTypename(obj: any): void {
	removePropertyRecursively(obj, '__typename');
}

export function removeAccents(value: string): string {
	if (value) {
		value = value.normalize('NFD').replace(/\p{Emoji_Presentation}/gu, '');
		value = value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
	}

	return value;
}

export const isNullOrUndefined = (object: any): boolean => (object ?? null) === null;
/*export function toHttpParams(obj: any): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const val = obj[key];
            if (val !== null && val !== undefined) {
                params = params.append(key, val.toString());
            }
        }
    }
    return params;
}*/

export function getFileIcon(fileName: string) {
	let fileExtension = fileName.split('.').pop().toLowerCase();
	let documentClass;
	switch (fileExtension) {
		case 'pdf':
			documentClass = 'pdf-document';
			break;
		case 'doc':
			documentClass = 'word-document';
			break;
		case 'docx':
			documentClass = 'word-document';
			break;
		case 'xls':
			documentClass = 'excel-document';
			break;
		case 'xlsx':
			documentClass = 'excel-document';
			break;
		case 'csv':
			documentClass = 'excel-document';
			break;
		default:
			documentClass = 'general-document';
	}

	return documentClass;
}

export function markdown(message: string): string {
    const regex = /\n \n/ig;
    var newmessage = message.replace(regex, '\n\n');
    const env = {};
    const md = new MarkdownIt({
      html: false,
      breaks: false,
      linkify: true,
      typographer: true,
    });
    const tokens = md.parse(newmessage, env);

    const processLinks = (token: any): void => {
      if (token.type === 'link_open') {
        token.attrs.push(['target', '_blank']);
      }
      if (token.children) {
        token.children.forEach(processLinks);
      }
    };

    tokens.forEach(processLinks);

    return md.renderer.render(tokens, md.options, env);
}

export function removeWhiteCharacters(value: string): string {
	return value.replace(/\s/g, "");
}

function cnpAndKeyDigitsSum(cnp: string): number {
	let key = "279146358279";

	let sum = 0;
	for (var i = 0; i < cnp.length - 1; i++) {
		var a = +cnp[i];
		var b = +key[i];
		sum = sum + (a * b);
	}
	return sum;
}

export function isCNPValid(cnp: string) {
	let valid = false;
	let control: number;

	let rest: number = cnpAndKeyDigitsSum(cnp) % 11;
	control = rest < 10 ? rest : 1;

	if (cnp.length == 13) {
		let controlDigit = +cnp[12];
		valid = control == controlDigit;
	}
	return valid;
}

export function formatPhoneNo(phoneNo: string): string {
	let phoneNoValue = phoneNo.trim();
	
	if (phoneNoValue.length >= 10 && phoneNoValue.length <= 13 && (new RegExp('^\\d+$').test(phoneNoValue) || new RegExp('^[+]\\d+$').test(phoneNoValue))) {
		let groupNo3 = phoneNoValue.substring(phoneNoValue.length - 3, phoneNoValue.length);
		let groupNo2 = phoneNoValue.substring(phoneNoValue.length - 6, phoneNoValue.length - 3);
		let groupNo1 = phoneNoValue.substring(phoneNoValue.length - 9, phoneNoValue.length - 6);
		let groupNo0 = phoneNoValue.substring(phoneNoValue.length - 12, phoneNoValue.length - 9);

		if (groupNo0.length == 1) {
			groupNo1 = `${groupNo0}${groupNo1}`;

			return `${groupNo1} ${groupNo2} ${groupNo3}`;
		}
		else if (groupNo0.length == 3) {
			let groupNo_1 = phoneNoValue.substring(phoneNoValue.length - 15, phoneNoValue.length - 12);
			if (groupNo_1.length == 1) {
				groupNo0 = `${groupNo_1}${groupNo0}`;
			}

			return `${groupNo0} ${groupNo1} ${groupNo2} ${groupNo3}`;
		}

		return phoneNoValue;
	}

	return phoneNo;
}

export function isEmailValid(value: string) {
	let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

	if (value != "" && !EMAIL_REGEXP.test(value)) {
		return false;
	}

	return true;
}