import * as Types from '../../../graphql';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ClientDocumentsDataQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter: Types.Scalars['String'];
}>;


export type ClientDocumentsDataQuery = { __typename?: 'Query', documents?: { __typename?: 'documents', GetClientDocumentsUsingGET?: Array<{ __typename?: 'documents_Document', eid?: string | null, clientEid?: string | null, costCenter?: string | null, documentDate?: number | null, metadata?: string | null, totalPages?: number | null, status?: { __typename?: 'documents_FinancialDocumentStatus', statusCode?: string | null, dateTime?: string | null } | null, pages?: Array<{ __typename?: 'documents_DocumentPage', pageEid?: string | null, visible?: boolean | null }> | null, comments?: Array<{ __typename?: 'documents_DocumentComment', dateTime?: string | null, comment?: string | null }> | null, created?: { __typename?: 'documents_EventOccurenceData', dateTime?: string | null, userEid?: string | null, userName?: string | null } | null }> | null } | null };

export type PayrollDocumentsDataQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter: Types.Scalars['String'];
}>;


export type PayrollDocumentsDataQuery = { __typename?: 'Query', documents?: { __typename?: 'documents', GetPayrollDocumentsUsingGET?: Array<{ __typename?: 'documents_PayrollDocumentRepresentation', eid?: string | null, clientEid?: string | null, documentDate?: number | null, metadata?: string | null, totalPages?: number | null, status?: { __typename?: 'documents_FinancialDocumentStatus', statusCode?: string | null, dateTime?: string | null } | null, pages?: Array<{ __typename?: 'documents_DocumentPage', pageEid?: string | null, visible?: boolean | null }> | null, comments?: Array<{ __typename?: 'documents_DocumentComment', dateTime?: string | null, comment?: string | null }> | null, created?: { __typename?: 'documents_EventOccurenceData', dateTime?: string | null, userEid?: string | null, userName?: string | null } | null }> | null } | null };

export type FinancialDocumentsDataQueryVariables = Types.Exact<{
  filter: Types.Scalars['String'];
}>;


export type FinancialDocumentsDataQuery = { __typename?: 'Query', subs?: { __typename?: 'subs', GetClientDocumentsUsingGET?: Array<{ __typename?: 'subs_Document', documentEid?: string | null, digitizationOutcome?: string | null, documentDate?: number | null, documentDateOutcome?: number | null, clientEid?: string | null, clientName?: string | null, metadata?: string | null, status?: { __typename?: 'subs_Status', statusCode?: string | null, comment?: string | null, dateTime?: string | null } | null, created?: { __typename?: 'subs_EventOccurenceData', dateTime?: string | null, userName?: string | null } | null, comments?: Array<{ __typename?: 'subs_Comment', comment?: string | null, moment?: { __typename?: 'subs_EventOccurenceData', dateTime?: string | null, userName?: string | null } | null }> | null }> | null } | null };

export const ClientDocumentsDataDocument = gql`
    query clientDocumentsData($clientEid: String!, $filter: String!) {
  documents {
    GetClientDocumentsUsingGET(clientEid: $clientEid, filter: $filter) {
      eid
      clientEid
      costCenter
      documentDate
      status {
        statusCode
        dateTime
      }
      metadata
      pages {
        pageEid
        visible
      }
      totalPages
      comments {
        dateTime
        comment
      }
      created {
        dateTime
        userEid
        userName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientDocumentsDataGQL extends Apollo.Query<ClientDocumentsDataQuery, ClientDocumentsDataQueryVariables> {
    document = ClientDocumentsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayrollDocumentsDataDocument = gql`
    query payrollDocumentsData($clientEid: String!, $filter: String!) {
  documents {
    GetPayrollDocumentsUsingGET(clientEid: $clientEid, filter: $filter) {
      eid
      clientEid
      documentDate
      status {
        statusCode
        dateTime
      }
      metadata
      pages {
        pageEid
        visible
      }
      totalPages
      comments {
        dateTime
        comment
      }
      created {
        dateTime
        userEid
        userName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayrollDocumentsDataGQL extends Apollo.Query<PayrollDocumentsDataQuery, PayrollDocumentsDataQueryVariables> {
    document = PayrollDocumentsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinancialDocumentsDataDocument = gql`
    query financialDocumentsData($filter: String!) {
  subs {
    GetClientDocumentsUsingGET(filter: $filter) {
      documentEid
      digitizationOutcome
      documentDate
      documentDateOutcome
      clientEid
      clientName
      status {
        statusCode
        comment
        dateTime
      }
      metadata
      created {
        dateTime
        userName
      }
      comments {
        comment
        moment {
          dateTime
          userName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialDocumentsDataGQL extends Apollo.Query<FinancialDocumentsDataQuery, FinancialDocumentsDataQueryVariables> {
    document = FinancialDocumentsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }