import { Component, Input, OnInit, OnDestroy, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription, } from 'rxjs';
import { filter, } from 'rxjs/operators';

import { SinglePopupService } from '@progress/kendo-angular-grid';

import { Control } from '../../../control';
import { CalendarView, DateInputFormatPlaceholder } from '@progress/kendo-angular-dateinputs';

export const DATEPICKER_FILTER_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeDatePickerFilterComponent),
	multi: true
};

@Component({
	selector: 'kee-datepicker-filter',
	templateUrl: 'datepicker-filter.component.html',
	providers: [DATEPICKER_FILTER_VALUE_ACCESSOR],
})
export class KeeDatePickerFilterComponent extends Control<Date> implements OnInit, OnDestroy {

	private subscription: Subscription;

	//#region properties

	private _disabled: boolean = false;
	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value) {
		this._disabled = this.coerceBooleanProperty(value);
	}


	//#region placeholder

	@Input() public formatPlaceholder: DateInputFormatPlaceholder;

	public _placeholder: string = null;
	@Input() get placeholder(): string {
		return this._placeholder == null ? " " : this._placeholder;
	}
	set placeholder(value) {
		this._placeholder = value;
	}

	//#endregion

	//#region format

	@Input() public format: string;

	//#endregion


	private _activeView: CalendarView;
	@Input() get activeView(): CalendarView {
		return this._activeView || 'month';
	}
	set activeView(value) {
		if (['month', 'year', 'decade', 'century'].indexOf(value) !== -1) {
			this._activeView = value;
		}
		else {
			this._activeView = 'month';
		}
	}

	private _bottomView: CalendarView;
	@Input() public get bottomView(): CalendarView {
		return this._bottomView || 'month';
	}
	set bottomView(value) {
		if (['month', 'year', 'decade', 'century'].indexOf(value) !== -1) {
			this._bottomView = value;
		}
		else {
			this._bottomView = 'month';
		}
	}

	@Input() public topView: CalendarView;
	@Input() public weekNumber: boolean;

	@Input() public min: Date;
	@Input() public max: Date;

	@Input() public ngModelOptions: any = { updateOn: 'change' };

	//#endregion

	//#region constructor

	public constructor(injector: Injector, private popupService: SinglePopupService, ) {
		super(injector);
	}

	//#endregion

	//#region methods

	public ngOnInit() {
		super.ngOnInit();
	}

	public open(picker: any) {
		this.subscription = this.popupService.onClose
			.pipe(filter(function () { return picker.isActive; }))
			.subscribe(function (e: any) { return e.preventDefault(); });
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	public _handleChange(value: Date) {
	}

	//#endregion
}