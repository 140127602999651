import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, HomeGuard } from '@saliente/common-auth';
import { AppHomeComponent } from './app-home/app-home.component';

import { COMMON_ROUTES } from './common.routes.constants';

const routes: Routes = [
	{
		path:'home',
		canActivate: [HomeGuard, /*AuthGuard*/],
		component: AppHomeComponent
	},
	{
		// path: COMMON_ROUTES.CONTACT_PAGE.substring(1),
		path: 'contact',
		loadChildren: () => import('./masterview/contact-page.module').then(m => m.ContactPageModule)
		// {
		// 	const modules: any = require('./masterview/contact-page.module');
		// 	return modules.ContactPageModule;
		// }
	},
	{
		path: COMMON_ROUTES.ERROR_500_1,
		loadChildren: () => import('./general/page-500.module').then(m => m.Page500Module)
		// {
		// 	const modules: any = require('./general/page-500.module');
		// 	return modules.Page500Module;
		// }
	},
	{
		path: COMMON_ROUTES.ERROR_UNIX_TIME_1,
		loadChildren: () => import('./general/page-unix-time-error.module').then(m => m.PageUnixTimeErrorModule)
		// {
		// 	const modules: any = require('./general/page-unix-time-error.module');
		// 	return modules.PageUnixTimeErrorModule;
		// }
	},
	{
		path: COMMON_ROUTES.MEETING_1,
		loadChildren: () => import('./notifications/meetings/meetings.module').then(m => m.MeetingsModule)
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
	exports: [RouterModule]
})
export class CommonRouterModule { }
