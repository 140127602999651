import { Type, Expose, Exclude } from 'class-transformer';
import { YYYYMMToDate, dateToInt, dateToYYYYMM, intToDate, isNullOrUndefined } from '@saliente/library';
import { AccountingPeriodModel, AccountingFiscalSheetModel } from '@saliente/common-accounting';
import {
	Accounts_SuperExpertProgressManagementDashboardRepresentation,
	Accounts_SuperExpertProgressManagementRepresentation,
} from 'src/app/common/services/super-expert-progress-management.services';

export function expertDocumentsSummaryDateTypeProvider(): DateConstructor {
	return Date;
}
export class ExpertDocumentsSummaryModel {
	@Expose({ name: 'externalId' })
	public clientEid: string;

	public fiscalNumber: string;
	public expertComments: string;

	@Expose({ name: 'serviceStartDate' })
	public serviceStartDateInt: number;
	@Exclude()
	protected _serviceStartDateEx: Date;
	@Exclude()
	get serviceStartDateEx(): Date {
		if (!this._serviceStartDateEx) {
			this._serviceStartDateEx = intToDate(this.serviceStartDateInt);
		}
		return this._serviceStartDateEx;
	}
	set serviceStartDateEx(value: Date) {
		if (this._serviceStartDateEx !== value) {
			this._serviceStartDateEx = value;
			this.serviceStartDateInt = dateToInt(this._serviceStartDateEx);
		}
	}

	public caenCode: string;
	public caenDescription: string;

	// @Type(StatutoryReportsModel.typeProvider)
	// public statutoryReports: StatutoryReportsModel;

	@Type(AccountingFiscalSheetModel.typeProvider)
	public fiscalSheet: AccountingFiscalSheetModel;

	public accuracySummary: any[];
	public accountingPeriod: AccountingPeriodModel;
	public summary: any[];
	public superExpertProgressManagementDasboard: Accounts_SuperExpertProgressManagementDashboardRepresentation;
	public superExpertProgressManagement: Accounts_SuperExpertProgressManagementRepresentation;
	public profitMicroStartMonthAsDate: Date;
	public profitMicroEditEnabled = false;
	/*- at cancel restore from these values*/
	public profitMicroStartMonthAsDateMem: Date;
	public profitMicroCodeMem: string;

	public hasOSS: boolean;
	public hasOSSStartingWithDate: number;
	public hasOSSStartingWithDateAsDate: Date;
	public hasOSSEditEnabled = false;
	/*- at cancel restore these values*/
	public hasOSSMem: boolean;

	public initialize(): void {
    this.hasOSSStartingWithDateAsDate = isNullOrUndefined(this.hasOSSStartingWithDate) ? null : intToDate(this.hasOSSStartingWithDate);
  }

	public update(superExpertProgressManagementDasboard: Accounts_SuperExpertProgressManagementDashboardRepresentation): void {
		this.superExpertProgressManagementDasboard = superExpertProgressManagementDasboard;
		this.superExpertProgressManagement = superExpertProgressManagementDasboard.clients[0];
		this.profitMicroStartMonthAsDate = new Date();
		if ((this.superExpertProgressManagement.profitMicroStartMonth ?? null) !== null) {
			this.profitMicroStartMonthAsDate = YYYYMMToDate(this.superExpertProgressManagement.profitMicroStartMonth);
		}
	}
	onStartEditMicroProfit(): void {
		this.profitMicroStartMonthAsDateMem = this.profitMicroStartMonthAsDate;
		this.profitMicroCodeMem = this.superExpertProgressManagement.profitMicroCode;
		this.profitMicroEditEnabled = true;
	}
	onCancelEditMicroProfit(): void {
		this.profitMicroStartMonthAsDate = this.profitMicroStartMonthAsDateMem;
		this.superExpertProgressManagement.profitMicroCode = this.profitMicroCodeMem;
		this.disableProfitMicroEdit();
	}
	disableProfitMicroEdit(): void {
		this.profitMicroEditEnabled = false;
	}
	prepareForSaveMicroProfit(): string {
		/*-    if(![0,3,6,9].includes( this.profitMicroStartMonthAsDate.getMonth())){
      return 'Trebuie selectat un inceput de trimestru';
    }
 */
		this.superExpertProgressManagement.profitMicroStartMonth = dateToYYYYMM(this.profitMicroStartMonthAsDate);
		return null;
	}
	onStartEditHasOSS(): void {
		this.hasOSSMem = this.hasOSS;
		this.hasOSSStartingWithDateAsDate = isNullOrUndefined(this.hasOSSStartingWithDate) ? new Date() : intToDate(this.hasOSSStartingWithDate);
		this.hasOSSEditEnabled = true;
	}
	prepareForSaveHasOSS(): void {
		this.hasOSSStartingWithDate = this.hasOSS ? dateToInt(this.hasOSSStartingWithDateAsDate) : null;
		this.hasOSSStartingWithDateAsDate = this.hasOSS ? this.hasOSSStartingWithDateAsDate : null;
	}
	onCancelEditHasOSS(): void {
		this.hasOSS = this.hasOSSMem;
		this.hasOSSStartingWithDateAsDate = isNullOrUndefined(this.hasOSSStartingWithDate) ? null : intToDate(this.hasOSSStartingWithDate);
		this.disableHasOSSEdit();
	}
	disableHasOSSEdit(): void {
		this.hasOSSEditEnabled = false;
	}
}
