<section class="row m-0">
	<form #changeEmployeeForm="ngForm" class="d-flex flex-wrap col-24 px-0">
		<kee-combobox name="employeeEid" class="col" i18n-label="@@ClientDocument$Employee" label="Angajat" [data]="employees" textField="fullName"
            valueField="employeeExternalId" [valuePrimitive]="false" [(ngModel)]="selectedEmployee" required>
			<ng-template kendoComboBoxItemTemplate let-dataItem>
				<div class="col-24 d-flex flex-wrap px-0">
					<div class="col px-0 {{dataItem.isActive ? '' : 'grey-text'}}">{{ dataItem.fullName }}</div>
					<div class="col-auto px-0 {{dataItem.isActive ? '' : 'grey-text'}}">{{ dataItem.employeePIN }}</div>
				</div>
			</ng-template>
        </kee-combobox>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="saveData(changeEmployeeForm)">
				<i class="mdi-image-edit mr-2"></i>
				<span i18n="@@Actions$Delete">Schimbă</span>
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" (click)="this.close.emit()">
				<i class="mdi-content-clear mr-2"></i>
				<span i18n="@@Actions$Cancel">Renunță</span>
			</button>
		</div>
	</form>
</section>