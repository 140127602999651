

import { LocalizationService } from '@progress/kendo-angular-l10n';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

var insertDefaultFilter = function (index: any, rootFilter: any, filter: any) {
	rootFilter = (rootFilter || { filters: [], logic: "and" });
	rootFilter.filters[index] = filter;
	return filter;
};

/**
 * @hidden
 */
export var isPresent = function (value: any) { return value !== null && value !== undefined; };
/**
 * @hidden
 */
export var setFilter = function (index: any, filter: any, field: any, defaultOperator: any): FilterDescriptor {
	if (isPresent(filter) && isPresent(filter.filters) && filter.filters.length > index) {
		return filter.filters[index];
	}
	else {
		return insertDefaultFilter(index, filter, {
			field: field,
			operator: defaultOperator
		});
	}
};
/**
 * @hidden
 */
export const logicOperators = function (localization: LocalizationService): { text: string; value: "or" | "and"; }[] {
	return [
		{ text: localization.get("filterAndLogic"), value: "and" },
		{ text: localization.get("filterOrLogic"), value: "or" }
	];
};
/**
 * @hidden
 */
export var localizeOperators = function (operators: any): (localization: any) => { text: any; value: any; }[] {
	return function (localization) {
		return Object.keys(operators).map(function (key) {
			return ({
				text: localization.get(key),
				value: operators[key]
			});
		});
	};
};
