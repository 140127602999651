import './polyfills';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

// enableProdMode();
const ocw = console.warn;
console.warn = (...data: any[]): void => {
	if (data && data.length === 1 && typeof data[0] === 'string' && (data[0] as string).startsWith('License activation failed for @progress/')) {
		return;
	}
	ocw(data);
};
const ocg = console.group;
console.group = (...data: any[]): void => {
	if (data && data.length === 1 && typeof data[0] === 'string' && (data[0] as string).startsWith('Progress Kendo UI for Angular')) {
		return;
	}
	ocg(data);
};

platformBrowserDynamic().bootstrapModule(AppModule);
