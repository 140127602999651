import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { IDocumentsService, PersonForAccountingModel } from "@saliente/common-documents";
import { Subscription } from "rxjs";
import { DocumentsCommomService } from "./documents-common.service";

@Component({
	selector: 'document-change-employee',
	templateUrl: 'document-change-employee.component.html'
})
export class DocumentChangeEmployeeDialogComponent implements OnDestroy {
    @Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	private employeesListSubscription: Subscription;
	public employees: PersonForAccountingModel[] = [];
	public selectedEmployee: PersonForAccountingModel;
	
	
	constructor(documentsCommomService: DocumentsCommomService) {
		this.employeesListSubscription = documentsCommomService
			.getEmployees()
			.subscribe((data) => {
				this.employees = data;
			});
	}

	ngOnDestroy(): void {
		if (this.employeesListSubscription) { this.employeesListSubscription.unsubscribe(); this.employeesListSubscription = null; }
	}

	public saveData(changeEmployeeForm: NgForm) {
		this.save.emit({changeEmployeeForm: changeEmployeeForm, employee: this.selectedEmployee});
	}
}