
export enum DocumentTypes {
	CONTRACT = 'Contract',
	IDENTIFICATION = 'ID',
	REGISTRATION = 'REGISTRATION',
	INCORPORATION = 'Incorporation',
	SPECIAL_VAT = 'SpecialVAT',
}

export enum DocumentFileStatus {
	Received = 'Received',
	QueuedForDigitization = 'QueuedForDigitization',
	Digitized = 'Digitized',
	QueuedForVerification = 'QueuedForVerification',
	Verified = 'Verified',
	NotUsable = 'NotUsable',
	SentToExpert = 'SentToExpert',
	Deleted = 'Deleted',
	MarkedForDeletion = 'MarkedForDeletion',
	Cancelled = 'Cancelled',
	MarkedForCancellation = 'MarkedForCancellation',
	Booked = 'Booked',

	//client internal status
	UnBooked = 'UnBooked',
	NotPayroll = 'NotPayroll',
	NotRevisal = 'NotRevisal'

}

export enum DocumentDigitizationOutcome {
	OK = 'OK',
	NotUsable = 'NotUsable',
	SendToExpert = 'SendToExpert'
}

export enum FinancialDocumentEntryType {
	SaleInvoice = 'SaleInvoice', 																//FV
	PurchaseInvoice = 'BuyInvoice', 															//FA
	AutoInvoice = 'AutoInvoice', 																//FAAU
	SaleFiscalReceipt = 'SaleFiscalReceipt',													//BFV
	PurchaseFiscalReceipt = 'PurchaseFiscalReceipt',											//BFA
	GasReceipt = 'GasReceipt',																	//BCM
	AutoReceipt = 'AutoReceipt',																//BAU
	CashReceipt = 'CashReceipt',																//CC
	CashIssued = 'CashIssued',																	//CE
	CashIssuedSales = 'CashIssuedSales',																	//CEFV
	ExpenseAccountReceipt = 'ExpenseAccountReceipt',											//CD
	PurchaseFiscalReceiptWithoutTIN = 'PurchaseFiscalReceiptWithoutTIN',						//BFAC
	ProtocolFiscalReceipt = 'ProtocolFiscalReceipt',											//BFP
	CashOrder = 'CashOrder',																	//DP
	CollectionOrder = 'CollectionOrder',														//DI
	StatementOfAccount = 'StatementOfAccount',													//EC
}

export enum FinancialDocumentType {
	Invoice = 'Invoice',
	Receipt = 'Receipt',
	StatementOfAccount = 'StatementOfAccount'
}

export enum FinancialDocumentPaymentType {
	Cash = 'Cash ',
	Card = 'Card ',
	Bank = 'Bank',
	Receipt = 'Receipt'
}

export enum RevisalStatus {
	Unanalyzed = 'Unanalyzed',
	NoRevisal = 'NoRevisal',
	RequiresRevisal = 'RequiresRevisal',
	SubmittedToRevisal = 'SubmittedToRevisal',
	NotUsable = 'NotUsable'
}