import { Type, Expose, Exclude, classToClass, } from 'class-transformer';
import { RowClassArgs, } from '@progress/kendo-angular-grid';

import { UID } from '@saliente/library';


class ImportStatisticsInfo {
    public info: string;
    public value: number;
}

class ImportBaseModel {
    @Expose({ name: 'import_eid' })
    public importEid: string;

    @Expose({ name: 'current_step' })
    public currentStep: string;
    @Expose({ name: 'current_step_code' })
    public currentStepCode: string;
    @Expose({ name: 'current_step_doc_link' })
    public currentStepDocLink: string;

    @Expose({ name: 'data_change_type' })
    public dataChangeType: string;
    @Expose({ name: 'explanation' })
    public explanation: string;
    @Expose({ name: 'extra_data' })
    public extraData: any[];

    @Expose({ name: 'import_doc_link' })
    public importDocLink: string;
    @Expose({ name: 'import_type_code' })
    public importTypeCode: string;
    @Expose({ name: 'import_type_documentation' })
    public importTypeDocumentation: string;
    @Expose({ name: 'import_type_name' })
    public importTypeName: string;

    @Expose({ name: 'is_runnable' })
    public isRunnable: boolean;


    @Expose({ name: 'last_modified_by_user_eid' })
    public lastModifiedByUserEid: string;
    @Expose({ name: 'last_modified_by_user_name' })
    public lastModifiedByUserName: string;
    @Expose({ name: 'last_modified_date_time' })
    @Type(() => Date)
    public lastModifiedDateTime: Date;


    @Expose({ name: 'started_by_user_eid' })
    public startedByUserEid: string;
    @Expose({ name: 'started_by_user_name' })
    public startedByUserName: string;
    @Expose({ name: 'started_date_time' })
    @Type(() => Date)
    public startedDateTime: Date;

    @Expose({ name: 'status' })
    public status: string;
    @Expose({ name: 'step_documentation' })
    public stepDocumentation: string;

    @Expose({ name: 'source_display_name' })
    public sourceDisplayName: string;
    @Expose({ name: 'support_document_eid' })
    public supportDocumentEid: string;
    @Expose({ name: 'support_file_eid' })
    public supportFileEid: string;

    public displayStatus: string;

    @Exclude()
    public summary: ImportStatisticsInfo[];
}

export class ImportListModel extends ImportBaseModel {
    
}

export class ImportsModel {
    public first: number;
    public last: number;
    public recordsCount: number;
    public data: ImportListModel[];
}

export class ImportColumnModel {
    public field: string;
    @Expose({ name: 'field_type' })
    public fieldType: string;
    public format: string;
    public header: string;
    public mandatory: boolean;
    public width: number;

    public get renderWidth(): number{
        if (this.width < 1)
            return 0;
        
        return this.width * 10;
    }
}

export class ImportHistoryModel {
    public info: string;
    public performed_dt: Date;
    public kind: string;

}

export class ImportTypeModel {
    public docLink: string;
    public importTypeEid: string;
    public importTypeName: string;
    public visible: boolean;
    public defaultVisibleRole: string;
}

export class ImportRowModel {
    public eid: string;
    public explanation: string;
    public order: number;
    public replacedByRowEid: string;
    public replacedReason: string;
    public rowData: string;
    public status: string;

    @Exclude()
    private _rowDataEx: any;
    @Exclude()
    public get rowDataEx() {
        if (!this._rowDataEx) {
            try {
                this._rowDataEx = JSON.parse(this.rowData);
            } catch (err) {
                this._rowDataEx = {};
            }
        }
        return this._rowDataEx;
    }
}

export class ImportModel extends ImportBaseModel {
    public columns: ImportColumnModel[] = [];
    public rows: ImportRowModel[] = [];
    @Exclude()
    public rowsCount: number = 0;
    @Exclude()
    public *visibleColumns() {
        for(let c of this.columns) {
            if (c.renderWidth > 0)
                yield c;
        }
    }

    public clone(): ImportModel {
		return classToClass(this, { ignoreDecorators: true, excludePrefixes: ['$$'] });
	}
    @Exclude()
    public history: ImportHistoryModel[];
}