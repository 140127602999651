import { forwardRef, Component, Input, Output, ViewChild, Injector, EventEmitter, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';
import { LabeledControl } from '../labeledcontrol';

import { Observable } from 'rxjs';

import $ from '../../jquery.plugins';
/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import Inputmask from 'inputmask';

let nextUniqueId = 0;

export const INPUT_FIELD_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputFieldComponent),
	multi: true
};

@Component({
	selector: 'inputfield',
	templateUrl: './inputfield.component.html',
	providers: [INPUT_FIELD_VALUE_ACCESSOR],
})
export class InputFieldComponent extends LabeledControl<string> implements OnInit, AfterViewInit {
	private inputEl: any;
	private isViewInitialized = false;
	@Input() public tabindex: number = null;
	@Input() public type: string = 'text';
	@Input() public name: string = null;
	// @Input() public placeholder: string = null;

	@Input() public ngModelOptions: any = { updateOn: 'change' };

	@Input() maxlength: string = null;

	private _format: string;
	@Input() get format() {
		return this._format;
	}
	set format(value) {
		if (this._format !== value) {
			this._format = value;
			if (this.isViewInitialized && this._format) {
				this.mask();
			}
		}
	}

	private _autofocus: boolean = false;
	@Input()
	get autofocus(): boolean {
		return this._autofocus;
	}

	set autofocus(value) {
		this._autofocus = this.coerceBooleanProperty(value);
	}



	private _spellcheck: boolean = false;
	@Input()
	get spellcheck(): boolean {
		return this._spellcheck;
	}

	set spellcheck(value) {
		this._spellcheck = this.coerceBooleanProperty(value);
	}

	@Input() public id: string = `field-input-${nextUniqueId++}`;
	get inputId() {
		return 'input' + this.id;
	}


	private _keyPressEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output('keyPress')
	get keyPress(): Observable<any> {
		return this._keyPressEmitter.asObservable();
	}

	/**
	 * Constructor
	 */
	constructor(private el: ElementRef, injector: Injector, ) {
		super(injector);
	}

	/**
	 * Implements onInit event handler.
	 */
	public ngOnInit() {
		//this.el.nativeElement.removeAttribute('name');
		//this.el.nativeElement.removeAttribute('class');
		super.ngOnInit();
	}

	public ngAfterViewInit() {
		this.inputEl = $(this.el.nativeElement).find('#' + this.inputId)[0];
		this.isViewInitialized = true;
		if (this._format) {
			this.mask();
		}
	}

	private mask() {
		Inputmask({
			autoUnmask: true,
			mask: this._format,
			placeholder: ' ',
			showMaskOnHover: false
		}).mask(this.inputEl);
	}

	public _handleKeyPress(event: any) {
		const args = { original: event, result: true };
		this._keyPressEmitter.emit(args);
		return args.result;
	}
}
