import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';

import { HrExpertRouterModule } from './hrexpert.routes';
import { HrExpertMenuService } from './menu/menu.service';

@NgModule({
	imports: [
		CommonModule, FormsModule,
		SalienteLibrarySharedModule, MasterViewModule, HrExpertRouterModule,
	],
	providers: [HrExpertMenuService]
})
export class HrExpertModule {
	constructor(private menuService: HrExpertMenuService) {
	}
}