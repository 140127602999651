import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ClientAccDocumentsService } from "src/app/clients/documents/acc-documents.service";
import { ExpertDocumentsService } from "src/app/expert/documents/expert-documents.service";
import { PredefinedUserZone } from "../auth/auth.models";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class DocumentStatisticsCommon {
    public statisticsDocsCount: BehaviorSubject<any>;

    constructor(private authService: AuthService, private accDocumentService: ClientAccDocumentsService, private expertDocumentsService: ExpertDocumentsService) {
		this.statisticsDocsCount = new BehaviorSubject<number>(null);
	}

    public docsStatisticsCountSubscription: Subscription;
    public computeDocsStatisticsCount() {
        if (this.docsStatisticsCountSubscription) {
            this.docsStatisticsCountSubscription.unsubscribe();
            this.docsStatisticsCountSubscription = null;
        }

		if (this.authService.selectedZoneCode == PredefinedUserZone.Administrator) {
            this.docsStatisticsCountSubscription = this.accDocumentService.documentsStatisticsQueryStream().subscribe((statistics) => {
                if (statistics != null) {
                    this.statisticsDocsCount.next(statistics);
                }
            });
        }
        else if (this.authService.selectedZoneCode == PredefinedUserZone.Expert) {
            this.docsStatisticsCountSubscription = this.expertDocumentsService.documentsStatisticsQueryStream().subscribe((statistics) => {
                if (statistics != null) {
                    this.statisticsDocsCount.next(statistics);
                }
            });
        }
    }

	public get notUsableDocumentsCount(): number {
		if (this.statisticsDocsCount != null && this.statisticsDocsCount.value != null) {
		  return this.statisticsDocsCount.value.notUsable;
		}
	}

	public get sentToExpertDocumentsCount(): number {
		if (this.statisticsDocsCount != null && this.statisticsDocsCount.value != null) {
		  return this.statisticsDocsCount.value.sentToExpert;
		}
	}
}