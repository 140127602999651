import { Exclude, Expose } from "class-transformer";
import { Observable } from "rxjs";
import { dateToInt, intToDate } from "src/app/library/utils";

export class HistoryModel {
    public fields: HistoryFieldModel[];
    public changes: HistoryChangeModel[];
	public urlSource: string;

    constructor(fields: HistoryFieldModel[], changes: HistoryChangeModel[], urlSource: string) {
        this.fields = fields;
        this.changes = changes;
		this.urlSource = urlSource;
    }
}

export class HistoryFieldModel {
    public code: string;
    public display: string;
    public type: string;
    public valueType?: HistoryFieldValueTypeModel

	constructor(code: string, display: string, type: string, valueType?: HistoryFieldValueTypeModel) {
        this.code = code;
        this.display = display;
        this.type = type;
        this.valueType = valueType;
    }
}

export class HistoryFieldValueTypeModel {
    public valueType?: string;
    // public source?: string;
    // public entry?: string;
    public id: string;
    public display: string;
    public valueTypeCode: string;
	public params: any[];
}

export class HistoryChangeModel {
    public dateFrom: number;
    @Exclude()
	private _dateFromEx: Date;
	@Exclude()
	public get dateFromEx(): Date {
		if (!this._dateFromEx && this.dateFrom) {
			if (this.dateFrom == 19000101) {
				this.dateFrom = undefined;
			}
			this._dateFromEx = intToDate(this.dateFrom);
		}
		return this._dateFromEx;
	}
	public set dateFromEx(value: Date) {
		if (this._dateFromEx != value) {
			this._dateFromEx = value;
			this.dateFrom = dateToInt(value);
		}
	}

    public dateTo: number;
    @Exclude()
	private _dateToEx: Date;
	@Exclude()
	public get dateToEx(): Date {
		if (!this._dateToEx && this.dateTo) {
			this._dateToEx = intToDate(this.dateTo);
		}
		return this._dateToEx;
	}
	public set dateToEx(value: Date) {
		if (this._dateToEx != value) {
			this._dateToEx = value;
			this.dateTo = dateToInt(value);
		}
	}

    public value: any;
    public performedBasedOnDocumentEid?: string;
    public performedByUserEid?: string;
    public performedByUserName?: string;
    public performedComment?: string;
    public performedDateTime?: Date;
}

export enum HistoryFieldType {
	Integer = 'Integer',
	Money = 'Money',
	Date = 'Date',
	Text = 'Text',
	Boolean = 'Boolean',
	Dictionary = 'Dictionary',
	LargeText = 'LargeText'
}

export class HistoryChangeDeleteModel {
	public codeField: string;
	public dateFrom: number;
	public comment?: string;
	public documentEid?: string;
}

export class HistoryChangeAddModel {
	public dateFrom: number;
    @Exclude()
	private _dateFromEx: Date;
	@Exclude()
	public get dateFromEx(): Date {
		if (!this._dateFromEx && this.dateFrom) {
			if (this.dateFrom == 19000101) {
				this.dateFrom = undefined;
			}
			this._dateFromEx = intToDate(this.dateFrom);
		}
		return this._dateFromEx;
	}
	public set dateFromEx(value: Date) {
		if (this._dateFromEx != value) {
			this._dateFromEx = value;
			this.dateFrom = dateToInt(value);
		}
	}

	public comment: string;
	public documentEid: string;
	public changes: HistoryChangeFieldModel[];
}

export class HistoryChangeFieldModel {
	@Expose({ name: 'field' })
	public fieldCode: string;
	public value: any;
	@Exclude()
	public fieldDisplay: string;
	@Exclude()
	public fieldType: string;
	@Exclude()
	public valueType: HistoryFieldValueTypeModel;
	@Exclude()
	public dictionaryValues: Observable<any[]>;
	@Exclude()
	public dictionary: any[];
	@Exclude()
	public dictionaryFilteredValues: any[];
}