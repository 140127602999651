import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { RestService } from "@saliente/common";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DeclaratiaUnicaPfaModel, DuActivitatiIndependenteModel, DuChiriiModel, FormStatus, SistemImpozitareActivitatiIndependente, SistemImpozitareChirii } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";
import { CLIENT_FORMS_ROUTES } from "../client-forms.routes.constants";
import { EXPERT_ROUTES } from "@saliente/expert";

@Component({
	selector: 'du-pfa-keez-form',
	templateUrl: 'du-pfa-keez-form.component.html',
    styleUrls: ['du-pfa-keez-form.component.scss']

})
export class DuPFAKeezFormComponent implements OnInit, OnDestroy {
    @Input() public isDialog: boolean;
    @Input() public clientEidParam: string;
    @Input() public instanceParam: string;

    @Output() public close = new EventEmitter();
    
    //subscriptions
	private duPfaDataSubscription: Subscription;
	private selectedCompanyIdSubscription: Subscription;
    
    //models
    public model: DeclaratiaUnicaPfaModel;

    public clientEid: string;
    public instance: string;

    //booleans
    public isReadOnly: boolean;
    public hasViewRight: boolean;
    public isLoading: boolean;
    public isExpert: boolean;
    public isClient: boolean;

    constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private restService: RestService, private route: ActivatedRoute, 
        private toastr: ToastrService, private cdr: ChangeDetectorRef, private router: Router) {}

    ngOnInit(): void {
		let currentCompanyId = this.authService.user.selectedCompanyId;
        this.selectedCompanyIdSubscription = this.authService
			.getSelectedCompanyId()
			.subscribe((companyId) => {
				if (currentCompanyId != companyId && this.isExpert) {
					this.router.navigate([EXPERT_ROUTES.ROOT, EXPERT_ROUTES.DASHBOARD]);
                }
			});

        this.getDuPfaKeezData();
        this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert && this.authService.isInRole('EXPERT');
        this.isClient = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
    }
    
    ngOnDestroy(): void {
        if (this.duPfaDataSubscription) { this.duPfaDataSubscription.unsubscribe(); this.duPfaDataSubscription = null; }
		if (this.selectedCompanyIdSubscription) { this.selectedCompanyIdSubscription.unsubscribe(); this.selectedCompanyIdSubscription = null; }
    }
    
    public getDuPfaKeezData() {
        this.isLoading = true;
        this.duPfaDataSubscription = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.clientEid = this.isDialog ? this.clientEidParam : params.get('id');
                    this.instance = this.isDialog ? this.instanceParam : params.get('instance');

                    return this.clientFormsService.getDuPfaKeezData(this.clientEid, this.instance);
                })
		    )
			.subscribe((duPFAFormModel: DeclaratiaUnicaPfaModel) => {
                if (duPFAFormModel) {
                    this.isReadOnly = duPFAFormModel.formData.status == FormStatus.Submitted;
                    
                    let roles = this.splitRoles(duPFAFormModel.formData.displayRole);
                    this.hasViewRight = this.authService.isInRole('EXPERT') || this.authService.isInRole('ZEUS') 
                        || roles.some((r) => this.authService.isInRole(r));

                    if (this.hasViewRight) {
                        this.model = duPFAFormModel;

                        if (this.model.formData.status != FormStatus.Submitted) {
                            //this.computeBooleans();
                        }
                    }
                }
                else {
                    this.model = null;
                }

                this.isLoading = false;

                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 200);
		});
    }

    public splitRoles(displayRole: string) {
        let roles: string[] = displayRole.split('|');
        return roles;
    }

    public async updateDuPfaForm(duPfaData: any) {
        if (duPfaData.doSubmit) {
            this.submitForm(duPfaData)
        }
        else {
           this.saveForm();
        }
    }

    public async saveForm() {
        let result = await this.clientFormsService.updateClientFormDupfaKeezData(this.clientEid, this.instance, false, this.model.data);
        if (result) {
            if (this.isDialog) {
                this.close.emit();
            }
            this.toastr.success("Formularul a fost salvat cu succes!");
        }
    }

    public async submitForm(duPfaData: any) {
        if (this.isClient) { // no validations needed
            let result = await this.clientFormsService.updateClientFormDupfaKeezData(this.clientEid, this.instance, true, this.model.data);
            if (result) {
                if (this.isDialog) {
                    this.close.emit();
                }
                else {
                    this.getDuPfaKeezData();
                }
                
                this.toastr.success("Formularul a fost trimis cu succes!");
            }
        }
        else if (this.isExpert) {
            if (duPfaData.duPfaForm.form.valid) {
                if (this.isFormValid()) {

                    //warning
                    if (this.model.data.persoana.areHandicap || this.model.data.persoana.nerezident) {
                        let warningMsg = "Atenție!";
                        warningMsg += this.model.data.persoana.areHandicap ? " Are handicap." : "";
                        warningMsg += this.model.data.persoana.nerezident ? " Este nerezisent." : "";

                        this.toastr.warning(warningMsg);
                    }

                    let result = await this.clientFormsService.updateClientFormDupfaKeezData(this.clientEid, this.instance, true, this.model.data);
                    if (result) {
                        if (this.isDialog) {
                            this.close.emit();
                        }
                        else {
                            this.getDuPfaKeezData();
                        }
                        
                        this.toastr.success("Formularul a fost trimis cu succes!");
                    }
                }
            }
            else {
                this.toastr.error("Există erori de validare în formular!");
                this.clientFormsService.isFormTouched.next();
                duPfaData.duPfaForm.form.markAllAsTouched();
            }
        }
    }

    public isFormValid() {
        //type: 0 - realizat, 1 - estimat
		let errorMessages: string[] = [];

        if (this.model.data.chiriiRealizat != undefined && this.model.data.chiriiRealizat.length > 0) {
            this.model.data.chiriiRealizat.forEach((chirie, index) => {
                let messages = this.validateChirie(chirie, index, 0);
                if (messages.length > 0) {
                    messages.forEach((msg) => {
                        errorMessages.push(msg);
                    });
                }
            })
        }

        if (this.model.data.chiriiEstimat != undefined && this.model.data.chiriiEstimat.length > 0) {
            this.model.data.chiriiEstimat.forEach((chirie, index) => {
                let messages = this.validateChirie(chirie, index, 1);
                if (messages.length > 0) {
                    messages.forEach((msg) => {
                        errorMessages.push(msg);
                    });
                }
            })
        }

        if (this.model.data.activitatiIndependenteRealizat != undefined && this.model.data.activitatiIndependenteRealizat.length > 0) {
            this.model.data.activitatiIndependenteRealizat.forEach((activitate, index) => {
                let messages = this.validateActivitateIndependenta(activitate, index, 0);
                if (messages.length > 0) {
                    messages.forEach((msg) => {
                        errorMessages.push(msg);
                    });
                }
            })
        }

        if (this.model.data.activitatiIndependenteEstimat != undefined && this.model.data.activitatiIndependenteEstimat.length > 0) {
            this.model.data.activitatiIndependenteEstimat.forEach((activitate, index) => {
                let messages = this.validateActivitateIndependenta(activitate, index, 1);
                if (messages.length > 0) {
                    messages.forEach((msg) => {
                        errorMessages.push(msg);
                    });
                }
            })
        }

        if (errorMessages.length > 0) {
            this.restService.displayErrors(errorMessages);
            return false;
        }

		return true;
    }

    public validateChirie(chirie: DuChiriiModel, indexChirie: number, type: number): string[] {
		let errorMessages: string[] = [];

        let isSistemReal = chirie.sistemImpozitare != undefined && chirie.sistemImpozitare == SistemImpozitareChirii.SistemReal;

        if (chirie.sistemImpozitare == undefined || chirie.sistemImpozitare == '' || 
            chirie.adresa == undefined || chirie.adresa == '' || 
            chirie.numarDocument == undefined ||
            chirie.dataDocument == undefined ||
            (type == 0 && chirie.venitBrut == undefined) ||
            (isSistemReal && chirie.cheltuieliDeductibile == undefined)) {
                errorMessages.push(`Nu ați completat câmpurile obligatorii: CHIRIA ${(indexChirie + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
                return errorMessages;
            }

        if (this.validateDate(chirie.dataInceperiiActivitatiiEx, type)) {
            errorMessages.push(`Data începerii activității trebuie să fie în interiorul anului de referință (${this.getYear(type).toString()}): CHIRIA ${(indexChirie + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
        }

        if (this.validateDate(chirie.dataIncetariiActivitatiiEx, type)) {
            errorMessages.push(`Data încetării activității trebuie să fie în interiorul anului de referință (${this.getYear(type).toString()}): CHIRIA ${(indexChirie + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
        }

        return errorMessages;
    }

    public validateActivitateIndependenta(activitate: DuActivitatiIndependenteModel, indexActivitate: number, type: number): string[] {
		let errorMessages: string[] = [];

        let isSistemReal = activitate.sistemImpozitare != undefined && activitate.sistemImpozitare == SistemImpozitareActivitatiIndependente.SistemReal;
        let isNormaDeVenit = activitate.sistemImpozitare != undefined && activitate.sistemImpozitare == SistemImpozitareActivitatiIndependente.NormaDeVenit;

        if (activitate.sistemImpozitare == undefined || activitate.sistemImpozitare == '' ||
            activitate.codCaen == undefined || activitate.codCaen == '' ||
            activitate.sediuSauIdentificareBun == undefined || activitate.sediuSauIdentificareBun == '' ||
            activitate.numarDocument == undefined || 
            activitate.dataDocument == undefined ||
            (isSistemReal && activitate.venitBrut == undefined) ||
            (isSistemReal && activitate.cheltuieliDeductibile == undefined) ||
            (isNormaDeVenit && activitate.normaDeVenit == undefined)) {
                errorMessages.push(`Nu ați completat câmpurile obligatorii: ACTIVITATEA ${(indexActivitate + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
                return errorMessages;
            }

        if (this.validateDate(activitate.dataInceperiiActivitatiiEx, type)) {
            errorMessages.push(`Data începerii activității trebuie să fie în interiorul anului de referință (${this.getYear(type).toString()}): ACTIVITATEA ${(indexActivitate + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
        }

        if (this.validateDate(activitate.dataIncheieriiActivitatiiEx, type)) {
            errorMessages.push(`Data încetării activității trebuie să fie în interiorul anului de referință (${this.getYear(type).toString()}): ACTIVITATEA ${(indexActivitate + 1).toString()}, secțiunea ${type == 0 ? 'REALIZAT' : 'ESTIMAT'}`);
        }

        return errorMessages;
    }

    public validateDate(date: Date, type: number) {
        return date != undefined && (date < this.getMinDate(type) || date > this.getMaxDate(type));
    }

    public getMinDate(type: number): Date {
        return type == 0 ? new Date(this.model.formDataParameters.anRealizat, 0, 1) : new Date(this.model.formDataParameters.anEstimat, 0, 1);
    }

    public getMaxDate(type: number): Date {
        return type == 0 ? new Date(this.model.formDataParameters.anRealizat, 11, 31) : new Date(this.model.formDataParameters.anEstimat, 11, 31);
    }

    public getYear(type: number): number {
        return type == 0 ? this.model.formDataParameters.anRealizat : this.model.formDataParameters.anEstimat;
    }

    public async invalidateForm() {
        let result = await this.clientFormsService.invalidateClientForm(this.clientEid, CLIENT_FORMS_ROUTES.DU_PFA_FORM, this.instance);
        if (result) {
            this.getDuPfaKeezData();
            this.toastr.success("Formularul a fost deblocat cu succes!");
        }
    }

    public downloadForm() {
        this.clientFormsService.generateDuPfaKeez(this.clientEid, this.instance);
    }
}