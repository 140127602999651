import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saliente/common-auth';
import { CLIENT_FORMS_ROUTES } from './client-forms.routes.constants';

const routes: Routes = [
  {
    path: CLIENT_FORMS_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
      	{
			path: `:id/${CLIENT_FORMS_ROUTES.MICRO_PROFIT_FORM}`,
			loadChildren: () => import('./micro-profit-form/micro-profit-form.module').then(m => m.MicroProfitFormModule)
		},
      	{
			path: `:id/${CLIENT_FORMS_ROUTES.DU_PFA_FORM}`,
			loadChildren: () => import('./du-pfa-keez-form/du-pfa-keez-form.module').then(m => m.DuPFAKeezFormModule)
		},
		{
			path: CLIENT_FORMS_ROUTES.CLIENT_FORMS,
			loadChildren: () => import('./form-list/forms.module').then(m => m.ClientFormsModule),
		},
		{
			path: CLIENT_FORMS_ROUTES.DU_PFA_FORMS,
			loadChildren: () => import('./du-pfa-form-list/du-pfa-forms.module').then(m => m.DuPfaFormsModule),
		},
		{
			path: `:id/${CLIENT_FORMS_ROUTES.MP2024_FORM}`,
			loadChildren: () => import('./mp2024-form/mp2024-form.module').then(m => m.MP2024FormModule),
		},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientFormsRoutingModule { }
