import { Injectable } from '@angular/core';
import { ISpinner } from '.';

let spinner: ISpinner;

@Injectable()
export class SpinnerService {

	private spinRequests: number = 0;
	constructor() {
	}

	public register(s: ISpinner) {
		spinner = s;
	}

	public show() {
		this.spinRequests++;
		if (spinner) {
			spinner.show();
		}
	}

	public hide() {
		if (spinner) {
			this.spinRequests--;
			if (this.spinRequests <= 0) {
				spinner.hide();
				this.spinRequests = 0;
			}
		}
	}
}
