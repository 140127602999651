import { Directive, Attribute, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[validateChecked][formControlName],[validateChecked][formControl],[validateChecked][ngModel]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CheckedValidatorDirective), multi: true }]
})
export class CheckedValidatorDirective implements Validator {
	@Input('validateChecked-message') public validateCheckedMessage: string;

	constructor() { }

	public validate(control: AbstractControl): { [key: string]: any } {
		const controlValue = control.value;
		if (controlValue !== true) {
			return {
				validateChecked: this.validateCheckedMessage || false
			};
		}
		return null;
	}
}
