<form #activitateIndependentaForm="ngForm" class="d-flex flex-wrap">
    <div class="col-24 px-0">
        <div class="col-24 d-flex flex-wrap"> 
            {{ index + 1 }}
            <i *ngIf="!isReadOnly" class="mdi-action-delete pink-text text-accent-2 pl-3 cursor-pointer" title="Șterge" (click)="this.delete.emit()"></i>
        </div>
        <div class="col-24 px-0 d-flex flex-wrap custom-card">
            <kee-dropdown name="sistemImpozitare" class="col-12" label="Sistem impozitare"
                [data]="sistemImpozitareList" textField="name" valueField="value" [valuePrimitive]="true"
                [(ngModel)]="model.sistemImpozitare" [disabled]="isReadOnly" required
                (selectionChange)="onSistemImpozitareChange($event)">
            </kee-dropdown>
            <inputfield name="formaOrganizare" class="col-12" label="Formă organizare"
                [(ngModel)]="model.formaOrganizare" [disabled]="true">
            </inputfield>
            <kee-combobox id="codCaen" name="codCaen" class="col-12" label="Cod CAEN" placeholder="Caută" [popupSettings]="{width: 800}"
                [data]="caenCodesFilteredData" textField="fullDisplay" valueField="code" [valuePrimitive]="true"
                [filterable]="true" [(ngModel)]="model.codCaen" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required
                (focus)="onCaenCodesFocus()" (filterChange)="handleCaenCodesFilter($event)" (selectionChange)="onCaenCodeChange($event)">
            </kee-combobox>
            <div class="col-12 d-flex flex-wrap px-0">
                <inputfield name="sediuSauIdentificareBun" class="col-24 address" label="Sediu/Datele de identificare ale bunului"
                    [(ngModel)]="model.sediuSauIdentificareBun" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required>
                </inputfield>
                <div class="col-24 font-weight-bold pt-2">ATENȚIE! Includeți orașul/comuna și județul.</div>
            </div>
            <div class="col-12 d-flex flex-wrap px-0 align-items-center">
                <kee-numerictextbox name="numarDocument" class="col-xxl-22 col-xl-21 col-sm-20 pr-0" 
                    label="Număr rezoluției de înființare/Număr document de liberă practică" [format]="'#'" [decimals]="0"
                    [(ngModel)]="model.numarDocument" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required>
                </kee-numerictextbox>
                <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 px-1" title="Ajutor" (click)="onNumarDocument()"></i>
            </div>
            <kee-datepicker name="dataDocumentEx" class="col-12" label="Data document"
                [(ngModel)]="model.dataDocumentEx" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required>
            </kee-datepicker>
            <kee-datepicker name="dataInceperiiActivitatiiEx" class="col-12" label="Data începerii activitații" [min]="getMinDate()"
                [(ngModel)]="model.dataInceperiiActivitatiiEx" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly"
                [onValidate]="validateDataActivitate.bind(this)">
            </kee-datepicker>
            <kee-datepicker name="dataIncheieriiActivitatiiEx" class="col-12" label="Data încheierii activitații" [min]="getMinDate()"
                [(ngModel)]="model.dataIncheieriiActivitatiiEx" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly"
                [onValidate]="validateDataActivitate.bind(this)">
            </kee-datepicker>
            <kee-numerictextbox *ngIf="isRealizat || isEstimatAndSistemReal" name="venitBrut" class="col-12" 
                label="Venit brut" [format]="'n0'" [min]="0" [decimals]="0"
                [(ngModel)]="model.venitBrut" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required>
            </kee-numerictextbox>
            <kee-numerictextbox *ngIf="isRealizat || isEstimatAndSistemReal" name="cheltuieliDeductibile" class="col-12" 
                label="Cheltuieli deductibile" [format]="'n0'" [min]="0" [decimals]="0"
                [(ngModel)]="model.cheltuieliDeductibile" [disabled]="isReadOnly || isRealizatWithNormaDeVenitReadOnly" required>
            </kee-numerictextbox>
            <kee-numerictextbox *ngIf="isEstimatAndNormaDeVenit" name="normaDeVenit" class="col-12" 
                label="Norma de venit" [format]="'n0'" [min]="0" [decimals]="0"
                [(ngModel)]="model.normaDeVenit" [disabled]="isReadOnly" required>
            </kee-numerictextbox>
        </div>
    </div>
</form>