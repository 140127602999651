import $ from './jquery.module';
import 'jquery.easing';
import 'jquery-lazy';
import 'src/js/waves.js';
import 'src/js/dropdown.js';
import 'src/js/collapsible.js';
import 'src/js/sideNav.js';
// import 'src/js/materialize.js';


export default $;
