import { classToClass } from 'class-transformer';

export class AppMenuModel {
	public home: boolean = false;
	public disabled: boolean;
	public isVisible: boolean = true; // applies only to menu children
	public zone: string;
	public accessRole?: string;
	public visibleRole?: string;
	public canExpand: boolean = false;
	public alwaysExpanded: boolean = false;
	public isAtFirstExpanded: boolean = false;
	public text: string;
	public link: string[];
	public icon: string;
	public childrenMenu: AppMenuModel[];

	public clone(): AppMenuModel {
		return classToClass(this, { ignoreDecorators: true });
	}
}
