import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, HomeGuard } from '@saliente/common-auth';
import { AppHomeComponent } from './common/app-home/app-home.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: AppHomeComponent,
		canActivate: [HomeGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy',*/ onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRouterModule { }