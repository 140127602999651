<section class="row m-0">
	<form #documentFileCommentForm="ngForm" class="col-24 px-0">
		<kee-textarea id="txtComment" name="comment" class="col-24" i18n-label="@@DocumentFileComment$Comment" label="Comentariu"
		 required [(ngModel)]="model.comment"></kee-textarea>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="this.save.emit(documentFileCommentForm)">
				<i class="mdi-image-healing"></i>
				<span i18n="@@Actions$Save"> Clarifică</span>
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" (click)="this.close.emit(documentFileCommentForm)">
				<i class="mdi-content-clear"></i>
				<span i18n="@@Actions$Cancel">Renunță</span>
			</button>
		</div>
	</form>
</section>