import { intToDate } from '@saliente/library';
import { Accounts_StatutoryReportDefinitionRepresentation, Accounts_StatutoryReportDefinitionsRepresentation } from '../services/statutory-reports-definition.service';
export class ModelLine {
	data: Accounts_StatutoryReportDefinitionRepresentation;
	startDate: Date;
	endDate: Date;
	initialize(data: Accounts_StatutoryReportDefinitionRepresentation): void {
		this.data = data;
		this.startDate = intToDate(data.startDate);
		this.endDate = intToDate(data.endDate);
	}
}
export class Model {
	data: Accounts_StatutoryReportDefinitionsRepresentation;
	lastImportDate: Date;
	public lines: ModelLine[] = [];
	public filteredLines: ModelLine[] = [];
	public visibleShowAll = false;

	public initialize(data: Accounts_StatutoryReportDefinitionsRepresentation): void {
		this.data = data;
		this.lines = [];
		if (this.data.lastImportDate !== 0) {
			this.lastImportDate = intToDate(this.data.lastImportDate);
		} else {
			this.lastImportDate = null;
		}

		this.data.definitions.forEach((definition) => {
			const line = new ModelLine();
			line.initialize(definition);
			this.lines.push(line);
		});
		this.onVisibleShowAll();
	}
	public onVisibleShowAll(): void {
		this.filteredLines = [];
		this.lines.forEach((line) => {
			if (this.visibleShowAll === true || line.data.isActiveForSelectedMonth) {
				this.filteredLines.push(line);
			}
		});
	}
}
