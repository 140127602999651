import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { SpinnerService } from "@saliente/library";
import { classToClass, plainToClass } from "class-transformer";
import { Observable, of, Subject } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { Accounts_ClientFormSpecRepresentationInput, Accounts_DuAnswersRepresentationInput, Accounts_MicroProfitAnswersRepresentationInput } from "src/graphql";
import { AuthService } from "../common/auth/auth.service";
import { KMutationOptions, RestService } from "../common/general/rest.service";
import { GetCaenCodesDocument, GetCaenCodesQuery, GetClientFormDuPfaKeezDataDocument, GetClientFormDuPfaKeezDataQuery, GetClientFormMicroProfitDataDocument, GetClientFormMicroProfitDataQuery, GetClientFormsDataDocument, GetClientFormsDataQuery, GetSuperExpertProgressManagementGQL, InvalidateClientFormGQL, UpdateClientFormDuPfaKeezDataGQL, UpdateClientFormMicroProfitDataGQL } from "./adapters/clientforms.generated";
import { CaenCodeModel, ClientFormDataModel, ClientFormListModel, ClientFormModel, DeclaratiaUnicaPfaModel, DuActivitatiIndependenteModel, DuChiriiModel, DuPfaDataModel, DuPfaParametersModel, FormStatus, MicroProfitDataModel, MicroProfitFormModel, MicroProfitParametersModel, SistemImpozitareActivitatiIndependente, SistemImpozitareChirii } from "./client-forms.models";
import { ConfigService } from "../common/general/config.service";
import { DownloadService } from "../common/general/download.service";

@Injectable()
export class ClientFormsService {
    public isFormTouched: Subject<void> = new Subject<void>();

    constructor(private authService: AuthService, private restService: RestService, private spinner: SpinnerService, private configService: ConfigService,
            private downloadService: DownloadService) { }
    
    public getMicroProfitData(clientEid: string, instance: string): Observable<MicroProfitFormModel> {
        this.spinner.show();
        return this.restService
            .query({
                query: GetClientFormMicroProfitDataDocument,
                variables: {
                    clientEid: clientEid,
                    instance: instance,
                },
                fetchPolicy: 'network-only'
            }, { spinner: true })
            .pipe(
                map((response: ApolloQueryResult<GetClientFormMicroProfitDataQuery>) => {
                    let result: MicroProfitFormModel = null;
                    try {
                        result = plainToClass(MicroProfitFormModel, response.data.accounts.getClientFormMicroProfitDataUsingGET);
                        result.data = plainToClass(MicroProfitDataModel, result.data);
                        if (result.data.micro3p)
                            result.data.hasCaenCode = true;
                        if (result.data.micro1p)
                            result.data.hasCaenCode = false;
                        result.formData = plainToClass(ClientFormDataModel, result.formData);
                        result.formDataParameters = plainToClass(MicroProfitParametersModel, result.formData.parameters);

                        this.applyMicroProfitFormDefaults(result.data);
                    } catch {
                        result = null;
                    }
                    this.spinner.hide();
                    return result;
                })
            );
    }

    public applyMicroProfitFormDefaults(data: MicroProfitDataModel) {
        data.are1FTE = data.are1FTE == null ? data.defaultAre1FTE : data.are1FTE;
        data.are253Micro = data.are253Micro == null ? data.defaultAre253Micro : data.are253Micro;
        data.areConsultanta80 = data.areConsultanta80 == null ? data.defaultAreConsultanta80 : data.areConsultanta80;
    }

    public async updateClientFormMicroProfitData(clientEid: string, instance: string, data: MicroProfitDataModel): Promise<boolean> {
		let mutation = new UpdateClientFormMicroProfitDataGQL(this.restService);

        let mutationData: Accounts_MicroProfitAnswersRepresentationInput = {};
        mutationData.are1FTE = data.are1FTE;
        mutationData.areConsultanta80 = data.areConsultanta80;
        mutationData.caSub500k = data.caSub500k;
        mutationData.are253Micro = data.are253Micro;
        mutationData.pastreazaFirmaMicro = data.pastreazaFirmaMicro;

        mutationData.ramaneMicro = data.ramaneMicro;
        mutationData.doresteMicro = data.doresteMicro;
        mutationData.devineMicro = data.devineMicro;
        mutationData.ramaneProfit = data.ramaneProfit;
        mutationData.devineProfit = data.devineProfit;
        mutationData.micro1p = data.micro1p;
        mutationData.micro3p = data.micro3p;

        let result = await mutation.mutate({
			clientEid: clientEid,
			instance: instance,
            data: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        if (result) {
			return true;
		}
		
		return false;
    }

    public sistemImpozitareActivitatiIndependenteDisplay(sistem: string): string {
		switch (sistem) {
			case SistemImpozitareActivitatiIndependente.SistemReal:
				return 'Sistem real';
			case SistemImpozitareActivitatiIndependente.CoteForfetareDeCheltuieli:
				return 'Cote forfetare de cheltuieli';
			case SistemImpozitareActivitatiIndependente.NormaDeVenit:
				return 'Normă de venit';
            default:
                return '';
        }
	}

    public sistemImpozitareChiriiDisplay(sistem: string): string {
		switch (sistem) {
			case SistemImpozitareChirii.SistemReal:
				return 'Sistem real';
			case SistemImpozitareChirii.CoteForfetare:
				return 'Cote forfetare';
            default:
                return '';
        }
	}

    public getCaenCodeList(): Observable<CaenCodeModel[]> {
        return this.restService
            .query({
                query: GetCaenCodesDocument,
                fetchPolicy: 'network-only'
            }, { spinner: false })
            .pipe(
                map((response: ApolloQueryResult<GetCaenCodesQuery>) => {
                    let result: CaenCodeModel[] = [] ;
                    try {
                        result = plainToClass<CaenCodeModel, object>(CaenCodeModel, response.data.accounts.getCaenCodesUsingGET || []);
                    } catch {
                        result = [];
                    }
                    
                    return result;
                })
            );
    }

    public async getSuperExpertProgressManagement(clientEid: string, forMonth: number): Promise<string> {
		let query = new GetSuperExpertProgressManagementGQL(this.restService);
		let queryData = await query.fetch(
			{
				clientEid: clientEid,
                forMonth: forMonth
			}
		).toPromise();

		if (queryData != null) {
			return queryData.data.accounts.getSuperExpertProgressManagementUsingGET.profitMicroCode;
		}

		return null;
	}

    public getDuPfaKeezData(clientEid: string, instance: string): Observable<DeclaratiaUnicaPfaModel> {
        this.spinner.show();
        return this.restService
            .query({
                query: GetClientFormDuPfaKeezDataDocument,
                variables: {
                    clientEid: clientEid,
                    instance: instance,
                },
                fetchPolicy: 'network-only'
            }, { spinner: true })
            .pipe(
                map((response: ApolloQueryResult<GetClientFormDuPfaKeezDataQuery>) => {
                    let result: DeclaratiaUnicaPfaModel = null;
                    try {
                        result = plainToClass(DeclaratiaUnicaPfaModel, response.data.accounts.getClientFormDuPfaKeezDataUsingGET);
                        result.data = plainToClass(DuPfaDataModel, result.data);
                        result.data.activitatiIndependenteEstimat = plainToClass<DuActivitatiIndependenteModel, object>(DuActivitatiIndependenteModel, result.data.activitatiIndependenteEstimat || []);
                        result.data.activitatiIndependenteRealizat = plainToClass<DuActivitatiIndependenteModel, object>(DuActivitatiIndependenteModel, result.data.activitatiIndependenteRealizat || []);
                        result.data.chiriiEstimat = plainToClass<DuChiriiModel, object>(DuChiriiModel, result.data.chiriiEstimat || []);
                        result.data.chiriiRealizat = plainToClass<DuChiriiModel, object>(DuChiriiModel, result.data.chiriiRealizat || []);
                        result.formData = plainToClass(ClientFormDataModel, result.formData);
                        result.formDataParameters = plainToClass(DuPfaParametersModel, result.formData.parameters);

                        this.applyDuPFAFormDefaults(result.data);
                    } catch {
                        result = null;
                    }
                    this.spinner.hide();
                    return result;
                })
            );
    }

    public applyDuPFAFormDefaults(data: DuPfaDataModel) {
        data.optionalPensieEstimat = data.optionalPensieEstimat ?? false;
        data.optionalPensieRealizat = data.optionalPensieRealizat ?? false;
        data.optionalSanatateEstimat = data.optionalSanatateEstimat ?? false;
        data.optionalSanatateRealizat = data.optionalSanatateRealizat ?? false;
        data.impozitONG = data.impozitONG ?? false;
    }

    public async updateClientFormDupfaKeezData(clientEid: string, instance: string, doSubmit: boolean, data: DuPfaDataModel): Promise<boolean> {
		let mutation = new UpdateClientFormDuPfaKeezDataGQL(this.restService);

        let mutationData: Accounts_DuAnswersRepresentationInput = {
            persoana: {},
            activitatiIndependenteEstimat: [],
            activitatiIndependenteRealizat: [],
            chiriiEstimat: [],
            chiriiRealizat: []
        };

        mutationData.persoana.adresa = data.persoana.adresa;
        mutationData.persoana.areHandicap = data.persoana.areHandicap;
        mutationData.persoana.cnp = data.persoana.cnp;
        mutationData.persoana.email = data.persoana.email;
        mutationData.persoana.iban = data.persoana.iban;
        mutationData.persoana.nerezident = data.persoana.nerezident;
        mutationData.persoana.nume = data.persoana.nume;
        mutationData.persoana.prenume = data.persoana.prenume;
        mutationData.persoana.telefon = data.persoana.telefon;

        mutationData.agriEstimat = data.agriEstimat;
        mutationData.agriRealizat = data.agriRealizat;
        mutationData.alteSurseEstimat = data.alteSurseEstimat;
        mutationData.alteSurseRealizat = data.alteSurseRealizat;
        mutationData.anEstimat = data.anEstimat;
        mutationData.anRealizat = data.anRealizat;
        mutationData.cimNedeterminat = data.cimNedeterminat;
        mutationData.comments = data.comments;
        mutationData.dividendeEstimat = data.dividendeEstimat;
        mutationData.dividendeRealizat = data.dividendeRealizat;
        mutationData.dreptAutorEstimat = data.dreptAutorEstimat;
        mutationData.dreptAutorRealizat = data.dreptAutorRealizat;
        mutationData.impozitONG = data.impozitONG;
        mutationData.ongCIF = data.ongCIF;
        mutationData.ongIBAN = data.ongIBAN;
        mutationData.optionalPensieEstimat = data.optionalPensieEstimat;
        mutationData.optionalPensieRealizat = data.optionalPensieRealizat;
        mutationData.optionalSanatateEstimat = data.optionalSanatateEstimat;
        mutationData.optionalSanatateRealizat = data.optionalSanatateRealizat;
        mutationData.optiunePensieValoareEstimat = data.optiunePensieValoareEstimat;
        mutationData.rectificativaEstimat = data.rectificativaEstimat;
        mutationData.rectificativaRealizat = data.rectificativaRealizat;
        mutationData.titluriValoareEstimat = data.titluriValoareEstimat;
        mutationData.titluriValoareRealizat = data.titluriValoareRealizat;
        mutationData.venituriStrainatate = data.venituriStrainatate;

        data.activitatiIndependenteEstimat.forEach((activitate) => {
            mutationData.activitatiIndependenteEstimat.push({
				cheltuieliDeductibile: activitate.cheltuieliDeductibile,
                codCaen: activitate.codCaen,
                denCaen: activitate.denCaen,
                dataDocument: activitate.dataDocument,
                dataInceperiiActivitatii: activitate.dataInceperiiActivitatii,
                dataIncheieriiActivitatii: activitate.dataIncheieriiActivitatii,
                formaOrganizare: activitate.formaOrganizare,
                normaDeVenit: activitate.normaDeVenit,
                numarDocument: activitate.numarDocument,
                sediuSauIdentificareBun: activitate.sediuSauIdentificareBun,
                sistemImpozitare: activitate.sistemImpozitare,
                venitBrut: activitate.venitBrut
			});
        });

        data.activitatiIndependenteRealizat.forEach((activitate) => {
            mutationData.activitatiIndependenteRealizat.push({
				cheltuieliDeductibile: activitate.cheltuieliDeductibile,
                codCaen: activitate.codCaen,
                denCaen: activitate.denCaen,
                dataDocument: activitate.dataDocument,
                dataInceperiiActivitatii: activitate.dataInceperiiActivitatii,
                dataIncheieriiActivitatii: activitate.dataIncheieriiActivitatii,
                formaOrganizare: activitate.formaOrganizare,
                normaDeVenit: activitate.normaDeVenit,
                numarDocument: activitate.numarDocument,
                sediuSauIdentificareBun: activitate.sediuSauIdentificareBun,
                sistemImpozitare: activitate.sistemImpozitare,
                venitBrut: activitate.venitBrut
			});
        });

        data.chiriiEstimat.forEach((chirie) => {
            mutationData.chiriiEstimat.push({
                adresa: chirie.adresa,
                dataDocument: chirie.dataDocument,
                dataInceperiiActivitatii: chirie.dataInceperiiActivitatii,
                dataIncetariiActivitatii: chirie.dataIncetariiActivitatii,
                numarDocument: chirie.numarDocument,
                sistemImpozitare: chirie.sistemImpozitare,
                venitBrut: chirie.venitBrut,
                cheltuieliDeductibile: chirie.cheltuieliDeductibile
            })
        });

        data.chiriiRealizat.forEach((chirie) => {
            mutationData.chiriiRealizat.push({
                adresa: chirie.adresa,
                dataDocument: chirie.dataDocument,
                dataInceperiiActivitatii: chirie.dataInceperiiActivitatii,
                dataIncetariiActivitatii: chirie.dataIncetariiActivitatii,
                numarDocument: chirie.numarDocument,
                sistemImpozitare: chirie.sistemImpozitare,
                venitBrut: chirie.venitBrut,
                cheltuieliDeductibile: chirie.cheltuieliDeductibile
            })
        });

        let result = await mutation.mutate({
			clientEid: clientEid,
			instance: instance,
            doSubmit: doSubmit,
            data: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        if (result) {
			return true;
		}
		
		return false;
    }

    public async invalidateClientForm(clientEid: string, form: string, instance: string) {
        let mutation = new InvalidateClientFormGQL(this.restService);

        let mutationData: Accounts_ClientFormSpecRepresentationInput = {
            form: form,
            instance: instance
        };

        let result = await mutation.mutate({
			clientEid: clientEid,
            body: mutationData
		}, { error: true } as KMutationOptions).toPromise();

        if (result) {
			return true;
		}
		
		return false;
    }

    public async generateDuPfaKeez(clientEid: string, instance: string) {
        return this.configService
            .getAccountsUrl()
            .pipe(
                switchMap((url) => {
                    url = url + `/clients/${clientEid}/forms/du-pfa-keez/${instance}/pdf`
                    return this.downloadService.download(url);
                }),
				take(1)
            )
			.subscribe();
	}

    public getClientFormsList(onlyDuPfa: boolean = false): Observable<ClientFormListModel> {
        let filter: string;
        if (onlyDuPfa) {
            filter = 'form.externalId[like]:du-pfa-keez'
        }
        
        return this.authService.getSelectedCompanyId()
			.pipe(
				switchMap((companyId) => {
					if (companyId) {
                        return this.restService
                            .query({
                                query: GetClientFormsDataDocument,
                                variables: {
                                    clientEid: companyId,
                                    filter: filter
                                },
                                fetchPolicy: 'network-only'
                            }, { spinner: true })
                            .pipe(
                                map((response: ApolloQueryResult<GetClientFormsDataQuery>) => {
                                    let result: ClientFormListModel = null;
                                    try {
                                        result = plainToClass(ClientFormListModel, response.data.accounts.getClientFormsDataUsingGET);
                                        result.data = plainToClass<ClientFormModel, object>(ClientFormModel, result.data || []);
                                    } catch {
                                        result = null;
                                    }
                                    return result;
                                })
                            );
                    }
                    return of(null);
				})
			);
    }

    public getDuPfaFormsList(): Observable<ClientFormListModel> {
        return this.getClientFormsList(true);
    }

    public formStatusDisplay(status: string): string {
		switch (status) {
			case FormStatus.Created:
				return 'Creat';
			case FormStatus.Draft:
				return 'În curs';
			case FormStatus.Submitted:
				return 'Trimis';
            case FormStatus.Deleted:
                return 'Șters';
            default:
                return '';
        }
	}
}
