import { Exclude } from "class-transformer";

export class UserRatingModel {
    public comment: string;
	public rating: number;
	public source: string;
}

export class UserFeedbackModel {
    public feedback: string;
	public type: string;
	public source: string;
}