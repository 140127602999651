import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import gql from 'graphql-tag';

import { dateToInt, intToDate } from '@saliente/library';
import { AuthService, } from '../auth/auth.service';
import { ConfigService, } from '../general/config.service';
import { RestService, } from '../general/rest.service';

const documentsOpisQueryText = gql`
query documentsOpis($clientEid: String!, $startDate: Int, $endDate: Int){
	subs{
		documentsOpisUsingGET(clientEid:$clientEid, startDate:$startDate, endDate:$endDate){
			documentDate
			documentEid
			documentNumber
			employeeName
			entryType
			id
			partenerName
			status
			valueLocalCurrency
	  }
	}
}
`;

@Injectable()
export class DocumentsOpisService {
	constructor(private configService: ConfigService, private authService: AuthService, private restService: RestService) {
	}

	public fetch(clientEid: string, startDate: Date, endDate: Date) {
		return this.restService
			.query({
				query: documentsOpisQueryText,
				fetchPolicy: 'network-only',
				variables: {
					clientEid,
					startDate: dateToInt(startDate),
					endDate: dateToInt(endDate)
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.subs.documentsOpisUsingGET.map((item: any) => {
							return {
								documentEid: item.documentEid,
								entryType: item.entryType,
								employeeName: item.employeeName,
								documentNumber: item.documentNumber,
								documentDate: intToDate(item.documentDate),
								partnerName: item.partenerName,
								status: item.status,
								value: item.valueLocalCurrency
							}
						});
					}
					return [];
				})
			);
	}
}