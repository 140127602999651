import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'document-file-comment',
	templateUrl: 'document-file-comment.component.html'
})
export class DocumentFileCommentComponent implements OnInit {
	@Input() public model: any;
	@Input() public note: string;
	@Input() public date: boolean = false;
	@Input() public dateLabel: string;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	constructor() { }

	public ngOnInit() {
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

}
