import { Injectable } from "@angular/core";
import { plainToClass } from "class-transformer";
import gql from "graphql-tag";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { RestService } from "../general/rest.service";
import { PersonForAccountingModel } from "./documents.models";

const employeesQueryText = gql`
	query employees($clientEid: String!) {
		accounts {
			getPersonsForAccountingUsingGET(clientExternalId: $clientEid) {
				employeeExternalId
				lastName
				firstName
				isActive
				ciDocumentNumber
			}
		}
	}
`;

@Injectable()
export class DocumentsCommomService {
	constructor(private authService: AuthService, private restService: RestService) {}

    public getEmployees(): Observable<PersonForAccountingModel[]> {
		return this.authService.getSelectedCompanyId().pipe(
			switchMap((companyId) => {
				return this.restService
					.query({
						query: employeesQueryText,
						variables: { clientEid: companyId },
						fetchPolicy: 'network-only',
					})
					.pipe(
						map((res: any) => {
							if (res) {
								let employees: PersonForAccountingModel[] = null;
								try {
									employees = plainToClass<PersonForAccountingModel, object>(PersonForAccountingModel, res.data.accounts.getPersonsForAccountingUsingGET || []);
									employees.forEach((employee: any) => {
										employee.fullName = `${employee.lastName} ${employee.firstName}`;
										employee.employeePIN = employee.ciDocumentNumber;
									});
								} catch {
									employees = null;
								}
								
								return employees;
							} else {
								return [];
							}
						})
					);
			})
		);
	}
}