import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@saliente/common-auth';
import { PARAMETERS_ROUTES } from './parameters.routes.constants';

const routes: Routes = [
  {
    path: PARAMETERS_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
      {
				path: PARAMETERS_ROUTES.COMPANY,
				data: { accessRole: 'COMPANY_PARAM_VIEW' },
				loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
			},
      {
				path: `${PARAMETERS_ROUTES.COMPANY}/${PARAMETERS_ROUTES.INVOICE_SERIAL}`,
        data: { accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW' },
				loadChildren: () => import('./company/invoice-serial/invoice-serial.module').then(m => m.InvoiceSerialModule),
			},
      {
				path: `${PARAMETERS_ROUTES.COMPANY}/${PARAMETERS_ROUTES.INVOICE_IBAN}`,
        data: { accessRole: 'COMPANY_PARAM_VIEW' },
				loadChildren: () => import('./company/invoice-iban/invoice-iban.module').then(m => m.InvoiceIbanModule),
			},
      // { 
      //   path: PARAMETERS_ROUTES.INVOICE,
      //   loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
      // },
      // {
			// 	path: PARAMETERS_ROUTES.PAYROLL,
			// 	loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule)
			// },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParametersRoutingModule { }
