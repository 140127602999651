<div id="opisDocuments" *ngIf="model" class=" {{ isCard ? 'card p-3' : '' }}">
	<div class="d-flex" style="line-height:1.25;">
		<h4 class="col" i18n="@@DocumentsOpis$Title">Opis documente</h4>
	</div>
	<div class="d-flex flex-wrap">
		<div *ngIf="showFilters" class="col d-flex">
			<div class="col d-flex flex-wrap">
				<kee-datepicker class="col min-width-175 max-width-175 pl-0" i18n-label="@@DocumentsOpis@StartDate"
					label="De la" [(ngModel)]="startDate">
				</kee-datepicker>
				<kee-datepicker class="col min-width-175 max-width-175" i18n-label="@@DocumentsOpis@EndDate" label="Până la"
					[(ngModel)]="endDate">
				</kee-datepicker>
			</div>
		</div>
		<div class="col d-flex justify-content-end align-items-center">
			<a class="col-auto cursor-pointer" target="_blank" (click)="downloadXls(opis)">
				<i class="xls-download"></i>
			</a>
			<a class="col-auto cursor-pointer" target="_blank" (click)="downloadPdf(opis)">
				<i class="pdf-download"></i>
			</a>
		</div>
	</div>
	<div class="d-flex">
		<kendo-grid #opis [data]="model" class="w-100" scrollable="none">
			<kendo-grid-column field="entryType" i18n-title="@@DocumentsOpis$EntryType" title="Tip"
				[headerStyle]="{'text-align': 'center'}" [width]="150">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ entryTypeDisplayName(dataItem.entryType) }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="documentNumber" i18n-title="@@DocumentsOpis$DocumentNumber" title="Număr"
				[headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}" [width]="150">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ dataItem.documentNumber }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="documentDate" i18n-title="@@DocumentsOpis$DocumentDate" title="Din data"
				[headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}" [width]="150">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ dataItem.documentDate | kendoDate: 'dd.MM.yyyy' }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="partnerName" i18n-title="@@DocumentsOpis$PartnerName"
				[headerStyle]="{'text-align': 'center'}" title="Partener">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ dataItem.partnerName }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="status" i18n-title="@@DocumentsOpis$Status" title="Stare"
				[headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ documentStatusName(dataItem.status) }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="value" i18n-title="@@DocumentsOpis$Value" title="Valoare"
				[headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}" [width]="150">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ dataItem.value | kendoNumber: 'n2' }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="employeeName" i18n-title="@@DocumentsOpis$EmployeeName" title="Angajat"
				[headerStyle]="{'text-align': 'center'}">
				<ng-template kendoGridCellTemplate let-dataItem>
					<span class="whole-cell">
						{{ dataItem.employeeName }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-command-column [style]="{'text-align': 'center'}" [width]="75">
				<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
					<i *ngIf="dataItem.documentEid" class="mdi-action-open-in-new px-2 cursor-pointer"
						(click)="gotoDocument(dataItem)"></i>
				</ng-template>
			</kendo-grid-command-column>
			<kendo-grid-pdf
				fileName="Opis documente {{this.intl.format('{0:dd.MM.yyyy}', startDate)}}-{{this.intl.format('{0:dd.MM.yyyy}', endDate)}}.pdf"
				[allPages]="true" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [landscape]="true">
				<kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
				<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
					<div class="opis-pdf-page-template">
						<div class="header">
							<div style="float: right">Pagina {{ pageNum }} din {{ totalPages }}</div>
							Opis documente
							{{this.intl.format('{0:dd.MM.yyyy}', startDate)}}-{{this.intl.format('{0:dd.MM.yyyy}', endDate)}}
						</div>
						<div class="footer">
							Pagina {{ pageNum }} din {{ totalPages }}
						</div>
					</div>
				</ng-template>
			</kendo-grid-pdf>
			<kendo-grid-excel
				fileName="Opis documente {{this.intl.format('{0:dd.MM.yyyy}', startDate)}}-{{this.intl.format('{0:dd.MM.yyyy}', endDate)}}.xlsx">
			</kendo-grid-excel>
		</kendo-grid>
	</div>
</div>