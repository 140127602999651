import { Component, EventEmitter, Input, Output, OnInit, Injector, ViewChild, ContentChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LabeledControl } from '../../labeledcontrol';
import { MultiSelectComponent, PopupSettings, ItemTemplateDirective, CustomItemTemplateDirective, TagTemplateDirective } from '@progress/kendo-angular-dropdowns';

export const MULTISELECT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeMultiSelectComponent),
	multi: true
};

@Component({
	selector: 'kee-multiselect',
	templateUrl: 'multiselect.component.html',
	providers: [MULTISELECT_VALUE_ACCESSOR],
})
export class KeeMultiSelectComponent extends LabeledControl<any> implements OnInit {

	private _template: ItemTemplateDirective;
	@ContentChild(ItemTemplateDirective) get template(): ItemTemplateDirective {
		return this._template;
	}
	set template(value: ItemTemplateDirective) {
		this._template = value
		if (this._multiselect) {
			this._multiselect.template = this._template;
		}
	}


	private _customItemTemplate: CustomItemTemplateDirective;
	@ContentChild(CustomItemTemplateDirective) get customItemTemplate(): CustomItemTemplateDirective {
		return this._customItemTemplate;
	}
	set customItemTemplate(value: CustomItemTemplateDirective) {
		this._customItemTemplate = value
		if (this._multiselect) {
			this._multiselect.customItemTemplate = this._customItemTemplate;
		}
	}

	private _tagTemplate: TagTemplateDirective;
	@ContentChild(TagTemplateDirective) get tagTemplate(): TagTemplateDirective {
		return this._tagTemplate;
	}
	set tagTemplate(value: TagTemplateDirective) {
		this._tagTemplate = value
		if (this._multiselect) {
			this._multiselect.tagTemplate = this._tagTemplate;
		}
	}

	@Input() public valueNormalizer = function (text: Observable<string>) {
		return text.pipe(map(function (userInput: string) {
			var comparer = function (item: string) { return userInput.toLowerCase() === item.toLowerCase(); };
			var matchingValue = this.value.find(comparer);
			if (matchingValue) {
				return matchingValue;
			}
			var matchingItem = this.data.find(comparer);
			return matchingItem ? matchingItem : userInput;
		}));
	};


	@Input() public data: any;
	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public valuePrimitive: boolean;
	@Input() public allowCustom: boolean;
	@Input() public filterable: boolean;

	@Input() public ngModelOptions: any = { updateOn: 'change' };
	@Input() public popupSettings: PopupSettings = { height: 200, animate: true };

	@Output() public selectionChange = new EventEmitter<any>();
	@Output() public filterChange = new EventEmitter<any>();

	private _multiselect: MultiSelectComponent;
	@ViewChild('multiselect') get multiselect(): MultiSelectComponent {
		return this._multiselect;
	}
	set multiselect(value: MultiSelectComponent) {
		this._multiselect = value;
	}

	public constructor(injector: Injector, ) {
		super(injector);
	}

	public ngOnInit() {
		super.ngOnInit();
	}

	public _handleFilterChange($event: any) {
		this.filterChange.emit($event);
	}
}