import { Injectable } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { plainToClass } from "class-transformer";
import { AccDocumentFileModel, PayrollDocumentFileModel } from "src/app/clients/documents/documents.models";
import { ClientDocumentsDataGQL, FinancialDocumentsDataGQL, PayrollDocumentsDataGQL } from "../adapters/common.generated";
import { PredefinedUserZone } from "../auth/auth.models";
import { AuthService } from "../auth/auth.service";
import { DocumentFileBaseModel } from "../documents/documents-base.models";
import { DocumentFileModel } from "../documents/documents.models";
import { FinancialDocumentFileModel } from "../documents/financial-documents.models";
import { RestService } from "../general/rest.service";
import { DocumentIndentificationModel } from "./document-display.models";

@Injectable()
export class DocumentDisplayService {

    constructor(private restService: RestService, private authService: AuthService) {}

    public async getDocument(documentIndentification: DocumentIndentificationModel): Promise<any> {
        if (this.authService.selectedZoneCode == PredefinedUserZone.Administrator) {
			if (documentIndentification.documentType == "accounting") {
				return await this.getClientDocument(documentIndentification);	
			}

			if (documentIndentification.documentType == "payroll") {
				return await this.getPayrollDocument(documentIndentification);	
			}

			return null;
		}
		else if (this.authService.selectedZoneCode == PredefinedUserZone.Expert) {
			if (documentIndentification.documentType == "accounting") {
				return await this.getExpertDocument(documentIndentification);
			}

			if (documentIndentification.documentType == "payroll") {
				return await this.getPayrollDocument(documentIndentification);	
			}

			return null;
		}

		return null;
    }

    private async getClientDocument(documentIndentification: DocumentIndentificationModel): Promise<AccDocumentFileModel> {
        let filter = 'category:Documents';

        if (documentIndentification.referenceNo != null) {
		    filter += ' AND reference:' + documentIndentification.referenceNo;
        }
        if (documentIndentification.externalId != null) {
		    filter += ' AND eid:' + documentIndentification.externalId;
        }
		
        let qry = new ClientDocumentsDataGQL(this.restService);
		let result = await qry.fetch(
			{
				clientEid: documentIndentification.clientEid == undefined ? this.authService.user.selectedCompanyId : documentIndentification.clientEid,
				filter: filter,
			}
		).toPromise();

		if (result != null) {
            let res = plainToClass(AccDocumentFileModel, this.processDocuments(result.data.documents.GetClientDocumentsUsingGET));
            if (res != undefined && res.length > 0) {
                return res[0];
            }
            else {
                return null;
            }
        }

		return null;
    }

	private async getPayrollDocument(documentIndentification: DocumentIndentificationModel): Promise<PayrollDocumentFileModel> {
        let filter = 'category:PayrollDocs';

        if (documentIndentification.referenceNo != null) {
		    filter += ' AND reference:' + documentIndentification.referenceNo;
        }
        if (documentIndentification.externalId != null) {
		    filter += ' AND eid:' + documentIndentification.externalId;
        }
		
        let qry = new PayrollDocumentsDataGQL(this.restService);
		let result = await qry.fetch(
			{
				clientEid: documentIndentification.clientEid == undefined ? this.authService.user.selectedCompanyId : documentIndentification.clientEid,
				filter: filter,
			}
		).toPromise();

		if (result != null) {
            let res = plainToClass(PayrollDocumentFileModel, this.processDocuments(result.data.documents.GetPayrollDocumentsUsingGET));
            if (res != undefined && res.length > 0) {
                return res[0];
            }
            else {
                return null;
            }
        }

		return null;
    }

    private async getExpertDocument(documentIndentification: DocumentIndentificationModel): Promise<FinancialDocumentFileModel> {
		let filter = 'clientEid:' + (documentIndentification.clientEid == undefined ? this.authService.user.selectedCompanyId : documentIndentification.clientEid);

        if (documentIndentification.referenceNo != null) {
		    filter += ' AND reference:' + documentIndentification.referenceNo;
        }
        if (documentIndentification.externalId != null) {
		    filter += ' AND eid:' + documentIndentification.externalId;
        }

		let qry = new FinancialDocumentsDataGQL(this.restService);
		let result = await qry.fetch(
			{
				filter: filter,
			}
		).toPromise();

		if (result != null) {
            let res = plainToClass(FinancialDocumentFileModel, this.processDocuments(result.data.subs.GetClientDocumentsUsingGET));
            if (res != undefined && res.length > 0) {
                return res[0];
            }
            else {
                return null;
            }
        }

		return null;
    }

    private processDocumentJson(element: any): any {
		function parseMetadata(element: any) {
			const metadataString = element.metadata;
			if (metadataString) {
				try {
					element.metadataEx = JSON.parse(metadataString);
				} catch (err) { }
			} else {
				element.metadataEx = {};
			}
		}
		if (!element.length) {
			parseMetadata(element);
		} else {
			element.forEach((e: any) => {
				parseMetadata(e);
			});
		}
		return element;
	}

	private processDocuments(data: any[]): any[] {
		data = JSON.parse(JSON.stringify(data));
		data.forEach((element: any) => {
			this.processDocumentJson(element);
		});
		return data;
	}
}