import { AfterViewInit, Component, ElementRef, Host, HostListener, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { MasterviewComponent } from '../masterview.component';
import { ZoneCompany } from '../../auth/auth.models';
import { Align, Margin } from '@progress/kendo-angular-popup';
import { of } from 'rxjs';

@Component({
	selector: 'masterview-header',
	templateUrl: 'masterview.header.component.html'
})
export class MasterviewHeaderComponent implements OnInit {	
	public companies: ZoneCompany[];
	public showFilter: boolean = false;
	//public userProfileAnchorMargin: Margin = { horizontal: 47, vertical: 17 };
	public userProfilePopupAlign: Align = { horizontal: "right", vertical: "top" };
	public userProfileAnchorAlign: Align = { horizontal: "right", vertical: "bottom" };

	public isUserProfileVisible: boolean = false;

	@ViewChild('filterInput') filterInput: any;
	@ViewChild('clientsMenu') clientsMenu: any;
	@ViewChild("userProfileAnchor") public userProfileAnchor: ElementRef;
  	@ViewChild("userProfilePopup", { read: ElementRef }) public userProfilePopup: ElementRef;

	// get & set for _companiesFilter
	private _companiesFilter: string;
	get companiesFilter(): string {
		return this._companiesFilter;
	}
	set companiesFilter(value: string) {
		this._companiesFilter = value;
		if (this._companiesFilter) {
			this.companies = this.parent.authService.user.companies.filter((c) => c.companyNameAI.toLowerCase().includes(this._companiesFilter.toLowerCase()) || c.fiscalNumber.includes(this._companiesFilter.toLowerCase()));
		} else {
			this.companies = this.parent.authService.user.companies;
		}
	}

	constructor(@Host() public parent: MasterviewComponent) {
		this.companies = this.parent.authService.user.companies;
		this.showFilter = this.companies.length > 10;
	}

	ngOnInit() { 
	}

	public onShowCompaniesMenu() {
		if(this.companies !== this.parent.authService.user.companies) {
		  // after adding a company sometimes masterview initializes before reload of companies occur
		  this.companies = this.parent.authService.user.companies;
		}
	
		if (this.filterInput) {
			this.filterInput.nativeElement.focus();
		}
	}

	public onHideCompaniesMenu() {
		this.companiesFilter = null;
	}

	public onInputClick($event: any) {
		$event.preventDefault();
		$event.stopImmediatePropagation();
		$event.stopPropagation();
	}

	public onInputKeyDown(e: any) {
		const self = this;
		if (e.keyCode === 13 && self.companies.length) {
			self.selectCompany(self.companies[0].externalId);
			self.clientsMenu.hide();
		}
	}

	public async selectCompany(companyId: string): Promise<void> {
		this.parent.makeSpecificMenuActive();
		this.parent.initLazy();

		await this.parent.selectCompanyId(companyId);
		this.companiesFilter = null;

		if (this.parent.isClient) {
			if (this.parent.isAccountSuspended()) {
				this.parent.goToContractPage();
			}
			else {
				this.parent.router.navigate(['/clients']);
			}
		}
    return of<void>().toPromise();
	}

	public get helpVideosAreVisible() {
		return !!(this.parent.helpConfig.videos);
	}

	public onShowVideosValueChange(event: any) {
		this.parent.authService.user.showHelp = !this.parent.authService.user.showHelp;
		this.parent.authService.updateMyData()
			.pipe(take(1))
			.subscribe();
	}

	public helpMenuItems() {
		let menuItems = [];
		if (this.parent.helpConfig.menus && this.parent.helpId) {
			menuItems = (this.parent.helpConfig.menus[this.parent.helpId] || []).filter((m: any) => m.client !== false);
		}
		return menuItems;
	}

	public freshDesk() {
		this.parent.commonService.freshdeskUrlStream()
			.pipe(take(1))
			.subscribe((url: string) => {
				if (url) {
					window.open(url, '_blank');
				}
			});
	}

	public showUserProfile() {
		this.isUserProfileVisible = !this.isUserProfileVisible;
	}

	public gotoProfile(): void {
		this.parent.gotoProfile();
		this.isUserProfileVisible = false;
	}

	public gotoContact(): void {
		this.parent.gotoContact();
		this.isUserProfileVisible = false;
	}

	@HostListener("keydown", ["$event"])
	public keydown(event: any): void {
		if (event.keyCode === 27) {
			this.isUserProfileVisible = false;
		}
	}

	@HostListener("document:click", ["$event"])
	public documentClick(event: any): void {
		if (!this.contains(event.target)) {
			this.isUserProfileVisible = false;
		}
	}

	private contains(target: any): boolean {
		return (
		  this.userProfileAnchor.nativeElement.contains(target) ||
		  (this.userProfilePopup ? this.userProfilePopup.nativeElement.contains(target) : false)
		);
	}
	
}