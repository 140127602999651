import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: '[wow]' })
export class WowDirective {
	private wow: any;

	private _wowClass: string;
	@Input('wow') get wowClass() {
		return this._wowClass;
	}
	set wowClass(value) {
		if (this._wowClass !== value) {
			this._wowClass && this.renderer.removeClass(this.el.nativeElement, this._wowClass);
			this._wowClass = value;
			this._wowClass && this.renderer.addClass(this.el.nativeElement, this._wowClass);
		}
	}

	private _wowDuration: string;
	@Input('wow-duration') get wowDuration() {
		return this._wowDuration;
	}
	set wowDuration(value) {
		if (this._wowDuration !== value) {
			this._wowDuration && this.renderer.removeAttribute(this.el.nativeElement, 'data-wow-duration');
			this._wowDuration = value;
			this._wowDuration && this.renderer.setAttribute(this.el.nativeElement, 'data-wow-duration', this._wowDuration);
		}
	}

	private _wowDelay: string;
	@Input('wow-delay') get wowDelay() {
		return this._wowDelay;
	}
	set wowDelay(value) {
		if (this._wowDelay !== value) {
			this._wowDelay && this.renderer.removeAttribute(this.el.nativeElement, 'data-wow-delay');
			this._wowDelay = value;
			this._wowDelay && this.renderer.setAttribute(this.el.nativeElement, 'data-wow-delay', this._wowDelay);
		}
	}

	private _wowIteration: string;
	@Input('wow-iteration') get wowIteration() {
		return this._wowIteration;
	}
	set wowIteration(value) {
		if (this._wowIteration !== value) {
			this._wowIteration && this.renderer.removeAttribute(this.el.nativeElement, 'data-wow-iteration');
			this._wowIteration = value;
			this._wowIteration && this.renderer.setAttribute(this.el.nativeElement, 'data-wow-iteration', this._wowIteration);
		}
	}

	private _wowOffset: string;
	@Input('wow-offset') get wowOffset() {
		return this._wowOffset;
	}
	set wowOffset(value) {
		if (this._wowOffset !== value) {
			this._wowOffset && this.renderer.removeAttribute(this.el.nativeElement, 'data-wow-offset');
			this._wowOffset = value;
			this._wowOffset && this.renderer.setAttribute(this.el.nativeElement, 'data-wow-offset', this._wowOffset);
		}
	}

	constructor(private el: ElementRef, private renderer: Renderer2) {
		this.renderer.addClass(this.el.nativeElement, 'wow');
		this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden');
	}
}
