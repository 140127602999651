import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SalienteLibrarySharedModule } from "@saliente/library";
import { ClientFormsService } from "../client-forms.service";
import { MicroProfitFormComponent } from "./micro-profit-form.component";

@NgModule({
    imports: [
		FormsModule, SalienteLibrarySharedModule
	],
	providers: [
		ClientFormsService
	],
	declarations: [
		MicroProfitFormComponent,
	],
	exports: [
		MicroProfitFormComponent
	]
})
export class MicroProfitSharedModule { }