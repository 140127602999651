import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockRoutingModule } from './stock.routes';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StockRoutingModule
  ]
})
export class StockModule { }
