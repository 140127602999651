import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, DownloadService, RestService, UploadService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import { buildQueryParams, dateToInt, SpinnerService, UID } from '@saliente/library';
import { NullValidationHandler } from 'angular-oauth2-oidc';
import { classToPlain, plainToClass } from 'class-transformer';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, first, map, switchMap, take } from 'rxjs/operators';
import { NavigableDataSource } from 'src/app/common/general/navigable_datasource';
import { HistoryChangeAddModel, HistoryChangeDeleteModel, HistoryChangeModel, HistoryFieldModel, HistoryModel } from 'src/app/payroll/general-history-grid/history.models';
import { SuspendareModel, TerminareModel } from '../common/payroll.models';
import { CoasiguratModel, DeducereModel, HandicapModel, EmployeeDetailsModel, EmployeeListModel, EmployeeModel, ChangeEmployeeSalaryModel, EmployeeTerminationModel, AngajatAttachmentModel } from './employees.models';


// need it form listSourceStream need in vehicle-edit.component
const employeesListSourceQueryText = gql`
query employeesListSource ($clientEid: String!){
	accounts {
		getPersonsForAccountingUsingGET(clientExternalId: $clientEid) {
			employeeExternalId
			ciDocumentNumber
			lastName
			firstName
			isActive
		}
	}
}`;

/* ------------------------------------------------------------------------------------------------------------ */

const dictionaryDefaultFieldsText = `
	id
	code
	display
`;

const statusAngajatFieldsText = `
	${dictionaryDefaultFieldsText}
	categorie
`;

const modelContractFieldsText = `
	${dictionaryDefaultFieldsText}
	permitePontaj
	utilizabilClient
`;

const coasiguratiFieldsText = `
	externalId
	clientEid
	tipCoasiguratId
	numeCoasigurat
	prenumeCoasigurat
	cnp
	deLa
	panaLa
	tipCoasigurat {
		${dictionaryDefaultFieldsText}
	}
`;

const deduceriFieldsText = `
	externalId
	clientEid
	persoanaInDeducere
	cnpPersoana
	tipDeducereId
	gradHandicapId
	deLa
	panaLa
	gradHandicap {
		${dictionaryDefaultFieldsText}
	}
	tipDeducere {
		${dictionaryDefaultFieldsText}
	}
`;

const handicapuriFieldsText = `
	externalId
	clientEid
	tipHandicapId
	gradHandicapId
	documentEid
	deLa
	panaLa
	tipHandicap {
		${dictionaryDefaultFieldsText}
	}
	gradHandicap {
		${dictionaryDefaultFieldsText}
	}
`;

const suspendariFieldsText = `
	motivSuspendareId
	deLa
	panaLa
	dataRevenire
	documentEid
	activ
	externalId
	clientEid
	motivSuspendare {
		${dictionaryDefaultFieldsText}
	}
`;

const angajatAttachmentsFieldsText = `
	fileType
	fileEid
	fileName
	fileSize
	displayOrder
`;

const employeeDetailsQueryText = gql`
query employeeDetails($clientEid: String!, $externalId: String!, $atDate: Int, $eom: Boolean){
	payroll {
		getEmployeeUsingGET(clientEid: $clientEid, externalId: $externalId, atDate: $atDate, eom: $eom) {
			initialaTata
			dataNasterii
			cis
			email
			telefon
			adresa
			judetCod
			judetNume
			orasNume
			taraCod
			taraNume
			banca
			iban
			cetatenieCod
			cetatenieNume
			serie
			numar
			eliberatDe
			valabilDeLa
			valabilPanaLa
			dataPlecarii
			contractNo
			contractDate
			perioadaProba
			contractDocumentEid
			preavizDemisie
			preavizConcediere
			dataPlataAvans
			dataPlataLichidare
			obiectContract
			scurtaCaracterizarePost
			criteriiEvaluare
			tipDocumentPersonalId
			nume
			prenume
			cnp
			dataAngajarii
			tarifarIncadrare
			functieInterna
			siteEid
			siteName
			numarOre
			dataTerminarii
			functieBaza
			sporWeekend
			departament
			concediuOdihna
			concediuOdihnaSuplimentar
			scutitImpozitIt
			pensionar
			sexId
			casaSanatateId
			tipNormaId
			repartizareTipMuncaId
			durataContractId
			functieCorId
			conditiiMuncaId
			statusAngajatId
			modelContractId
			tipOreId
			tipSporWeekendId
			dataAsigurat
			deducereOug67
			oug16
			lunaPlataCenzor
			valoareSporWeekend
			copiiDeducereOug16100ron
			deducereOug16200ron
			pilonIiOptional
			externalId
			clientEid
			validat
			sex {
				${dictionaryDefaultFieldsText}
			}
			casaSanatate {
				${dictionaryDefaultFieldsText}
			}
			tipDocumentPersonal {
				${dictionaryDefaultFieldsText}
			}
			tipNorma {
				${dictionaryDefaultFieldsText}
			}
			repartizareTipMunca {
				${dictionaryDefaultFieldsText}
			}
			durataContract {
				${dictionaryDefaultFieldsText}
			}
			functieCor {
				${dictionaryDefaultFieldsText}
			}
			conditiiMunca {
				${dictionaryDefaultFieldsText}
			}
			statusAngajat {
				${statusAngajatFieldsText}
			}
			modelContract {
				${modelContractFieldsText}
			}
			tipOre {
				${dictionaryDefaultFieldsText}
			}
			tipSporWeekend {
				${dictionaryDefaultFieldsText}
			}
			coasigurati {
				${coasiguratiFieldsText}
			}
			deduceri {
				${deduceriFieldsText}
			}
			handicapuri {
				${handicapuriFieldsText}
			}
			suspendari {
				${suspendariFieldsText}
			}
			angajatAttachments {
				${angajatAttachmentsFieldsText}
			}
		}
	}
}`;

const employeeListQueryText = gql`
query employeeList($clientEid: String!, $filter: String, $order: String, $offset: Int, $count: Int){
	payroll {
		getEmployeesUsingGET(clientEid: $clientEid, filter: $filter, order: $order, offset: $offset, count: $count) {
			recordsCount
			first
			last
			data {
				externalId
				cnp
				prenume
				nume
				contractNo
				contractDate
				siteName
				departament
				validat
				tarifarIncadrare
				minimumWage
				hasMinimumWage
				minimumWageYearsExceeded
				minimumWageDate
				modelContract {
					${modelContractFieldsText}
				}
				statusAngajat {
					${statusAngajatFieldsText}
				}
			}
		}
	}
}`;


const insertEmployeeMutationText = gql`
mutation insertEmployee($clientEid: String!, $externalId: String!, $data: payroll_EmployeeRepresentationInput!){
	payroll {
		insertEmployeeUsingPUT(clientEid: $clientEid, externalId: $externalId, data: $data) {
			externalId
		}
	}
}`;

const invalidateEmployeeMutationText = gql`
mutation invalidateEmployee($clientEid: String!, $externalId: String!) {
	payroll {
		invalidateEmployeeUsingPUT(clientEid: $clientEid, externalId: $externalId) {
			Message
		}
	}
}`;

const insertGradHandicapMutationText = gql`
mutation insertGradHandicap($clientEid: String!, $employeeEid: String!, $externalId: String!, $data: payroll_HandicapRepresentationInput!){
	payroll {
		insertHandicapUsingPUT(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId, data: $data) {
			externalId
		}
	}
}`;

const deleteGradHandicapMutationText = gql`
mutation deleteGradHandicap($clientEid: String!, $employeeEid: String!, $externalId: String!){
	payroll {
		deleteHandicapUsingDELETE(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId) {
			Message
		}
	}
}`;

const insertCoasiguratMutationText = gql`
mutation insertCoasigurat($clientEid: String!, $employeeEid: String!, $externalId: String!, $data: payroll_CoasiguratRepresentationInput!){
	payroll {
		insertCoasiguratUsingPUT(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId, data: $data) {
			externalId
		}
	}
}`;

const deleteCoasiguratMutationText = gql`
mutation deleteCoasigurat($clientEid: String!, $employeeEid: String!, $externalId: String!){
	payroll {
		deleteCoasiguratUsingDELETE(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId) {
			Message
		}
	}
}`;

const insertDeducereMutationText = gql`
mutation insertDeducere($clientEid: String!, $employeeEid: String!, $externalId: String!, $data: payroll_DeducereRepresentationInput!){
	payroll {
		insertDeducereUsingPUT(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId, data: $data) {
			externalId
		}
	}
}`;

const deleteDeducereMutationText = gql`
mutation deleteDeducere($clientEid: String!, $employeeEid: String!, $externalId: String!){
	payroll {
		deleteDeducereUsingDELETE(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId) {
			Message
		}
	}
}`;

const insertSuspendareMutationText = gql`
mutation insertSuspendare($clientEid: String!, $employeeEid: String!, $externalId: String!, $data: payroll_SuspendareEmployeeRepresentationInput!){
	payroll {
		insertSuspendareUsingPUT(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId, data: $data) {
			externalId
		}
	}
}`;

const deleteSuspendareMutationText = gql`
mutation deleteSuspendare($clientEid: String!, $employeeEid: String!, $externalId: String!){
	payroll {
		deleteSuspendareUsingDELETE(clientEid: $clientEid, employeeEid: $employeeEid, externalId: $externalId) {
			Message
		}
	}
}`;

const insertTerminareMutationText = gql`
mutation insertTerminare($clientEid: String!, $data: payroll_EmployeeTerminationRepresentationInput!){
	payroll {
		terminateEmployeeUsingPUT(clientEid: $clientEid, data: $data) {
			Message
		}
	}
}`;

const reactivateEmployeMutationText = gql`
mutation reactivateEmployee($clientEid: String!, $externalId: String!, $comment: String!){
	payroll {
		reactivateEmployeeUsingPOST(clientEid: $clientEid, externalId: $externalId, comment: $comment) {
			Message
		}
	}
}`;

const removeEmployeeMutationText = gql`
mutation removeEmployee($clientEid: String!, $employeeEid: String!){
	payroll {
		removeEmployeeUsingDELETE(clientEid: $clientEid, employeeEid: $employeeEid) {
			Message
		}
	}
}`;

const validatePersonEmployeeMutationText = gql`
mutation validatePersonEmployee($clientEid: String!, $externalId: String!){
	payroll {
		validateEmployeeUsingPUT(clientEid: $clientEid, externalId: $externalId) {
			Message
		}
	}
}`;

const clientCaenQueryText = gql`
query clientCaen($clientEid: String, $atDate: Int) {
	accounts {
		GetClientUsingGET(clientEid: $clientEid, atDate: $atDate) {
			sysCaenCode {
				code
				description
			}
		}
	}
}`;


@Injectable({
	providedIn: 'root'
})
export class EmployeesService {

	private sortStream = new BehaviorSubject<string>('contractDate asc');
	private filtersStream = new BehaviorSubject<string>(null);
	private pageSizeStream = new BehaviorSubject<number>(20);
	public pageIndexStream = new BehaviorSubject<number>(0);
	public currentListModel : EmployeeListModel;

	get filters() {
		return this.filtersStream.value;
	}
	set filters(value: string) {
		this.filtersStream.next(value);
	}

	get pageSize() {
		return this.pageSizeStream.value;
	}
	set pageSize(value: number) {
		this.pageSizeStream.next(value);
	}

	get pageIndex() {
		return this.pageIndexStream.value;
	}
	set pageIndex(value: number) {
		this.pageIndexStream.next(value);
	}

	constructor(private authService: AuthService, private restService: RestService, private configService: ConfigService, private uploadService: UploadService,
		private spinner: SpinnerService, private downloadService: DownloadService, private http: HttpClient, private toastr: ToastrService) { }

	// need it in vehicle-edit.component
	public listSourceStream(clientEid: string, onlyActive: boolean = false): Observable<any[]> {
		var self = this,
			stream = function (clientEid: string) {
				return self.restService
					.query({
						query: employeesListSourceQueryText,
						variables: { clientEid: clientEid },
						fetchPolicy: 'network-only'
					})
					.pipe(
						map((res: any) => {
							if (res) {
								const employees = JSON.parse(JSON.stringify(res.data.accounts.getPersonsForAccountingUsingGET));
								employees.forEach((employee: any) => {
									employee.name = `${employee.lastName} ${employee.firstName}`;
								});
								if (onlyActive) {
									return employees.filter((e: any) => e.isActive);
								}
								return employees;
							} else {
								return [];
							}
						})
					);
			}
		if (!clientEid) {
			return self.authService.getSelectedCompanyId()
				.pipe(
					switchMap((companyId) => {
						return stream(companyId);
					})
				);
		}
		return stream(clientEid);
	}

	/* ------------------------------------------------------------------------------------------------------------ */
	/**
	* Employee list grid
	*/
	public fetchEmployeeList(debounce: number = 500): Observable<EmployeeListModel> {
		return combineLatest([this.authService.getSelectedCompanyId(), this.filtersStream, this.sortStream, this.pageSizeStream, this.pageIndexStream])
			.pipe(
				debounceTime(debounce),
				switchMap(([clientEid, filter, sort, pageSize, pageIndex]) => {
					if (clientEid) {
						return this.restService
							.query({
								query: employeeListQueryText,
								variables: {
									clientEid: clientEid,
									filter,
									order: sort,
									offset: pageIndex * pageSize,
									count: pageSize
								},
								fetchPolicy: 'network-only'
							})
							.pipe(
								map((response: any) => {
									let result: EmployeeListModel = null;
									try {
										result = plainToClass(EmployeeListModel, response.data.payroll.getEmployeesUsingGET);
										result.data = plainToClass<EmployeeModel, object>(EmployeeModel, result.data || []);
									} catch {
										result = null;
									}
									this.currentListModel = result;
									return result;
								})
							);
					}
					return of(null);
				})
			);
	}

	public fetchAllEmployeeList(): Observable<EmployeeListModel> {
		if (this.authService.user.selectedCompanyId == null)
			return;
		
		return this.restService
			.query({
				query: employeeListQueryText,
				variables: { clientEid: this.authService.user.selectedCompanyId, filter: null, order: null, offset: null, count: null },
				fetchPolicy: 'network-only'
			},
			{ spinner: false, error: false })
			.pipe(
				map((response: any) => {
					let result: EmployeeListModel = null;
					try {
						result = plainToClass(EmployeeListModel, response.data.payroll.getEmployeesUsingGET);
					} catch {
						result = null;
					}
					return result;
				})
			);
	}

	public filter(filter: string) {
		const self = this;
		self.pageIndexStream.next(0);
		self.filtersStream.next(filter);
	}

	public sort(sort: string) {
		if (this.sortStream.value != sort) {
			this.sortStream.next(sort);
		}
	}


	/**
	* Employee details
	*/
	public fetchEmployeeDetails(externalId: string, spinner: boolean = true): Observable<EmployeeDetailsModel> {
		return this.restService
			.query({
				query: employeeDetailsQueryText,
				variables: { 
					clientEid: this.authService.user.selectedCompanyId, 
					externalId,
					atDate: dateToInt(new Date())
				},
				fetchPolicy: 'network-only'
			}, { spinner: spinner })
			.pipe(
				map((response: any) => {
					if (response) {
						let personEmployeeModel: EmployeeDetailsModel = null;
						try {
							personEmployeeModel = plainToClass(EmployeeDetailsModel, response.data.payroll.getEmployeeUsingGET);
						} catch {
							personEmployeeModel = null;
						}
						return personEmployeeModel;
					}
					return null;
				})
			);
	}

	public invalidateEmployee(model: EmployeeDetailsModel) {
		return this.restService
			.mutate({
				mutation: invalidateEmployeeMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					externalId: model.externalId
				}
			}, { spinner: true })
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public insertOrUpdatePersonEmployee(model: EmployeeDetailsModel, spinner: boolean) {
		return this.restService
			.mutate({
				mutation: insertEmployeeMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					externalId: model.externalId,
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			}, { spinner: spinner })
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.payroll.insertEmployeeUsingPUT.externalId;
					}
					return null;
				})
			);
	}

	public getCompanySites() {
		return combineLatest([this.authService.getSelectedCompanyId(), this.configService.getPayrollUrl()])
			.pipe(
				switchMap(([clientEid, resultUrl]) => {
					let headers = new HttpHeaders();
					headers = headers.append('authorization', this.authService.authHeaderValue());
					headers = headers.append('Content-Type', "application/json");

					let params = {
						clientEid: clientEid,
					};
			
					let queryParams = buildQueryParams(params); 
		
					return this.http
						.get(`${resultUrl}/dictionaries/sys_sites`, {
							headers,
							params: queryParams
						})
						.pipe(
							map((response: any) => {
								return response;
							}),
							catchError((err: any) => {
								return of(null);
							}),
							finalize(() => {
							})
						)
				})
			);
	}

	public validatePersonEmployee(model: EmployeeDetailsModel) {
		return this.restService
			.mutate({
				mutation: validatePersonEmployeeMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					externalId: model.externalId,
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public generateContract(employeeId: string) {
		let clientEid = this.authService.user.selectedCompanyId;
		return this.configService
			.getPayrollUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/clients/${clientEid}/employees/${employeeId}/generate`;
					return this.downloadService.download(url);
				}),
				take(1)
			)
			.subscribe();
	}

	public generateModificareContract(employeeId: string, data: ChangeEmployeeSalaryModel): Observable<string> {
		let clientEid = this.authService.user.selectedCompanyId;
		return this.configService.getPayrollUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/clients/${clientEid}/employees/${employeeId}/generate/salary`;
					return this.downloadService.downloadUsingPost(url, classToPlain(data, { excludePrefixes: ['__'] }));
				})
			);
	}

	public generateDemisieAcordParti(employeeId: string, data: EmployeeTerminationModel): Observable<string> {
		let clientEid = this.authService.user.selectedCompanyId;
		return this.configService.getPayrollUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/clients/${clientEid}/employees/${employeeId}/generate/termination`;
					return this.downloadService.downloadUsingPost(url, classToPlain(data, { excludePrefixes: ['__'] }));
				})
			);
	}

	public downloadAttachment(fileEid: string) {
		return this.configService
			.getDocumentsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/storage/${fileEid}`;
					return this.downloadService.download(url);
				}),
				take(1)
			)
			.subscribe();
	}

	public uploadFile(employeeEid: string, file: File, spinner: boolean = true): Observable<string> {
		spinner && this.spinner.show();

		let fileEid = UID();
		const meta = {
			clientEid: this.authService.user.selectedCompanyId,
			angajatEid: employeeEid,
			category: 'AngajatAttachment',
			fileEid
		};
		return this.configService
			.getDocumentsUploadUrl()
			.pipe(
				switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
				filter((data) => !!data.done),
				map((data) => {
					return fileEid;
				}),
				finalize(() => {
					spinner && this.spinner.hide();
				})
			);
	}

	public uploadFileStream(employeeEid: string, file: File): Observable<AngajatAttachmentModel> {
		return this.uploadFile(employeeEid, file)
			.pipe(
				map((fileEid: string) => {
					if (fileEid) {
						let newAttachment = this.createAttachment(fileEid, file);
						return newAttachment;
					}
					return null;
				})
			);
	}

	private createAttachment(fileEid: string, file: File) {
		let newAttachment = new AngajatAttachmentModel();
		newAttachment.fileEid = fileEid;
		newAttachment.fileName = file.name;
		newAttachment.fileSize = file.size;
		newAttachment.fileType = "CI";
		
		return newAttachment;
	}


	/**
	* Grids: Handicap, Coasigurat, Deducere
	*/
	public insertOrUpdateGradHandicap(employeeId: string, model: HandicapModel) {
		return this.restService
			.mutate({
				mutation: insertGradHandicapMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: model.externalId != null ? model.externalId : UID(),
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.payroll.insertHandicapUsingPUT.externalId;
					}
					return null;
				})
			);
	}

	public deleteGradHandicap(employeeId: string, gradHandicalExternalId: string) {
		return this.restService
			.mutate({
				mutation: deleteGradHandicapMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: gradHandicalExternalId
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public insertOrUpdateCoasigurat(employeeId: string, model: CoasiguratModel) {
		return this.restService
			.mutate({
				mutation: insertCoasiguratMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: model.externalId != null ? model.externalId : UID(),
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.payroll.insertCoasiguratUsingPUT.externalId;
					}
					return null;
				})
			);
	}

	public deleteCoasigurat(employeeId: string, coasiguratExternalId: string) {
		return this.restService
			.mutate({
				mutation: deleteCoasiguratMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: coasiguratExternalId
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public insertOrUpdateDeducere(employeeId: string, model: DeducereModel) {
		return this.restService
			.mutate({
				mutation: insertDeducereMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: model.externalId != null ? model.externalId : UID(),
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.payroll.insertDeducereUsingPUT.externalId;
					}
					return null;
				})
			);
	}

	public deleteDeducere(employeeId: string, deducereExternalId: string) {
		return this.restService
			.mutate({
				mutation: deleteDeducereMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: deducereExternalId
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public insertOrUpdateSuspendare(employeeId: string, model: SuspendareModel) {
		return this.restService
			.mutate({
				mutation: insertSuspendareMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: model.externalId != null ? model.externalId : UID(),
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.payroll.insertSuspendareUsingPUT.externalId;
					}
					return null;
				})
			);
	}

		
	public deleteSuspendare(employeeId: string, suspendareExternalId: string) {
		return this.restService
			.mutate({
				mutation: deleteSuspendareMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId,
					externalId: suspendareExternalId
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public removeEmployee(employeeId: string, ) {
		return this.restService
			.mutate({
				mutation: removeEmployeeMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					employeeEid: employeeId
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public insertTerminare(model: TerminareModel) {
		return this.restService
			.mutate({
				mutation: insertTerminareMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					data: classToPlain(model, { excludePrefixes: ["__"] })
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public reactivateContract(employeeId: string, comment: string) {
		return this.restService
			.mutate({
				mutation: reactivateEmployeMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					externalId: employeeId,
					comment: comment
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}


	/**
	* Employee history
	*/
	public getEmployeeHistory(employeeExternalId: string, parameterName: string): Observable<any> {
		let sourceUrl = this.configService.payrollUrl;
		let clientEid = this.authService.user.selectedCompanyId;
        
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authService.authHeaderValue());
		headers = headers.append('Content-Type', "application/json");

		this.spinner.show();
		return this.http
			.get(`${sourceUrl}/clients/history/${clientEid}/employees/${employeeExternalId}/${parameterName}`, {
				headers
			})
			.pipe(
				map((response: any) => {
					let model: HistoryModel;
					let fields: HistoryFieldModel[];
					let changes: HistoryChangeModel[];
					
					try {
						fields = plainToClass<HistoryFieldModel, object>(HistoryFieldModel, response.fields || []);
					} catch {
						fields = null;
					}
					try {
						changes = plainToClass<HistoryChangeModel, object>(HistoryChangeModel, response.changes || []);
					} catch{
						changes = null;
					}

					model = new HistoryModel(fields, changes, sourceUrl);
					
					return model;
				}),
				catchError((err: any) => {
					this.restService.handleError(err);
					return of(null);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public addEmployeeParameterHistory(employeeExternalId: string, historyChangeAddModel: HistoryChangeAddModel): Observable<boolean> {
		let sourceUrl = this.configService.payrollUrl;
		let clientEid = this.authService.user.selectedCompanyId;
		let body = JSON.stringify(classToPlain<HistoryChangeAddModel>(historyChangeAddModel, { excludePrefixes: ["__"] }));
		
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authService.authHeaderValue());
		headers = headers.append('Content-Type', "application/json");

		this.spinner.show();
		return this.http
			.post(`${sourceUrl}/clients/history/${clientEid}/employees/${employeeExternalId}`, body, {
				headers: headers
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				}),
				catchError((err: any) => {
					this.restService.handleError(err);
					return of(false);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public invalidateEmployeeHistoryChange(employeeExternalId: string, historyChangeDeleteModel?: HistoryChangeDeleteModel): Observable<boolean> {
		let sourceUrl = this.configService.payrollUrl;
		let clientEid = this.authService.user.selectedCompanyId;
		let body = JSON.stringify(classToPlain<HistoryChangeDeleteModel>(historyChangeDeleteModel, { excludePrefixes: ["__"] }));
		
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authService.authHeaderValue());
		headers = headers.set('Content-Type', "application/json");

		this.spinner.show();
		return this.http
			.request('DELETE', `${sourceUrl}/clients/history/${clientEid}/employees/${employeeExternalId}`, {
				body: body,
				headers: headers
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				}),
				catchError((err: any) => {
					this.restService.handleError(err);
					return of(false);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public getClientCaenCodes(): Observable<any> {
		return this.restService
			.query({
				query: clientCaenQueryText,
				variables: { 
					clientEid: this.authService.user.selectedCompanyId, 
					atDate: dateToInt(new Date())
				},
				fetchPolicy: 'network-only'
			}, { spinner: false })
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.accounts.GetClientUsingGET.sysCaenCode;
					}
					return null;
				})
			);
	}
}
