<kendo-dialog *ngIf="showHistoryDialog" title="Istoric acțiuni" (close)="closeHistoryDialog()" [minWidth]="700" [width]="700">
    <div class="col-24 px-0 d-flex flex-wrap">
        <div *ngFor="let history of meetingRequestData.history" class="col-24 px-0">
            {{ history.createdDate | date: 'dd.MM.yyyy hh:mm' }}:
            <span *ngIf="history.eventType == 'Created'">
                <span class="font-weight-bold">{{ history.userName }}</span>
                a cerut o întâlnire pentru 
                <span class="font-weight-bold">{{ history.dateEx | date: 'dd.MM.yyyy'}}, </span>
                <span class="font-weight-bold">{{ history.fromTimeFormat }}</span>
                <span *ngIf="history.comment"> (</span>
                <span *ngIf="history.comment" class="font-weight-bold">{{ history.comment }})</span>
            </span>
            <span *ngIf="history.eventType == 'ProposedNewTime'">
                <span class="font-weight-bold">{{ history.userName }}</span>
                dorește reprogramarea pentru 
                    <span class="font-weight-bold">{{ history.dateEx | date: 'dd.MM.yyyy'}}, </span>
                    <span class="font-weight-bold">{{ history.fromTimeFormat }}</span>.
            </span>
            <span *ngIf="history.eventType == 'Accepted'">
                <span class="font-weight-bold">{{ history.userName }}</span>
                a acceptat întâlnirea.
            </span>
            <span *ngIf="history.eventType == 'Canceled'">
                <span class="font-weight-bold">{{ history.userName }}</span>
                a anulat întâlnirea.
            </span>
            <span *ngIf="history.eventType == 'AutoAccepted'">
                Întâlnirea a fost acceptată automat.
            </span>
        </div>
    </div>
</kendo-dialog>

<form #meetingRequestForm="ngForm">
    <div class="col-24 d-flex flex-wrap px-0 pb-2 align-items-center justify-content-end">
        <i *ngIf="hasHistory" class="col-auto mdi mdi-action-info-outline cursor-pointer font-size-1-5 mt-1 mb-1" title="Istoric" (click)="viewHistoryDialog()"></i>
		<i class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
    </div>
    <div class="col-24 d-flex flex-wrap px-0">
        <div class="col-24 d-flex flex-wrap justify-content-center">
            <img *ngIf="hasProfilePicture" src="{{ getProfilePicture() }}" class="profile-image" (error)="onProfileImageError($event)"/>
            <div *ngIf="!hasProfilePicture" class="d-flex initialLetters">{{ getInitialLetters() }}</div>
        </div>
        <h4 class="col-24 d-flex flex-wrap justify-content-center text-center py-3">
            {{ getMeetingText() }}
        </h4>
        <h5 class="col-24 d-flex flex-wrap justify-content-center meeting-date-info mb-0">
            {{ meetingRequestData.dateEx | date: "dd.MM.yyyy" }}
        </h5>
        <h5 class="col-24 d-flex flex-wrap justify-content-center meeting-date-info mb-0">
            {{ meetingRequestData.fromTimeFormat }} - {{ meetingRequestData.toTimeFormat }}
        </h5>
    </div>
    <div *ngIf="meetingRequestData.comment != undefined && meetingRequestData.comment != ''"
        class="col-24 d-flex flex-wrap justify-content-center mb-0 pt-2 meeting-comment">
        Agenda întâlnirii:
    </div>
    <div *ngIf="meetingRequestData.comment != undefined && meetingRequestData.comment != ''"
        class="col-24 d-flex flex-wrap justify-content-center mb-0 ws-pre-line max-height-100 overflow-y-auto text-center">
        {{ meetingRequestData.comment }}
    </div>
    <div *ngIf="meetingHasExpired" class="col-24 d-flex flex-wrap justify-content-center font-weight-italic pt-2">
        Sesiunea a expirat.
    </div>
    <div class="col-24 d-flex justify-content-center pt-5">
        <button *ngIf="allowAccept" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$AcceptRequest" (click)="this.acceptMeetingRequest()">
            <i class="mdi-navigation-check mr-2"></i>Acceptă
        </button>
        <button *ngIf="allowChangeInterval" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$ChangeRequest" (click)="this.changeMeetingRequest()">
            <i class="mdi-image-timer mr-2"></i>Propune alt interval
        </button>
        <button *ngIf="allowCancel" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$CancelRequest" (click)="this.cancelMeetingRequest()">
            <i class="mdi-action-delete mr-2"></i>Anulează
        </button>
    </div>
    <!-- <div class="col-24 d-flex justify-content-between pt-3">
        <div class="d-flex flex-wrap">
            <button *ngIf="allowAccept" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$AcceptRequest" (click)="this.acceptMeetingRequest()">
                <i class="mdi-navigation-check mr-2"></i>Acceptă
              </button>
              <button *ngIf="allowChangeInterval" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$ChangeRequest" (click)="this.changeMeetingRequest()">
                  <i class="mdi-image-timer mr-2"></i>Propune alt interval
              </button>
              <button *ngIf="allowCancel" class="btn btn-primary waves-effect waves-light mr-2" type="submit" name="action" i18n="@@Actions$CancelRequest" (click)="this.cancelMeetingRequest()">
                  <i class="mdi-action-delete mr-2"></i>Anulează
              </button>
        </div>
        <div class="d-flex flex-wrap">
            <button class="btn waves-effect waves-light" type="button" name="action" i18n="@@Actions$Cancel" (click)="this.close.emit()">
                <i class="mdi-content-clear mr-2"></i>Renunță
            </button>
        </div>
    </div> -->
</form>