import { Component, Input, OnInit, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { LabeledControl } from '../../labeledcontrol';

export const NUMERICTEXTBOX_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeNumericTextboxComponent),
	multi: true
};

@Component({
	selector: 'kee-numerictextbox',
	templateUrl: 'numerictextbox.component.html',
	providers: [NUMERICTEXTBOX_VALUE_ACCESSOR],
})
export class KeeNumericTextboxComponent extends LabeledControl<Number> implements OnInit {

	//#region properties

	//#region format

	@Input() public format: string;

	//#endregion

	//#region decimals

	@Input() public decimals: number;

	//#endregion

	//#region min

	@Input() public min: number;

	//#endregion

	//#region max

	@Input() public max: number;

	//#endregion

	//#region autoCorrect

	@Input() public autoCorrect: boolean = false;

	//#endregion

	@Input() public infoMessage: string;

	//#endregion

	//#region constructor

	public constructor(injector: Injector,) {
		super(injector);
	}

	//#endregion

	//#region methods

	public ngOnInit() {
		super.ngOnInit();
	}

	//#endregion
}
