import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ClientAccDocumentsService } from "src/app/clients/documents/acc-documents.service";
import { ClientDocumentItemComponent } from "src/app/clients/documents/document-item.component";
import { AuthService } from "../auth/auth.service";
import { DocumentFileModel } from "../documents/documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";
import { IClientDocumentItemParent } from "./client-document-item-parent";

@Component({
	selector: 'client-document-host',
	templateUrl: 'client-document-host.component.html',
	providers: [{ provide: IClientDocumentItemParent, useExisting: forwardRef(() => ClientDocumentHostComponent) }]
})
export class ClientDocumentHostComponent extends IClientDocumentItemParent implements OnInit {
	@Input() public model: DocumentFileModel;
	@Input() public documentType: string;
	@Input() public documentsNavigator: NavigableDataSource<string, DocumentFileModel>;
	@Output() public close = new EventEmitter();

	@ViewChild('clientDocumentItem') private clientDocumentItem: ClientDocumentItemComponent;
	
	public hasMarkUsableRight: boolean = false;
	public hasDeleteRight: boolean = false;
	public hasDeleteOwnRight: boolean = false;
	public hasAdnotateRight: boolean = false;
	public hasAdnotateOwnRight: boolean = false;
	public hasEFacturaDeleteRight: boolean = false;

	constructor(public documentsService: ClientAccDocumentsService, private authService: AuthService) {
		super();

		// from acc-documents.component.ts
		this.hasMarkUsableRight = this.authService.isInRole('ACCNT_DOCUMENTS_MARK_USABLE');
		this.hasDeleteRight = this.authService.isInRole('ACCNT_DOCUMENTS_DELETE');
		this.hasDeleteOwnRight = this.authService.isInRole('ACCNT_DOCUMENTS_DELETE_OWN');
		this.hasAdnotateOwnRight = this.authService.isInRole('ACCNT_DOCUMENTS_ADNOTATE_OWN');
		this.hasAdnotateRight = this.authService.isInRole('ACCNT_DOCUMENTS_ADNOTATE');
		this.hasEFacturaDeleteRight = this.authService.isInRole('EFACTURA_CLIENT_OPS');
	}

	ngOnInit() {
		setTimeout(() => {
			if (this.clientDocumentItem != undefined) {
				this.clientDocumentItem.showDocument = true;
			}
		}, 100);
	}
}