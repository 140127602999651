import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe, IntlService } from '@progress/kendo-angular-intl';
import { CLIENTS_ROUTES } from '@saliente/clients';
import { EXPERT_ROUTES } from '@saliente/expert';
import { $, MessageBoxService, UID, markdown } from '@saliente/library';
import { ToastrService } from 'ngx-toastr';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, first, take } from 'rxjs/operators';
import { CLIENT_FORMS_ROUTES } from 'src/app/client-forms';
import { PredefinedUserZone } from '../auth/auth.models';
import { AuthService } from '../auth/auth.service';
import { COMMON_ROUTES } from '../common.routes.constants';
import { DocumentIndentificationModel } from '../document-display/document-display.models';
import { PageContentComponent } from '../general/pagecontent.component';
import { RestService } from '../general/rest.service';
import { ChatStatus } from './chat.enums';
import { ChatEditCommentModel, ChatMessageModel, ChatModel, ChatTypeModel, ClientChatCommentInputModel, ClientChatInputModel } from './chat.models';
import { ChatService } from './chat.service';
import * as Meetings from './meetings/adapters/meetings-api.generated';
import { ExpertInfoPersonModel, MeetingRequestDataHistoryModel, MeetingRequestDataModel, MeetingRequestModel } from './meetings/meetings.model';

const nativeElement = (component: any): any => {
	if (component) {
		return component.nativeElement;
	}
	return null;
};

const clientDocumentRouteUrl = `${CLIENTS_ROUTES.ROOT}/${CLIENTS_ROUTES.ACC_DOCUMENTS}/`;
const expertDocumentRouteUrl = `${EXPERT_ROUTES.ROOT}/${EXPERT_ROUTES.DOCUMENTS}/`;
const payrollDocumentRouteUrl = `${CLIENTS_ROUTES.ROOT}/${CLIENTS_ROUTES.PAYROLL_DOCUMENTS}/`;
const microProfitFormRouteUrl = { 'form': `${CLIENT_FORMS_ROUTES.ROOT}/`, 'microProfit': `/${CLIENT_FORMS_ROUTES.MICRO_PROFIT_FORM}/`};
const duPFAFormRouteUrl = { 'form': `${CLIENT_FORMS_ROUTES.ROOT}/`, 'duPFA': `/${CLIENT_FORMS_ROUTES.DU_PFA_FORM}/`};
const mp2024FormRouteUrl = { 'form': `${CLIENT_FORMS_ROUTES.ROOT}/`, 'mp2024': `/${CLIENT_FORMS_ROUTES.MP2024_FORM}/`};
const meetingRouteUrl = `${COMMON_ROUTES.MEETING_1}/`;
const meetingRequestRouteUrl = { 'base': `${COMMON_ROUTES.MEETING}/${CLIENTS_ROUTES.ROOT_1}/`, 'meetings': '/meetings/'};

@Component({
	selector: 'chat',
	templateUrl: 'chat.component.html',
	styleUrls: ['./chat.component.scss'],
	providers: [{ provide: PageContentComponent, useExisting: forwardRef(() => ChatComponent) }],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent extends PageContentComponent implements OnInit, OnDestroy {
	private _messagesContainerEl: any;
	@ViewChild('messagesContainer', { static: false }) set messagesContainer(value: any) {
		const valueEl = nativeElement(value);
		if (this._messagesContainerEl !== valueEl) {
			this.unhookMessagesContainer();
			this._messagesContainerEl = valueEl;
			this.hookMessagesContainer();
		}
		this.windowLayoutProps();
		this.resizeTextInput();
	}

	private _unreadMessagesEl: any;
	@ViewChild('unreadMessages', { static: false }) set unreadMessages(value: any) {
		const valueEl = nativeElement(value);
		if (this._unreadMessagesEl !== valueEl) {
			this._unreadMessagesEl = valueEl;
		}
	}

	private _prevMessagesLoaderEl: any;
	@ViewChild('prevMessagesLoader', { static: false }) set prevMessagesLoader(value: any) {
		const valueEl = nativeElement(value);
		if (this._prevMessagesLoaderEl !== valueEl) {
			this._prevMessagesLoaderEl = valueEl;
		}
	}

	private _nextMessagesLoaderEl: any;
	@ViewChild('nextMessagesLoader', { static: false }) set nextMessagesLoader(value: any) {
		const valueEl = nativeElement(value);
		if (this._nextMessagesLoaderEl !== valueEl) {
			this._nextMessagesLoaderEl = valueEl;
		}
	}

	public isClientAdmin: boolean = true;
	public isExpert: boolean = false;
	public isHrExpert: boolean = false;
	public isSpecialCareOps: boolean = false;

	private _chatRoomSearch: string;
	public get chatRoomSearch(): string {
		return this._chatRoomSearch;
	}
	public set chatRoomSearch(value: string) {
		if (value !== this._chatRoomSearch) {
			this._chatRoomSearch = value;
			this.delayedFilterRooms();
		}
	}

	public chats: ChatModel[] = [];
	public filteredChats: ChatModel[] = [];
	public filteredChatsTemp: ChatModel[] = [];
	public chatsSubscription: Subscription;
	public chatTypes: ChatTypeModel[] = [];
	public filteredChatTypes: ChatTypeModel[] = [];
	public chatTypesSubscription: Subscription;
	public messages: ChatMessageModel[] = [];
	public messagesSubscription: Subscription;
	public zoneSubscription: Subscription;
	private resizeSubscription: Subscription;
	private scrollSubscription: Subscription;
	private dataChangedSubscription: Subscription;

	public loadingInitialChunk = false;
	public userEid: string;
	private loadingPrevChunk = false;
	public prevChunkEid: string;
	private loadingNextChunk = false;
	public nextChunkEid: string;
	public displayMessageId: number;

	public ChatStatus = ChatStatus;
	public filterStatus: string;
	public now: number;
	public isFromRefresh: boolean = false;
	public showMarkChatAnsweredDialog: boolean;
	public chatOpInput: ChatEditCommentModel;

	public authorIsKeez: boolean;
	public authorIsExpert: boolean;

	private _message = '';
	public get message(): string {
		return this._message;
	}
	public set message(value: string) {
		if (this._message !== value) {
			this._message = value;
			this.resizeTextInput();
		}
	}

	public showDocumentDialog: boolean = false;
	public documentIndentification: DocumentIndentificationModel;

	public showMicroProfitForm: boolean = false;
	public clientEid: string;
	public instance: string;
	public showDuPFAForm: boolean = false;
	public showMp2024Form: boolean = false;

	public showExpertStartMeeting: boolean = false;
	public showClientStartMeeting: boolean = false;
	
	// meeting calendar
	public showMeetingCalendar: boolean = false;
	public fromProposal: boolean;
	public meetingRequest: MeetingRequestModel ; //calendar time slots

	public showMeetingRequest: boolean = false;
	public meetingRequestData: MeetingRequestDataModel; //info
	public expertInfoPersonModel: ExpertInfoPersonModel;
	public meetingInfoLastHistory: MeetingRequestDataHistoryModel[] = [];
	public showMeetingInfo: boolean = false;

	public showScrollToBottom: boolean = false;

	constructor(
		private authService: AuthService,
		public chatService: ChatService,
		private zone: NgZone,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private router: Router,
		private toastr: ToastrService,
		public intlService: IntlService,
		private restService: RestService,
		public messageBoxService: MessageBoxService
	) {
		super(authService);
		this.rootCssClass = 'chat-page';
		this.isClientAdmin = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
		this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert;
		this.isHrExpert = this.authService.selectedZoneCode == PredefinedUserZone.HrExpert;
		this.isSpecialCareOps = this.authService.isInRole('SPECIAL_CARE_OPS');
		this.userEid = this.authService.user.externalId;

		this.authorIsExpert = this.authService.isInRole("EXPERT") || this.authService.isInRole("HR_EXPERT");
		this.authorIsKeez = this.authService.isInRole("KEEZ_USER");
		this.showExpertStartMeeting = this.authService.isInRole("CONNECT_START_VIDEO");
		this.showClientStartMeeting = this.authService.isInRole("CONNECT_CLIENT_START_VIDEO");
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.now = new Date().getTime();

		!this.isClientAdmin && (this._chatRoomSearch = this.authService.user.selectedCompany.companyName);
		this.chatsSubscription = this.chatService.chats.subscribe((chats) => {
			if (this.isFromRefresh) {
				this.isFromRefresh = false;
				this.mergeChats(chats);
				// this.chatService.sortChats(this.chats);
			}
			else {
				this.chats = chats.slice();
			}

			this.chatService.sortChats(this.chats);
			this.filterRooms();
			this.scrollRoomIntoView();
		});

		this.chatTypesSubscription = this.chatService.chatTypes.subscribe((chatTypes) => {
			this.chatTypes = chatTypes.slice();
		});
		this.messagesSubscription = this.chatService.messages.subscribe((messages) => {
			this.messages = messages;
			this.prevChunkEid = this.chatService.prevChunkEid;
			this.nextChunkEid = this.chatService.nextChunkEid;

			if (this.loadingInitialChunk) {
				this.displayMessageId = this.chatService.displayMessageId;
			}
			this.onNewMessages();
			if (this.nextChunkEid != null) {
				this.showScrollToBottom = true;
			}
		});
		this.zoneSubscription = this.authService.getSelectedZoneCode().subscribe((zone: string) => {
			this.isClientAdmin = zone === PredefinedUserZone.Administrator;
		});
		this.windowLayoutProps();
		this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
			this.windowResize();
		});

		this.dataChangedSubscription = this.chatService.$$dataChanged.subscribe(() => {
			this.detectChanges();
		});
		this.message = this.chatService.restore();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		if (this.chatsSubscription) {
			this.chatsSubscription.unsubscribe();
			this.chatsSubscription = null;
		}
		if (this.chatTypesSubscription) {
			this.chatTypesSubscription.unsubscribe();
			this.chatTypesSubscription = null;
		}
		if (this.messagesSubscription) {
			this.messagesSubscription.unsubscribe();
			this.messagesSubscription = null;
		}
		if (this.zoneSubscription) {
			this.zoneSubscription.unsubscribe();
			this.zoneSubscription = null;
		}
		if (this.resizeSubscription) {
			this.resizeSubscription.unsubscribe();
			this.resizeSubscription = null;
		}
		if (this.dataChangedSubscription) {
			this.dataChangedSubscription.unsubscribe();
			this.dataChangedSubscription = null;
		}
		this.unhookMessagesContainer();
		this.chatService.leave(this.message);
	}

	private detectChanges() {
		this.cdr.detectChanges();
	}

	private setTimeout(callback: Function, timeout: number) {
		return this.zone.runOutsideAngular(() => {
			return setTimeout(callback, timeout);
		});
	}

	private clearTimeout(timeout: any) {
		this.zone.runOutsideAngular(() => {
			clearTimeout(timeout);
		});
	}

	private filterRoomsTimeout: any;
	private delayedFilterRooms() {
		const self = this;

		self.chatService.sortChats(self.chats);
		
		if (self.filterRoomsTimeout) {
			self.clearTimeout(self.filterRoomsTimeout);
		}
		self.filterRoomsTimeout = self.setTimeout(() => {
			self.filterRooms();
		}, 500);
	}

	private filterRooms() {
		if (this._chatRoomSearch) {
			const searchText = this._chatRoomSearch.toLowerCase();
			this.filteredChatsTemp = this.chats.filter((c) => 
				this.processString(c.clientName).indexOf(searchText) != -1 || this.processString(c.chatTypeName).indexOf(searchText) != -1 ||
				c.clientName.toLowerCase().indexOf(searchText) != -1 || c.chatTypeName.toLowerCase().indexOf(searchText) != -1);
		} else {
			this.filteredChatsTemp = this.chats.slice();
		}

		this.filterRoomsByStatus(this.filterStatus);

		this.detectChanges();
	}

	public filterRoomsByStatus(filterStatus?: string) {
		this.filterStatus = filterStatus;

		if (filterStatus == ChatStatus.Unanswered) {
			this.filteredChats = this.filteredChatsTemp.filter((c) => c.chatStatus > 0);
		}
		else {
			this.filteredChats = this.filteredChatsTemp.slice();
		}
	}

	private scrollRoomIntoView() {
		this.zone.onStable.pipe(debounceTime(100), take(1)).subscribe(() => {
			const $selectedRoom = $('.room.selected');
			if ($selectedRoom.length) {
				const $chatRoomsList = $('#chatRoomsList');
				const containerScrollTop = $chatRoomsList.scrollTop();
				const containerHeight = $chatRoomsList.height();
				const offsetTop = $selectedRoom[0].offsetTop;
				const deltaTop = offsetTop - containerScrollTop;
				const deltaBottom = containerScrollTop + containerHeight - offsetTop;
				if (deltaTop < -10 || deltaBottom < 100) {
					$selectedRoom[0].scrollIntoView();
				}
			}
		});
	}

	private hookMessagesContainer() {
		if (this._messagesContainerEl) {
			this.scrollSubscription = fromEvent(this._messagesContainerEl, 'scroll')
				.pipe(debounceTime(500))
				.subscribe(() => {
					this.messagesContainerScroll();
					this.onContentScrolled();
				});
		}
	}

	private unhookMessagesContainer() {
		if (this.scrollSubscription) {
			this.scrollSubscription.unsubscribe();
			this.scrollSubscription = null;
		}
	}

	public messageBubbleClass(message: ChatMessageModel, messageIndex: number) {
		let baseClass = message.authorEid == this.userEid ? 'top-right' : 'top-left';
		if (!message.messageId && message.messageId != 0) {
			baseClass += ' grey-text text-lighten-1';
		}

		const arrowClass = baseClass + ' arrow mt-3';
		const noArrowClass = baseClass + ' mt-1';
		if (messageIndex === this.messages.length - 1) {
			return arrowClass;
		}
		const nextMessage = this.messages[messageIndex + 1];
		if (message.authorEid === nextMessage.authorEid && message.datePostedInt == nextMessage.datePostedInt) {
			return noArrowClass;
		}
		return arrowClass;
	}

	public get isInAnyRoom(): boolean {
		return this.chatService.isInAnyRoom;
	}

	public isCurrent(chat: ChatModel) {
		return this.chatService.currentChatRoom == chat;
	}

	public joinRoom(chat: ChatModel) {
		const currentChatRoom = this.chatService.currentChatRoom;
		if (!currentChatRoom || currentChatRoom.clientEid != chat.clientEid || currentChatRoom.chatTypeEid != chat.chatTypeEid) {
			this.loadingInitialChunk = true;
			this.messages = [];
			this.filteredChatTypes = this.chatTypes.filter((ct) => ct.externalId != chat.chatTypeEid);
			this.detectChanges();
			this.chatService.join(chat);
			this.loadingPrevChunk = false;
			this.loadingNextChunk = false;
		}
	}

	public sendMessage() {
		if (this._message) {
			//this.chatService.sendMessageByStomp(this._message);
			this.chatService.sendMessage(this._message);
			this._message = '';
			this.detectChanges();
			this.delayedResizeTextInput();
		}
	}

	private onNewMessages() {
		const self = this;
		const scrollToEnd = (): void => {
			if (self._messagesContainerEl) {
				setTimeout(() => {
					self._messagesContainerEl.scrollTo({ top: self._messagesContainerEl.scrollHeight + 10 });
				});
			}
		};
		if (self.loadingInitialChunk) {
			self.detectChanges();
			if (self.displayMessageId && self._unreadMessagesEl) {
				self._unreadMessagesEl.scrollIntoView();
				if (self._unreadMessagesEl.offsetTop > 10) {
					scrollToEnd();
				}
			} else {
				scrollToEnd();
			}
			self.delayedMarkAsRead();
		} else if (self.loadingPrevChunk) {
			const scrollTopOffset = self._messagesContainerEl.scrollHeight - self._messagesContainerEl.scrollTop;
			self.detectChanges();
			self._messagesContainerEl.scrollTo({ top: self._messagesContainerEl.scrollHeight - scrollTopOffset });
		} else if (self.loadingNextChunk) {
			const scrollTop = self._messagesContainerEl.scrollTop;
			self.detectChanges();
			self._messagesContainerEl.scrollTo({ top: scrollTop });
		} else {
			scrollToEnd();
		}
		self.loadingInitialChunk = false;
		self.loadingPrevChunk = false;
		self.loadingNextChunk = false;
		self.detectChanges();
	}

	public sendBulk() {
		const self = this;
		for (let i = 0; i < 13; i++) {
			setTimeout(() => {
				self.chatService.sendMessage(`linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}
        linia ${new Date().getTime()}`);
			}, i * 500);
		}
	}

	public textInputKeyDown(e: any) {
		if (e.keyCode === 13 && e.ctrlKey) {
			this._message = (this._message || '') + '\n';
			$('#textInput').val(this._message);
		}
		this.delayedResizeTextInput();
	}

	public textInputKeyPress(e: any) {
		if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
			this.sendMessage();
			return false;
		}
	}

	private delayedResizeTextInputTimeout: any;
	public delayedResizeTextInput() {
		const self = this;
		if (self.delayedResizeTextInputTimeout) {
			self.clearTimeout(self.delayedResizeTextInputTimeout);
		}
		self.delayedResizeTextInputTimeout = self.setTimeout(() => {
			self.resizeTextInput();
		}, 200);
	}

	private resizeTextInput() {
		const $textInput = $('#textInput');
		$textInput.height(this.getTextSize($textInput.val()));
	}

	private getTextSize(text: string) {
		const $textHiddenInput = $('#textHiddenInput');
		$textHiddenInput.val(text);
		$textHiddenInput.height('auto');
		$textHiddenInput.height($textHiddenInput.prop('scrollHeight'));
		return $textHiddenInput.height();
	}

	private isElementInView(element: any, fullyInView: boolean = false) {
		if ($(element).length > 0) {
			const pageTop = this.layoutProps.containerTop || this.layoutProps.pageTop;
			const pageBottom = this.layoutProps.containerBottom || this.layoutProps.pageBottom;
			const elementTop = $(element).offset().top;
			const elementBottom = elementTop + $(element).height();

			if (fullyInView === true) {
				return pageTop < elementTop && pageBottom > elementBottom;
			} else {
				return elementTop <= pageBottom && elementBottom >= pageTop;
			}
		}
		return false;
	}

	private layoutProps: any = {};

	private windowLayoutProps() {
		const self = this;
		const $window = $(window);
		self.layoutProps.pageHeight = $window.height();
		self.layoutProps.pageTop = 0;
		self.layoutProps.pageBottom = self.layoutProps.pageHeight;
		if (self._messagesContainerEl) {
			const $container = $(self._messagesContainerEl);
			const containerHeight = (self.layoutProps.containerHeight = $container.height());
			const containerTop = (self.layoutProps.containerTop = $container.offset().top);
			self.layoutProps.containerBottom = containerTop + containerHeight;
		} else {
			self.layoutProps.containerHeight = 0;
			self.layoutProps.containerTop = 0;
			self.layoutProps.containerBottom = 0;
		}

		/*
		self.layoutProps.unread = [];
		if (self.displayMessageId) {
			self.layoutProps.unread = self.messages.filter((m) => m.messageId > self.displayMessageId)
				.map((m) => {
					const $element = $(`#message${m.messageId}`);
					const height = $element.height() || 0,
						offsetTop = $element.prop('offsetTop') || 0;
					return {
						messageId: m.messageId,
						height,
						offsetTop,
						isInView(scrollTop: number) {
							let absoluteTop = this.offsetTop,
								absoluteBottom = absoluteTop + this.height,
								deltaTop = absoluteTop - scrollTop,
								deltaBottom = scrollTop + self.layoutProps.containerHeight - absoluteBottom;
							return !!$element.length && deltaTop > -10 && deltaBottom > -10;
						}
					};
				});
		}*/
	}

	private windowResize() {
		this.resizeTextInput();
		this.windowLayoutProps();
	}

	private checkMessagesSubscription: Subscription;
	private checkMessages() {
		if (this.checkMessagesSubscription) {
			this.checkMessagesSubscription.unsubscribe();
		}
		this.checkMessagesSubscription = this.zone.onStable.pipe(debounceTime(100), take(1)).subscribe(() => {
			if (!this.loadingPrevChunk && this.prevChunkEid && this._prevMessagesLoaderEl && this.isElementInView(this._prevMessagesLoaderEl)) {
				this.loadingPrevChunk = true;
				this.chatService.prevMessages();
			}
			if (!this.loadingNextChunk && this.nextChunkEid && this._nextMessagesLoaderEl && this.isElementInView(this._nextMessagesLoaderEl)) {
				this.loadingNextChunk = true;
				this.chatService.nextMessages();
			}
		});
	}

	private messagesContainerScroll() {
		this.checkMessages();
		//this.delayedMarkAsRead();
	}

	private markAsReadTimeout: any;
	private delayedMarkAsRead() {
		const self = this;
		if (self.markAsReadTimeout) {
			self.clearTimeout(self.markAsReadTimeout);
		}
		self.markAsReadTimeout = self.setTimeout(() => {
			self.markAsRead();
		}, 1000);
	}

	private markAsRead() {
		/*if (this.layoutProps && this.layoutProps.unread) {
			const scrollTop = $(this._messagesContainerEl).scrollTop(),
				unreadMessages = this.layoutProps.unread,
				readMessagesIds = unreadMessages.filter((m: any) => m.isInView(scrollTop)).map((m: any) => m.messageId);
			if (readMessagesIds.length) {
				const messageId = Math.max.apply(null, readMessagesIds);
				this.chatService.messageSeen(messageId);
			}
		}*/
		this.chatService.allMessagesSeen();
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(e: Event): void {
		if (e.target && (e.target as any).tagName === 'A') {
			const el = e.target as HTMLElement;
			const linkURL = el.getAttribute && el.getAttribute('href');
			if (linkURL) {
				if (linkURL.toLowerCase() === 'https://app.keez.ro/clients/timesheet') {
					e.preventDefault();
					this.router.navigate(['/clients/timesheet']);
				}
				else if (linkURL.toLowerCase().includes(clientDocumentRouteUrl)) {
					e.preventDefault();
					this.getDocumentAndOpen(linkURL, clientDocumentRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(expertDocumentRouteUrl)) {
					e.preventDefault();
					this.getDocumentAndOpen(linkURL, expertDocumentRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(payrollDocumentRouteUrl)) {
					e.preventDefault();
					this.getDocumentAndOpen(linkURL, payrollDocumentRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(microProfitFormRouteUrl.form) && linkURL.toLowerCase().includes(microProfitFormRouteUrl.microProfit)) {
					e.preventDefault();
					this.getMicroProfitFormDataAndOpen(linkURL, microProfitFormRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(duPFAFormRouteUrl.form) && linkURL.toLowerCase().includes(duPFAFormRouteUrl.duPFA)) {
					e.preventDefault();
					this.getDuPFAFormDataAndOpen(linkURL, duPFAFormRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(mp2024FormRouteUrl.form) && linkURL.toLowerCase().includes(mp2024FormRouteUrl.mp2024)) {
					e.preventDefault();
					this.getMp2024FormDataAndOpen(linkURL, mp2024FormRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(meetingRequestRouteUrl.base) && linkURL.toLowerCase().includes(meetingRequestRouteUrl.meetings)) {
					e.preventDefault();
					this.getMeetingRequestAndOpen(linkURL, meetingRequestRouteUrl);
				}
				else if (linkURL.toLowerCase().includes(meetingRouteUrl)) {
					e.preventDefault();
					this.getMeetingDataAndOpen(linkURL, meetingRouteUrl);
				}
			}
			// else if (linkURL && linkURL.toLowerCase().startsWith("https://app.keez.ro/clients/accdocuments/locate")) {
			// 	e.preventDefault();
			// 	this.router.navigate(['/clients', 'accdocuments', 'locate', 'f469152e9d754bd3be6ff56318f4a1b0']);
			// }
		}

		// if (e.target && ((e.target as any).className).includes('k-overlay')) {
		// 	if (this.showDocumentDialog) {
		// 		this.closeDocumentDialog();
		// 	}
		// }
	}

	@HostListener("keydown", ["$event"])
	public keydown(event: any): void {
		if (event.keyCode === 27) {
			if (this.showDocumentDialog) {
				this.closeDocumentDialog();
			}
			if (this.showMicroProfitForm) {
				this.closeMicroProfitFormDialog();
			}
			if (this.showDuPFAForm) {
				this.closeDuPFAFormDialog();
			}
			if (this.showMp2024Form) {
				this.closeMp2024FormDialog();
			}
		}
	}

	public getDocumentAndOpen(linkURL: string, urlSourceRoute: string) {
		let urlStartPosition = linkURL.indexOf(`${urlSourceRoute}`) + urlSourceRoute.length;
		let documentEid = linkURL.substring(urlStartPosition);

		this.documentIndentification = new DocumentIndentificationModel();
		this.documentIndentification.externalId = documentEid;
		this.documentIndentification.clientEid = this.chatService.currentChatRoom.clientEid;

		if (urlSourceRoute == clientDocumentRouteUrl || urlSourceRoute == expertDocumentRouteUrl) {
			this.documentIndentification.documentType = "accounting";
		}
		else if (urlSourceRoute == payrollDocumentRouteUrl) {
			this.documentIndentification.documentType = "payroll";
		}

		this.openDocumentDialog();
	}

	public openDocumentDialog() {
		this.showDocumentDialog = true;
		setTimeout(() => {
			this.detectChanges();	
		}, 200);
	}

	public closeDocumentDialog() {
		this.showDocumentDialog = false;
	}

	public getMp2024FormDataAndOpen(linkURL: string, urlSourceRoute: any) {
		this.clientEid = linkURL.substring(linkURL.indexOf(`${urlSourceRoute.form}`) + urlSourceRoute.form.length, linkURL.indexOf(`${urlSourceRoute.mp2024}`));
		let instanceStartPosition = linkURL.indexOf(`${urlSourceRoute.mp2024}`) + `${urlSourceRoute.mp2024}`.length;
		this.instance = linkURL.substring(instanceStartPosition);

		this.openMp2024FormDialog();
	}

	public openMp2024FormDialog() {
		this.showMp2024Form = true;

		setTimeout(() => {
			this.detectChanges();	
		}, 200);
	}

	public closeMp2024FormDialog() {
		this.showMp2024Form = false;
		this.clearFormInputs();
	}

	public getDuPFAFormDataAndOpen(linkURL: string, urlSourceRoute: any) {
		this.clientEid = linkURL.substring(linkURL.indexOf(`${urlSourceRoute.form}`) + urlSourceRoute.form.length, linkURL.indexOf(`${urlSourceRoute.duPFA}`));
		let instanceStartPosition = linkURL.indexOf(`${urlSourceRoute.duPFA}`) + `${urlSourceRoute.duPFA}`.length;
		this.instance = linkURL.substring(instanceStartPosition);

		this.openDuPFAFormDialog();
		//this.router.navigate([CLIENT_FORMS_ROUTES.ROOT, this.clientEid, CLIENT_FORMS_ROUTES.DU_PFA_FORM, this.instance]);
	}

	public openDuPFAFormDialog() {
		this.showDuPFAForm = true;

		setTimeout(() => {
			this.detectChanges();	
		}, 200);
	}

	public closeDuPFAFormDialog() {
		this.showDuPFAForm = false;
		this.clearFormInputs();
	}

	public getMicroProfitFormDataAndOpen(linkURL: string, urlSourceRoute: any) {
		this.clientEid = linkURL.substring(linkURL.indexOf(`${urlSourceRoute.form}`) + urlSourceRoute.form.length, linkURL.indexOf(`${urlSourceRoute.microProfit}`));
		let instanceStartPosition = linkURL.indexOf(`${urlSourceRoute.microProfit}`) + `${urlSourceRoute.microProfit}`.length;
		this.instance = linkURL.substring(instanceStartPosition);

		//this.router.navigate([CLIENT_FORMS_ROUTES.ROOT, this.clientEid, CLIENT_FORMS_ROUTES.MICRO_PROFIT_FORM, this.instance]);
		this.openMicroProfitFormDialog();
	}

	public openMicroProfitFormDialog() {
		this.showMicroProfitForm = true;

		setTimeout(() => {
			this.detectChanges();	
		}, 200);
	}

	public closeMicroProfitFormDialog() {
		this.showMicroProfitForm = false;
		this.clearFormInputs();
	}

	public async getMeetingDataAndOpen(linkURL: string, urlSourceRoute: any) {
		let urlStartPosition = linkURL.indexOf(`${urlSourceRoute}`) + urlSourceRoute.length;
		let eid = linkURL.substring(urlStartPosition);

		let token = await this.chatService.joinVideoChatMeeting(eid);
		if (token) {
			console.log("meetingToken: " + token);
			let url = `${window.location.origin}/xmeeting/join/${token}`;
			window.open(url, '_blank', 'noopener noreferrer');
		}
	}

	public clearFormInputs() {
		this.clientEid = null;
		this.instance = null;
	}

	public markdown(message: string): string {
		return markdown(message);
	}

	public replyMessage(messageModel: ChatMessageModel): void {
		if (messageModel.content) {
			const datepipe: DatePipe = new DatePipe(this.intlService);
			let formattedDate = datepipe.transform(messageModel.datePosted, 'dd.MM.yyyy kk:mm')

			let content = messageModel.authorName + " - " + formattedDate + '\n\n' + messageModel.content;

			content = '> ' + content.replace('\r\n', '\n').split('\n').join('\n> ') + '\n\n';

			//textInput
			$('#textInput').height(this.getTextSize(content)).focus().val('').val(content);
		}
	}

	public moveMessage(message: ChatMessageModel, chatType: ChatTypeModel): void {
		this.chatService.moveMessageStream(message.messageId, chatType.externalId).pipe(take(1)).subscribe();
	}

	public notificationDotColor(chat: ChatModel) {
		if (this.isSpecialCareOps) {
			return 'unanswered-count-color0';
		}
		else if (chat.clientInSpecialCare && chat.chatStatus >= 1) {
			return 'unanswered-count-color3';
		}
		else {
			return this.notificationCountClass(chat.chatStatus);
		}
	}

	public notificationCountClass(chatStatus: number): string {
		var colorClass;
		switch (chatStatus) {
			case 0:
				colorClass = 'unanswered-count-color0';
				break;
			case 1:
				colorClass = 'unanswered-count-color1';
				break;
			case 2:
				colorClass = 'unanswered-count-color2';
				break;
			case 3:
				colorClass = 'unanswered-count-color3';
				break;
			default:
				colorClass = 'unanswered-count-color0'
				break;
		}

		return colorClass
	}

	public refresh() {
		this.isFromRefresh = true;
		this.chatService.initializeChats(true, true);
	}

	public markClientChatUrgent(chat: ChatModel) {
		var chatInputModel = new ClientChatInputModel();
		chatInputModel.clientEid = chat.clientEid;
		chatInputModel.chatExternalId = chat.chatTypeEid;
		
		this.chatService.markClientChatUrgent(chatInputModel)
			.pipe(take(1))
			.subscribe((response: boolean) => {
				if (response) {
					// CM 20211008
					//this.refresh();
					chat.setUrgent();
					this.detectChanges();
					this.toastr.success('Chat-ul a fost marcat important!');
				}
			});
	}

	public keepClientChatImportant(chat: ChatModel) {
		var chatInputModel = new ClientChatInputModel();
		chatInputModel.clientEid = chat.clientEid;
		chatInputModel.chatExternalId = chat.chatTypeEid;

		this.chatService.markClientChatImportant(chatInputModel)
			.pipe(take(1))
			.subscribe((response: boolean) => {
				if (response) {
					// CM 20211008
					//this.refresh();
					chat.keepImportant();
					this.detectChanges();
					this.toastr.success('Chat-ul a fost marcat nerezolvat!');
				}
			});
	}

	public markChatAnswered(chat: ChatModel) {
		// this.chatAnsweredInputModel = new ClientChatCommentInputModel();
		// this.chatAnsweredInputModel.clientEid = chat.clientEid;
		// this.chatAnsweredInputModel.chatExternalId = chat.chatTypeEid;

		if (chat.chatStatus == 3) {
			this.chatOpInput = new ChatEditCommentModel();
			this.chatOpInput.chat = chat;

			this.showMarkChatAnsweredDialog = true;
		}
		else {
			this.markClientChatAnswered(new ChatEditCommentModel({ chat: chat}));
		}
	}

	public markClientChatAnswered(chat: ChatEditCommentModel) {
		var chatInputModel = new ClientChatCommentInputModel();
		chatInputModel.clientEid = chat.chat.clientEid;
		chatInputModel.chatExternalId = chat.chat.chatTypeEid;
		chatInputModel.comment = chat.comment;

		this.chatService.markClientChatAnswered(chatInputModel)
			.pipe(take(1))
			.subscribe((response: boolean) => {
				if (response) {
					this.showMarkChatAnsweredDialog = false;
					chat.chat.setAnswered();
					this.detectChanges();
					// CM 20211008 do not refresh, just set chat properties accordingly
					//this.refresh();
					//this.chatAnsweredInputModel.chat.setAnswered();
					this.toastr.success('Chat-ul a fost marcat rezolvat!');
				}
			});
	}

	public closeMarkChatAnsweredDialog(save: boolean, chatCommentForm: NgForm = null) {
		if (save && chatCommentForm != null) {
			if (chatCommentForm.form != null && chatCommentForm.form.valid) {
				this.markClientChatAnswered(this.chatOpInput);
			}
		} else {
			this.showMarkChatAnsweredDialog = false;
		}
	}

	private mergeChats(chats: ChatModel[]) {
		this.chats = chats.slice();
		
		// for (var i=0;i<this.chats.length;i++) {
		// // this.chats.forEach((c) => {
		// 	var c = this.chats[i];
		// 	chats.forEach((nc) => {
		// 		if (c.clientEid == nc.clientEid && c.chatTypeEid == nc.chatTypeEid) {
		// 			// c.chatStatus = nc.chatStatus;
		// 			// c.firstUnseenMessage = nc.firstUnseenMessage;
		// 			// c.firstUnansweredMessage = nc.firstUnansweredMessage;
		// 			// c.lastMessageDelivered = nc.lastMessageDelivered;
		// 			// c.unseenMessages = nc.unseenMessages;
		// 			// c.unansweredMessages = nc.unansweredMessages;
		// 			this.chats[i] = nc;
		// 		}
		// 	})
		// }
		// //);
	}

	public onMessagesCountMouseOver(chatStatus: number): string {
		let messageTooltip: string;
		switch (chatStatus) {
			case 3:
				messageTooltip = "Urgent";
				break;
			case 2:
				messageTooltip = "Important";
				break;
			case 1:
				messageTooltip = "Așteaptă răspuns";
				break;
			case 0:
				messageTooltip = "Există răspuns";
				break;
			default:
				messageTooltip = "";
				break;
		}

		return messageTooltip;
	}

	private processString(text: string): string {
		var str;
		// remove accents/diacritics
		str = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

		// lower case
		return str.toLowerCase();
	}

	public async requestVideo() {
		//by Expert
		if (this.showExpertStartMeeting) {
			let m = new Meetings.CreateMeetingGQL(this.restService);
			await m.mutate({
				data: {
					chatTypeEid: this.chatService.currentChatRoom.chatTypeEid,
					clientEid: this.chatService.currentChatRoom.clientEid
				}
			}).toPromise();
		}
		//by Client
		else if (this.showClientStartMeeting && this.chatService.isFINChatRoom) {
			this.showMeetingInfo = true;
		}

		// this.refresh();
		// this.chatAnsweredInputModel.chat.setAnswered();
		// this.chatService.initializeChats(true, false);
	}

	public closeMeetingDialogInfo(agreed: boolean) {
		if (agreed) {
			this.openMeetingCalendarDialog();
		}

		this.showMeetingInfo = false;
	}

	public async openMeetingCalendarDialog(meetingRequestData?: MeetingRequestDataModel, fromProposal: boolean = false) {
		let self = this;
		let today = new Date(), tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1); //tomorrow

		this.meetingRequest = new MeetingRequestModel();
		this.meetingRequest.chatTypeEid = this.chatService.currentChatRoom.chatTypeEid;
		this.meetingRequest.dateEx = tomorrow;

		if (meetingRequestData == null) {
			this.meetingRequest.meetingRequestEid = UID();
		}
		else {
			this.meetingRequest.meetingRequestEid = meetingRequestData.meetingRequestEid;
			this.meetingRequest.comment = meetingRequestData.comment;
		}

		this.fromProposal = fromProposal;

		this.expertInfoPersonModel = await this.chatService.getMainClientExpertInfo(self.authService.user.selectedCompanyId);
		if (meetingRequestData != null && meetingRequestData.history != null) {
			this.meetingInfoLastHistory = meetingRequestData.history;
		}

		this.showMeetingCalendar = true;
		this.detectChanges();
	}

	public async closeMeetingCalendarDialog(save: boolean, meetingRequestModel?: any) {
		let meetingRequestEid;

		if (save && meetingRequestModel) {
			if (!meetingRequestModel.fromProposal) {
				meetingRequestEid = await this.chatService.requestMeeting(this.chatService.currentChatRoom.clientEid, meetingRequestModel.meetingRequest);
			}
			else {
				meetingRequestEid = await this.chatService.proposeNewMeeting(this.chatService.currentChatRoom.clientEid, meetingRequestModel.meetingRequest);
			}
		}

		this.showMeetingCalendar = false;
	}

	public getMeetingRequestAndOpen(linkURL: string, urlSourceRoute: any) {
		let clientEid = linkURL.substring(linkURL.indexOf(`${urlSourceRoute.base}`) + urlSourceRoute.base.length, linkURL.indexOf(`${urlSourceRoute.meetings}`));
		let meetingStartPosition = linkURL.indexOf(`${urlSourceRoute.meetings}`) + `${urlSourceRoute.meetings}`.length;
		let meetingRequestEid = linkURL.substring(meetingStartPosition);

		this.openMeetingInfo(clientEid, meetingRequestEid);
	}

	public async openMeetingInfo(clientEid: string, meetingRequestEid: string) {
		this.expertInfoPersonModel = await this.chatService.getMainClientExpertInfo(this.authService.user.selectedCompanyId);
		this.meetingRequestData = await this.chatService.getMeetingRequestData(clientEid, meetingRequestEid);
		this.showMeetingRequest = true;

		setTimeout(() => {
			this.detectChanges();	
		}, 200);
	}

	public closeMeetingRequestDataDialog() {
		this.showMeetingRequest = false;
	}

	public async acceptMeetingRequest(meetingRequestData: MeetingRequestDataModel) {
		let meetingRequestEid = await this.chatService.acceptMeetingRequest(meetingRequestData.clientEid, meetingRequestData.meetingRequestEid);
		if (meetingRequestEid) {
			this.toastr.success("Întâlnirea a fost acceptată.");
		}

		this.closeMeetingRequestDataDialog();
	}

	public async cancelMeetingRequest(meetingRequestData: MeetingRequestDataModel) {
		let result = await this.chatService.cancelMeetingRequest(meetingRequestData.clientEid, meetingRequestData.meetingRequestEid);
		if (result) {
			this.toastr.success("Întâlnirea a fost anulată.");
		}

		this.closeMeetingRequestDataDialog();
	}

	public changeMeetingRequestInterval(meetingRequestData: MeetingRequestDataModel) {
		this.closeMeetingRequestDataDialog();
		this.openMeetingCalendarDialog(meetingRequestData, true);
	}

	onContentScrolled() {
		const scrollTopOffset = this._messagesContainerEl.scrollHeight - this._messagesContainerEl.scrollTop;
		if (scrollTopOffset > 900) {
			this.showScrollToBottom = true;
		}

		if ( this._messagesContainerEl.scrollHeight - this._messagesContainerEl.scrollTop - this._messagesContainerEl.clientHeight <= 10) {
			this.showScrollToBottom = false;
		}

		this.detectChanges();
	}

	scrollToBottom() {
		let self = this;
		if (this.nextChunkEid != null) {
			setTimeout(() => {
				this.loadingInitialChunk = true;
				this.messages = [];
				this.chatService.initialMessages()
					.pipe(
						first()
					)
					.subscribe((messages: ChatMessageModel[]) => {
						if (messages) {
							this.chatService.messages.next(messages.reverse());
						} else {
							this.chatService.messages.next([]);
						}

						if (self._messagesContainerEl) {
							self._messagesContainerEl.scrollTo({ top: self._messagesContainerEl.scrollHeight + 10 });
							this.showScrollToBottom = false;
						}
					});

				this.loadingPrevChunk = false;
				this.loadingNextChunk = false;
			});
		}
		else {
			if (self._messagesContainerEl) {
				self._messagesContainerEl.scrollTo({ top: self._messagesContainerEl.scrollHeight + 10 });
				this.showScrollToBottom = false;
			}
		}		
	}
}

