import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "@saliente/common";
import { AuthService } from "@saliente/common-auth";
import { Observable, of, Subscription } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { buildQueryParams } from "@saliente/library";

@Injectable()
export class PayrollCommonService {

    constructor(private authService: AuthService, private http: HttpClient) {

    }
    
    public getDictionary(sourceUrl: string, dictionaryCode: string, parameters?: any[]): Observable<any[]> {
        let headers = new HttpHeaders();
        let queryParams: HttpParams;
        headers = headers.append('authorization', this.authService.authHeaderValue());
        headers = headers.append('Content-Type', "application/json");

        if (parameters != undefined && parameters.length > 0) {
            queryParams = this.createDictionaryQueryParameters(parameters);
        }

        return this.http
            .get(`${sourceUrl}/dictionaries/${dictionaryCode}`, {
                headers,
                params: queryParams
            })
            .pipe(
                map((response: any) => {
                    return response;
            }),
            catchError((err: any) => {
                return of(null);
            }),
            finalize(() => {
            })
        )
    }

    createDictionaryQueryParameters(parameters: any[]): HttpParams {
        let params: any = {};

        parameters.forEach((p) => {
            if (p == 'clientEid') {
                params.clientEid = this.authService.user.selectedCompanyId;              
            }
        });

        return buildQueryParams(params);       
    }
}