import { dateToInt, intToDate } from "@saliente/library";
import { Exclude, Expose } from "class-transformer";
 
export enum TimeSlotStatus {
	Available = 'Available',
	Occupied = 'Occupied',
	Blocked = 'Blocked',
	Pending = 'Pending'
}

export enum MeetingRequestStatus {
    Created = 'Created',
    Settled = 'Settled',
    Canceled = 'Canceled',
    Closed_By_System = "Closed_By_System",
    Expired = "Expired"
}

export enum MeetingHistoryStatus {
    Created = 'Created',
    ProposedNewTime = 'ProposedNewTime',
    Accepted = 'Accepted',
    Canceled = 'Canceled',
    AutoAccepted = 'AutoAccepted'
}

export class MeetingTimeSlotsModel {
    public date: number;
    public fromTime: number;
    public toTime: number;
    public status: string;
    public isWeekend: boolean;
    public isLegalDay: boolean;

    @Exclude()
    public isSelected: boolean = false;
    
    @Exclude()
	private _dateEx: Date;
	@Exclude()
	public get dateEx(): Date {
		if (!this._dateEx && this.date) {
			this._dateEx = intToDate(this.date);
		}
		return this._dateEx;
	}
	public set dateEx(value: Date) {
		if (this._dateEx != value) {
			this._dateEx = value;
			this.date = dateToInt(value);
		}
	}

    @Exclude()
    public get intervalFormat(): string {
        let fromTime = this.fromTime.toString(),
            toTime = this.toTime.toString();
        let fromTimeAsHour = fromTime,
            toTimeAsHour = toTime;

        if (fromTime.length == 3) {
            fromTimeAsHour = `${fromTime.substring(0, 1)}:${fromTime.substring(1, 3)}`;
        }
        else if (fromTime.length == 4) {
            fromTimeAsHour = `${fromTime.substring(0, 2)}:${fromTime.substring(2, 4)}`;
        }

        if (toTime.length == 3) {
            toTimeAsHour = `${toTime.substring(0, 1)}:${toTime.substring(1, 3)}`;
        }
        else if (toTime.length == 4) {
            toTimeAsHour = `${toTime.substring(0, 2)}:${toTime.substring(2, 4)}`;
        }

        return `${fromTimeAsHour} - ${toTimeAsHour}`;
    }

    @Exclude()
    public get fromTimeFormat(): string {
        let fromTime = this.fromTime.toString();
        let fromTimeAsHour = fromTime;

        if (fromTime.length == 3) {
            fromTimeAsHour = `${fromTime.substring(0, 1)}:${fromTime.substring(1, 3)}`;
        }
        else if (fromTime.length == 4) {
            fromTimeAsHour = `${fromTime.substring(0, 2)}:${fromTime.substring(2, 4)}`;
        }

        return fromTimeAsHour;
    }
}

export class MeetingSlotsExpertListModel {
    public expertUserEid: string;
    public expertUserName: string;
    public timeSlots: MeetingTimeSlotsModel[];
}

export class MeetingSlotsListModel {
    public clientExternalId: string;
    public clientName: string;
    public expertList: MeetingSlotsExpertListModel[];
}

export class MeetingRequestModel {
    meetingRequestEid: string;
    date: number
    fromTime: number
    comment: string
    chatTypeEid: string

    @Exclude()
	private _dateEx: Date;
	@Exclude()
	public get dateEx(): Date {
		if (!this._dateEx && this.date) {
			this._dateEx = intToDate(this.date);
		}
		return this._dateEx;
	}
	public set dateEx(value: Date) {
		if (this._dateEx != value) {
			this._dateEx = value;
			this.date = dateToInt(value);
		}
	}
}

export class MeetingRequestDataHistoryModel {
    public meetingRequestEid: string;
    public clientEid: string;
    public clientName: string;
    public status: string;
    public date: number;
    public fromTime: number;
    public toTime: number;
    public duration: number;
    public comment: string;
    public chatTypeEid: string;
    public userEid: string;
    public userName: string;
    public role: string;
    public createdDate: string;
    public eventType: string;

    @Exclude()
	private _dateEx: Date;
	@Exclude()
	public get dateEx(): Date {
		if (!this._dateEx && this.date) {
			this._dateEx = intToDate(this.date);
		}
		return this._dateEx;
	}
	public set dateEx(value: Date) {
		if (this._dateEx != value) {
			this._dateEx = value;
			this.date = dateToInt(value);
		}
	}

    @Exclude()
    public get fromTimeFormat(): String {
        let fromTime = this.fromTime.toString();
        let fromTimeAsHour = fromTime;

        if (fromTime.length == 3) {
            fromTimeAsHour = `${fromTime.substring(0, 1)}:${fromTime.substring(1, 3)}`;
        }
        else if (fromTime.length == 4) {
            fromTimeAsHour = `${fromTime.substring(0, 2)}:${fromTime.substring(2, 4)}`;
        }

        return fromTimeAsHour;
    }

    @Exclude()
    public get toTimeFormat(): String {
        let toTime = this.toTime.toString();
        let toTimeAsHour = toTime;

        if (toTime.length == 3) {
            toTimeAsHour = `${toTime.substring(0, 1)}:${toTime.substring(1, 3)}`;
        }
        else if (toTime.length == 4) {
            toTimeAsHour = `${toTime.substring(0, 2)}:${toTime.substring(2, 4)}`;
        }

        return toTimeAsHour;
    }

	@Exclude()
	public get createdDateAsDate(): Date {
        let _createdDate;
        if (this.createdDate) {
            const createdDateInt = parseInt(this.createdDate.replace(/-/g,''), 10);
            const day = createdDateInt % 100;
            const createdDateMonthYear = (createdDateInt - day) / 100;
            const month = createdDateMonthYear % 100;
            const year = (createdDateMonthYear - month) / 100;
            _createdDate = new Date(year, month - 1, day);
        }
        return _createdDate;
    }

    @Exclude()
    public get initialLetters(): string {
        let spacePosition = this.userName.indexOf(' ');
        return `${this.userName.substring(0, 1)}${this.userName.substring(spacePosition + 1, spacePosition + 1 + 1)}`.toUpperCase();
    }
}

export class MeetingRequestDataModel {
    public meetingRequestEid: string;
    public date: number;
    public fromTime: number;
    public comment: string;
    public chatTypeEid: string;
    public toTime: number;
    public duration: number;
    public clientEid: string;
    public clientName: string;
    public status: string;
    public reschedulesByExpert: number;
    public reschedulesByClient: number;
    public modifiedByRole: string;
    public history: MeetingRequestDataHistoryModel[];

    @Exclude()
	private _dateEx: Date;
	@Exclude()
	public get dateEx(): Date {
		if (!this._dateEx && this.date) {
			this._dateEx = intToDate(this.date);
		}
		return this._dateEx;
	}
	public set dateEx(value: Date) {
		if (this._dateEx != value) {
			this._dateEx = value;
			this.date = dateToInt(value);
		}
	}

    @Exclude()
    public get fromTimeFormat(): String {
        let fromTime = this.fromTime.toString();
        let fromTimeAsHour = fromTime;

        if (fromTime.length == 3) {
            fromTimeAsHour = `${fromTime.substring(0, 1)}:${fromTime.substring(1, 3)}`;
        }
        else if (fromTime.length == 4) {
            fromTimeAsHour = `${fromTime.substring(0, 2)}:${fromTime.substring(2, 4)}`;
        }

        return fromTimeAsHour;
    }

    @Exclude()
    public get toTimeFormat(): String {
        let toTime = this.toTime.toString();
        let toTimeAsHour = toTime;

        if (toTime.length == 3) {
            toTimeAsHour = `${toTime.substring(0, 1)}:${toTime.substring(1, 3)}`;
        }
        else if (toTime.length == 4) {
            toTimeAsHour = `${toTime.substring(0, 2)}:${toTime.substring(2, 4)}`;
        }

        return toTimeAsHour;
    }

    @Exclude()
    public get toTimeHour(): number {
        let toTime = this.toTime.toString();
        let toTimeHour;

        if (toTime.length == 3) {
            toTimeHour = Number(toTime.substring(0, 1));
        }
        else if (toTime.length == 4) {
            toTimeHour = Number(toTime.substring(0, 2));
        }

        return toTimeHour;
    }

    @Exclude()
    public get toTimeMinutes(): number {
        let toTime = this.toTime.toString();
        let toTimeMinutes;

        if (toTime.length == 3) {
            toTimeMinutes = Number(toTime.substring(1, 3));
        }
        else if (toTime.length == 4) {
            toTimeMinutes = Number(toTime.substring(2, 4));
        }

        return toTimeMinutes;
    }
}

export class ExpertInfoPersonModel {
    @Expose({ name: 'externalId' })
    public userEid: string;
    public firstName: string;
    public lastName: string;
    @Expose({ name: 'fullName' })
    public userName: string;

    public get initialLetters(): string {
		return `${this.firstName != undefined ? this.firstName.substring(0, 1) : ''}${this.lastName != undefined ? this.lastName.substring(0, 1) : ''}`.toUpperCase();
    }
}