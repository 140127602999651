import { Injectable } from '@angular/core';

import { Observable, of, } from 'rxjs';
import { switchMap, map, } from 'rxjs/operators';

import gql from 'graphql-tag';

import { $ } from '@saliente/library';

import { RestService } from '../general/rest.service';
import { ConfigService } from '../general/config.service';

const getPayUDataQueryText = gql`
mutation getPayUData($clientEid: String!, $payload: payU_LUOrderRepresentationInput!){
	payU{
	  getCardEnrollmentPOSTDataUsingPOST(clientExternalId: $clientEid, lUOrderRepresentation: $payload){
		parameters{
			name,
			value
		}
	  }
	}
  }
`;

const updateBrowserInfoMutationText = gql`
mutation updateBrowserInfo($ip: String, $browserInfo: payU_BrowserInfoRepresentationInput!){
	payU{
		updateBrowserInfoUsingPOST(x_real_ip: $ip, browserInfo: $browserInfo){
			Message
	  	}
	}
  }
`;

let browserInfoUpdated = false;

@Injectable()
export class PayUService {
	constructor(private configService: ConfigService, private restService: RestService) { }

	public pay(clientEid: string, paymentData: any) {
		return this.restService
			.mutate({
				mutation: getPayUDataQueryText,
				variables: {
					clientEid: clientEid,
					payload: paymentData
				}
			})
			.pipe(
				switchMap((response: any) => {
					if (response) {
						const $form = $('<form>', {
							action: this.configService.payU,
							method: 'post'
						});
						const parameters: any[] = response.data.payU.getCardEnrollmentPOSTDataUsingPOST.parameters;
						parameters.forEach((parameter) => {
							$('<input>').attr({
								type: "hidden",
								name: parameter.name,
								value: parameter.value
							}).appendTo($form);
						});
						$form.appendTo('body').submit();
					}
					return of(null);
				})
			);
	}

	public updateBrowserInfo() {
		if (!browserInfoUpdated) {
			return this.restService
				.mutate({
					mutation: updateBrowserInfoMutationText,
					variables: {
						browserInfo: {
							acceptHeader: "*/*",
							colorDepth: screen.colorDepth.toString(),
							javaEnabled: navigator.javaEnabled().toString(),
							language: navigator.language,
							screenHeight: screen.height.toString(),
							screenWidth: screen.width.toString(),
							timezone: (new Date()).getTimezoneOffset().toString(),
							userAgent: navigator.userAgent
						}
					}
				}, { spinner: false })
				.pipe(
					map((response) => {
						browserInfoUpdated = !!response;
						return browserInfoUpdated;
					})
				);
		}
		return of(true);
	}
}