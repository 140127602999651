<kendo-dialog *ngIf="showConfirmationDialog" title="Confirmare" [width]="450" (close)="closeConfirmationDialog()">
    <h5> {{ getMicroProfitFormConclusion() }} </h5>
    <div class="col-24 d-flex justify-content-around mt-4">
        <button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="saveMicroProfitForm()">
            <i class="mdi-content-send mr-2"></i>
            <span i18n="@@Actions$Save"> Trimite</span>
        </button>
        <button class="btn waves-effect waves-light" type="button" name="action" (click)="cancelMicroProfitForm()">
            <i class="mdi-content-clear mr-2"></i>
            <span i18n="@@Actions$Cancel">Renunță</span>
        </button>
    </div>
</kendo-dialog>

<section *ngIf="microProfitFormModel && hasViewRight && !isLoading" class="d-flex flex-column micro-profit-form">
    <form #microProfitForm="ngForm" class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-start">
					<div class="col d-flex">
						<div class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{microProfitFormModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2">{{microProfitFormModel.formData.description}}</h5>
                            <h5 class="col-24 mt-2">Completați cu datele corespunzătoare companiei dumneavoastră.</h5>
                            <h5 class="col-24 mt-2 pink-text text-accent-2 font-weight-bold">{{microProfitFormModel.formData.companyName}}</h5>
                            <label *ngIf="microProfitFormModel.formData.status == 'Submitted'" class="col-24 mt-2 font-size-1-1 pink-text text-accent-2">
                                Răspunsurile au fost trimise.
                            </label>
						</div>
					</div>		
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5" title="Închide" (click)="this.close.emit()"></i>
				</div>
			</div>
		</div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <!-- Intrebari -->
        <div class="d-flex flex-wrap mb-2">
            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23">
                        La data de {{microProfitFormModel.formDataParameters.lastDayOfMonth | date: 'dd.MM.yyyy'}}, aveți salariați care au norma însumată de cel puțin 8 ore pe zi sau contracte de mandat cu minim salariul minim pe economie?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre1FTEHelp()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre1FTE" [value]="true" #yesAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.are1FTE" [disabled]="isReadOnly"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre1FTE">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre1FTE" [value]="false" #noAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.are1FTE" [disabled]="isReadOnly"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label" [for]="noAre1FTE">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && areConsultanta80Disabled}">
                        În perioada ianuarie - {{ microProfitFormModel.formDataParameters.lastDayOfMonth | date: 'MMMM' }} {{ microProfitFormModel.formDataParameters.lastDayOfMonth | date: 'yyyy' }}, 
                        ați realizat un procent mai mare de 20% din totalul cifrei de afaceri din activitate de consultanță?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAreConsultanta80()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAreConsultanta80" [value]="true" #yesAreConsultanta80 size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.areConsultanta80" [disabled]="isReadOnly || areConsultanta80Disabled"
                            (change)="onAreConsultanta80Change()"/>
                        <label class="k-radio-label pr-3" [for]="yesAreConsultanta80">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAreConsultanta80" [value]="false" #noAreConsultanta80 size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.areConsultanta80" [disabled]="isReadOnly || areConsultanta80Disabled"
                            (change)="onAreConsultanta80Change()"/>
                        <label class="k-radio-label" [for]="noAreConsultanta80">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && are253MicroDisabled}">
                        Există cel puțin un acționar care deține mai mult de 25% din capitalul social mai deține aceeași proporție și la alte 3 microîntreprinderi?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre253Micro()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre253Micro" [value]="true" #yesAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre253Micro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre253Micro" [value]="false" #noAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label" [for]="noAre253Micro">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && pastreazaFirmaMicroDisabled}">
                        Doriți ca această firmă să rămână microîntreprindere? Atenție: una sau mai multe dintre celelalte 3 firme trebuie să treacă la profit până când numărul de microîntreprinderi în care dețineți >25% din capitalul social este mai mic sau egal cu 3.
                    </h5>
                    <!-- <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="are253Micro()"></i> -->
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesPastreazaFirmaMicro" [value]="true" #yesPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesPastreazaFirmaMicro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noPastreazaFirmaMicro" [value]="false" #noPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="microProfitFormModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label" [for]="noPastreazaFirmaMicro">nu</label>
                    </div>
                </div>
            </div>
            <div class="divider col-24 p-0 mt-2 mb-4"></div>
        </div>

        <!-- Concluzii -->
        <div class="col-24 px-0 m-0 d-flex flex-wrap">
            <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                <h5 class="col-23"> {{ microProfitFormModel.data.ramaneMicro == null ? '' : getMicroProfitFormConclusion() }} </h5>
                <i *ngIf="microProfitFormModel.data.ramaneMicro != null" class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onRamaneMicroHelp()"></i>
            </div>
            <!-- <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                <div class="k-radio-item">
                    <input type="radio" name="yesRamaneMicro" [value]="true" #yesRamaneMicro size="large" kendoRadioButton
                        [(ngModel)]="microProfitFormModel.data.ramaneMicro" [disabled]="true"/>
                    <label class="k-radio-label pr-3" [for]="yesRamaneMicro">da</label>	
                </div>
                <div class="k-radio-item">
                    <input type="radio" name="noRamaneMicro" [value]="false" #noRamaneMicro size="large" kendoRadioButton
                        [(ngModel)]="microProfitFormModel.data.ramaneMicro" [disabled]="true"/>
                    <label class="k-radio-label" [for]="noRamaneMicro">nu</label>
                </div>
            </div> -->
        </div>
        <div class="col-24 d-flex justify-content-around mt-2">
            <button *ngIf="!isReadOnly" type="submit" class="col-auto btn waves-effect waves-light mx-1" [disabled]="saveDisabled"
                (click)="openConfirmationDialog()">
                <i class="mdi-content-send mr-2"></i>
                <span i18n="@@Actions$Save">Trimite</span>
            </button>
        </div>
    </form>
</section>

<section *ngIf="!hasViewRight && !isLoading">
    <div class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-center">
					<div class="col d-flex">
						<div *ngIf="microProfitFormModel" class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{microProfitFormModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2"> {{microProfitFormModel.formData.description}} </h5>
						</div>
					</div>
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                </div>
            </div>
        </div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <div class="d-flex flex-wrap">
            <h5>Nu aveți drepturi de vizualizare.</h5>
        </div>
    </div>
</section>