<form #duPfaForm="ngForm" class="d-flex flex-wrap">
    <div class="col-24 px-3 d-flex align-items-start">
        <div class="col px-0">
            <div class="row align-items-start">
                <div class="col d-flex">
                    <div class="row align-items-center">
                        <h3 class="col-auto my-0"> {{ model.formData.display }} </h3>
                        <h5 class="col-24 mt-2"> {{ model.formData.description }} </h5>
                        <h5 class="col-24 mt-2"> Completați cu datele corespunzătoare companiei dumneavoastră. </h5>
                        <h5 class="col-24 mt-2 pink-text text-accent-2 font-weight-bold"> {{ model.formData.companyName }}</h5>
                        <label *ngIf="model.formData.status == 'Submitted'" class="col-24 mt-2 font-size-1-1 pink-text text-accent-2">
                            Răspunsurile au fost trimise.
                        </label>
                    </div>
                </div>		
                <button *ngIf="isExpert && isReadOnly" type="button" class="col-auto btn waves-effect waves-light mx-1" (click)="invalidateForm()">
                    <i class="mdi-unlock mr-2"></i>
                    <span i18n="@@Actions$Cancel">Deblochează</span>
                </button>
                <button *ngIf="isExpert && isReadOnly" type="button" class="col-auto btn waves-effect waves-light mx-1" (click)="downloadForm()">
                    <i class="mdi-file-file-download mr-2"></i>
                    <span i18n="@@Actions$Cancel">Descarcă</span>
                </button>
                <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5" title="Închide" (click)="this.close.emit()"></i>
            </div>
        </div>
    </div>
    <div class="divider col-24 p-0 mt-2 mb-2"></div>

    <!-- Person -->
    <div class="col-24 px-0">
        <div class="d-flex flex-wrap">
            <div class="col-24 d-flex flex-wrap px-0 mb-2 justify-content-center section-header align-items-center">
                <h5 class="my-3">Date contribuabil</h5>
                <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1 mb-1" title="Ajutor" (click)="personHelp()"></i>
            </div>
            <div class="col-24 px-0 m-0 d-flex flex-wrap">
                <inputfield name="nume" class="col-6" label="Nume"
                    [(ngModel)]="model.data.persoana.nume" [disabled]="isReadOnly" required>
                </inputfield>
                <inputfield name="prenume" class="col-6" label="Prenume"
                    [(ngModel)]="model.data.persoana.prenume" [disabled]="isReadOnly" required>
                </inputfield>
                <inputfield name="cnp" class="col-6" label="CNP"
                    [(ngModel)]="model.data.persoana.cnp" [disabled]="isPfa || isReadOnly" required>
                </inputfield>
                <inputfield name="telefon" class="col-6" label="Telefon"
                    [(ngModel)]="model.data.persoana.telefon" [disabled]="isReadOnly" required>
                </inputfield>
            </div>
            <div class="col-24 px-0 m-0 d-flex flex-wrap">
                <div class="col-12 px-0">
                    <kee-textarea name="adresa" class="col-24 address" label="Adresa completă din cartea de identitate"
                        [(ngModel)]="model.data.persoana.adresa" [disabled]="isReadOnly" required [autoheight]="isReadOnly">
                    </kee-textarea>
                    <div class="col-24 font-weight-bold pt-2">ATENȚIE! Completați orașul și județul.</div>
                </div>
                <inputfield name="email" class="col-6" label="Email"
                    [(ngModel)]="model.data.persoana.email" [disabled]="isReadOnly" required
                    [onValidate]="validateEmail.bind(this)" (blur)="emailOnBlur(model.data.persoana.email)">
                </inputfield>
                <inputfield name="iban" class="col-6" label="IBAN"
                    [(ngModel)]="model.data.persoana.iban" [disabled]="isReadOnly">
                </inputfield>
            </div>
            <div class="col-24 px-0 pt-2 m-0 d-flex flex-wrap">
                <div class="col-6 px-0 d-flex align-items-center">
                    <label class="col-8 custom-label">Are handicap:</label>
                    <checkbox name="areHandicap" class="pl-4"
                        [(ngModel)]="model.data.persoana.areHandicap" [disabled]="isReadOnly" (valueChanged)="onAreHandicapChanged($event)">
                    </checkbox>
                </div>
                <div class="col-6 px-0 d-flex align-items-center">
                    <label class="col-8 custom-label">Nerezident:</label>
                    <checkbox name="nerezident" class="pl-4"
                        [(ngModel)]="model.data.persoana.nerezident" [disabled]="isReadOnly" (valueChanged)="onNerezidentChanged($event)">
                    </checkbox>
                </div>
                <!-- <div class="col-6 px-0 d-flex align-items-center">
                    <label class="col-10 custom-label">Rectificativă realizat:</label>
                    <checkbox name="rectificativaRealizat" class="pl-4"
                        [(ngModel)]="model.data.rectificativaRealizat" [disabled]="isReadOnly">
                    </checkbox>
                </div>
                <div class="col-6 px-0 d-flex align-items-center">
                    <label class="col-10 custom-label">Rectificativă estimat:</label>
                    <checkbox name="rectificativaEstimat" class="pl-4"
                        [(ngModel)]="model.data.rectificativaEstimat" [disabled]="isReadOnly">
                    </checkbox>
                </div> -->
            </div>
        </div>
    </div>

    <div *ngIf="!isFormEditable" class="d-flex flex-wrap col-24 mt-4">
        <div class="notEditableFormReason-divider col-24 p-0 mt-2 mb-2"></div>
        <h5 class="col-24 px-0 m-0 py-2 card-content red-text text-darken-1">{{ notEditableFormReason }}</h5>
        <div class="notEditableFormReason-divider col-24 p-0 mt-2 mb-2"></div>
    </div>

    <div *ngIf="isFormEditable" class="d-flex flex-wrap">
        <!-- Comentarii -->
        <div class="col-24 px-0 mt-3">
            <div class="col-24 d-flex flex-wrap align-items-center">
                <h5>
                    Alte venituri decât cele din PFA
                </h5>
                <div class="col-24 px-0 d-flex flex-wrap">
                    <p class="col-24 px-0 mb-0 pr-4 help-text">
                        În cazul în care ați obținut în anul {{ model.formDataParameters.anRealizat }} alte venituri decât cele din activitatea independentă cum ar fi:<br>
                        - drepturi de autor,<br>
                        - chirii,<br>
                        - investiții,<br>
                        - activități agricole,<br>
                        - alte surse,<br>
                        vă rugăm să menționați aici tipul de venit și suma obținută (pentru veniturile din chirii se trece suma în valută, pentru celelalte doar RON).<br>
                        Veniturile obținute din chirii în RON și dividendele sunt tratate separat în chestionar mai jos. Expertul contabil va lua legatura cu dumneavoastră pentru detalii.  
                    </p>
                    <a href="{{ comentariiHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                </div>
            </div>
            <div class="col-24 m-0 px-0 d-flex flex-wrap">
                <kee-textarea *ngIf="isPfa" name="comments" class="col-24 custom-text-area no-resize" rows="5"
                    [(ngModel)]="model.data.comments" [disabled]="isReadOnly" [autoheight]="isReadOnly">
                </kee-textarea>
            </div>
        </div>

        <!-- Venituri din activități independente -->
        <div class="col-24 px-0 mt-3">
            <div class="d-flex flex-wrap">
                <div class="col-24 d-flex flex-wrap px-0 mb-2 justify-content-center section-header align-items-center">
                    <h4 class="my-3">Venituri din PFA, II, IF si PFI (activități independente)</h4>
                </div>
                <div class="col-24 px-0 m-0 d-flex flex-wrap">
                    <div class="col-12 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 help-text">
                            Se completează doar dacă sunteți impozitat în <span class="font-weight-bold">Sistem Real. Dacă nu stiți sumele de venit brut și cheltuieli deductibile le puteti lăsa necompletate și le completam noi.</span><br>
                            Dacă sunteți la normă atunci nu se completează nimic.<br>
                            Completați <span class="font-weight-bold">Numărul rezoluției de înfiintare și data acesteia.</span>
                        </p>
                        <a class="col-24 px-0" href="{{ activitatiIndependenteHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                    </div>
                    <div class="col-12 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 help-text">
                            Dacă sunteți la Sistem Real trebuie să completați obligatoriu o estimare a Veniturilor si Cheltuielilor pentru anul {{ model.formDataParameters.anEstimat }}.<br>
                            Dacă sunteți la normă și nu stiți norma, lăsați necompletat și o vom completa noi.<br>
                            Completați <span class="font-weight-bold">Numărul rezoluției de înființare și data acesteia.</span>
                        </p>
                        <a class="col-24 px-0" href="{{ activitatiIndependenteHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                    </div>
                </div>
                <div class="col-24 px-0 m-0 d-flex flex-wrap">
                    <div class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap px-0 justify-content-center">
                            <h5>Realizat ({{ model.formDataParameters.anRealizat }})</h5>
                            <i *ngIf="!isReadOnly" class="mdi-content-add pink-text text-accent-2 font-weight-bold pl-3 cursor-pointer" title="Adaugă" (click)="addNewActivitateRealizat()"></i>
                        </div>
                        <div *ngFor="let activitateRealizat of model.data.activitatiIndependenteRealizat; let activitateRealizatIndex = index" class="col-24 mb-1">
                            <activitate-independenta [model]="activitateRealizat" [index]="activitateRealizatIndex" [type]="0" [year]="model.formDataParameters.anRealizat"
                                [caenCodes]="caenCodes" [isReadOnly]="isReadOnly" (delete)="deleteActivitateRealizat(activitateRealizatIndex)"></activitate-independenta>
                        </div>
                    </div>
                    <div class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap px-0 justify-content-center">
                            <h5>Estimat ({{ model.formDataParameters.anRealizat + 1 }})</h5>
                            <i *ngIf="!isReadOnly" class="mdi-content-add pink-text text-accent-2 font-weight-bold pl-3 cursor-pointer" title="Adaugă" (click)="addNewActivitateEstimat()"></i>
                        </div>
                        <div *ngFor="let activitateEstimat of model.data.activitatiIndependenteEstimat; let activitateEstimatIndex = index" class="col-24 mb-1">
                            <activitate-independenta [model]="activitateEstimat" [index]="activitateEstimatIndex" [type]="1" [year]="model.formDataParameters.anEstimat"
                                [caenCodes]="caenCodes" [isReadOnly]="isReadOnly" (delete)="deleteActivitateEstimat(activitateEstimatIndex)"></activitate-independenta>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dividende -->
        <div class="col-24 px-0 mt-3">
            <div class="col-24 d-flex flex-wrap px-0 mb-2 justify-content-center section-header align-items-center">
                <h4 class="my-3">Dividende</h4>
            </div>
            <div class="col-24 px-0 m-0 d-flex flex-wrap">
                <div class="col-12 d-flex flex-wrap">
                    <p class="col-24 px-0 mb-0 help-text">
                        Se completează suma dividendelor <span class="font-weight-bold">încasate</span> în anul {{ model.formDataParameters.anRealizat }} de la toate firmele din România.
                    </p>
                    <a class="col-24 px-0" href="{{ dividendeHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                </div>
                <div class="col-12 d-flex flex-wrap">
                    <p class="col-24 px-0 mb-0 help-text">
                        Se completează suma dividendelor estimate a fi <span class="font-weight-bold">încasate</span> în anul {{ model.formDataParameters.anEstimat }} de la toate firmele din România.
                    </p>
                    <a class="col-24 px-0" href="{{ dividendeHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                </div>
            </div>
            <div class="col-24 px-0 m-0 d-flex flex-wrap">
                <div class="col-12 m-0 pr-2">
                    <kee-numerictextbox name="dividendeRealizat" label="Dividende realizat" [format]="'n0'" [min]="0" [decimals]="2"
                        [(ngModel)]="model.data.dividendeRealizat" [disabled]="isReadOnly" (blur)="dividendeRealizatOnBlur()">
                    </kee-numerictextbox>
                </div>
                <div class="col-12 m-0 pl-2">
                    <kee-numerictextbox name="dividendeEstimat" label="Dividende estimat" [format]="'n0'" [min]="0" [decimals]="2"
                        [(ngModel)]="model.data.dividendeEstimat" [disabled]="isReadOnly" (blur)="dividendeEstimatOnBlur()">
                    </kee-numerictextbox>
                </div>
            </div>
        </div>

        <!-- Chirii -->
        <div class="col-24 px-0 mt-3">
            <div class="d-flex flex-wrap">
                <div class="col-24 d-flex flex-wrap px-0 mb-2 justify-content-center section-header align-items-center">
                    <h4 class="my-3">Chirii</h4>
                    <!-- <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1 mb-1" title="Ajutor" (click)="chiriiHelp()"></i> -->
                </div>
                <div class="col-24 px-0 m-0 d-flex flex-wrap">
                    <div class="col-12 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 help-text">
                            Dacă aveți chirii în valută vă rugăm să menționați la "Comentarii", nu le completați aici.<br>
                            <span class="font-weight-bold">Important</span> - dacă aveți chirii în lei vă rugăm să mentionați la comentarii dacă sunt deja raportate la estimarea anului anterior.<br>
                            Dacă contractul de chirie a început sau a încetat în {{ model.formDataParameters.anRealizat }} atunci completați data începerii sau data încheierii activității.
                        </p>
                        <a class="col-24 px-0" href="{{ chiriiHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                    </div>
                    <div class="col-12 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 help-text">
                            Dacă aveți chirii în valută vă rugăm să menționați la "Comentarii".<br>
                            Dacă contractul de chirie a început sau va înceta în {{ model.formDataParameters.anEstimat }} atunci completați data începerii sau data încheierii activității.
                        </p>
                        <a class="col-24 px-0" href="{{ chiriiHelp() }}" target="_blank" rel="noopener noreferrer" >Vezi mai mult</a>
                    </div>
                </div>
                <div class="col-24 px-0 m-0 d-flex flex-wrap">
                    <div class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap px-0 justify-content-center">
                            <h5>Realizat ({{ model.formDataParameters.anRealizat }})</h5>
                            <i *ngIf="!isReadOnly" class="mdi-content-add pink-text text-accent-2 font-weight-bold pl-3 cursor-pointer" title="Adaugă" (click)="addNewChirieRealizat()"></i>
                        </div>
                        <div *ngFor="let chirieRealizata of model.data.chiriiRealizat; let chirieRealizatIndex = index" class="col-24 mb-1">
                            <chirie [model]="chirieRealizata" [index]="chirieRealizatIndex" [type]="0" [year]="model.formDataParameters.anRealizat"
                                [isReadOnly]="isReadOnly" (delete)="deleteChirieRealizat(chirieRealizatIndex)"></chirie>
                        </div>
                    </div>
                    <div class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap px-0 justify-content-center">
                            <h5>Estimat ({{ model.formDataParameters.anRealizat + 1 }})</h5>
                            <i *ngIf="!isReadOnly" class="mdi-content-add pink-text text-accent-2 font-weight-bold pl-3 cursor-pointer" title="Adaugă" (click)="addNewChirieEstimat()"></i>
                        </div>
                        <div *ngFor="let chirieEstimat of model.data.chiriiEstimat; let chirieEstimatIndex = index" class="col-24 mb-1">
                            <chirie [model]="chirieEstimat" [index]="chirieEstimatIndex" [type]="1" [year]="model.formDataParameters.anEstimat"
                                [isReadOnly]="isReadOnly" (delete)="deleteChirieEstimat(chirieEstimatIndex)"></chirie>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Intrebari -->
        <div class="col-24 px-0 mt-3">
            <div class="d-flex flex-wrap">
                <div class="col-24 d-flex flex-wrap px-0 mb-2 justify-content-center section-header align-items-center">
                    <h4 class="my-3">Alte informații</h4>
                </div>
                <div class="col-24 px-0 d-flex flex-wrap">
                    <div class="col-12 px-0">
                        <!-- empty space -->
                    </div>
                    <div *ngIf="!hasActivitatiIndependenteEstimat" class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap mb-1 align-items-center">
                            <h5>
                                În cazul în care ați estimat venituri nete mai mici de {{ model.formDataParameters.salSanatate }} salarii minime ({{ model.formDataParameters.limitaSanatate }} RON), doriți să vă asigurați opțional la sănătate?
                            </h5>
                            <div class="col-24 px-0 d-flex flex-wrap">
                                <p class="col-24 px-0 mb-0 pr-4 help-text">
                                    Se completează doar dacă estimați că obțineți venituri mai mici decât {{ model.formDataParameters.salSanatate }} salarii minime ({{ model.formDataParameters.limitaSanatate }} RON) în {{ model.formDataParameters.anEstimat }} și doriti asigurare de sănătate.
                                </p>
                                <a href="{{ sanatateHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                            </div>
                        </div>
                        <div class="col-24 m-0 d-flex flex-wrap">
                            <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                                <div class="k-radio-item">
                                    <input type="radio" name="yesOptionalSanatate" [value]="true" #yesOptionalSanatate size="large" kendoRadioButton
                                        [(ngModel)]="model.data.optionalSanatateEstimat" [disabled]="isReadOnly"/>
                                    <label class="k-radio-label pr-3" [for]="yesOptionalSanatate">da</label>	
                                </div>
                                <div class="k-radio-item">
                                    <input type="radio" name="noOptionalSanatate" [value]="false" #noOptionalSanatate size="large" kendoRadioButton
                                        [(ngModel)]="model.data.optionalSanatateEstimat" [disabled]="isReadOnly"/>
                                    <label class="k-radio-label pr-3" [for]="noOptionalSanatate">nu</label>
                                </div>
                            </div>
                            <p *ngIf="model.data.optionalSanatateEstimat" class="col-24 px-0 mt-1 mb-0 d-flex flex-wrap">
                                Contribuție: {{ model.formDataParameters.sanatate }} RON
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-24 px-0 d-flex flex-wrap mt-3">
                    <div class="col-12 px-0">
                        <!-- empty space -->
                    </div>
                    <div class="col-12 px-0 m-0">
                        <div class="col-24 d-flex flex-wrap mb-1 align-items-center">
                            <h5>
                                În cazul în care ați estimat venituri nete mai mici decât {{ model.formDataParameters.salPensie }} salarii minime ({{ model.formDataParameters.limitaPensie }} RON), doriți să contribuiți opțional la pensie?
                            </h5>
                            <div class="col-24 px-0 d-flex flex-wrap">
                                <p class="col-24 px-0 mb-0 pr-4 help-text">
                                    Se completează doar dacă estimați că obțineți venituri mai mici decât {{ model.formDataParameters.salPensie }} salarii minime ({{ model.formDataParameters.limitaPensie }} RON) în {{ model.formDataParameters.anEstimat }} și doriti să contribuiți la pensie.
                                </p>
                                <a href="{{ pensieHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                            </div>
                            <!-- <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1 mb-2" title="Ajutor" (click)="pensieHelp()"></i> -->
                        </div>
                        <div class="col-24 m-0 d-flex flex-wrap">
                            <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                                <div class="k-radio-item">
                                    <input type="radio" name="yesOptionalPensie" [value]="true" #yesOptionalPensie size="large" kendoRadioButton
                                        [(ngModel)]="model.data.optionalPensieEstimat" [disabled]="isReadOnly">
                                    <label class="k-radio-label pr-3" [for]="yesOptionalPensie">da</label>	
                                </div>
                                <div class="k-radio-item">
                                    <input type="radio" name="noOptionalPensie" [value]="false" #noOptionalPensie size="large" kendoRadioButton
                                        [(ngModel)]="model.data.optionalPensieEstimat" [disabled]="isReadOnly"/>
                                    <label class="k-radio-label pr-3" [for]="noOptionalPensie">nu</label>
                                </div>
                            </div>
                            <div *ngIf="model.data.optionalPensieEstimat" class="col-10">
                                <kee-numerictextbox name="optiunePensieValoareEstimat" label="Valoare pensie estimat" class="valoare-pensie"
                                    [format]="'n0'" [min]="model.formDataParameters.limitaPensie" [decimals]="0"
                                    [(ngModel)]="model.data.optiunePensieValoareEstimat" [disabled]="isReadOnly" required
                                    [onValidate]="validateOptiunePensieValoareEstimat.bind(this)">
                                </kee-numerictextbox>
                            </div>
                            <p *ngIf="model.data.optionalPensieEstimat" class="col-24 px-0 mt-1 mb-0 d-flex flex-wrap">
                                Contribuție: {{ model.formDataParameters.pensie }} RON
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-24 px-0 mt-3">
                    <div class="col-24 d-flex flex-wrap align-items-center">
                        <h5>
                            Persoana fizică realizează venituri din străinătate prestate pe teritoriul altei țări?
                        </h5>
                    </div>
                    <p class="col-24 my-0">
                        Se va completa suma în lei încasată în anul {{ model.formDataParameters.anRealizat }}.
                    </p>
                    <div class="col-24 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 pr-4 help-text">
                            Se completează valoarea veniturilor obținute din străinătate <span class="font-weight-bold">doar pentru prestările pe teritoriul altor țări.</span>
                        </p>
                        <a href="{{ venituriStrainatateHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                    </div>
                    <div class="col-24 px-0 m-0 d-flex flex-wrap">
                        <kee-numerictextbox name="venituriStrainatate" class="col-12 custom-numeric" [format]="'n0'" [min]="0" [decimals]="0"
                            [(ngModel)]="model.data.venituriStrainatate" [disabled]="isReadOnly" required>
                        </kee-numerictextbox>
                    </div>
                </div>

                <div class="col-24 px-0 mt-3">
                    <div class="col-24 d-flex flex-wrap align-items-center">
                        <h5>
                            Persoana fizică este și angajată cu Contract de Muncă cu norma întreagă pe perioada nedeterminată? ({{ model.formDataParameters.anRealizat }} / {{ model.formDataParameters.anEstimat }})
                        </h5>
                    </div>
                    <p class="col-24 my-0">
                        Vă rugăm să descrieți contractele din anul {{ model.formDataParameters.anRealizat }} / {{ model.formDataParameters.anEstimat }}. Dacă nu ați avut completați "nu".
                    </p>
                    <div class="col-24 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 pr-4 help-text">
                            Câmpul nu este obligatoriu.<br>
                            Această informație este utilă pentru analiza Normelor de venit (unde este cazul).<br>
                            Unele județe au reduceri la Norma de venit în anumite condiții, cea mai des întâlnită fiind dacă persoana este și angajată cu norma întreagă.
                        </p>
                        <a href="{{ cimNedeterminatHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                    </div>
                    <div class="col-24 px-0 m-0 d-flex flex-wrap">
                        <kee-textarea name="cimNedeterminat" class="custom-text-area col-12 no-resize"
                            [(ngModel)]="model.data.cimNedeterminat" [disabled]="isReadOnly" [autoheight]="isReadOnly">
                        </kee-textarea>
                    </div>
                </div>

                <div class="col-24 px-0 mt-3">
                    <div class="col-24 d-flex flex-wrap align-items-center">
                        <h5>
                            Doriți să repartizați o sumă din impozit către o entitate non-profit? ({{ model.formDataParameters.anRealizat }})
                        </h5>
                    </div>
                    <p class="col-24 my-0">
                        Legea permite repartizarea a 3.5% din impozit către un ONG.
                    </p>
                    <div class="col-24 d-flex flex-wrap">
                        <p class="col-24 px-0 mb-0 pr-4 help-text">
                            Se completează datele ONG-ului către care doriți să repartizați 3.5% din impozit. Se completează CUI și IBAN.<br>
                            CUI-ul trebuie să fie în acest registru
                            <span><a href="https://www.anaf.ro/RegistrulEntitatilorUnitatilorCult/" target="_blank" rel="noopener noreferrer">https://www.anaf.ro/RegistrulEntitatilorUnitatilorCult/</a></span>
                        </p>
                        <a href="{{ impozitONGHelp() }}" target="_blank" rel="noopener noreferrer">Vezi mai mult</a>
                    </div>
                    <div class="col-24 m-0 d-flex flex-wrap">
                        <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                            <div class="k-radio-item">
                                <input type="radio" name="yesImpozitONG" [value]="true" #yesImpozitONG size="large" kendoRadioButton
                                    [(ngModel)]="model.data.impozitONG" [disabled]="isReadOnly"/>
                                <label class="k-radio-label pr-3" [for]="yesImpozitONG">da</label>	
                            </div>
                            <div class="k-radio-item">
                                <input type="radio" name="noImpozitONG" [value]="false" #noImpozitONG size="large" kendoRadioButton
                                    [(ngModel)]="model.data.impozitONG" [disabled]="isReadOnly"/>
                                <label class="k-radio-label pr-3" [for]="noImpozitONG">nu</label>
                            </div>
                        </div>
                        <div *ngIf="model.data.impozitONG" class="col-24 px-0 d-flex flex-wrap mt-1">
                            <p class="col-24 px-0 my-0">
                                Completați datele organizației non-Profit
                            </p>
                            <inputfield name="ongCIF" class="col-6 px-0 mr-2" label="CUI"
                                [(ngModel)]="model.data.ongCIF" [disabled]="isReadOnly" required>
                            </inputfield>
                            <inputfield name="ongIBAN" class="col-6 px-0 mr-2" label="IBAN"
                                [(ngModel)]="model.data.ongIBAN" [disabled]="isReadOnly" required>
                            </inputfield>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isFormEditable" class="col-24 d-flex justify-content-around m-2">
        <button *ngIf="!isReadOnly" type="submit" class="col-auto btn waves-effect waves-light mx-1"
            (click)="saveForm(duPfaForm)">
            <i class="mdi-content-save mr-2"></i>
            <span i18n="@@Actions$Save">Salvează</span>
        </button>
        <button *ngIf="!isReadOnly" type="submit" class="col-auto btn waves-effect waves-light mx-1"
            (click)="submitForm(duPfaForm)">
            <i class="mdi-content-send mr-2"></i>
            <span i18n="@@Actions$Save">Trimite</span>
        </button>
    </div>

</form>