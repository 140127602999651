export * from './messagebox';
import { MessageBoxService } from './messagebox';

export * from './spinner';
import { SpinnerService } from './spinner';

export * from './windowref';
import { WindowRef } from './windowref';

export * from './authheader.provider';

export * from './kendo-messages.service';
import { KendoMessageService } from './kendo-messages.service';

export const SERVICES = [
	MessageBoxService, SpinnerService, WindowRef, KendoMessageService
]
