import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SalienteLibrarySharedModule } from "@saliente/library";
import { ClientFormsService } from "../client-forms.service";
import { MP2024FormComponent } from "./mp2024-form.component";

@NgModule({
    imports: [
		FormsModule, SalienteLibrarySharedModule
	],
	providers: [
		ClientFormsService
	],
	declarations: [
		MP2024FormComponent,
	],
	exports: [
		MP2024FormComponent
	]
})
export class MP2024FormSharedModule { }