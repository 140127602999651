import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { ClientDeleteDocumentFileComponent } from './delete-document-file.component';

const COMPONENTS = [ ClientDeleteDocumentFileComponent ];

@NgModule({
	imports: [
		CommonModule, FormsModule, SalienteLibrarySharedModule
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class DeleteDocumentFileSharedModule {
}