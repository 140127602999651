import { Injectable } from '@angular/core';

import { Observable, Observer, of, } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { classToPlain, plainToClass } from 'class-transformer';

import { MessageBoxService, MessageBoxButton, dateToInt, } from '@saliente/library';

import { CommonService, ConfigService, DownloadService, RestService, } from '@saliente/common';
import { AuthService, } from '@saliente/common-auth';

import {
	DocumentFileStatus, FinancialDocumentsService, FinancialDocumentEntryType,
	FinancialDocumentFileModel, FinancialDocumentFileCommentModel, FinancialDocumentFileCommentMomentModel, FinancialDocumentStatusChangeToDigitizedModel,
	DocumentStatusChangeModel, FinancialDocumentStatusChangeToBookedModel, DocumentStatusChangeToNotUsableModel, FinancialDocumentsQueryRequest, PersonForAccountingModel,
} from '@saliente/common-documents';

import { ExpertDocumentsLiteService } from './expert-documents-lite.service';
import { BalanceSheetDefinitionModel, BalanceSheetInstanceListModel } from 'src/app/clients/balance-sheet/expert/balance-sheet.models';
import { Subs_MoveDocumentToBalanceSheetRepresentationInput } from 'src/graphql';


const deleteDocumentMutationText = gql`
mutation confirmDeletion($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  deleteDocumentByExpertUsingPOST(statusChange:$newStatus){
			Message # empty
	  }
	}
}
`;

const updateDueDateMutationText = gql`
mutation updateDueDate($documentEid: String!, $dueDate: Int!){
	subs{
		updateDocumentDueDateUsingPOST(documentExternalId: $documentEid, dueDate: $dueDate){
			Message # empty
		}
	}
}`;


const confirmDigitizationByExpertMutationText = gql`
mutation confirmDigitizationByExpert($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
		ConfirmDigitizationByExpertUsingPOST(statusChange:$newStatus){
			Message # empty
	  	}
	}
}
`;

const setEmployeeMutationText = gql`
mutation setEmployee($docEid: String!, $data: documents_DocStructurePatchDataInput){
  documents {
    UpdateDocumentUsingPUT(docEid: $docEid, data: $data) {
		Message
    }
  }
}`;

const addChatMessageMutationText = gql`
mutation addChatMessage($clientExternalId: String!, $chatTypeExternalId: String!, $message: notifications_ChatMessageRepresentationInput!) {
	notifications {
		addChatMessageUsingPOST(clientExternalId: $clientExternalId, chatTypeExternalId: $chatTypeExternalId, message: $message) {
			Message # empty
		}
	}
}
`;

const getBalanceSheetsClientWorkInProgressQueryText = gql`
query getBalanceSheetsClientWorkInProgress($clientEid: String!) {
  subs {
    getBalanceSheetsClientWorkInProgressUsingGET(clientEid: $clientEid) {
		instances {
			clientFullDocumentEid
			clientFullDocumentFileName
			externalId
			forMonth
			clientViewStatus
			clientViewStatusDescription
			downloadUrl
			balanceSheetDefinition {
				clientType
				description
				externalId
				pdfanafFileName
				pdfanafTemplateEid
				forMonth
				periodicity
				isActive
				displayOrder
				templates {
					documentEid
					type
					name
				}
			}
		}
    }
  }
}
`;

const moveToBalanceSheetMutationText = gql`
mutation moveToBalanceSheet($data: subs_MoveDocumentToBalanceSheetRepresentationInput!) {
	subs {
		moveToBalanceSheetUsingPOST(data: $data) {
			Message
		}
	}
}
`;


@Injectable()
export class ExpertDocumentsService extends FinancialDocumentsService<FinancialDocumentFileModel> {

	public filterTypes: any[] = [
		{ type: 'keyword', text: 'Cuvânt cheie' },
		{ type: 'value', text: 'Valoare document' },
		{ type: 'date', text: 'Dată document' },
		{ type: 'reference', text: 'Referință' },
	];

	private _groupBy: string = 'documentDate';
	public get groupBy(): string {
		return this._groupBy;
	}
	public set groupBy(value: string) {
		if (this._groupBy != value) {
			switch (value) {
				case 'documentDate':
					this._groupBy = value;
					this.sortByStream.next('documentDate DESC');
					return;
				case 'scanDate':
					this._groupBy = value;
					this.sortByStream.next('created.dateTime DESC');
					return;
			}
		}
	}

	constructor(private documentsLiteService: ExpertDocumentsLiteService, configService: ConfigService, commonService: CommonService, restService: RestService, downloadService: DownloadService, authService: AuthService, private messageBoxService: MessageBoxService,) {
		super(configService, commonService, restService, downloadService, authService);
	}

	protected initializeStreams() {
		this.groupBy = 'documentDate';
		super.initializeStreams();
		this.authService
			.getSelectedCompanyId()
			.subscribe((companyId) => {
				if (this.filters.clientEid != companyId) {
					this.filters.clientEid = companyId;
				}
			});
	}

	protected createFilters() {
		const result = super.createFilters();
		if (this.authService.user != undefined) {
			result.clientEid = this.authService.user.selectedCompanyId;
		}
		return result;
	}

	protected documentsQueryRequest(): FinancialDocumentsQueryRequest {
		const result: FinancialDocumentsQueryRequest = super.documentsQueryRequest();
		return result;
	}

	public financialDocumentEntryTypeFilterList() {
		const self = this;
		return super.financialDocumentEntryTypeList()
			.pipe(
				map((list: any[]) => {
					list.splice(0, 0, { id: 'None', code: 'NECOMPLETAT', name: 'Necompletat' });
					list.splice(0, 0, { id: '', code: 'TOATE', name: 'Toate' });
					list.splice(6, 0, { id: FinancialDocumentEntryType.SaleFiscalReceipt, code: 'BFV', name: self.entryTypeDisplayName(FinancialDocumentEntryType.SaleFiscalReceipt) });
					list.push({ id: FinancialDocumentEntryType.StatementOfAccount, code: 'EC', name: self.entryTypeDisplayName(FinancialDocumentEntryType.StatementOfAccount) });
					return list;
				})
			);
	}

	public moveTo(documentIndex: number) {
		this.documentIndexStream.next(documentIndex);
	}

	public blur(document: FinancialDocumentFileModel, statusChange: DocumentStatusChangeToNotUsableModel) {
		return this.documentsLiteService.blur(statusChange)
			.pipe(
				switchMap(this.refreshMap(document, DocumentFileStatus.NotUsable))
			);
	}

	protected cielReferenceNotFound(error: any, statusChange: FinancialDocumentStatusChangeToBookedModel) {
		const self = this;
		return Observable.create((observer: Observer<any>) => {
			self.messageBoxService.show({
				title: 'Marchează contat',
				message: "Cel puțin o referință este greșită. Vrei să continui?",
				defaultButton: 'no',
				buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
				callback: function (result: string) {
					if (result === 'yes') {
						self.bookStream(statusChange, true).pipe(first())
							.subscribe((res: any) => {
								observer.next(res);
								observer.complete();
							});
					} else {
						observer.next(null);
						observer.complete();
					}
				}
			});
		});
	}

	public digitizedNBook(document: FinancialDocumentFileModel, statusChange: FinancialDocumentStatusChangeToBookedModel, force: boolean = false) {
		return this.digitize(document, FinancialDocumentStatusChangeToDigitizedModel.createFrom(statusChange))
			.pipe(
				switchMap((response) => {
					if (response) {
						return this.confirmDigitization(document, statusChange.getStatusChangeToConfirmationModel());
					}
					return of(null);
				}),
				switchMap((response) => {
					if (response) {
						return this.book(document, statusChange, true, force);
					}
					return of(null);
				})
			);
	}

	public assignToMeStream(document: FinancialDocumentFileModel): Observable<any> {
		const documentEid = document.id;
		return this.documentsLiteService.assignToMeStream(documentEid)
			.pipe(
				switchMap(this.refreshMap(document, DocumentFileStatus.SentToExpert))
				// map((response) => {
				// 	if (response) {
				// 		document.status.statusCode = DocumentFileStatus.SentToExpert;
				// 		this.refreshMap(document, DocumentFileStatus.SentToExpert)
				// 	}
				// 	return response;
				// })
			);
	}

	public reprocessDocumentStream(document: FinancialDocumentFileModel): Observable<any> {
		const documentEid = document.id;
		return this.documentsLiteService.reprocessDocumentStream(documentEid)
			.pipe(
				switchMap(this.refreshMap(document))
			);
	}

	public addDocumentCommentStream(file: FinancialDocumentFileModel, commentText: string): Observable<any> {
		const documentEid = file.id;
		return this.documentsLiteService.addDocumentCommentStream(documentEid, commentText)
			.pipe(
				map((response) => {
					if (response) {
						file.comments = file.comments || [];
						file.comments.splice(0, 0, response);
					}
					return response;
				})
			);
	}

	public deleteDocumentStream(document: FinancialDocumentFileModel, statusChange: DocumentStatusChangeModel) {
		return this.restService
			.mutate({
				mutation: deleteDocumentMutationText,
				variables: {
					newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
				}
			}, { error: this.onRestError.bind(this) })
			.pipe(
				switchMap(this.refreshMap(document))
			);
	}

	public confirmDigitizationByExpertStream(document: FinancialDocumentFileModel, statusChange: DocumentStatusChangeModel) {
		return this.restService
			.mutate({
				mutation: confirmDigitizationByExpertMutationText,
				variables: {
					newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
				}
			}, { error: this.onRestError.bind(this) })
			.pipe(
				switchMap(this.refreshMap(document))
			);
	}

	public requeueDocumentStream(document: FinancialDocumentFileModel, statusChange: DocumentStatusChangeModel) {
		return this.documentsLiteService.requeueDocumentStream(statusChange)
			.pipe(
				switchMap(this.refreshMap(document))
			);
	}


	public moveToPayroll(document: FinancialDocumentFileModel) {
		return this.documentsLiteService.moveToPayroll(document.id)
			.pipe(
				map((response: any) => {
					if (response) {
						this.remove(document);
						return true;
					}
					return false;
				})
			);
	}


	public syncAccNote(document: FinancialDocumentFileModel) {
		return this.documentsLiteService.syncAccNote(document.id)
			.pipe(
				switchMap((response: any) => {
					if (response) {
						return this.refreshDocumentStream(document);
					}
					return of(null);
				}),
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public updateDueDate(document: FinancialDocumentFileModel, dueDate: Date) {
		return this.restService
			.mutate({
				mutation: updateDueDateMutationText,
				variables: {
					documentEid: document.id,
					dueDate: dateToInt(dueDate)
				}
			}, { error: this.onRestError.bind(this) })
			.pipe(
				switchMap((response: any) => {
					if (response) {
						return this.refreshDocumentStream(document);
					}
					return of(null);
				}),
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public downloadAccMetadata(file: FinancialDocumentFileModel) {
		return this.documentsLiteService.downloadAccMetadata(file.id);
	}

	public setEmployee(document: FinancialDocumentFileModel, employee: PersonForAccountingModel) {
		return this.restService
			.mutate({
				mutation: setEmployeeMutationText,
				variables: {
					docEid: document.id,
					data: { employee: classToPlain(employee, { excludePrefixes: ["__"] })}
				}
			})
			.pipe(
				switchMap((response: any) => {
					if (response) {
						return this.refreshDocumentStream(document);
					}
					return of(null);
				}),
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public addChatMessage(document: FinancialDocumentFileModel, chatTypeExternalId: string, messageContent: string): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: addChatMessageMutationText,
				variables: {
					clientExternalId: document.clientEid,
					chatTypeExternalId: chatTypeExternalId,
					message: { content: messageContent, source: 'web' }
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public getBalanceSheetsClientWorkInProgress(document: FinancialDocumentFileModel): Observable<BalanceSheetInstanceListModel[]> {
		return this.restService
			.query({
				query: getBalanceSheetsClientWorkInProgressQueryText,
				variables: {
					clientEid: document.clientEid,
				},
				fetchPolicy: 'network-only',
			}, { spinner: true })
			.pipe(
				map((res: any) => {
					if (res) {
						let result: BalanceSheetInstanceListModel[] = null;
						try {
							result = plainToClass<BalanceSheetInstanceListModel, object>(BalanceSheetInstanceListModel, res.data.subs.getBalanceSheetsClientWorkInProgressUsingGET.instances || []);
							result.forEach((res) => {
								res.definition = plainToClass(BalanceSheetDefinitionModel, res.definition);
							})
						} catch {
							result = null;
						}

						return result;
					}
					return null;
				})
			);
	}

	public moveToBalanceSheet(document: FinancialDocumentFileModel, balanceSheet: BalanceSheetInstanceListModel): Observable<boolean> {
		let mutationData: Subs_MoveDocumentToBalanceSheetRepresentationInput = {
			documentEid: document.id,
			instanceEid: balanceSheet.externalId
		};

		return this.restService
			.mutate({
				mutation: moveToBalanceSheetMutationText,
				variables: {
					data: mutationData
				}
			})
			.pipe(
				map((response) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}
}
