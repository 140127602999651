import { Component, Input, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { AuthService } from '@saliente/common-auth';
import { ToastrService } from 'ngx-toastr';
import { switchMap, take } from 'rxjs/operators';
import { Accounts_GetStatutoryReportDefinitionUsingGetQuery, StatutoryReportsServices } from '../services/statutory-reports-definition.service';
import { Model } from './accounting-statutory-reports.model';

@Component({
	selector: 'accounting-statutory-reports',
	templateUrl: 'accounting-statutory-reports.component.html',
})
export class AccountingStatutoryReportsComponent implements OnInit {
	//@Input() public model: StatutoryReportsModel;
	@Input()
	set date(value: Date) {
		if (value != null) {
			this._date = value;
			// console.log('+++++++++++++++++++++++++++++++'+value);
			this.initialize();
		}
	}

	get date(): Date {
		return this._date;
	}
	private _date: Date;
	public model: Model;
	public dropzoneActive = false;

	constructor(private statutoryReportsServices: StatutoryReportsServices, private authService: AuthService, private toastr: ToastrService, public intl: IntlService) {
		this.model = new Model();
	}

	public ngOnInit(): void {
		// this.initialize();
	}
	initialize(): void {
		this.authService
			.getSelectedCompanyId()
			.pipe(
				take(1),
				switchMap((clientEid) => {
					// console.log('=============================='+clientEid);
					return this.statutoryReportsServices.getStatutoryReportDefinition(clientEid, this.date);
				})
			)
			.subscribe((res: Accounts_GetStatutoryReportDefinitionUsingGetQuery) => {
				this.model.initialize(res.accounts.getStatutoryReportDefinitionUsingGET);
			});
	}
	public handleDrop(fileList: FileList): void {
		if (fileList.length === 1 && fileList[0].name.toLowerCase().endsWith('.pdf')) {
			const file = fileList[0];
			this.statutoryReportsServices.uploadDefinition(file).subscribe(() => {
				this.initialize();
			});
		} else {
			this.toastr.error('Incarcati doar un fisier cu extensia pdf');
		}
	}
	public dropzoneState($event: boolean): void {
		this.dropzoneActive = $event;
	}
}
