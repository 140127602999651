import { Observable, Subject, BehaviorSubject, combineLatest, merge, of, Subscription } from 'rxjs';
import { debounceTime, map, share, switchMap, scan, startWith, catchError, } from 'rxjs/operators';

import gql from 'graphql-tag';
import { plainToClass, classToPlain } from 'class-transformer';

import { dateToInt } from '@saliente/library';

import {
	FinancialDocumentFileModel, FinancialDocumentDataModel,
	FinancialDocumentStatusChangeToBookedModel, FinancialDocumentStatusChangeToDigitizedModel,

} from './financial-documents.models';
import { DocumentPageModel, DocumentStatusChangeModel, } from './documents-base.models';
import {
	DocumentFileStatus, DocumentDigitizationOutcome,
	FinancialDocumentEntryType, FinancialDocumentPaymentType
} from './documents.enums';

import { CommonService, } from '../general/common.service';
import { ConfigService, } from '../general/config.service';
import { DownloadService, } from '../general/download.service';
import { RestService, } from '../general/rest.service';
import { AuthService, } from '../auth/auth.service';
import { DocumentsBaseService, } from './documents-base.service';
import { Injectable } from '@angular/core';
import { PredefinedUserZone } from '../auth/auth.models';


const documentFinancialDataPartialQueryText = `
	accountingPeriod
	currency
	documentDate
	documentNumber
	dueDate
	entryTypeName
	exchangeRate
	externalId
	financialDocumentType
	invoiceData {
		paymentType
		simplifiedInvoice
		vatOnCollection
		receiptEid
	}
	isInputDocument
	postings {
		amount
		savedAmount:amount
		cardNumber
		comment
		creditAccount
		creditAccountName
		currencyAmount
		debitAccount
		debitAccountName
		employeeEid
		employeeName
		employeePIN
		extraInfo
		partnerCIF
		partnerCountry
		partnerEid
		partnerName
		postingEid
		vatOption
		vatQuota
		vatOnPayment
	}
	receiptData {
		paymentType
		invoiceEid
	}
	reference
	soaData {
		bankName
		iban
	}`;

const documentPartialQueryText = `
	documentEid
	digitizationOutcome
	documentDate
	documentDateOutcome
	clientEid
	clientName
	status { statusCode, comment, dateTime }
	metadata
	created { dateTime, userName }
	comments{
		comment
		moment {dateTime, userName}
	}
`;

const financialDocumentsQueryText = gql`
query financialDocumentsData($filter: String!, $sort: String, $offset: Int, $count: Int){
	subs{
	  GetClientDocumentsUsingGET(filter:$filter, sort:$sort, offset:$offset, count:$count){
		${documentPartialQueryText}
	  }
	}
  }
`;

const financialDocumentQueryText = gql`
query financialDocumentData($docEid: String!){
	subs{
	  GetDocumentUsingGET(docEid:$docEid){
		${documentPartialQueryText}
	  }
	  GetFinancialDataUsingGET(docEid:$docEid){
		${documentFinancialDataPartialQueryText}
	  }
	}
  }
`;

const financialDocumentsStatisticsQueryText = gql`
query financialDocumentsStatistics($clientEid: String!){
	subs{
		GetClientDocumentsTotalStatisticsUsingGET(clientEid:$clientEid){
			bookedCounter
			cancelledCounter
			deletedCounter
			digitizedCounter
			invalidCounter
			markedForCancellationCounter
			markedForDeletionCounter
			notUsableCounter
			queuedForDigitizationCounter
			queuedForVerificationCounter
			receivedCounter
			sentToExpertCounter
			unavailableCounter
			verifiedCounter
		}
	}
}`;

const documentFieldsQueryText = gql`
query documentFields($docEid: String!){
	documents{
	  GetDocumentUsingGET(docEid:$docEid){
		pages { pageEid, visible }
	  }
	}
	subs{
	  GetFinancialDataUsingGET(docEid:$docEid){
		${documentFinancialDataPartialQueryText}
	  }
	  GetDocumentHistoryUsingGET(docEid:$docEid){
		comment
		dateTime
		statusCode
		userEid
		userName
	  }
	}
}
`;

const digitizeDocumentMutationText = gql`
mutation digitizeDocument($newStatus: subs_DocumentDigitizationOutcomeDataInput!){
	subs{
	  SetDigitizedUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const confirmDigitizationMutationText = gql`
mutation confirmDigitization($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  ConfirmDigitizationUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const rejectDigitizationMutationText = gql`
mutation rejectDigitization($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  RejectDigitizationUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const bookDocumentMutationText = gql`
mutation bookDocument($booking: subs_DocumentManualBookingInput!, $force: Boolean!){
	subs{
	  SetBookedUsingPOST(booking:$booking, force: $force){
		Message # empty
	  }
	}
}
`;

const confirmDeletionMutationText = gql`
mutation confirmDeletion($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  ConfirmDeletionUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const rejectDeletionMutationText = gql`
mutation rejectDeletion($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  RejectDeletionUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const confirmCancellationMutationText = gql`
mutation confirmCancellation($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  ConfirmCancellationUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const rejectCancellationMutationText = gql`
mutation rejectCancellation($newStatus: subs_FinDocStatusChangeDataInput!){
	subs{
	  RejectCancellationUsingPOST(statusChange:$newStatus){
		Message # empty
	  }
	}
}
`;

const clientDocumentsStatisticsQueryText = gql`
query clientDocumentsStatisticsData($clientEid: String!) {
	documents {
		GetClientDocumentsStatisticsUsingGET(clientEid: $clientEid){
			chargeableAmount
			chargeableInterval
		}
	}
  }
`;

const documentStartIndex = 0;
const pageStartIndex = 0;

export interface FinancialDocumentsFiltersValues {
	status?: string,
	keyword?: string,
	clientEid?: string,
	entryTypeName?: string,
	minDocumentValue?: number,
	maxDocumentValue?: number,
	minDocumentDate?: Date,
	maxDocumentDate?: Date,
	reference?: string,
}

export interface FinancialDocumentsQueryRequest {
	filter?: string,
	sort?: string,
	offset?: number,
	count?: number,
}

export class FinancialDocumentsFilters {
	public filterType: string = 'keyword';

	private statusStream = new Subject<string>();
	private _status: string;
	get status() {
		return this._status;
	}
	set status(value: string) {
		this._status = value;
		this.statusStream.next(this._status);
	}

	private keywordStream = new Subject<string>();
	private _keyword: string;
	get keyword() {
		return this._keyword;
	}
	set keyword(value: string) {
		this._keyword = value;
		this.keywordStream.next(this._keyword);
	}

	private clientEidStream = new BehaviorSubject<string>(null);
	private _clientEid: string;
	get clientEid() {
		return this._clientEid;
	}
	set clientEid(value: string) {
		this._clientEid = value;
		this.clientEidStream.next(this._clientEid);
	}

	private entryTypeNameStream = new Subject<string>();
	private _entryTypeName: string = '';
	get entryTypeName() {
		return this._entryTypeName;
	}
	set entryTypeName(value: string) {
		this._entryTypeName = value;
		this.entryTypeNameStream.next(this._entryTypeName);
	}

	private minDocumentValueStream = new Subject<number>();
	private _minDocumentValue: number;
	get minDocumentValue() {
		return this._minDocumentValue;
	}
	set minDocumentValue(value: number) {
		this._minDocumentValue = value;
		this.minDocumentValueStream.next(this._minDocumentValue);
	}

	private maxDocumentValueStream = new Subject<number>();
	private _maxDocumentValue: number;
	get maxDocumentValue() {
		return this._maxDocumentValue;
	}
	set maxDocumentValue(value: number) {
		this._maxDocumentValue = value;
		this.maxDocumentValueStream.next(this._maxDocumentValue);
	}


	private minDocumentDateStream = new Subject<Date>();
	private _minDocumentDate: Date;
	get minDocumentDate() {
		return this._minDocumentDate;
	}
	set minDocumentDate(value: Date) {
		this._minDocumentDate = value;
		this.minDocumentDateStream.next(this._minDocumentDate);
	}

	private maxDocumentDateStream = new Subject<Date>();
	private _maxDocumentDate: Date;
	get maxDocumentDate() {
		return this._maxDocumentDate;
	}
	set maxDocumentDate(value: Date) {
		this._maxDocumentDate = value;
		this.maxDocumentDateStream.next(this._maxDocumentDate);
	}


	private referenceStream = new Subject<string>();
	private _reference: string = '';
	get reference() {
		return this._reference;
	}
	set reference(value: string) {
		this._reference = value;
		this.referenceStream.next(this._reference);
	}


	get keywordAndReference() {
		return this.keyword;
	}
	set keywordAndReference(value: string) {
		this.keyword = value;
		this.reference = value;
	}

	private _stream: Observable<string>;
	get stream() {
		return this._stream;
	}

	get value() {
		return this.buildFilter(this.getFilterData());
	}

	constructor() {
		this._stream = merge(this.statusStream,
			this.keywordStream,
			this.clientEidStream,
			this.entryTypeNameStream,
			this.minDocumentValueStream,
			this.maxDocumentValueStream,
			this.minDocumentDateStream,
			this.maxDocumentDateStream,
			this.referenceStream)
			.pipe(
				debounceTime(500),
				map(() => {
					return this.getFilterData();
				}),
				map((filtersValues: FinancialDocumentsFiltersValues) => {
					return this.buildFilter(filtersValues);
				})
			);
	}

	private getFilterData() {
		return {
			status: this._status,
			keyword: this._keyword,
			clientEid: this._clientEid,
			entryTypeName: this._entryTypeName,
			minDocumentValue: this._minDocumentValue,
			maxDocumentValue: this._maxDocumentValue,
			minDocumentDate: this._minDocumentDate,
			maxDocumentDate: this._maxDocumentDate,
			reference: this._reference,
		}
	}

	private buildFilter(filtersValues: FinancialDocumentsFiltersValues) {
		let filter = "";
		let separator = "";

		if (filtersValues.status) {
			if (filtersValues.status === DocumentFileStatus.UnBooked) {
				filter += separator + 'booked:false';
			} else {
				filter += separator + 'status:' + filtersValues.status.trim();
			}
			separator = ' AND ';
		}
		if (filtersValues.clientEid) {
			filter += separator + 'clientEid:' + filtersValues.clientEid.trim();
			separator = ' AND ';
		}
		if (filtersValues.entryTypeName) {
			filter += separator + 'entryTypeName:' + filtersValues.entryTypeName.trim();
			separator = ' AND ';
		}

		switch (this.filterType) {
			case 'keyword':
				if (filtersValues.keyword) {
					filter += separator + 'text:' + filtersValues.keyword.trim();
					separator = ' AND ';
				}
				break;
			case 'value':
				let minValue = filtersValues.minDocumentValue || filtersValues.minDocumentValue == 0 ? String(filtersValues.minDocumentValue) : '';
				let maxValue = filtersValues.maxDocumentValue || filtersValues.maxDocumentValue == 0 ? String(filtersValues.maxDocumentValue) : '';
				if (minValue || maxValue) {
					filter += separator + `value:(${minValue}, ${maxValue})`;
					separator = ' AND ';
				}
				break;
			case 'date':
				let minDate = filtersValues.minDocumentDate ? dateToInt(filtersValues.minDocumentDate) : '';
				let maxDate = filtersValues.maxDocumentDate ? dateToInt(filtersValues.maxDocumentDate) : '';
				if (minDate || maxDate) {
					filter += separator + `finDataDate:(${minDate}, ${maxDate})`;
					separator = ' AND ';
				}
				break;
			case 'reference':
				if (filtersValues.reference) {
					filter += separator + 'reference:' + filtersValues.reference.trim();
					separator = ' AND ';
				}
				break;
		}
		return filter;
	}
}


@Injectable()
export class FinancialDocumentsService<DocumentFile extends FinancialDocumentFileModel> extends DocumentsBaseService<DocumentFile, DocumentPageModel> {
	protected endOfList: boolean = false;

	private pageIndex = pageStartIndex;
	public pageIndexStream = new BehaviorSubject<number>(0);
	public pageSize = 100;

	private _filters: FinancialDocumentsFilters;
	private filtersValue: string;
	get filters() {
		return this._filters;
	}

	private sortBy: string = 'documentDate DESC';
	protected sortByStream: BehaviorSubject<string>;

	protected documentsLength = 0;
	private documentsStream: BehaviorSubject<DocumentFile[]>;

	protected documentIndex = documentStartIndex;
	protected documentIndexStream = new Subject<number>();
	private documentStream: Observable<DocumentFile>;

	public changedDocStream: BehaviorSubject<FinancialDocumentFileModel>;

	private _currentListModel: FinancialDocumentFileModel[];
	get currentListModel() {
		return this._currentListModel;
	}

	constructor(configService: ConfigService, protected commonService: CommonService, protected restService: RestService, downloadService: DownloadService, protected authService: AuthService) {
		super(configService, downloadService);

		this.documentsStream = new BehaviorSubject([]);
		this.changedDocStream = new BehaviorSubject(new FinancialDocumentFileModel());
		this.sortByStream = new BehaviorSubject(this.sortBy);

		this._filters = this.createFilters();
		this.filtersValue = this._filters.value;

		this.initializeStreams();
	}

	protected initializeStreams() {
		if (this.authService.selectedZoneCode === PredefinedUserZone.Expert || this.authService.selectedZoneCode === PredefinedUserZone.Subcontractor
			|| this.authService.selectedZoneCode === PredefinedUserZone.Administrator || this.authService.selectedZoneCode === PredefinedUserZone.HrExpert) {
			const pageSource = this.pageIndexStream.pipe(
				map((pageIndex: number): number => {
					this.pageIndex = pageIndex;
					return pageIndex;
				}),
				share()
			);
			const filterSource = this._filters.stream.pipe(
				map((filter: string): string => {
					this.filtersValue = filter;
					this.pageIndex = 0;
					this.documentIndex = 0;
					return filter;
				}),
				share()
			);
			const sortBySource = this.sortByStream.pipe(
				map((sortBy: string): string => {
					this.sortBy = sortBy;
					this.pageIndex = 0;
					this.documentIndex = 0;
					return sortBy;
				}),
				share()
			);

			combineLatest([pageSource, filterSource, sortBySource]).pipe(
				map(() => { return this.documentsQueryRequest(); }),
				debounceTime(50),
				switchMap(this.documentsQueryStream.bind(this)),
				scan(
					(existingDocuments: DocumentFile[], newDocuments: DocumentFile[]) => {
						if (this.pageIndex) {
							if (newDocuments) {
								return this.mergeDocuments(existingDocuments, newDocuments);
							}
							return existingDocuments;
						}
						return newDocuments || [];
					}, [])
			).subscribe((documents: DocumentFile[]) => {
				this.documentsLength = documents.length;
				this._currentListModel = documents;
				this.documentsStream.next(documents);
			});

			const documentIndexSource = this.documentIndexStream.pipe(
				startWith(documentStartIndex),
				map((documentIndex) => {
					this.documentIndex = documentIndex;
					return documentIndex;
				})
			);

			this.documentStream = combineLatest([this.documentsStream, documentIndexSource]).pipe(
				debounceTime(50),
				switchMap(
					([documents, documentIndex]): Observable<DocumentFile> => {
						if (documents && documents.length) {
							const model = documents[this.checkDocumentIndex(documents, this.documentIndex)];
							if (!model.fullyLoaded) {
								return this.documentQueryStream(model);
							}
							return of(model);
						}
						return of(null);
					})
			);
		}
	}
	
	protected createFilters() {
		return new FinancialDocumentsFilters();
	}

	protected documentsQueryRequest(): FinancialDocumentsQueryRequest {
		var result = {
			filter: this.filtersValue,
			sort: this.sortBy,
			offset: this.pageIndex * this.pageSize,
			count: this.pageSize
		}

		const overrlapDocuments = 10;
		if (result.offset >= overrlapDocuments) {
			result.offset -= overrlapDocuments;
			result.count += overrlapDocuments;
		}
		return result;
	}

	protected documentsQueryStream(request: FinancialDocumentsQueryRequest): Observable<DocumentFile[]> {
		return this.restService
			.query({
				query: financialDocumentsQueryText,
				fetchPolicy: 'network-only',
				variables: request
			})
			.pipe(
				map((response: any) => {
					if (response) {
						const data = JSON.parse(JSON.stringify(response.data.subs.GetClientDocumentsUsingGET));
						const documents: DocumentFile[] = this.plainToDocumentClass(data);
						this.endOfList = false;
						return documents;
					}
					return null;
				})
			);
	}

	public documentQueryStream(model: DocumentFile, options?: any) {
		return this.restService
			.query({
				query: documentFieldsQueryText,
				fetchPolicy: 'network-only',
				variables: {
					docEid: model.id
				}
			}, options)
			.pipe(
				map((response: any) => {
					if (response) {
						model.pages = plainToClass<DocumentPageModel, object>(DocumentPageModel, response.data.documents.GetDocumentUsingGET.pages);
						model.totalPages = model.pages.length;
						model.financialData = plainToClass<FinancialDocumentDataModel, object>(FinancialDocumentDataModel, response.data.subs.GetFinancialDataUsingGET);
						model.history = (response.data.subs.GetDocumentHistoryUsingGET || []).slice();
						model.transformComplete();
						model.fullyLoaded = true;
					}
					return model;
				})
			);
	}

	private refreshDocumentsStatisticsStream = new BehaviorSubject(0);
	public documentsStatisticsQueryStream(): Observable<any> {
		return combineLatest([this.authService.user.selectedCompanyIdObservable, this.refreshDocumentsStatisticsStream])
			.pipe(
				switchMap(([companyId]) => {
					return this.restService
						.query({
							query: financialDocumentsStatisticsQueryText,
							fetchPolicy: 'network-only',
							variables: {
								clientEid: companyId
							}
						}, { spinner: false })
						.pipe(
							map((response: any) => {
								if (response) {
									const detailed = response.data.subs.GetClientDocumentsTotalStatisticsUsingGET;
									return {
										sentToExpert: detailed.sentToExpertCounter,
										notUsable: detailed.notUsableCounter,
										unBooked: detailed.digitizedCounter +
											detailed.markedForCancellationCounter +
											detailed.markedForDeletionCounter +
											detailed.queuedForDigitizationCounter +
											detailed.queuedForVerificationCounter +
											detailed.receivedCounter +
											detailed.sentToExpertCounter +
											detailed.verifiedCounter,
										markedForDeletion: detailed.markedForDeletionCounter,
										markedForCancellation: detailed.markedForCancellationCounter,
									};
								}
								return {
									sentToExpert: 0,
									notUsable: 0,
									unBooked: 0,
									markedForDeletion: 0,
									markedForCancellation: 0,
								};
							})
						);
				})
			);
	}
	
	public refreshDocumentsStatistics() {
		this.refreshDocumentsStatisticsStream.next(this.refreshDocumentsStatisticsStream.value + 1);
	}

	protected refreshDocumentStream(model: DocumentFile) {
		return this.restService
			.query({
				query: financialDocumentQueryText,
				fetchPolicy: 'network-only',
				variables: {
					docEid: model.id
				}
			})
			.pipe(
				map((response: any) => {
					if (response) {
						const data = JSON.parse(JSON.stringify(response.data.subs.GetDocumentUsingGET));
						data.financialData = JSON.parse(JSON.stringify(response.data.subs.GetFinancialDataUsingGET));
						const document = this.plainToDocumentClass(data);
						document.transformComplete();
						model.copy(document);
						this.changedDocStream.next(document);
						return model;
					}
					return null;
				})
			);
	}

	public clientDocumentsStatisticsQueryStream(): Observable<any> {
		return this.authService.getSelectedCompanyId().pipe(
			switchMap((companyId) => {
				return this.restService
					.query({
						query: clientDocumentsStatisticsQueryText,
						fetchPolicy: 'network-only',
						variables: {
							clientEid: companyId
						}
					}, { spinner: false })
					.pipe(
						map((response: any) => {
							let clientStatistics: any = {};
							
							if (response && response.data) {
								const statistics = response.data.documents.GetClientDocumentsStatisticsUsingGET;
								if (statistics && statistics.length) {
									statistics.forEach((s: any) => {
										clientStatistics[s.chargeableInterval] = s.chargeableAmount;
									});
								}
							}

							return clientStatistics;
						})
					);
			})
		);
	}

	protected plainToDocumentClass(data: any): any {
		return plainToClass(FinancialDocumentFileModel, this.processDocumentJson(data));
	}

	private mergeDocuments(existingDocuments: DocumentFile[], newDocuments: DocumentFile[]): DocumentFile[] {
		const newDocumentsLength = newDocuments.length;
		for (let documentIndex = 0; documentIndex < newDocumentsLength; documentIndex++) {
			const newDocument = newDocuments[documentIndex];
			var existingDocument = existingDocuments.find((existingDocument: DocumentFile) => {
				return existingDocument.id === newDocument.id;
			});
			if (existingDocument) {
				existingDocument.copy(newDocument);
			} else {
				existingDocuments.push(newDocument);
			}
		}
		return existingDocuments;
	}

	private checkDocumentIndex(documents: DocumentFile[], value: number): number {
		if (value < 0) {
			value = 0;
		} else if (value >= documents.length) {
			value = documents.length - 1;
		}
		this.documentIndex = value;
		return value;
	}

	public fetchList(refresh: boolean = false) {
		if (refresh) {
			this.pageIndexStream.next(0);
		}
		return this.documentsStream;
	}

	public fetch() {
		return this.documentStream;
	}
	
	public fetchChangedDoc() {
		return this.changedDocStream;
	}

	public hasPrev() {
		return this.documentIndex > 0;
	}

	public prev() {
		let documentIndex = this.documentIndex - 1;
		if (documentIndex < documentStartIndex) {
			documentIndex = documentStartIndex;
		}
		if (documentIndex !== this.documentIndex) {
			this.documentIndexStream.next(documentIndex);
		}
	}

	public hasNext() {
		return this.documentIndex + 1 < this.documentsLength || !this.endOfList;
	}

	public next() {
		const nextDocumentIndex = this.documentIndex + 1;
		if (nextDocumentIndex >= this.documentsLength) {
			if (nextDocumentIndex >= (this.pageIndex + 1) * this.pageSize) {
				this.documentIndex = nextDocumentIndex;
				this.pageIndexStream.next(this.pageIndex + 1);
			} else {
				this.endOfList = true;
			}
		} else {
			this.documentIndexStream.next(nextDocumentIndex);
		}
	}

	public nextPage() {
		if (this.documentsLength < (this.pageIndex + 1) * this.pageSize) {
			this.endOfList = true;
		} else {
			this.pageIndexStream.next(this.pageIndex + 1);
		}
		return !this.endOfList;
	}

	public getDocumentsLength() {
		return this.documentsLength;
	}

	public refresh() {
		this.documentIndex = 0;
		this.pageIndexStream.next(0);
	}

	public refreshDocumentInList(document: DocumentFile) {
		const documents = this.documentsStream.value,
			docIdx = documents.findIndex((doc) => doc.id == document.id);
		if (docIdx != -1) {
			documents[docIdx] = document;
			this.documentsStream.next(documents);
		}
	}

	protected remove(document: DocumentFile) {
		const documents = this.documentsStream.value,
			docIdx = documents.indexOf(document);
		if (docIdx != -1) {
			documents.splice(docIdx, 1);
			this.documentsStream.next(documents);
		}
	}

	public extendedOverlayClass(statusCode: string, outcome?: string): string {
		switch (statusCode) {
			case DocumentFileStatus.Received:
			case DocumentFileStatus.QueuedForDigitization:
				return 'cyan lighten-4';
			case DocumentFileStatus.Digitized:
			case DocumentFileStatus.QueuedForVerification:
				if (outcome === DocumentDigitizationOutcome.NotUsable)
					return this.extendedOverlayClass(DocumentFileStatus.NotUsable);
				if (outcome === DocumentDigitizationOutcome.SendToExpert)
					return this.extendedOverlayClass(DocumentFileStatus.SentToExpert);
				return 'orange lighten-5';
			case DocumentFileStatus.Verified: return 'orange lighten-5';
			case DocumentFileStatus.NotUsable: return 'red lighten-4';
			case DocumentFileStatus.SentToExpert: return 'purple lighten-4';
			case DocumentFileStatus.Deleted: return 'blue-grey lighten-4';
			case DocumentFileStatus.MarkedForDeletion: return 'blue-grey lighten-4';
			case DocumentFileStatus.Cancelled: return 'blue-grey lighten-4';
			case DocumentFileStatus.MarkedForCancellation: return 'blue-grey lighten-4';
			case DocumentFileStatus.Booked: return 'light-green lighten-4';
			default:
				return 'cyan lighten-4';
		}
	}

	public digitizationOutcomeName(outcome: string): string {
		switch (outcome) {
			case DocumentDigitizationOutcome.NotUsable: return 'Neclar';
			case DocumentDigitizationOutcome.SendToExpert: return 'Trimite la expert';
			case DocumentDigitizationOutcome.OK: return 'OK';

		}
		return '';
	}

	protected setDocumentStatus(document: DocumentFile, statusCode: string) {
		document.status.statusCode = statusCode;
		document.status.dateTime = new Date();

		this.changedDocStream.next(document);
	}

	public financialDocumentEntryTypeList(): Observable<any[]> {
		return of([
			{ id: FinancialDocumentEntryType.SaleInvoice, code: 'FV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.SaleInvoice), defaultJournal: 'JV' },
			//{ id: FinancialDocumentEntryType.SaleFiscalReceipt, code: 'BFV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.SaleFiscalReceipt), defaultJournal: 'RC' },
			{ id: FinancialDocumentEntryType.PurchaseInvoice, code: 'FA', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseInvoice), defaultJournal: 'JC'},
			{ id: FinancialDocumentEntryType.AutoInvoice, code: 'FAAU', name: this.entryTypeDisplayName(FinancialDocumentEntryType.AutoInvoice), defaultJournal: 'JC', indent: true },
			{ id: FinancialDocumentEntryType.PurchaseFiscalReceipt, code: 'BFA', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseFiscalReceipt), defaultJournal: 'JC' },
			{ id: FinancialDocumentEntryType.PurchaseFiscalReceiptWithoutTIN, code: 'BFAC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.PurchaseFiscalReceiptWithoutTIN), defaultJournal: 'JD', indent: true },
			{ id: FinancialDocumentEntryType.GasReceipt, code: 'BCM', name: this.entryTypeDisplayName(FinancialDocumentEntryType.GasReceipt), defaultJournal: 'JC', indent: true },
			{ id: FinancialDocumentEntryType.AutoReceipt, code: 'BAU', name: this.entryTypeDisplayName(FinancialDocumentEntryType.AutoReceipt), defaultJournal: 'JC', indent: true },
			{ id: FinancialDocumentEntryType.ProtocolFiscalReceipt, code: 'BFP', name: this.entryTypeDisplayName(FinancialDocumentEntryType.ProtocolFiscalReceipt), defaultJournal: 'JC', indent: true },
			{ id: FinancialDocumentEntryType.CashReceipt, code: 'CC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashReceipt), defaultJournal: 'RC'},
			{ id: FinancialDocumentEntryType.CashIssued, code: 'CE', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashIssued), defaultJournal: 'RC'},
			{ id: FinancialDocumentEntryType.CashIssuedSales, code: 'CEFV', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashIssuedSales), defaultJournal: 'RC'},
			{ id: FinancialDocumentEntryType.ExpenseAccountReceipt, code: 'CD', name: this.entryTypeDisplayName(FinancialDocumentEntryType.ExpenseAccountReceipt), defaultJournal: 'JD'},
			{ id: FinancialDocumentEntryType.CashOrder, code: 'DP', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CashOrder), defaultJournal: 'RC'},
			{ id: FinancialDocumentEntryType.CollectionOrder, code: 'DI', name: this.entryTypeDisplayName(FinancialDocumentEntryType.CollectionOrder), defaultJournal: 'RC'},
			//{ id: FinancialDocumentEntryType.StatementOfAccount, code: 'EC', name: this.entryTypeDisplayName(FinancialDocumentEntryType.StatementOfAccount), defaultJournal: 'JB'},
		]);
	}

	public invoicePaymentTypeList(): Observable<any[]> {
		return of([{
			code: FinancialDocumentPaymentType.Bank,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Bank)
		}, {
			code: FinancialDocumentPaymentType.Cash,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Cash)
		}, {
			code: FinancialDocumentPaymentType.Card,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Card)
		},]);
	}

	public saleInvoicePaymentTypeList(): Observable<any[]> {
		return of([{
			code: FinancialDocumentPaymentType.Bank,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Bank)
		}, {
			code: FinancialDocumentPaymentType.Cash,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Cash)
		}, {
			code: FinancialDocumentPaymentType.Card,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Card)
		}, {
			code: FinancialDocumentPaymentType.Receipt,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Receipt)
		}]);
	}

	public receiptPaymentTypeList(): Observable<any[]> {
		return of([{
			code: FinancialDocumentPaymentType.Cash,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Cash)
		}, {
			code: FinancialDocumentPaymentType.Card,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Card)
		}]);
	}

	public cashOnlyPaymentTypeList(): Observable<any[]> {
		return of([{
			code: FinancialDocumentPaymentType.Cash,
			name: DocumentsBaseService.paymentTypeDisplayName(FinancialDocumentPaymentType.Cash)
		}]);
	}

  public exchangeRate(currency: string, date: number, silent: boolean = false): Observable<number> {
		if (currency === this.configService.localCurrencyCode) {
			return of(1);
		}
		return this.commonService.exchangeRateStream(currency, date, silent).pipe(
			map((data) => {
				if (data) {
					return data.exchangeRate;
				}
				return 1;
			})
		);
	}


	public exchangeRateOld(currency: string, date: number, silent: boolean = false): Observable<number> {
		if (currency === this.configService.localCurrencyCode) {
			return of(1);
		}
		return this.commonService
			.exchangeRatesStream(date, silent).pipe(
				map((data: any) => {
					if (data && data.length) {
						let rate = data.find((er: any) => er.currencyCode === currency);
						if (rate) {
							return rate.exchangeRate;
						}
					}
					return 1;
				})
			);
	}

	protected refreshMap(document: DocumentFile, newStatus: DocumentFileStatus = null) {
		const refresh = (newStatus === null);
		return (response: any) => {
			if (response) {
				if (refresh) {
					return this.refreshDocumentStream(document);
				} else {
					this.setDocumentStatus(document, newStatus);
				}
				this.refreshDocumentsStatistics();				
				return of(document);
			}
			return of(null);
		};
	}

	protected onRestError(error: any) {

	}

	public digitize(document: DocumentFile, statusChange: FinancialDocumentStatusChangeToDigitizedModel, refresh: boolean = false) {
		if (document.status.statusIsReceived()) {
			const newStatus = refresh ? null : DocumentFileStatus.Digitized;
			return this.restService
				.mutate(
					{
						mutation: digitizeDocumentMutationText,
						variables: {
							newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
						}
					}, { error: this.onRestError.bind(this) }
				).pipe(
					switchMap(this.refreshMap(document, newStatus))
				);
		}
		return of(document);
	}

	public confirmDigitization(document: DocumentFile, statusChange: DocumentStatusChangeModel, refresh: boolean = false) {
		if (document.status.statusIsDigitized()) {
			const newStatus = refresh ? null : DocumentFileStatus.Verified;
			return this.restService
				.mutate(
					{
						mutation: confirmDigitizationMutationText,
						variables: {
							newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
						}
					}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document, newStatus))
				);
		}
		return of(document);
	}

	public rejectDigitization(document: DocumentFile, statusChange: DocumentStatusChangeModel, refresh: boolean = false) {
		if (document.status.statusIsDigitized()) {
			const newStatus = refresh ? null : DocumentFileStatus.Received;
			return this.restService
				.mutate(
					{
						mutation: rejectDigitizationMutationText,
						variables: {
							newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
						}
					}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document, newStatus))
				);
		}
		return of(document);
	}

	public confirmDeletion(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		if (document.status.statusCode === DocumentFileStatus.MarkedForDeletion) {
			return this.restService
				.mutate(
					{
						mutation: confirmDeletionMutationText,
						variables: {
							newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
						}
					}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document))
				);
		}
		return of(document);
	}

	public rejectDeletion(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		if (document.status.statusCode === DocumentFileStatus.MarkedForDeletion) {
			return this.restService
				.mutate(
					{
						mutation: rejectDeletionMutationText,
						variables: {
							newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
						}
					}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document))
				);
		}
		return of(document);
	}

	public confirmCancellation(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		if (document.status.statusCode === DocumentFileStatus.MarkedForCancellation) {
			return this.restService
				.mutate({
					mutation: confirmCancellationMutationText,
					variables: {
						newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
					}
				}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document))
				);
		}
		return of(document);
	}

	public rejectCancellation(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		if (document.status.statusCode === DocumentFileStatus.MarkedForCancellation) {
			return this.restService
				.mutate({
					mutation: rejectCancellationMutationText,
					variables: {
						newStatus: classToPlain(statusChange, { excludePrefixes: ["__"] })
					}
				}, { error: this.onRestError.bind(this) })
				.pipe(
					switchMap(this.refreshMap(document))
				);
		}
		return of(document);
	}

	public markBlur(document: DocumentFile, statusChange: FinancialDocumentStatusChangeToDigitizedModel, refresh: boolean = false) {
		return this.digitize(document, statusChange, refresh);
	}

	public confirmBlur(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		return this.confirmDigitization(document, statusChange.getStatusChangeToConfirmationModel(), true);
	}

	public sendToExpert(document: DocumentFile, statusChange: FinancialDocumentStatusChangeToDigitizedModel, refresh: boolean = false) {
		return this.digitize(document, statusChange, refresh);
	}

	public confirmSendToExpert(document: DocumentFile, statusChange: DocumentStatusChangeModel) {
		return this.confirmDigitization(document, statusChange.getStatusChangeToConfirmationModel(), true);
	}

	protected cielReferenceNotFound(error: any, statusChange: FinancialDocumentStatusChangeToBookedModel) {
		this.restService.handleError(error);
		return of(null);
	}

	public bookStream(statusChange: FinancialDocumentStatusChangeToBookedModel, force: boolean = false, options: any = {}) {
		return this.restService
			.mutate({
				mutation: bookDocumentMutationText,
				variables: {
					booking: classToPlain(statusChange, { excludePrefixes: ["__"] }),
					force
				}
			}, options);
	}

	public book(document: DocumentFile, statusChange: FinancialDocumentStatusChangeToBookedModel, refresh: boolean = false, force: boolean = false) {
		const newStatus = refresh ? null : DocumentFileStatus.Booked;
		return this.bookStream(statusChange, force, { error: false })
			.pipe(
				catchError((error) => {
					var errorCodes = this.restService.getErrorsCodes(error);
					if (errorCodes.indexOf('ERROR_NOT_CIEL_REFERENCE_FOUND') !== -1) {
						return this.cielReferenceNotFound(error, statusChange);
					}
					this.restService.handleError(error);
					return of(null);
				}),
				switchMap(this.refreshMap(document, newStatus))
			);
	}

	static documentFinancialDataPartialQueryText() {
		return documentFinancialDataPartialQueryText;
	}
}
