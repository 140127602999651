import { NgModel, } from '@angular/forms';

var moment = require('moment');

function message(model: NgModel, key: string) {
	switch (key) {
		case 'required':
			return 'Obligatoriu';
		case 'invalidDate':
			return 'Data invalida';
		case 'minlength':
			return `Acest câmp trebuie să aibă cel puțin ${model.errors[key].requiredLength} de caractere`;
		case 'maxlength':
			return `Acest câmp trebuie să aibă cel mult ${model.errors[key].requiredLength} de caractere`;
		case 'validateEqual':
			return model.errors[key] || 'Valoarea acestui câmp nu se potrivește';
		case 'maxDate':
			return `Max: ${moment(model.errors[key]).format('DD.MM.YYYY')}`;
		case 'minDate':
			return `Min: ${moment(model.errors[key]).format('DD.MM.YYYY')}`;
		default:
			return model.errors[key];
	}
}

export const validationMessages = (model: NgModel): string[] => {
	if (model.errors) {
		return Object.keys(model.errors).map((key) => message(model, key));
	}
	return null;
};
