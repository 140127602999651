
import { FilterDescriptor, CompositeFilterDescriptor, } from '@progress/kendo-data-query';
import { FilterService, } from '@progress/kendo-angular-grid';

import * as kendoUtils from '../../../kendo.utils';


export interface ListGridContainer {
    applyFilters(fieldName: string, filters: CompositeFilterDescriptor): void;
}
export class IntervalGridFilter {

    private __root__: CompositeFilterDescriptor;
    public get root(): CompositeFilterDescriptor {
        return this.__root__;
    }
    public set root(value: CompositeFilterDescriptor) {
        this.__root__ = value;
        this.__filter__ = {
            filters: kendoUtils.cloneFilters(kendoUtils.filtersByField(this.__root__, this.fieldName)),
            logic: 'and'
        };

        // AM. 01.09.2021. no task. fix invoice bug: only when dataTo and dataFrom are empty, when dataTo has been selected, dataFrom is filled with dataTo value
        kendoUtils.ensureIntervalFilters(this.__filter__, this.fieldName, ['gte', 'lte']);
        this.__from__ = <FilterDescriptor>this.__filter__.filters[0];
        this.__to__ = <FilterDescriptor>this.__filter__.filters[1];

        // this.__from__ = <FilterDescriptor>kendoUtils.setFilter(0, this.__filter__, this.fieldName, 'gte');
        // this.__to__ = <FilterDescriptor>kendoUtils.setFilter(1, this.__filter__, this.fieldName, 'lte');
    }

    private __filter__: CompositeFilterDescriptor;
    public get filter(): CompositeFilterDescriptor {
        return this.__filter__;
    }

    private __from__: FilterDescriptor;
    public get from(): FilterDescriptor {
        return this.__from__;
    }
    public get fromValue(): any {
        return this.__from__.value;
    }
    public set fromValue(value: any) {
        if (this.__from__.value != value) {
            this.__from__.value = value;
            this.applyFilters();
        }
    }

    private __to__: FilterDescriptor;
    public get to(): FilterDescriptor {
        return this.__to__;
    }
    public get toValue(): any {
        return this.__to__.value;
    }
    public set toValue(value: any) {
        if (this.__to__.value != value) {
            this.__to__.value = value;
            this.applyFilters();
        }
    }

    constructor(private owner: ListGridContainer, private fieldName: string) {
    }

    private filtersTimeout: any;
    public applyFilters() {
        const self = this;
        if (self.filtersTimeout) {
            clearTimeout(self.filtersTimeout);
            self.filtersTimeout = null;
        }
        self.filtersTimeout = setTimeout(() => {
            self.__filter__.filters = [self.__from__, self.__to__];
            self.owner.applyFilters(self.fieldName, self.__filter__);
        }, 1000)
    }

    public setFilter(filterService: FilterService) {
        filterService.filter(this.filter);
    }
}
