import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@saliente/common-auth';
import { dateToYYYYMM } from '@saliente/library';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Model } from './company-fiscal-summary.model';
import { Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQueryVariables, CompanyFiscalSummaryService } from './company-fiscal-summary.service';

@Component({
	selector: 'company-fiscal-summary-ex',
	templateUrl: 'company-fiscal-summary.component-ex.html',
})
export class CompanyFiscalSummaryExComponent implements OnInit, OnDestroy {
	@Input() public dateBehaviourSubject: BehaviorSubject<Date>;
	@Input() public somethingChangedBehaviourSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
	@Input() public clientChangedBehaviourSubject: BehaviorSubject<string> = null;

	currentDate: Date = null;
	currentCompanyEid = '';
	private variablesChangedSubscription: Subscription = null;

	public model: Model;

	constructor(private authService: AuthService, private companyFiscalSummaryService: CompanyFiscalSummaryService) {
		this.model = new Model();
	}

	public ngOnInit(): void {
		let clientObservable = this.authService.getSelectedCompanyId();
		if (this.clientChangedBehaviourSubject != null) {
			clientObservable = this.clientChangedBehaviourSubject;
		}
		this.variablesChangedSubscription = combineLatest([clientObservable, this.dateBehaviourSubject, this.somethingChangedBehaviourSubject])
			.pipe(
				switchMap(([clientEid, date, _]) => {
					const variables: Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQueryVariables = {
						clientEid: clientEid,
						forMonth: dateToYYYYMM(date),
					};
					return this.companyFiscalSummaryService.getCompanyFiscalSummary(variables);
				})
			)
			.subscribe((rez) => {
				this.model.initialize(rez.accounts.getClientFiscalInfoSummaryForMonthUsingGET);
			});
	}
	ngOnDestroy(): void {
		if (this.variablesChangedSubscription != null) {
			this.variablesChangedSubscription.unsubscribe();
			this.variablesChangedSubscription = null;
		}
	}
}
