import { UID, precisionRound } from '@saliente/library';
import { Exclude, Type } from 'class-transformer';

export function evaluateFormula(value: string): number {
	if (value) {
		let floatValue,
			evaledValue

		try {
			evaledValue = eval(value);
		} catch{ }
		if (evaledValue) {
			floatValue = parseFloat(evaledValue);
		}
		else {
			floatValue = NaN;
		}
		if (!isNaN(floatValue)) {
			return precisionRound(floatValue, 6);
		}
	}
	return null;
}

export class MeasureUnitModel {
	public id: number;
	public shortName: string;
	public description: string;
	public isAdvanced: boolean;
	public shortNameEn: string;
	public descriptionEn: string;
	public unCode: string;
	public displayOrder: number;

	static typeProvider() {
		return MeasureUnitModel;
	}
}

export class CpvCodeModel {
	public id: number;
	public code: string;
	public description: string;
	public codeEn: string;
	public descriptionEn: string;
	public displayOrder: number;

	static typeProvider() {
		return CpvCodeModel;
	}
}

export class NcCodeModel {
	public id: number;
	public code: string;
	public description: string;
	public isEFactura: boolean;
	public codeEn: string;
	public descriptionEn: string;
	public displayOrder: number;

	static typeProvider() {
		return NcCodeModel;
	}
}

export class ItemListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public pageSize: number;
	public data: ItemModel[]
}

export class ItemModel {
	public externalId: string = UID();;
	public clientEid: string;
	public name: string;
	public currencyCode: string;
	public lastPrice: number;
	public measureUnitId: number;
	public isActive: boolean = true;
	public hasExcise: boolean;
	public exciseFormula: string;
	@Exclude()
	public get exciseFormulaEx() {
		return this.exciseFormula;
	}
	public set exciseFormulaEx(value: string) {
		this.exciseFormula = value;
		this.exciseValue = evaluateFormula(value);
	}
	public exciseValue: number;
	public cpvCodeId: number;
	public ncCodeId: number;
	public categoryExternalId: string;
	public categoryName: string;
	public categoryRevenueAccount: string;
	public categoryDetails: string;
	public categoryHasVat: boolean = true;
	public categoryVatPercent: number = 19;
	public categoryType: string = 'SIMPLE';
	public categoryExpenseAccount: string;
	public categoryStockAccount: string;
	public itemCode: string;
	public isStockable: boolean;

	@Exclude()
	public displayName: string;
	
	@Type(MeasureUnitModel.typeProvider)
	public measureUnit: MeasureUnitModel;

	@Type(CpvCodeModel.typeProvider)
	public cpvCode: CpvCodeModel;

	@Type(NcCodeModel.typeProvider)
	public ncCode: NcCodeModel;

	static typeProvider() {
		return ItemModel;
	}
}

export class ItemModelLite {
	public externalId: string = UID();;
	public clientEid: string;
	public name: string;
	public isActive: boolean = true;
	public itemCode: string;

	static typeProvider() {
		return ItemModelLite;
	}
}

