import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ClientAccDocumentsService } from "src/app/clients/documents/acc-documents.service";
import { ClientDocumentItemComponent } from "src/app/clients/documents/document-item.component";
import { AuthService } from "../auth/auth.service";
import { DocumentFileModel } from "../documents/documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";
import { IClientDocumentItemParent } from "./client-document-item-parent";
import { ClientPayrollDocumentsService } from "src/app/clients/documents/payroll-documents.service";

@Component({
	selector: 'client-payroll-document-host',
	templateUrl: 'client-payroll-document-host.component.html',
	providers: [{ provide: IClientDocumentItemParent, useExisting: forwardRef(() => PayrollDocumentHostComponent) }]
})
export class PayrollDocumentHostComponent extends IClientDocumentItemParent implements OnInit {
	@Input() public model: DocumentFileModel;
	@Input() public documentType: string;
	@Input() public documentsNavigator: NavigableDataSource<string, DocumentFileModel>;
	@Output() public close = new EventEmitter();

	@ViewChild('clientPayrollDocumentItem') private clientPayrollDocumentItem: ClientDocumentItemComponent;
	
	public readonly hasAddRight: boolean = false;
	public readonly hasDeleteOwnRight: boolean = false;
	public readonly hasDeleteRight: boolean = false;
	public readonly hasDownloadArchiveRight: boolean = false;
	public readonly hasViewOpisRight: boolean = false;
	public readonly hasMarkUsableRight: boolean = false;
	public readonly hasAdnotateOwnRight: boolean = false;
	public readonly hasAdnotateRight: boolean = false;

	constructor(public documentsService: ClientPayrollDocumentsService, private authService: AuthService) {
		super();

		// from payroll-documents.component.ts
		this.hasAddRight = this.authService.isInRole('PAYROLL_DOCUMENTS_ADD');
		this.hasDeleteOwnRight = this.hasDeleteRight = this.authService.isInRole('PAYROLL_DOCUMENTS_DELETE');
		this.hasDownloadArchiveRight = this.authService.isInRole('PAYROLL_DOCUMENTS_ARCHIVE');
		this.hasViewOpisRight = false;
		this.hasMarkUsableRight = this.authService.isInRole('PAYROLL_DOCUMENTS_MARK_USABLE');
		this.hasAdnotateOwnRight = this.hasAdnotateRight = this.authService.isInRole('PAYROLL_DOCUMENTS_ADNOTATE');
	}

	ngOnInit() {
		setTimeout(() => {
			if (this.clientPayrollDocumentItem != undefined) {
				this.clientPayrollDocumentItem.showDocument = true;
			}
		}, 100);
	}
}