import { Type, Expose, Exclude, classToClass } from 'class-transformer';
import { dateToInt, intToDate } from '@saliente/library';
import { DictionaryItem } from '@saliente/common';
import { Subs_StatutoryReportConstraintRepresentation } from './expert-statutory-reports-processors.service';

export function expertDocumentsSummaryDateTypeProvider(): DateConstructor {
	return Date;
}

export class ExpertStatutoryReportProcessorModel {
	public code: string;
	public extendedCode: string;
	public description: string;
	public canGenerate: boolean;
	public canValidate: boolean;
	public canEvaluateParameters: boolean;
	public parametersValidated: boolean;

	public get displayCssClass(): string {
		if (this.canEvaluateParameters) {
			return this.parametersValidated ? 'green-text text-lighten-1' : 'red-text text-lighten-1';
		}
		return 'blue-text text-lighten-1';
	}

	static typeProvider(): typeof ExpertStatutoryReportProcessorModel {
		return ExpertStatutoryReportProcessorModel;
	}
}

export class ExpertStatutoryReportProcessorParamModel {
	public code: string;
	public value: string;

	static typeProvider(): typeof ExpertStatutoryReportProcessorParamModel {
		return ExpertStatutoryReportProcessorParamModel;
	}

	public clone(): ExpertStatutoryReportProcessorParamModel {
		return classToClass(this, { ignoreDecorators: true });
	}
}
export class ExpertStatutoryReportProcessStatusModel {
	public externalId: string;
	public message: string;
	public reportCode: string;
	public statusCode: string;
	public isRequiredThisMonth: boolean;
	public isVisible: boolean;
	public manuallyMarkedFinalized: boolean;
	public periodicity: string;
	public hasWrongPeriodicity: boolean;
	public hasWrongRequired: boolean;
	public messageForHasWrongXXX: string;

	@Exclude()
	public get hasProblems(): boolean {
		return this.hasWrongPeriodicity || this.hasWrongRequired;
	}

	@Expose({ name: 'uploadDeadline' })
	public uploadDeadlineInt: number;
	@Exclude()
	protected _uploadDeadlineEx: Date;
	@Exclude()
	get uploadDeadlineEx(): Date {
		if (!this._uploadDeadlineEx) {
			this._uploadDeadlineEx = intToDate(this.uploadDeadlineInt);
		}
		return this._uploadDeadlineEx;
	}
	set uploadDeadlineEx(value: Date) {
		if (this._uploadDeadlineEx !== value) {
			this._uploadDeadlineEx = value;
			this.uploadDeadlineInt = dateToInt(this._uploadDeadlineEx);
		}
	}

	static typeProvider(): typeof ExpertStatutoryReportProcessStatusModel {
		return ExpertStatutoryReportProcessStatusModel;
	}
}
export class ExpertStatutoryReportProcessorDefinitionModel {
	public code: string;
	public name: string;
	public isPlatformManaged: boolean;

  public constraint: Subs_StatutoryReportConstraintRepresentation;

	@Expose({ name: 'processStatusRepresentation' })
	@Type(ExpertStatutoryReportProcessStatusModel.typeProvider)
	public processStatus: ExpertStatutoryReportProcessStatusModel;

	@Type(ExpertStatutoryReportProcessorParamModel.typeProvider)
	public extraParameters: ExpertStatutoryReportProcessorParamModel[];

	static typeProvider(): typeof ExpertStatutoryReportProcessorDefinitionModel {
		return ExpertStatutoryReportProcessorDefinitionModel;
	}
}
export class ExpertStatutoryReportsProcessorsDefinitionsModel {
	public isVatPayer: boolean;
	public periodicityDictionary: DictionaryItem[];

	@Type(ExpertStatutoryReportProcessorDefinitionModel.typeProvider)
	public definitions: ExpertStatutoryReportProcessorDefinitionModel[];

	@Exclude()
	public filteredDefinitions: ExpertStatutoryReportProcessorDefinitionModel[];

	static typeProvider(): typeof ExpertStatutoryReportsProcessorsDefinitionsModel {
		return ExpertStatutoryReportsProcessorsDefinitionsModel;
	}
}

export class ExpertStatutoryReportsProcessorsModel {
	/*filters*/
	visibleShowAll = false;
	/*^^^^^^^*/

	showConfigurationDialog = false;
	constraints: Subs_StatutoryReportConstraintRepresentation[];
	public showStatutoryReportsDialog = false;

	@Expose({ name: 'externalId' })
	public clientEid: string;

	public fiscalNumber: string;
	public expertComments: string;

	@Expose({ name: 'serviceStartDate' })
	public serviceStartDateInt: number;
	@Exclude()
	protected _serviceStartDateEx: Date;

	@Exclude()
	get serviceStartDateEx(): Date {
		if (!this._serviceStartDateEx) {
			this._serviceStartDateEx = intToDate(this.serviceStartDateInt);
		}
		return this._serviceStartDateEx;
	}
	set serviceStartDateEx(value: Date) {
		if (this._serviceStartDateEx !== value) {
			this._serviceStartDateEx = value;
			this.serviceStartDateInt = dateToInt(this._serviceStartDateEx);
		}
	}

	public caenCode: string;
	public caenDescription: string;

	@Type(ExpertStatutoryReportsProcessorsDefinitionsModel.typeProvider)
	public statutoryReportsProcessors: ExpertStatutoryReportsProcessorsDefinitionsModel;

	initialize(): void {
		this.onVisibleShowAll();
	}
  clean(): void{
    this.statutoryReportsProcessors.filteredDefinitions = [];
  }

	onVisibleShowAll(): void {
		this.statutoryReportsProcessors.filteredDefinitions = [];
		this.statutoryReportsProcessors.definitions.forEach((def) => {
			if (this.visibleShowAll || def.processStatus.isVisible || def.processStatus.hasProblems) {
				this.statutoryReportsProcessors.filteredDefinitions.push(def);
			}
		});
	}
	public setConstraints(constraints: Subs_StatutoryReportConstraintRepresentation[], forRole: string): void {
		this.constraints = [];
		constraints.forEach((constraint) => {
			if (constraint.statutoryReportDictionary.forRole === forRole) {
				this.constraints.push(constraint);
			}
		});
	}
}
