import { Component, Input, OnInit, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { LabeledControl } from '../../labeledcontrol';
import { CalendarView, DateInputFormatPlaceholder } from '@progress/kendo-angular-dateinputs';

export const DATEPICKER_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeDatePickerComponent),
	multi: true
};

@Component({
	selector: 'kee-datepicker',
	templateUrl: 'datepicker.component.html',
	providers: [DATEPICKER_VALUE_ACCESSOR],
})
export class KeeDatePickerComponent extends LabeledControl<Date> implements OnInit {

	//#region properties

	//#region placeholder

	@Input() public formatPlaceholder: DateInputFormatPlaceholder;

	public _placeholder: string = null;
	@Input() get placeholder(): string {
		return this._placeholder == null ? " " : this._placeholder;
	}
	set placeholder(value) {
		this._placeholder = value;
	}

	//#endregion

	//#region format

	@Input() public format: string;

	//#endregion


	private _activeView: CalendarView;
	@Input() get activeView(): CalendarView {
		return this._activeView || 'month';
	}
	set activeView(value) {
		if (['month', 'year', 'decade', 'century'].indexOf(value) !== -1) {
			this._activeView = value;
		}
		else {
			this._activeView = 'month';
		}
	}

	private _bottomView: CalendarView;
	@Input() public get bottomView(): CalendarView {
		return this._bottomView || 'month';
	}
	set bottomView(value) {
		if (['month', 'year', 'decade', 'century'].indexOf(value) !== -1) {
			this._bottomView = value;
		}
		else {
			this._bottomView = 'month';
		}
	}

	@Input() public topView: CalendarView;
	@Input() public weekNumber: boolean;

	@Input() public min: Date;
	@Input() public max: Date;

	@Input() public ngModelOptions: any = { updateOn: 'change' };

	//#endregion

	//#region constructor

	public constructor(injector: Injector, ) {
		super(injector);
	}

	//#endregion

	//#region methods

	public ngOnInit() {
		super.ngOnInit();
	}

	public _handleChange(value: Date) {
		//if (this.ngModelOptions && this.ngModelOptions.updateOn != 'blur') {
		//	this.value = value;
		//	this.touch();
		//}
	}

	//#endregion
}