import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MessageBoxButton, MessageBoxService } from "@saliente/library";
import { from, Subscription } from "rxjs";
import { PredefinedUserZone } from "../auth/auth.models";
import { AuthService } from "../auth/auth.service";
import { DocumentFileModel } from "../documents/documents.models";
import { FinancialDocumentFileModel } from "../documents/financial-documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";
import { DocumentIndentificationModel } from "./document-display.models";
import { DocumentDisplayService } from "./document-display.service";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: 'document-display',
	templateUrl: 'document-display.component.html',
	styleUrls: ['document-display.component.scss'],
})
export class DocumentDisplayComponent implements OnInit, OnDestroy {
	public documentFileSubscription: Subscription;
	public documentFile: any;

	public documentFileExists: boolean = false;
	public documentFileLoading: boolean;

	public isExpert: boolean = false;
	public isClient: boolean = false;
	public isPayrollDoc: boolean = false;

	@Input() public documentIndentification: DocumentIndentificationModel;
	@Input() public documentsNavigator: NavigableDataSource<string, DocumentFileModel>;
	@Input() public expertDocumentsNavigator: NavigableDataSource<string, FinancialDocumentFileModel>;

	@Output() public close = new EventEmitter();

    constructor(public documentDisplayService: DocumentDisplayService, private authService: AuthService, private cdr: ChangeDetectorRef, 
		private messageBoxService: MessageBoxService, private toastr: ToastrService) {
		this.isClient = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
		this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert;
	}

	ngOnInit(): void {
		this.isPayrollDoc = this.documentIndentification.documentType == "payroll" ? true : false;

		this.documentFileLoading = true;

		this.documentFileSubscription = from(this.documentDisplayService.getDocument(this.documentIndentification))
			.subscribe((document) => {
				this.documentFileLoading = false;
				
				if (document) {
					this.documentFile = document;
					this.documentFileExists = true;
					this.cdr.detectChanges();
				}
			})
		
		document.body.style.overflowY = "hidden";
	}

	ngOnDestroy() {
		if (this.documentFileSubscription) { this.documentFileSubscription.unsubscribe(); this.documentFileSubscription = null; }

		// show parent scroll
		document.body.style.overflowY = "";
	}

	get showDocument(): boolean {
		return !this.documentFileLoading && this.documentFile;
    }

	get showDocumentTextNotExist(): boolean {
		return !this.documentFileLoading && !this.documentFileExists;
	}

	getRouteComponent(): string {
		return null;
	}

	initLazy(): void {
		return null;
	}

	public showConfirmationDialog(onConfirmation: Function) {
		const self = this;
		self.messageBoxService.show({
			title: 'Atenție!',
			message: 'Documentul aparține altei companii decât cea curentă. Doriți schimbarea companiei la cea a documentului?',
			defaultButton: 'yes',
			buttons: [ MessageBoxButton.yes(), MessageBoxButton.no() ],
			callback: function (result: string) {
				if (result === 'yes') {
					self.authService.user.setSelectedCompanyId(self.documentIndentification.clientEid).subscribe(() => {
						self.toastr.success("Clientul a fost schimbat cu succes.");
						onConfirmation();
					});
				}
			},
		});
	}
}