import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { RestService } from '@saliente/common';
import { dateToInt } from '@saliente/library';
import gql from 'graphql-tag';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Exact, Maybe, Scalars } from '../general/gql-codegen.types';
import * as base64 from 'byte-base64';
import { AuthService } from '../auth/auth.service';

export type Accounts_StatutoryReportTypeRepresentation = {
	code?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	isActive?: Maybe<Scalars['Boolean']>;
	isSystemManaged?: Maybe<Scalars['Boolean']>;
	actualCode?: Maybe<Scalars['String']>;
};

export type Accounts_StatutoryReportDefinitionRepresentation = {
	endDate?: Maybe<Scalars['Int']>;
	externalId?: Maybe<Scalars['String']>;
	periodicity?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['Int']>;
	statutoryReportTypeRepresentation?: Maybe<Accounts_StatutoryReportTypeRepresentation>;
	forFiscalNumber?: Maybe<Scalars['String']>;
	isActiveForSelectedMonth?: Maybe<Scalars['Boolean']>;
	isLastPeriodMonthForSelectedMonth?: Maybe<Scalars['Boolean']>;
};

export type Accounts_StatutoryReportDefinitionsRepresentation = {
	definitions?: Maybe<Array<Accounts_StatutoryReportDefinitionRepresentation>>;
	lastImportDate?: Maybe<Scalars['Int']>;
};
export type Accounts_GetStatutoryReportDefinitionUsingGetQuery = {
	accounts?: Maybe<{
		getStatutoryReportDefinitionUsingGET?: Accounts_StatutoryReportDefinitionsRepresentation;
	}>;
};
const statutoryReportsQueryText = gql`
	query statutoryReports($clientEid: String!, $date: Int!) {
		accounts {
			getStatutoryReportDefinitionUsingGET(clientEid: $clientEid, forMonth: $date) {
				definitions {
					endDate
					externalId
					periodicity
					startDate
					statutoryReportTypeRepresentation {
						code
						actualCode
						description
					}
					isActiveForSelectedMonth
				}
				lastImportDate
			}
		}
	}
`;
/*--------------------------upload definition-------------------------------*/
export type Accounts_PdfDefinitionInput = {
	fileName?: Maybe<Scalars['String']>;
	forDate?: Maybe<Scalars['Int']>;
	contentBase64?: Maybe<Scalars['String']>;
  fiscalNumber?: Maybe<Scalars['String']>;
};
export type Accounts_UploadPdfDefinitionUsingPatchMutationVariables = Exact<{
	definition?: Maybe<Accounts_PdfDefinitionInput>;
}>;

export type Accounts_SuccessResponse = {
	Message?: Maybe<Scalars['String']>;
};
export type Accounts_UploadPdfDefinitionUsingPatchMutation = {
	accounts?: Maybe<{
		uploadPDFDefinitionUsingPATCH?: Accounts_SuccessResponse;
	}>;
};
export const Accounts_UploadPdfDefinitionUsingPatchDocument = gql`
	mutation accounts_uploadPDFDefinitionUsingPATCH($definition: accounts_PDFDefinitionInput) {
		accounts {
			uploadPDFDefinitionUsingPATCH(definition: $definition) {
				Message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root',
})
export class StatutoryReportsServices {
	constructor(private authService: AuthService,private restService: RestService) {}

	public getStatutoryReportDefinition(clientEid: string, date: Date): Observable<Accounts_GetStatutoryReportDefinitionUsingGetQuery> {
		return this.restService
			.query({
				query: statutoryReportsQueryText,
				variables: {
					clientEid: clientEid,
					date: dateToInt(date),
				},
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((res: ApolloQueryResult<Accounts_GetStatutoryReportDefinitionUsingGetQuery>) => {
					if (res) {
						return res.data;
					}
					return null;
				})
			);
	}
	uploadDefinition(file: File): Observable<Accounts_UploadPdfDefinitionUsingPatchMutation> {
		return from(file.arrayBuffer()).pipe(
			switchMap((blob: ArrayBuffer) => {
        const fiscalNumber = this.authService.user.selectedCompany.fiscalNumber;

				const variables: Accounts_UploadPdfDefinitionUsingPatchMutationVariables = {
					definition: {
						fileName: file.name,
						forDate: dateToInt(new Date(file.lastModified)),
						contentBase64: base64.bytesToBase64(new Uint8Array(blob)),
            fiscalNumber: fiscalNumber
					},
				};
				return this.restService
					.mutate({
						mutation: Accounts_UploadPdfDefinitionUsingPatchDocument,
						variables: variables,
					})
					.pipe(
						map((res: ApolloQueryResult<Accounts_UploadPdfDefinitionUsingPatchMutation>) => {
							if (res) {
								return res.data;
							}
							return null;
						})
					);
			})
		);
	}
}
