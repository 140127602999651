import { OnInit, Injector, EventEmitter, Output, Directive } from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';
import { validationMessages } from '../validation/validation-messages';
import { Observable } from 'rxjs';

import { coerceBooleanProperty } from '../../utils';

@Directive()
export class Control<T> implements OnInit, ControlValueAccessor {
	protected model: NgModel;

	@Output() public valueChanged = new EventEmitter<any>();

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	protected _onTouchedCallback = new Array<() => void>();
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	protected _onChangeCallback = new Array<(value: T) => void>();

	private _value: T;
	get value(): T {
		return this._value;
	}

	set value(value: T) {
		if (this._value !== value) {
			const oldValue = this._value;
			this._value = value;
			this._onChangeCallback.forEach((f) => f(value));
			this.valueChanged.emit({ oldValue, value: this._value });
		}
	}

	get touched() {
		return this.model && this.model.touched;
	}

	get dirty() {
		return this.model && this.model.dirty;
	}

	get formSubmitted() {
		return this.model && this.model.formDirective && this.model.formDirective.submitted;
	}

	/**
	 * Constructor
	 */
	public constructor(private injector: Injector, ) { }

	public ngOnInit() {
		try {
			this.model = this.injector.get(NgModel);
		} catch {
			this.model = null;
		}
	}

	public touch() {
		this._onTouchedCallback.forEach((f) => f());
	}

	public coerceBooleanProperty = coerceBooleanProperty;

	public coerceIntegerProperty(value: any) {
		return parseInt(value, 10);
	}

	public coerceNumericProperty(value: any) {
		value = parseFloat(value).toFixed(4);
		return value * 1;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	public writeValue(value: T) {
		this._value = value;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	public registerOnChange(fn: (value: T) => void) {
		this._onChangeCallback.push(fn);
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	public registerOnTouched(fn: () => void) {
		this._onTouchedCallback.push(fn);
	}

	public get invalid(): boolean {
		return this.model && this.model.invalid;
	}

	public get showFailures() {
		return (this.touched && this.invalid) || this.formSubmitted;
	}

	public get failures(): string[] {
		if (this.model) {
			return validationMessages(this.model);
		}
		return null;
	}
}
