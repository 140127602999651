import { Component, ContentChild, EventEmitter, Input, Output, OnDestroy, Renderer2, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
	selector: 'grid-context-menu',
	templateUrl: 'grid-context-menu.component.html',
	styleUrls: ['grid-context-menu.component.scss']
})
export class KeeGridContextMenuComponent implements OnDestroy {

	@ContentChild(TemplateRef)
	public menuItemTemplate: TemplateRef<any>;

	@Input()
	public menuItems: any[] = [];

	@Output()
	public select: EventEmitter<any> = new EventEmitter<any>();

	@Input() public set for(grid: GridComponent) {
		this.unsubscribe();
		this.cellClickSubscription = grid.cellClick.subscribe(this.onCellClick);
	}

	@Input()
	enabled: boolean = true;

	public show: boolean;
	public dataItem: any;
	public offset: any;

	private cellClickSubscription: Subscription;
	private documentClickSubscription: any;

	constructor(private renderer: Renderer2) {
		this.onCellClick = this.onCellClick.bind(this);
		this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
			this.show = false;
		});
	}

	public ngOnDestroy(): void {
		this.unsubscribe();
		this.documentClickSubscription();
	}

	public menuItemSelected(item: any): void {
		this.select.emit({ item: item, dataItem: this.dataItem });
	}

	private onCellClick({ dataItem, type, originalEvent }: { dataItem: any, type: any, originalEvent: any }): void {
		if (type === 'contextmenu' && this.enabled) {
			originalEvent.preventDefault();
			this.dataItem = dataItem;
			this.show = true;
			this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
		}
	}

	public displayText(dataItem: any) {
		return typeof dataItem === 'string' ? dataItem : dataItem.text;
	}

	private unsubscribe(): void {
		if (this.cellClickSubscription) {
			this.cellClickSubscription.unsubscribe();
			this.cellClickSubscription = null;
		}
	}

}
