import { Expose, Type, classToClass, Transform } from 'class-transformer';
import { dateToInt } from '@saliente/library';


export class ChatTypesProvider {
	static date() {
		return Date;
	}
}

export class ChatTypeModel {
	public externalId: string;
	public code: string;
	public name: string;

	static typeProvider() {
		return ChatTypeModel;
	}
}

export class ChatMessageLinkModel {
	public displayName: string;
	public url: string;

	static typeProvider() {
		return ChatMessageLinkModel;
	}
}

export class ChatMessageAttachedDocumentModel {
	public documentEid: string;
	public documentFileName: string;

	static typeProvider() {
		return ChatMessageAttachedDocumentModel;
	}
}

export class ChatMessageModel {

	public messageId: number;
	public content: string;
	public authorEid: string;
	public authorName: string;
	public authorIsExpert: boolean;
	public authorIsKeez: boolean;

	@Type(ChatTypesProvider.date)
	public datePosted: Date;

	private _datePostedInt: number;
	public get datePostedInt() {
		if (!this._datePostedInt) {
			this._datePostedInt = dateToInt(this.datePosted);
		}
		return this._datePostedInt;
	}

	@Type(ChatMessageLinkModel.typeProvider)
	public links: ChatMessageLinkModel[] = [];

	@Type(ChatMessageLinkModel.typeProvider)
	public attachedDocuments: ChatMessageAttachedDocumentModel[] = [];

	static typeProvider() {
		return ChatMessageModel;
	}
}

export class ChatMessageChunkModel {
	@Expose({ name: 'eid' })
	public chunkEid: string;
	public prevChunkEid: string;
	public nextChunkEid: string;

	public chatTypeEid: string;
	public clientEid: string;
	public clientName: string;

	public chunkLength: number;
	public firstMessageId: number;
	public lastMessageId: number;
	public displayMessageId: number;

	@Type(ChatMessageModel.typeProvider)
	public messages: ChatMessageModel[] = [];

	static typeProvider() {
		return ChatMessageChunkModel;
	}
}

export class ChatModel {
	@Expose({ name: 'chatTypeExternalId' })
	public chatTypeEid: string;
	public chatTypeCode: string;
	public chatTypeName: string;

	@Transform(value => value || 0, { toClassOnly: true })
	public chatStatus: number;

	@Expose({ name: 'clientExternalId' })
	public clientEid: string;
	public clientName: string;

	public clientInSpecialCare: boolean;
	
	@Type(ChatTypesProvider.date)
	public firstUnseenMessage: Date;

	@Type(ChatTypesProvider.date)
	public firstUnansweredMessage: Date;

	@Type(ChatTypesProvider.date)
	public lastMessageDelivered: Date;
	
	@Transform(value => value || 0, { toClassOnly: true })
	public unseenMessages: number;

	@Transform(value => value || 0, { toClassOnly: true })
	public unansweredMessages: number;

	public lastMessageDeliveredTime() {
		if (this.lastMessageDelivered) {
			return this.lastMessageDelivered.getTime();
		}
		return 0;
	}

	// CM 20211008
	public setAnswered() {
		this.chatStatus = 0;
		this.unansweredMessages = 0;
		this.unseenMessages = 0;
	}

	public setUrgent() {
		this.chatStatus = 3;
	}

	public keepImportant() {
		this.chatStatus = 2;
	}

	static typeProvider() {
		return ChatModel;
	}
}

export class ClientChatInputModel {
    public chatExternalId: string;
	public clientEid: string;
}

export class ClientChatCommentInputModel {
    public chatExternalId: string;
	public clientEid: string;
	public comment: string;
}

export class ChatEditCommentModel {
	public chat: ChatModel;
	public comment: string;

	public constructor(init?:Partial<ChatEditCommentModel>) {
        Object.assign(this, init);
    }
}
