import { Expose, Type, Exclude } from 'class-transformer';

export enum BalanceSheetState {
	AtExpert = 'ExpertWorkInProgress',
	AtClient = 'ClientWorkInProgress',
	Ready = 'ReadyForUpload',
	FinalizedForANAF = 'FinalizedForANAF',
	FinalizedManuallyForANAF = 'FinalizedManuallyForANAF',
	FinalizedNonANAF = 'FinalizedNonANAF',
	Canceled = 'Canceled',
}
export type SuccessResponse = {
  Message?: string;
};

export class BalanceSheetDefinitionTemplateModel {
	public documentEid: string;
	public name: string;
	public type: string;

	static typeProvider(): typeof BalanceSheetDefinitionTemplateModel {
		return BalanceSheetDefinitionTemplateModel;
	}
}

export class BalanceSheetDefinitionModel {
	public externalId: string;
	public clientType: string;
	public description: string;
	public forMonth: number;
	public isActive: boolean;
	public periodicity: number;

	@Type(BalanceSheetDefinitionTemplateModel.typeProvider)
	public templates: BalanceSheetDefinitionTemplateModel[] = [];

	static typeProvider(): typeof BalanceSheetDefinitionModel {
		return BalanceSheetDefinitionModel;
	}
}

export class BalanceSheetInstanceListModel {
	public externalId: string;
	public clientEid: string;
	public forMonth: number;
	public isWorkInProgress: boolean;
	public momentClientToExpert: Date;
	public momentExpertToClient: Date;
	public momentVerified: Date;
	public status: string;
	public statusDescription: string;

	@Type(BalanceSheetDefinitionModel.typeProvider)
	@Expose({ name: 'balanceSheetDefinition' })
	public definition: BalanceSheetDefinitionModel;

	static typeProvider(): typeof BalanceSheetInstanceListModel {
		return BalanceSheetInstanceListModel;
	}
}
export class BalanceSheetAdditionalDocumentModel {
	public documentEid: string;
	public externalId: string;
	public fileName: string;
	public waitingForUpload = false;

	@Exclude()
	public fileType = 'Document';

	public isDummy = false;

	static typeProvider(): typeof BalanceSheetAdditionalDocumentModel {
		return BalanceSheetAdditionalDocumentModel;
	}
}

export class BalanceSheetInstanceModel {
	public externalId: string;
	public clientEid: string;
	public forMonth: number;
	public isWorkInProgress: boolean;
	public momentClientToExpert: Date;
	public momentExpertToClient: Date;
	public momentVerified: Date;

	public clientFullDocumentEid: string;
	public clientFullDocumentProcessedEid: string;
	public clientFullDocumentFileName: string;
	public clientFullDocumentWaitingForUpload = false;

	public pdfanafDocumentEid: string;
	public pdfanafFileName: string;
	public pdfanafDocumentWaitingForUpload = false;
  	public paymentGenerated: boolean;

	public status: string;
	public statusDescription: string;

  	/* some potentially dangerous actions must be narked as active*/
	public activateSpecialOperations: boolean;

	/* balance sheet can be downloaded if ReadyForUpload status && (clientFullDocumentProcessedEid !==null || periodicity===6)*/
	public canDownloadBalanceSheet: boolean;

	@Type(BalanceSheetDefinitionModel.typeProvider)
	@Expose({ name: 'balanceSheetDefinition' })
	public definition: BalanceSheetDefinitionModel;

	@Type(BalanceSheetAdditionalDocumentModel.typeProvider)
	public additionalDocuments: BalanceSheetAdditionalDocumentModel[] = [];

	static typeProvider(): typeof BalanceSheetInstanceModel {
		return BalanceSheetInstanceModel;
	}
}
