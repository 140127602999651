import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'document-status-change',
	templateUrl: 'document-status-change.component.html'
})
export class DocumentStatusChangeComponent implements OnInit {
	@Input() public model: any;
	@Input() public commentIsRequired: boolean = true;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	constructor() { }

	public ngOnInit() {
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}
}
