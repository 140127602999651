import { Component, Input } from '@angular/core';

import { LocalizationService } from '@progress/kendo-angular-l10n';
import { ColumnComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { DropdownFilterComponent } from '../dropdown-filter.component';

@Component({
	selector: 'kendo-grid-dropdown-filter-cell',
	templateUrl: 'dropdown-filter-cell.component.html'
})
export class DropdownFilterCellComponent extends DropdownFilterComponent {

	@Input() public showOperators: boolean;

	@Input() public data: any;
	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public valuePrimitive: boolean;

	constructor(filterService: FilterService, localization: LocalizationService) {
		super(filterService, localization);
		this.showOperators = true;
	}

}