import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxStarsModule } from 'ngx-stars';
import { take } from 'rxjs/operators';
import { UserFeedbackModel, UserRatingModel } from './rating.models';
import { RatingService as RatingService } from './rating.service';

@Component({
	selector: 'rating-dialog',
	templateUrl: 'rating.component.html'
})
export class RatingDialogComponent implements OnInit {
	@Input() public showFeedback: boolean = false;
	@Input() public source: string;
	@Output() public onRatingSave = new EventEmitter();

	public feedbackText: string;
	public rating: number;
	public isSendRatingButtonDisabled: boolean = true;

	constructor(private ratingService: RatingService) { }

	public ngOnInit() { }

    public onRatingSet(rating: number): void {
		this.rating = rating;
		this.isSendRatingButtonDisabled = false;
	}

	public sendRating() {
		let ratingData = new UserRatingModel();
		ratingData.rating = this.rating;
		ratingData.source = this.source;
		ratingData.comment = null;

		this.ratingService.saveRating(ratingData)
			.pipe(take(1))
			.subscribe((response: boolean) => {
				if (response) {
					// when the task was to send rating at the time of selection
					this.onRatingSave.emit(true);
					// when the task was to send feedback
					// if (rating == 5) {
					// 	this.showFeedback = false;
					// 	this.onRatingSave.emit(true);
					// }
					// else {
					// 	this.showFeedback = true;
					// }
				}
			});
	}

	// when the task was to send feedback
	public saveFeedback(): void {
		let feedBackData = new UserFeedbackModel();
		feedBackData.feedback = this.feedbackText;
		feedBackData.source = "web";
		feedBackData.type = "bug";

		this.ratingService.saveFeedback(feedBackData)
			.pipe(take(1))
			.subscribe((response: boolean) => {
				if (response) {
						this.onRatingSave.emit(true);
					}
					else {
						this.showFeedback = true;
					}
			});
	}
}
