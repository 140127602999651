import { Injectable } from '@angular/core';

import { Observable, Observer, of, } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { classToPlain } from 'class-transformer';

import { ConfigService, DownloadService, RestService, } from '@saliente/common';
import { AuthService, } from '@saliente/common-auth';

import {
	FinancialDocumentFileCommentModel, FinancialDocumentFileCommentMomentModel,
	DocumentStatusChangeModel, DocumentStatusChangeToNotUsableModel,
} from '@saliente/common-documents';



const notUsableDocumentMutationText = gql`
mutation notUsableDocument($booking: subs_DocumentNotUsableDataInput!){
	subs{
	  SetNotUsingPOST(booking:$booking){
		Message # empty
	  }
	}
}
`;

const assignToMeMutationText = gql`
mutation assignToMe($data: subs_DocumentSendToExpertInput!){
  subs {
    SetToExpertUsingPOST(statusChange:$data) {
      Message # empty
    }
  }
}`;

const reprocessDocumentMutationText = gql`
mutation reprocessDocument($data: subs_ReprocessDocumentRepresentationInput!){
  subs {
    reprocessDocumentUsingPOST(data:$data) {
      Message # empty
    }
  }
}`;

const requeueDocumentMutationText = gql`
mutation requeueDocument($commentData: documents_MarkReceivedDataInput!){
	documents{
	  MarkReceiveUsingPOST(markData: $commentData){
		Message
	  }
	}
}
`;

const addDocumentCommentMutationText = gql`
mutation addDocumentComment($commentData: subs_FinDocStatusChangeDataInput){
  subs {
    AddCommentUsingPOST(statusChange: $commentData) {
      Message # empty
    }
  }
}`;

const moveToPayrollMutationText = gql`
mutation moveToPayroll($documentEid: String!){
	subs{
		changeToPayrollDocumentUsingPOST(documentExternalId: $documentEid){
			Message # empty
		}
	}
}`;

const syncAccNoteMutationText = gql`
mutation syncAccNote($documentEid: String!){
	subs{
		refreshFinDataUsingPOST(documentExternalId: $documentEid){
			Message # empty
		}
	}
}`;


@Injectable()
export class ExpertDocumentsLiteService {

	constructor(private configService: ConfigService, private restService: RestService,
		private downloadService: DownloadService, private authService: AuthService,) {

	}

	protected onRestError(error: any) {

	}


	public blur(statusChange: DocumentStatusChangeToNotUsableModel) {
		return this.restService
			.mutate({
				mutation: notUsableDocumentMutationText,
				variables: {
					booking: classToPlain(statusChange, { excludePrefixes: ["__"] })
				}
			});
	}

	public assignToMeStream(documentEid: string): Observable<any> {
		return this.restService
			.mutate({
				mutation: assignToMeMutationText,
				variables: {
					data: { documentEid }
				}
			});
	}

	public reprocessDocumentStream(documentEid: string): Observable<any> {
		return this.restService
			.mutate({
				mutation: reprocessDocumentMutationText,
				variables: {
					data: { documentEid }
				}
			});
	}

	public addDocumentCommentStream(documentEid: string, commentText: string): Observable<any> {
		return this.restService
			.mutate({
				mutation: addDocumentCommentMutationText,
				variables: {
					docEid: documentEid,
					commentData: {
						documentEid,
						comment: commentText
					}
				}
			})
			.pipe(
				map((response) => {
					const saved = !!response;
					if (saved) {
						const commentModel = new FinancialDocumentFileCommentModel();
						commentModel.comment = commentText;
						commentModel.moment = new FinancialDocumentFileCommentMomentModel();
						commentModel.moment.dateTime = new Date();
						commentModel.moment.userName = this.authService.userName;
						return commentModel;
					}
					return saved;
				})
			);
	}

	public requeueDocumentStream(statusChange: DocumentStatusChangeModel) {
		return this.restService
			.mutate({
				mutation: requeueDocumentMutationText,
				variables: {
					commentData: classToPlain(statusChange, { excludePrefixes: ["__"] })
				}
			}, { error: this.onRestError.bind(this) });
	}


	public moveToPayroll(documentEid: string) {
		return this.restService
			.mutate({
				mutation: moveToPayrollMutationText,
				variables: {
					documentEid: documentEid
				}
			}, { error: this.onRestError.bind(this) });
	}


	public syncAccNote(documentEid: string) {
		return this.restService
			.mutate({
				mutation: syncAccNoteMutationText,
				variables: {
					documentEid: documentEid
				}
			}, { error: this.onRestError.bind(this) });
	}

	public downloadAccMetadata(documentEid: string) {
		return this.configService
			.getSubsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/findocs/${documentEid}/findata/download?attachment=true`;
					return this.downloadService.download(url);
				})
			);
	}

}
