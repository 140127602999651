
import { Type, Exclude } from 'class-transformer';

export function notificationDateTypeProvider() {
	return Date;
}

export class NotificationModel {
	public externalId: string;
	public subject: string;
	public message: string;
	public status: String = '';

	@Type(notificationDateTypeProvider)
	public created: Date;

	@Exclude()
	public createdText: String;

	public static typeProvider() {
		return NotificationModel;
	}
}