import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AUTH_ROUTES } from './auth.routes.constants';

const routes: Routes = [
	{
		path: AUTH_ROUTES.USER_REGISTRATION_1,
		loadChildren: () => import('./user-registration/user-registration.module').then(m => m.UserRegistrationModule)
	},
	{
		path: AUTH_ROUTES.USER_PROFILE_1,
		loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
	},
	{
		path: AUTH_ROUTES.CLIENT_REGISTRATION_1,
		loadChildren: () => import('./client-registration-diy/client-registration.module').then(m => m.ClientRegistrationDiyModule)
	},
	{
		path: AUTH_ROUTES.ACTIVATE_ACCOUNT_1,
		loadChildren: () => import('./activate-account/activate-account.module').then(m => m.ActivateAccountModule)
	},
	{
		path: AUTH_ROUTES.ENROLLMENT_OFFER,
		loadChildren: () => import('./enrollment-offer/enrollment-offer.module').then(m => m.EnrollmentOfferModule)
	},

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
	exports: [RouterModule]
})
export class AuthRouterModule { }
