import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { CaenCodeModel, DuActivitatiIndependenteModel, SistemImpozitareActivitatiIndependente } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";

@Component({
	selector: 'activitate-independenta',
	templateUrl: 'activitate-independenta.component.html',
    styleUrls: ['activitate-independenta.component.scss']
})
export class ActivitateIndependentaComponent implements OnInit, OnDestroy {
    @Input() public index: number;
    @Input() public model: DuActivitatiIndependenteModel;
    @Input() public type: number; // 0 - realizat, 1 - estimat
    @Input() public year: number;
    @Input() public isReadOnly: boolean;
    @Input() public caenCodes: CaenCodeModel[];
    @Input() public isFormsTouched: boolean;

    @Output() public delete = new EventEmitter();

    // view child
	@ViewChild('activitateIndependentaForm') public activitateIndependentaForm: NgForm;

    //subscription
    private isFormTouchedSubscription: Subscription;

    //model
    public sistemImpozitareList: any[] = [
        { value: SistemImpozitareActivitatiIndependente.SistemReal, name: this.clientFormsService.sistemImpozitareActivitatiIndependenteDisplay(SistemImpozitareActivitatiIndependente.SistemReal) }, 
        { value: SistemImpozitareActivitatiIndependente.NormaDeVenit, name: this.clientFormsService.sistemImpozitareActivitatiIndependenteDisplay(SistemImpozitareActivitatiIndependente.NormaDeVenit) }
    ];

    /*public sistemImpozitareRealizatList: any[] = [
        { value: SistemImpozitareActivitatiIndependente.SistemReal, name: this.clientFormsService.sistemImpozitareActivitatiIndependenteDisplay(SistemImpozitareActivitatiIndependente.SistemReal) }];
    public sistemImpozitareEstimatList: any[] = [
        { value: SistemImpozitareActivitatiIndependente.SistemReal, name: this.clientFormsService.sistemImpozitareActivitatiIndependenteDisplay(SistemImpozitareActivitatiIndependente.SistemReal) }, 
        { value: SistemImpozitareActivitatiIndependente.NormaDeVenit, name: this.clientFormsService.sistemImpozitareActivitatiIndependenteDisplay(SistemImpozitareActivitatiIndependente.NormaDeVenit) }];
    */

    public caenCodesFilteredData: CaenCodeModel[] = [];
    
    constructor(private clientFormsService: ClientFormsService) {}
    
    ngOnInit(): void {
        /*if (this.type == 1) {
            this.sistemImpozitareList = this.sistemImpozitareEstimatList;
        }
        else {
            this.sistemImpozitareList = this.sistemImpozitareRealizatList;
        }*/

        this.fillCaenCodeFirstEntry();
        this.isFormTouchedSubscription = this.clientFormsService.isFormTouched.subscribe(this.onFormTouched.bind(this));
    }

    ngOnDestroy(): void {
        if (this.isFormTouchedSubscription) { this.isFormTouchedSubscription.unsubscribe(); this.isFormTouchedSubscription = null; }
    }

    private onFormTouched() {
        if (this.activitateIndependentaForm != undefined && this.activitateIndependentaForm.form != undefined) {
            this.activitateIndependentaForm.form.markAllAsTouched();
        }
    }

    public get isRealizat(): boolean {
        return this.type == 0;
    }

    public get isEstimatAndSistemReal(): boolean {
        return this.model.sistemImpozitare == undefined || (this.type == 1 && this.model.sistemImpozitare == SistemImpozitareActivitatiIndependente.SistemReal);
    }

    public get isEstimatAndNormaDeVenit(): boolean {
        return this.model.sistemImpozitare == undefined || (this.type == 1 && this.model.sistemImpozitare == SistemImpozitareActivitatiIndependente.NormaDeVenit);
    }

    public get isRealizatWithNormaDeVenitReadOnly(): boolean {
        return this.isRealizat && this.model.sistemImpozitare != undefined && this.model.sistemImpozitare == SistemImpozitareActivitatiIndependente.NormaDeVenit;
    }

    public fillCaenCodeFirstEntry() {
        if (this.model.codCaen != undefined) {
            let caen = new CaenCodeModel();
            caen.code = this.model.codCaen;
            caen.description = this.model.denCaen;
            this.caenCodesFilteredData.push(caen);
        }
    }

    public onSistemImpozitareChange(selectedCaenCode: any) {
        if (selectedCaenCode.value == SistemImpozitareActivitatiIndependente.SistemReal) {
            this.model.normaDeVenit = null;
        }
        else if (selectedCaenCode.value == SistemImpozitareActivitatiIndependente.NormaDeVenit) {
            this.model.venitBrut = null;
            this.model.cheltuieliDeductibile = null;
        }
    }

    public onCaenCodesFocus() {
		this.caenCodesFilteredData = this.caenCodes.slice();
    }
    
    public handleCaenCodesFilter(value: any) {
		if (this.caenCodes != undefined) {
			this.caenCodesFilteredData = this.caenCodes.filter((c) => c.fullDisplay.toLowerCase().includes(value.toLowerCase()));
		}
	}

    public onCaenCodeChange(selectedCaenCode: CaenCodeModel) {
        if (selectedCaenCode != undefined) {
            this.model.denCaen = selectedCaenCode.description;
        }
    }

    public getMinDate(): Date {
        return new Date(this.year, 0, 1);
    }

    public getMaxDate(): Date {
        return new Date(this.year, 11, 31);
    }

    public validateDataActivitate(value: Date) {
		if (value && (value < this.getMinDate() || value > this.getMaxDate())) {
			return `Data trebuie să fie în interiorul anului de referință: ${this.year}`;
		}

		return true;
	}

    public onNumarDocument() {
        let url = "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_numar_rezolutie.html";
		window.open(url, '_blank', 'noopener noreferrer');
    }
}