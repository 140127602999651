import { Component, OnInit } from '@angular/core';
import { ISpinner, SpinnerService } from '../../services';

@Component({
	selector: 'kee-spinner',
	templateUrl: 'spinner.component.html'
})

export class SpinnerComponent implements OnInit, ISpinner {

	public visible: boolean = false;

	constructor(private spinnerService: SpinnerService) {
		this.spinnerService.register(this);
	}

	public ngOnInit() { }

	public show() {
		this.visible = true;
	}

	public hide() {
		this.visible = false;
	}
}
