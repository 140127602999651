export class CLIENT_FORMS_ROUTES {
	static ROOT: string = '/forms';
	static ROOT_1: string = 'forms';

	static MICRO_PROFIT_FORM: string = 'micro-profit';
	static DU_PFA_FORM: string = 'du-pfa-keez';
	static CLIENT_FORMS: string = 'client-forms';
	static DU_PFA_FORMS: string = 'du-pfa-forms';
	static MP2024_FORM: string = 'mp2024';

}
