<div *ngIf="model && model.data && model.data.definitions" class="px-0 col-24">
  <div class="card col-24 px-0 mx-0" >
    <div class="col-24 px-0 pb-3 d-flex flex-column">
      <div class="px-3 summary-header d-flex">
        <span class="title pr-1" i18n="@@StatutoryReportDefinition$Title">Vector fiscal</span
        ><span>(ultimul import: {{ model.lastImportDate | kendoDate: "dd.MM.yyyy" }}); pentru a actualiza datele trageti (drag & drop) vectorul fiscal (in format PDF) peste tabelul de mai jos</span>
      </div>
      <div class="px-3 flex-grow-1 d-flex" fileDrop (filesDropped)="handleDrop($event)" [ngClass]="{'drop-target': dropzoneActive}" (filesHovered)="dropzoneState($event)">
        <kendo-grid class="col-24 px-0 transparent" style="font-size: 0.8rem" [data]="model.filteredLines" scrollable="none">
          <kendo-grid-column field="representationDisplay" title="Declarație" headerClass="px-1" class="p-1">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="{{ dataItem.data.isActiveForSelectedMonth ? 'green-text font-weight-bold' : '' }}">
                {{ dataItem.data.statutoryReportTypeRepresentation.code }} - {{ dataItem.data.statutoryReportTypeRepresentation.description }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="periodicity" title="Periodicitate" headerClass="px-1" class="p-1">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>
                {{ dataItem.data.periodicity }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="startDate" title="Data start" format="{0:dd.MM.yyyy}" [headerStyle]="{ 'text-align': 'center' }" [style]="{ 'text-align': 'center' }">
          </kendo-grid-column>
          <kendo-grid-column field="endDate" title="Data final" format="{0:dd.MM.yyyy}" [headerStyle]="{ 'text-align': 'center' }" [style]="{ 'text-align': 'center' }">
          </kendo-grid-column>
          <kendo-grid-column field="isActiveForSelectedMonth" title="Activ" [headerStyle]="{ 'text-align': 'center' }" class="p-0" [width]="75">
            <ng-template kendoGridHeaderTemplate let-column>
              <div class="d-flex justify-content-around align-items-center">
                <checkbox class="" [(ngModel)]="model.visibleShowAll" (change)="model.onVisibleShowAll()"> </checkbox> Activ
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex pl-3 pt-2">
                <checkbox class="m-auto" [disabled]="true" [(ngModel)]="dataItem.data.isActiveForSelectedMonth"> </checkbox>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
