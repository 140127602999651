import { MessageService } from '@progress/kendo-angular-l10n';
import { Injectable } from "@angular/core";

const data: any = {
	ro: {
		rtl: false,
		messages: {
			'kendo.grid.noRecords': 'Nu este nici o înregistrare disponibilă',
			'kendo.grid.filterAndLogic': 'Și',
			'kendo.grid.filterOrLogic': 'Sau',
			'kendo.grid.groupPanelEmpty': 'Trageți aici un cap de coloană pentru grupare',
			'kendo.grid.pagerFirstPage': 'Prima pagină',
			'kendo.grid.pagerLastPage': 'Ultima pagină',
			'kendo.grid.pagerPreviousPage': 'Pagina precedentă',
			'kendo.grid.pagerNextPage': 'Pagina următoare',
			'kendo.grid.pagerPage': 'Pagină',
			'kendo.grid.pagerItemsPerPage': 'înregistrări per pagină',
			'kendo.grid.pagerOf': 'din',
			'kendo.grid.pagerItems': 'înregistrări',
			'kendo.grid.filter': 'Filtrează',
			'kendo.grid.filterEqOperator': 'Egal cu',
			'kendo.grid.filterNotEqOperator': 'Diferit de',
			'kendo.grid.filterIsNullOperator': 'E nul',
			'kendo.grid.filterIsNotNullOperator': 'Nu e nul',
			'kendo.grid.filterIsEmptyOperator': 'E gol',
			'kendo.grid.filterIsNotEmptyOperator': 'Nu e gol',
			'kendo.grid.filterStartsWithOperator': 'Începe cu',
			'kendo.grid.filterContainsOperator': 'Conține',
			'kendo.grid.filterNotContainsOperator': 'Nu conține',
			'kendo.grid.filterEndsWithOperator': 'Se termină cu',
			'kendo.grid.filterGteOperator': 'Mai mare sau egal cu',
			'kendo.grid.filterGtOperator': 'Mai mare decât',
			'kendo.grid.filterLteOperator': 'Mai mic sau egal cu',
			'kendo.grid.filterLtOperator': 'Mai mic decât',
			'kendo.grid.filterIsTrue': 'Da',
			'kendo.grid.filterIsFalse': 'Nu',
			'kendo.grid.filterBooleanAll': '(Toate)',
			'kendo.grid.filterAfterOrEqualOperator': 'E după sau în',
			'kendo.grid.filterAfterOperator': 'E după',
			'kendo.grid.filterBeforeOperator': 'E înainte de',
			'kendo.grid.filterBeforeOrEqualOperator': 'E înainte de sau în',
			'kendo.grid.filterFilterButton': 'Filtrează',
			'kendo.grid.filterClearButton': 'Șterge',
		}
	}
};

@Injectable()
export class KendoMessageService extends MessageService {
	public set language(value: string) {
		const lang = data[value];
		if (lang) {
			this.localeId = value;
			this.notify(lang.rtl);
		}
	}

	public get language(): string {
		return this.localeId;
	}

	private localeId = 'ro';
	private get messages(): any {
		const lang = data[this.localeId];
		if (lang) {
			return lang.messages;
		}
	}

	public get(key: string): string {
		const result = this.messages[key];
		if (!result) {
			//console.log('Localization message key not found:' + key);
		}
		return result;
	}
}