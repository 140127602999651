import { dateToInt, intToDate } from '@saliente/library';
import { classToClass, Exclude, Type } from "class-transformer";


export class SysDictionaryStandardFields {
	public id: number;
	public code: string;
	public display: string;

	static typeProvider() {
		return SysDictionaryStandardFields;
	}
}

export class SysCasaSanatate {
	public id: number;
	public code: string;
	public display: string;

	static typeProvider() {
		return SysCasaSanatate;
	}
}

export class SysModelContract {
	public id: number;
	public code: string;
	public display: string;
	public permitePontaj: boolean;
	public utilizabilClient: boolean;
	public beneficiiTipSalariat: boolean;

	static typeProvider() {
		return SysModelContract;
	}
}

export class SuspendareModel {
	public motivSuspendareId: number;
	public deLa: number;
	@Exclude()
	private _deLaEx: Date;
	@Exclude()
	public get deLaEx(): Date {
		if (!this._deLaEx && this.deLa) {
			this._deLaEx = intToDate(this.deLa);
		}
		return this._deLaEx;
	}
	public set deLaEx(value: Date) {
		if (this._deLaEx != value) {
			this._deLaEx = value;
			this.deLa = dateToInt(value);
		}
	}

	public panaLa: number;
	@Exclude()
	private _panaLaEx: Date;
	@Exclude()
	public get panaLaEx(): Date {
		if (!this._panaLaEx && this.panaLa) {
			this._panaLaEx = intToDate(this.panaLa);
		}
		return this._panaLaEx;
	}
	public set panaLaEx(value: Date) {
		if (this._panaLaEx != value) {
			this._panaLaEx = value;
			this.panaLa = dateToInt(value);
		}
	}
	public dataRevenire: number;
	@Exclude()
	private _dataRevenireEx: Date;
	@Exclude()
	public get dataRevenireEx(): Date {
		if (!this._dataRevenireEx && this.dataRevenire) {
			this._dataRevenireEx = intToDate(this.dataRevenire);
		}
		return this._dataRevenireEx;
	}
	public set dataRevenireEx(value: Date) {
		if (this._dataRevenireEx != value) {
			this._dataRevenireEx = value;
			this.dataRevenire = dateToInt(value);
		}
	}
	public documentEid: string;
	public activ: boolean;
	public externalId: string;
	public clientEid: string;

	@Exclude({ toPlainOnly: true })
	@Type(SysDictionaryStandardFields.typeProvider)
	public motivSuspendare: SysDictionaryStandardFields;

	@Exclude()
	public isNew: boolean; // new or update existing
	@Exclude()
	public isAdded: boolean = true; //need it when main save button (for new contract) is pressed multiple times;

	public clone(): SuspendareModel {
		return classToClass(this, { ignoreDecorators: true });
	}

	static typeProvider() {
		return SuspendareModel;
	}
}

export class TerminareModel {
	public employeeEid: String
	public terminationDate: number;
	@Exclude()
	private _terminationDateEx: Date;
	@Exclude()
	public get terminationDateEx(): Date {
		if (!this._terminationDateEx && this.terminationDate) {
			this._terminationDateEx = intToDate(this.terminationDate);
		}
		return this._terminationDateEx;
	}
	public set terminationDateEx(value: Date) {
		if (this._terminationDateEx != value) {
			this._terminationDateEx = value;
			this.terminationDate = dateToInt(value);
		}
	}
	public terminationReason: number;
}
