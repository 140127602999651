import { Injectable } from '@angular/core';

import { Observable, of, } from 'rxjs';
import { map, catchError, } from 'rxjs/operators';

import { plainToClass, classToPlain } from 'class-transformer';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';

import { $, dateToInt } from '@saliente/library';
import { RestService, } from '../general/rest.service';
import { AuthService, } from '../auth/auth.service';
import { ConfigService, } from '../general/config.service';

import { Accounts_GetViesVatDataUsingGetQuery, PartnerModel } from './partners.models';


const partnerListQueryText = gql`
query partnerList($filter: String, $filterNew: String, $clientEid: String, $offset:Int, $count: Int) {
  accounts {
    getPartnersUsingGET(filter:$filter, filterNew:$filterNew, clientEid:$clientEid, offset: $offset, count: $count) {
		externalId
		partnerName
		isLegalPerson
		fiscalNumber
		registrationNumber
		region
		country_code
		country_name
		county
		city
		addressDetails
		taxAttribute
		taxCode
    }
  }
}`;

export const partnerFields = `
	addressDetails
	city
	clientExternalId
	country_code
	country_name
	county
	externalId
	fiscalNumber
	isLegalPerson
	partnerCode
	partnerName
	region
	registrationNumber
	taxAttribute
	taxCode
`;

const combinedPartnerListQueryText = gql`
query partnerList($filter: String, $filterNew: String, $clientEid: String, $offset:Int, $count: Int) {
  accounts {
    getCombinedClientPartnersUsingGET(clientExternalId:$clientEid, filterNew:$filterNew, filter:$filter, offset: $offset, count: $count) {
		clientPartners{
			${partnerFields}
		}
		restOfPartners{
			${partnerFields}
		}
    }
  }
}`;


const addOrUpdatePartnerMutationText = gql`
mutation addOrUpdatePartner($partnerEid: String!, $partner: accounts_PartnerInput!) {
  accounts {
    addOrUpdatePartnerUsingPUT(partnerEid:$partnerEid, partner: $partner) {
	    externalId
    }
  }
}`;


const companyDataQueryText = gql`
query companyData($fiscalNumber: String!, $date: Int!){
	accounts{
	  companyData: getCompanyRoDataUsingGET(fiscalNumber:$fiscalNumber, forDate:$date){
      companyName,
      comercialRegistryNo: registrationNumber,
      city,
      county,
      details: addressDetails,
      isVATPayer: vatPayer
      fiscalNo: fiscalNumber
	  }
	}
}
`;

export const Accounts_GetViesVatDataUsingGetDocument = gql`
    query accounts_getViesVatDataUsingGET($fiscalNumber: String) {
  accounts {
    getViesVatDataUsingGET(fiscalNumber: $fiscalNumber) {
      address
      country
      found
      name
      requestDate
      resultCode
      resultMessage
      validCountry
      vatNumber
    }
  }
}
    `;

@Injectable()
export class PartnersService {

  constructor(private restService: RestService, private authService: AuthService, private configService: ConfigService, private toastr: ToastrService,) { }

  public partnerDisplay(fiscalNumber: string, partnerName: string) {
    return (fiscalNumber ? fiscalNumber + ' - ' : '') + partnerName;
  }

  private mapServerPartner(partner: any) {
    return {
      eid: partner.externalId,
      name: partner.partnerName || '',
      identificationNumber: partner.fiscalNumber || '',
      registrationNumber: partner.registrationNumber,
      taxCode: partner.taxCode,
      display: this.partnerDisplay(partner.fiscalNumber, partner.partnerName),
      countryCode: partner.country_code,
      countryName: partner.country_name,
      countyName: partner.county,
      region: partner.region,
      cityName: partner.city,
      addressDetails: partner.addressDetails
    }
  }

  public partnerListStream(params: { filter?: string, filterNew?: string, clientEid?: string, offset?: Number, count?: Number }, options?: any): Observable<any> {
    return this.restService
      .query({
        query: partnerListQueryText,
        fetchPolicy: 'network-only',
        variables: params
      }, options)
      .pipe(
        map((response: any) => {
          if (response) {
            return response.data.accounts.getPartnersUsingGET.map(this.mapServerPartner.bind(this));;
          }
          return [];
        })
      );
  }

  public combinedPartnerListStream(params: { filter?: string, filterNew?: string, clientEid?: string, offset?: Number, count?: Number }, options?: any): Observable<any> {
    const self = this;
    return self.restService
      .query({
        query: combinedPartnerListQueryText,
        fetchPolicy: 'network-only',
        variables: params
      }, options)
      .pipe(
        map((response: any) => {
          if (response) {
            response = response.data.accounts.getCombinedClientPartnersUsingGET;
            const mapServerPartner = (group: string, isLocal: boolean) => {
              return (p: any) => {
                const result: any = self.mapServerPartner(p);
                result.group = group;
                result.isLocal = isLocal;
                return result
              }
            };
            const clientPartners = (response.clientPartners || []).map(mapServerPartner('cu tranzacții', true)),
              restOfPartners = (response.restOfPartners || []).map(mapServerPartner('fără tranzacții', false));
            return clientPartners.concat(restOfPartners);
          }
          return [];
        })
      );
  }

  public save(model: PartnerModel) {
    if (model.isLegalPerson) {
      model.clientExternalId = null;
    } else {
      model.clientExternalId = this.authService.user.selectedCompanyId;
      model.countryCode = model.countryCode || this.configService.localCountryCode;
    }
    return this.restService
      .mutate({
        mutation: addOrUpdatePartnerMutationText,
        variables: {
          partnerEid: model.id,
          partner: classToPlain(model, { excludePrefixes: ["__"] })
        }
      })
      .pipe(
        map((res: any) => {
          if (res) {
            model.id = res.data.accounts.addOrUpdatePartnerUsingPUT.externalId;
            return true;
          }
          return false;
        })
      );
  }

  public companyDataServiceIsDown: boolean = false;
  public companyRegServiceIsDown: boolean = false;
  public companyDataStream(fiscalNumber: string) {
    this.companyRegServiceIsDown = false;
    return this.restService
      .query({
        query: companyDataQueryText,
        variables: {
          fiscalNumber,
          date: dateToInt(new Date())
        }
      }, { error: false })
      .pipe(
        map((res: any) => {
          if (res) {
            const companyData = res.data.accounts.companyData;
            this.companyRegServiceIsDown = !!companyData.companyName && !companyData.comercialRegistryNo;
            return companyData;
          }
          return false;
        }),
        catchError((error) => {
          var errorCodes = this.restService.getErrorsCodes(error);
          if (errorCodes.indexOf('ERROR_ANAF_NOT_WORKING') !== -1 || errorCodes.indexOf('ERROR_ANAF_NON_SUCCESS_MESSAGE') !== -1) {
            this.companyDataServiceIsDown = true;
            this.toastr.error('Serviciul de interogare date firme al ANAF nu funcționează pentru moment. Te rog să introduci datele manual');
          } else {
            this.restService.handleError(error);
          }

          return of(false);
        })
      );
  }


  public fetchVIESStream(fiscalNumber: string): Observable<Accounts_GetViesVatDataUsingGetQuery> {
    return this.restService
      .query(
        {
          query: Accounts_GetViesVatDataUsingGetDocument,
          variables: {
            fiscalNumber: fiscalNumber
          }
        }
      )
      .pipe(
        map((response: { data: Accounts_GetViesVatDataUsingGetQuery }) => {
          if (response) {
            return response.data;
          }
          return { accounts: { getViesVatDataUsingGET: { found: false } } };
        })
      );
  }
}