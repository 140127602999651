import { Directive, Attribute, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { coerceBooleanProperty } from '../utils';

@Directive({
	selector: '[onValidate][formControlName],[onValidate][formControl],[onValidate][ngModel]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CustomValidatorDirective), multi: true }]
})
export class CustomValidatorDirective implements Validator {
	@Input('onValidate') public onValidate: Function;

	constructor() { }

	public validate(control: AbstractControl): { [key: string]: any } {
		const controlValue = control.value;
		if (this.onValidate && Object.prototype.toString.call(this.onValidate) === '[object Function]') {
			const isValid = this.onValidate(controlValue);
			if (isValid !== true) {
				return {
					onValidate: isValid || false
				};
			}
		}
		return null;
	}
}
