import { Injectable } from '@angular/core';

import { Observable, } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../auth/auth.service';

import { $ } from '@saliente/library';

@Injectable()
export class UploadService {

	constructor(private authService: AuthService, private toastr: ToastrService,) { }

	public upload(fileUrl: string, file: File, metadata?: any) {
		const result: Observable<{ done: boolean, fileName: string, bytesTotal: number, bytesUploaded: number }> =
			new Observable((observer) => {
				if (file.size > 0) {
					const tus = require('tus-js-client');
					let fileName: string;
					const getFileName = (): string => {
						return fileName || (fileName = upload.url.split('/').pop());
					};

					const upload = new tus.Upload(file, {
						endpoint: fileUrl,
						headers: this.authService.authHeader(),
						resume: false,
						retryDelays: [0, 1000, 3000, 5000],
						chunkSize: 1 * 1024 * 1024, // 1MB
						metadata: $.extend({ fileType: file.type, fileName: file.name, agent: 'web' }, metadata),
						onError: (error: any) => {
							observer.error(error);
						},
						onProgress: (bytesUploaded: number, bytesTotal: number) => {
							observer.next({
								done: false,
								fileName: getFileName(),
								bytesTotal,
								bytesUploaded
							});
						},
						onSuccess: () => {
							observer.next({
								done: true,
								fileName: getFileName(),
								bytesTotal: upload.file.size,
								bytesUploaded: upload.file.size
							});
							observer.complete();
						}
					});

					// Start the upload
					upload.start();
				} else {
					const message = 'Fișierul nu poate fi încărcat pentru că este gol';
					this.toastr.error(message);
					observer.error();
				}
			});
		return result;
	}

	public directUpload(fileUrl: string, file: File, metadata?: any) {
		const reader = new FileReader();

		reader.onloadend = function () {
			// Since it contains the Data URI, we should remove the prefix and keep only Base64 string
			var b64 = (<string>reader.result).replace(/^data:.+;base64,/, '');
			console.log(b64); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
		};
		reader.readAsDataURL(file);
	}
}
