import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';

import { MasterviewComponent } from './masterview.component';
import { ClientHeaderComponent } from './headers/client.header.component';
import { DefaultHeaderComponent } from './headers/default.header.component';
import { ExpertHeaderComponent } from './headers/expert.header.component';
import { HrExpertHeaderComponent } from './headers/hrexpert.header.component';
import { SubcontractorHeaderComponent } from './headers/subcontractor.header.component';
import { RatingDialogComponent } from '../rating/rating.component';
import { NgxStarsModule } from 'ngx-stars';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MasterviewHeaderComponent } from './headers/masterview.header.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	//suppressScrollX: true
};

const COMPONENTS = [MasterviewComponent, MasterviewHeaderComponent, ClientHeaderComponent, DefaultHeaderComponent, ExpertHeaderComponent, HrExpertHeaderComponent, SubcontractorHeaderComponent, RatingDialogComponent];

@NgModule({
	imports: [
		CommonModule, FormsModule, RouterModule, SalienteLibrarySharedModule, NgxStarsModule, PerfectScrollbarModule
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}
	]
})
export class MasterViewModule {
	constructor() {

	}
}
