import { Component, ElementRef, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

import $ from '../../jquery.plugins';

let nextUniqueId = 0;

@Component({
	selector: 'dropdown-button',
	templateUrl: 'dropdown-button.component.html',
	styleUrls: ['dropdown-button.component.scss']
})

export class DropdownButtonComponent implements OnInit, AfterViewInit {

	@Input() public class: string = '';
	@Input() public inDuration: number = 300;
	@Input() public outDuration: number = 225;
	@Input() public constraintWidth: boolean = false; // Does not change width of dropdown to that of the activator
	@Input() public hover: boolean = false; // Activate on hover
	@Input() public gutter: number = 0; // Spacing from edge
	@Input() public belowOrigin: boolean = true; // Displays dropdown below the button
	@Input() public alignment: string = 'left';
	@Input() public container: string;


	@Input('list-class') public listClass: string = '';

	@Input() public id: string = `dropdown-button-${nextUniqueId++}`;
	get componentId() {
		return this.id + 'Inner';
	}


	@Output() public onshow = new EventEmitter<any>();
	@Output() public onhide = new EventEmitter<any>();

	constructor(private el: ElementRef) { }

	public ngOnInit() {
		this.el.nativeElement.removeAttribute('class');
	}

	public ngAfterViewInit() {
		const self = this;
		$(self.el.nativeElement).find('#' + self.componentId).dropdown({
			inDuration: self.inDuration,
			outDuration: self.outDuration,
			constrain_width: self.constraintWidth,
			hover: self.hover,
			gutter: self.gutter,
			belowOrigin: self.belowOrigin,
			alignment: self.alignment,
			container: self.container,
			onShow: () => { self.onShow() },
			onHide: () => { self.onHide() }
		});
	}

	private onShow() {
		this.onshow.emit();
	}

	private onHide() {
		this.onhide.emit();
	}

	public hide() {
		const self = this;
		$(self.el.nativeElement).find('#' + self.componentId).trigger('close');
	}
}
