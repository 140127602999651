import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { ExpertDocumentsService } from "./expert-documents.service";
import { FinancialDocumentFileModel } from "@saliente/common-documents";
import { take } from "rxjs/operators";
import { BalanceSheetInstanceListModel } from "src/app/clients/balance-sheet/expert/balance-sheet.models";

@Component({
	selector: 'wip-balance-sheets-dialog',
	templateUrl: 'wip-balance-sheets-dialog.component.html'
})
export class WipBalanceSheetsDialogComponent implements OnInit {
	@Input()
	public model: FinancialDocumentFileModel;
	@Output()
	public onBalanceSheetSelected: EventEmitter<BalanceSheetInstanceListModel> = new EventEmitter<BalanceSheetInstanceListModel>();

	constructor(private documentsService: ExpertDocumentsService) {}

	//grid
	public gridView: GridDataResult;
	
	ngOnInit() {
		this.documentsService.getBalanceSheetsClientWorkInProgress(this.model).pipe(take(1))
			.subscribe((balanceSheets) => {
				if (balanceSheets) {
					this.gridView = {
						data: balanceSheets,
						total: balanceSheets.length
					};
				}
				else {
					this.gridView = {
						data: [],
						total: 0
					};
				}
		});
	}

    public selectBalanceSheet(balanceSheet: BalanceSheetInstanceListModel) {
		this.onBalanceSheetSelected.emit(balanceSheet);
	}
}