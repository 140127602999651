import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { FinancialDocumentStatusChangeToBookedModel } from '@saliente/common-documents';

@Component({
	selector: 'expert-book-document',
	templateUrl: 'book-document.component.html'
})
export class ExpertBookDocumentComponent implements OnInit {
	@Input() public model: FinancialDocumentStatusChangeToBookedModel;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	// AM. 03.11.2021. task 5364. comment. datePicker removed
	// public get dateIsRequired(): boolean {
	// 	return !this.model.reference;
	// }

	constructor() { }

	public ngOnInit() { }

	public referenceKeyPress($event: any) {
		const pattern = /[0-9\,\ \/]/,
			event = $event.original;
		if (event.metaKey || event.ctrlKey || event.which < 33) {
			return $event.result = true;
		}
		let inputChar = String.fromCharCode(event.which);
		return $event.result = pattern.test(inputChar);
	}
}
