export class STOCK_ROUTES {
	static ROOT: string = '/stocks';
	static ROOT_1: string = 'stocks';

	static WAREHOUSES: string = 'warehouses';
	static INPUT_EVENTS: string = 'input_events';
	static OUTPUT_EVENTS: string = 'output_events';
	static INVENTORY_MANAGEMENT: string = 'inventory_management';
	static INVENTORY: string = 'inventory';
	static INVENTORY_REPORTS: string = 'inventory_reports';
	static INVENTORY_CONFIG: string = 'inventory_config';
	static INVENTORY_PERIODS: string = 'inventory_periods';
	static ITEMS: string = 'items';
}