import * as Types from '../../../../../graphql';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateMeetingMutationVariables = Types.Exact<{
  data?: Types.InputMaybe<Types.Notifications_MeetingRepresentationInput>;
}>;


export type CreateMeetingMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', createMeetingUsingPOST?: { __typename?: 'notifications_Eid', externalId?: string | null } | null } | null };

export type JoinMeetingMutationVariables = Types.Exact<{
  meetingEid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type JoinMeetingMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', joinMeetingUsingPOST?: { __typename?: 'notifications_MeetingParticipantRepresentation', tokenEid?: string | null } | null } | null };

export type GetMeetingTokenQueryVariables = Types.Exact<{
  tokenEid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetMeetingTokenQuery = { __typename?: 'Query', notifications?: { __typename?: 'notifications', getParticipantTokenUsingGET?: { __typename?: 'notifications_MeetingTokenRepresentation', externalId?: string | null, sessionName?: string | null, identity?: string | null, userEid?: string | null, userFirstName?: string | null, userLastName?: string | null, token?: string | null } | null } | null };

export type GetMeetingSlotsQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['Int']>;
  endDate?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetMeetingSlotsQuery = { __typename?: 'Query', notifications?: { __typename?: 'notifications', getMeetingSlotsUsingGET?: { __typename?: 'notifications_MeetingSlotsListRepresentation', clientExternalId?: string | null, clientName?: string | null, expertList?: Array<{ __typename?: 'notifications_MeetingSlotsExpertListRepresentation', expertUserEid?: string | null, expertUserName?: string | null, timeSlots?: Array<{ __typename?: 'notifications_MeetingTimeSlotsRepresentation', date?: number | null, fromTime?: number | null, toTime?: number | null, status?: string | null, isWeekend?: boolean | null, isLegalDay?: boolean | null }> | null }> | null } | null } | null };

export type RequestMeetingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Notifications_MeetingRequestRepresentationInput;
}>;


export type RequestMeetingMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', requestMeetingUsingPUT?: { __typename?: 'notifications_MeetingRequestViewRepresentation', meetingRequestEid?: string | null } | null } | null };

export type GetMeetingRequestQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  meetingRequestEid: Types.Scalars['String'];
}>;


export type GetMeetingRequestQuery = { __typename?: 'Query', notifications?: { __typename?: 'notifications', getMeetingRequestUsingGET?: { __typename?: 'notifications_MeetingRequestViewRepresentation', meetingRequestEid?: string | null, date?: number | null, fromTime?: number | null, comment?: string | null, chatTypeEid?: string | null, toTime?: number | null, duration?: number | null, clientEid?: string | null, clientName?: string | null, status?: string | null, reschedulesByExpert?: number | null, reschedulesByClient?: number | null, modifiedByRole?: string | null, meetingUrl?: string | null, history?: Array<{ __typename?: 'notifications_MeetingRequestHistoryRepresentation', meetingRequestEid?: string | null, clientEid?: string | null, clientName?: string | null, status?: string | null, date?: number | null, fromTime?: number | null, toTime?: number | null, duration?: number | null, comment?: string | null, chatTypeEid?: string | null, userEid?: string | null, userName?: string | null, role?: string | null, createdDate?: string | null, eventType?: string | null }> | null } | null } | null };

export type AcceptMeetingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Notifications_MeetingRequestEidRepresentationInput;
}>;


export type AcceptMeetingMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', acceptMeetingUsingPOST?: { __typename?: 'notifications_MeetingRequestViewRepresentation', meetingRequestEid?: string | null } | null } | null };

export type CancelMeetingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  meetingRequestEid: Types.Scalars['String'];
}>;


export type CancelMeetingMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', cancelMeetingUsingDELETE?: { __typename?: 'notifications_SuccessResponse', Message?: string | null } | null } | null };

export type ProposeNewMeetingScheduleMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  meetingRequestEid: Types.Scalars['String'];
  data: Types.Notifications_MeetingRequestRepresentationInput;
}>;


export type ProposeNewMeetingScheduleMutation = { __typename?: 'Mutation', notifications?: { __typename?: 'notifications_mutation', proposeNewMeetingScheduleUsingPATCH?: { __typename?: 'notifications_MeetingRequestViewRepresentation', meetingRequestEid?: string | null } | null } | null };

export type GetMainClientExpertInfoQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
}>;


export type GetMainClientExpertInfoQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', mainClientExpertUsingGET?: { __typename?: 'accounts_ExpertProfileRepresentation', person?: { __typename?: 'accounts_PersonRepresentation', externalId?: string | null, firstName?: string | null, lastName?: string | null, fullName?: string | null } | null } | null } | null };

export const CreateMeetingDocument = gql`
    mutation createMeeting($data: notifications_MeetingRepresentationInput) {
  notifications {
    createMeetingUsingPOST(data: $data) {
      externalId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMeetingGQL extends Apollo.Mutation<CreateMeetingMutation, CreateMeetingMutationVariables> {
    document = CreateMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JoinMeetingDocument = gql`
    mutation joinMeeting($meetingEid: String) {
  notifications {
    joinMeetingUsingPOST(meetingEid: $meetingEid) {
      tokenEid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JoinMeetingGQL extends Apollo.Mutation<JoinMeetingMutation, JoinMeetingMutationVariables> {
    document = JoinMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMeetingTokenDocument = gql`
    query getMeetingToken($tokenEid: String) {
  notifications {
    getParticipantTokenUsingGET(meetingTokenEid: $tokenEid) {
      externalId
      sessionName
      identity
      userEid
      userFirstName
      userLastName
      token
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMeetingTokenGQL extends Apollo.Query<GetMeetingTokenQuery, GetMeetingTokenQueryVariables> {
    document = GetMeetingTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMeetingSlotsDocument = gql`
    query getMeetingSlots($clientEid: String!, $startDate: Int, $endDate: Int) {
  notifications {
    getMeetingSlotsUsingGET(clientEid: $clientEid, startDate: $startDate, endDate: $endDate) {
      clientExternalId
      clientName
      expertList {
        expertUserEid
        expertUserName
        timeSlots {
          date
          fromTime
          toTime
          status
          isWeekend
          isLegalDay
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMeetingSlotsGQL extends Apollo.Query<GetMeetingSlotsQuery, GetMeetingSlotsQueryVariables> {
    document = GetMeetingSlotsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestMeetingDocument = gql`
    mutation requestMeeting($clientEid: String!, $data: notifications_MeetingRequestRepresentationInput!) {
  notifications {
    requestMeetingUsingPUT(clientEid: $clientEid, data: $data) {
      meetingRequestEid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestMeetingGQL extends Apollo.Mutation<RequestMeetingMutation, RequestMeetingMutationVariables> {
    document = RequestMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMeetingRequestDocument = gql`
    query getMeetingRequest($clientEid: String!, $meetingRequestEid: String!) {
  notifications {
    getMeetingRequestUsingGET(clientEid: $clientEid, meetingRequestEid: $meetingRequestEid) {
      meetingRequestEid
      date
      fromTime
      comment
      chatTypeEid
      toTime
      duration
      clientEid
      clientName
      status
      reschedulesByExpert
      reschedulesByClient
      modifiedByRole
      meetingUrl
      history {
        meetingRequestEid
        clientEid
        clientName
        status
        date
        fromTime
        toTime
        duration
        comment
        chatTypeEid
        userEid
        userName
        role
        createdDate
        eventType
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMeetingRequestGQL extends Apollo.Query<GetMeetingRequestQuery, GetMeetingRequestQueryVariables> {
    document = GetMeetingRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptMeetingDocument = gql`
    mutation acceptMeeting($clientEid: String!, $data: notifications_MeetingRequestEidRepresentationInput!) {
  notifications {
    acceptMeetingUsingPOST(clientEid: $clientEid, data: $data) {
      meetingRequestEid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptMeetingGQL extends Apollo.Mutation<AcceptMeetingMutation, AcceptMeetingMutationVariables> {
    document = AcceptMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelMeetingDocument = gql`
    mutation cancelMeeting($clientEid: String!, $meetingRequestEid: String!) {
  notifications {
    cancelMeetingUsingDELETE(clientEid: $clientEid, meetingRequestEid: $meetingRequestEid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelMeetingGQL extends Apollo.Mutation<CancelMeetingMutation, CancelMeetingMutationVariables> {
    document = CancelMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProposeNewMeetingScheduleDocument = gql`
    mutation proposeNewMeetingSchedule($clientEid: String!, $meetingRequestEid: String!, $data: notifications_MeetingRequestRepresentationInput!) {
  notifications {
    proposeNewMeetingScheduleUsingPATCH(clientEid: $clientEid, meetingRequestEid: $meetingRequestEid, data: $data) {
      meetingRequestEid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProposeNewMeetingScheduleGQL extends Apollo.Mutation<ProposeNewMeetingScheduleMutation, ProposeNewMeetingScheduleMutationVariables> {
    document = ProposeNewMeetingScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMainClientExpertInfoDocument = gql`
    query getMainClientExpertInfo($clientEid: String!) {
  accounts {
    mainClientExpertUsingGET(clientEid: $clientEid) {
      person {
        externalId
        firstName
        lastName
        fullName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMainClientExpertInfoGQL extends Apollo.Query<GetMainClientExpertInfoQuery, GetMainClientExpertInfoQueryVariables> {
    document = GetMainClientExpertInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }