import { intToDate, intYYYYMMToString, isNullOrUndefined } from '@saliente/library';
import { Accounts_GetClientFiscalInfoSummaryUsingGet } from './company-fiscal-summary.service';

export class Model {
	data: Accounts_GetClientFiscalInfoSummaryUsingGet;
	vatPayerStartDate: Date = null;
	vatPayerEndDate: Date = null;
	vatOnCollectionStartDate: Date = null;
	vatOnCollectionEndDate: Date = null;
	specialVATStartDate: Date = null;
	specialVATEndDate: Date = null;
	vatPayerPeriodicity = '';
	lastClosedMonth = '?';
	ossStartDate: Date;

	initialize(data: Accounts_GetClientFiscalInfoSummaryUsingGet): void {
		this.data = data;
		if (data.isVATPayer) {
			if (data.vatPayerStartDate != null) {
				this.vatPayerStartDate = intToDate(data.vatPayerStartDate);
				if (data.vatPayerEndDate != null) {
					this.vatPayerEndDate = intToDate(data.vatPayerEndDate);
				}
			}
			this.vatPayerPeriodicity = '?';
			if (data.hasStatutoryReportsDefinitions && data.hasActiveStatutoryReportVatDefinition) {
				switch (data.vatPeriodicity) {
					case 'Trimestriala':
						this.vatPayerPeriodicity = 'Trimestrial';
						break;
					case 'Lunara':
						this.vatPayerPeriodicity = 'Lunar';
						break;
				}
			}
		}
		if (data.isVATPayerOnCollection) {
			if (data.vatOnCollectionStartDate != null) {
				this.vatOnCollectionStartDate = intToDate(data.vatOnCollectionStartDate);
				if (data.vatOnCollectionEndDate != null) {
					this.vatOnCollectionEndDate = intToDate(data.vatOnCollectionEndDate);
				}
			}
		}
		if (data.specialVAT != null) {
			this.specialVATStartDate = intToDate(data.specialVAT.startDate);
			this.specialVATEndDate = null;
			if (!isNullOrUndefined(data.specialVAT.endDate) && data.specialVAT.endDate < 29990101) {
				this.specialVATEndDate = intToDate(data.specialVAT.endDate);
			}
		}
		if (!isNullOrUndefined(data.lastClosedMonth)) {
			this.lastClosedMonth = intYYYYMMToString(data.lastClosedMonth, 'ro-ro');
		}
		if (data.hasOSS) {
			this.ossStartDate = intToDate(data.hasOSSStartingWithDate);
		}
	}
}
