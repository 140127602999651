import { Component, OnInit, Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { GridComponent } from '@progress/kendo-angular-grid';

import { switchMap, first, } from 'rxjs/operators';

import { ConfigService, } from '../general/config.service';
import { DownloadService, } from '../general/download.service';
import { AccountingFiscalSheetModel, AccountingFiscalSheetDataModel, } from './accounting.models';

@Component({
    selector: 'accounting-fiscal-sheet',
    templateUrl: 'accounting-fiscal-sheet.component.html'
})
export class AccountingFiscalSheetComponent implements OnInit {
    @Input() public model: AccountingFiscalSheetModel;

    constructor(public intl: IntlService, private configService: ConfigService, private downloadService: DownloadService,) {
    }

    public ngOnInit() {
    }

    public downloadFiscalSheetDataXls(grid: GridComponent): void {
        grid.saveAsExcel();
    }

    public downloadFiscalSheetDataCsv(fiscalSheetData: AccountingFiscalSheetDataModel) {
        return this.configService
            .getAccountsDownloadUrl()
            .pipe(
                switchMap((url) => {
                    url = url + `/statutory-reports/fisa-rolCSV/fiscal-numbers/${fiscalSheetData.fiscalNumber}?attachment=true`;
                    return this.downloadService.download(url);
                }),
                first()
            )
            .subscribe();
    }
}