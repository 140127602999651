import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccDocumentFileModel, PayrollDocumentFileModel } from 'src/app/clients/documents/documents.models';
import { PayrollDocumentFileModel as HRDocumentFileModel } from 'src/app/hrexpert/documents/hrexpert-documents.models';
import { SubsFinancialDocumentFileModel } from 'src/app/subcontractor/documents/subs-documents.models';

// import { DocumentFileModel } from './documents.models';

@Component({
	selector: 'document-item-info-dialog',
	templateUrl: 'document-item-info-dialog.component.html'
})
export class DocumentItemInfoDialogComponent implements OnInit {
	// @Input() public model: DocumentFileModel;
	@Input() public model: AccDocumentFileModel | PayrollDocumentFileModel | HRDocumentFileModel | SubsFinancialDocumentFileModel;

	constructor(private toastr: ToastrService) { }

	public ngOnInit() { }

	copyToClipboard(item: any) {
		let textToCopy = (e : ClipboardEvent) => {
			e.clipboardData.setData('text/plain', item);
			e.preventDefault();
		};

		document.addEventListener('copy', textToCopy);
		document.execCommand('copy');
		document.removeEventListener('copy', textToCopy);
		this.toastr.success('Textul a fost copiat in clipboard.', "", {timeOut: 1500});
	  }
}