import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { ExpertDocumentItemComponent } from './expert-document-item.component';
import { ExpertBookDocumentComponent } from './book-document.component';
import { ExpertBookDocumentWithZeroComponent } from './book-document-with-zero.component';
import { CommonDocumentsSharedModule } from '@saliente/common-documents';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DocumentDisplayService } from 'src/app/common/document-display';
import { WipBalanceSheetsDialogComponent } from './wip-balance-sheets-dialog.component';

const COMPONENTS = [ExpertDocumentItemComponent, ExpertBookDocumentComponent, ExpertBookDocumentWithZeroComponent, WipBalanceSheetsDialogComponent];


@NgModule({
	imports: [
		CommonModule, FormsModule, SalienteLibrarySharedModule, CommonDocumentsSharedModule, PerfectScrollbarModule
	],
	providers: [
		DocumentDisplayService
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class ExpertDocumentsSharedModule {
}
