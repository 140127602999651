import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { SalienteLibrarySharedModule } from '@saliente/library';

import { BlurDocumentComponent } from './blur-document.component';
import { DocumentFileCommentComponent } from './document-file-comment.component';
import { DocumentItemInfoDialogComponent } from './document-item-info-dialog.component';
import { DocumentStatusChangeComponent } from './document-status-change.component';
import { DocumentChangeEmployeeDialogComponent } from './document-change-employee.component';
import { DocumentsCommomService } from './documents-common.service';
import { DocumentCommentsDialog } from './document-comments-dialog.component';

const COMPONENTS = [BlurDocumentComponent, DocumentItemInfoDialogComponent, DocumentFileCommentComponent, DocumentStatusChangeComponent,
					DocumentChangeEmployeeDialogComponent, DocumentCommentsDialog];

@NgModule({
	imports: [
		CommonModule, FormsModule, RouterModule, SalienteLibrarySharedModule,
	],
	providers: [
		DocumentsCommomService
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class CommonDocumentsSharedModule {
}