export class AUTH_ROUTES {
  	static CLIENT_REGISTRATION_1 = 'clients/registration';
	static CLIENT_REGISTRATION = '/'+AUTH_ROUTES.CLIENT_REGISTRATION_1; //'/clients/registration';
	static CLIENT_REGISTRATION_FINALIZE: string = AUTH_ROUTES.CLIENT_REGISTRATION+'/finalize';
	static ACTIVATE_ACCOUNT = '/activateaccount';
	static ACTIVATE_ACCOUNT_1 = 'activateaccount';
	static USER_PROFILE = '/profile';
	static USER_PROFILE_1 = 'profile';
	static USER_REGISTRATION = '/register';
	static USER_REGISTRATION_1 = 'register';
	static USER_REGISTRATION_COMPLETE = 'complete';
	static ENROLLMENT_OFFER = 'offer';
}
