export class COMMON_ROUTES {
	static DEMO: string = '/demo';
	static CONTACT_PAGE: string = '/contact';
	static ERROR_500: string = '/error';
	static ERROR_500_1: string = 'error';
	static ERROR_UNIX_TIME: string = '/unixtimeerror';
	static ERROR_UNIX_TIME_1: string = 'unixtimeerror';
	static MEETING: string = '/meeting';
	static MEETING_1: string = 'meeting';
}
