<form #chirieForm="ngForm" class="d-flex flex-wrap">
    <div class="col-24 px-0">
        <div class="col-24 d-flex flex-wrap"> 
            {{ index + 1 }}
            <i *ngIf="!isReadOnly" class="mdi-action-delete pink-text text-accent-2 pl-3 cursor-pointer" title="Șterge" (click)="this.delete.emit()"></i>
        </div>
        <div class="col-24 px-0 d-flex flex-wrap custom-card">
            <kee-dropdown name="sistemImpozitare" class="col-12" label="Sistem impozitare"
                [data]="sistemImpozitareList" textField="name" valueField="value" [valuePrimitive]="true"
                [(ngModel)]="model.sistemImpozitare" [disabled]="isReadOnly" required>
            </kee-dropdown>
            <inputfield name="adresa" class="col-12" label="Adresa"
                [(ngModel)]="model.adresa" [disabled]="isReadOnly" required>
            </inputfield>
            <kee-numerictextbox name="numarDocument" class="col-12 ng-invalid ng-untouched ng-pristine" 
                label="Număr document" [format]="'#'" [decimals]="0"
                [(ngModel)]="model.numarDocument" [disabled]="isReadOnly" required>
            </kee-numerictextbox>
            <kee-datepicker name="dataDocumentEx" class="col-12" label="Data document"
                [(ngModel)]="model.dataDocumentEx" [disabled]="isReadOnly" required>
            </kee-datepicker>
            <kee-datepicker name="dataInceperiiActivitatiiEx" class="col-12" label="Data începerii activitații" [min]="getMinDate()"
                [(ngModel)]="model.dataInceperiiActivitatiiEx" [disabled]="isReadOnly"
                [onValidate]="validateDataActivitate.bind(this)">
            </kee-datepicker>
            <kee-datepicker name="dataIncheieriiActivitatiiEx" class="col-12" label="Data încheierii activitații" [min]="getMinDate()"
                [(ngModel)]="model.dataIncetariiActivitatiiEx" [disabled]="isReadOnly"
                [onValidate]="validateDataActivitate.bind(this)">
            </kee-datepicker>
            <kee-numerictextbox name="venitBrut" class="col-12" 
                label="Venit brut" [format]="'n0'" [min]="0" [decimals]="0"
                [(ngModel)]="model.venitBrut" [disabled]="isReadOnly" required>
            </kee-numerictextbox>
            <kee-numerictextbox *ngIf="isSistemReal" name="cheltuieliDeductibile" class="col-12" 
                label="Cheltuieli deductibile" [format]="'n0'" [min]="0" [decimals]="0"
                [(ngModel)]="model.cheltuieliDeductibile" [disabled]="isReadOnly" required>
            </kee-numerictextbox>
        </div>
    </div>
</form>