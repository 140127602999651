import { Type, Expose, Exclude, plainToClassFromExist, classToPlain, classToClass } from 'class-transformer';

import { intToDate, dateToInt } from '@saliente/library';

import { DocumentFileStatus, } from './documents.enums';

export class DocumentsBaseTypesProvider {
	static date() {
		return Date;
	}
}

export class DocumentStatusChangeModel {
	public comment: string;
	public documentEid: string;

	constructor(documentEid?: string) {
		this.documentEid = documentEid;
	}

	public getStatusChangeToConfirmationModel() {
		const result = new DocumentStatusChangeModel();
		result.documentEid = this.documentEid;
		result.comment = this.comment;
		return result;
	}
}
export class DocumentStatusChangeToNotUsableModel extends DocumentStatusChangeModel {
	public reasonCode: string;
	@Exclude()
	public alsoSendInConnect: boolean = false;
}


export class DocumentFileChangeInfoModel {
	@Type(DocumentsBaseTypesProvider.date)
	public dateTime: Date;

	public userEid: String;
	public userName: String;

	static typeProvider() {
		return DocumentFileChangeInfoModel;
	}
}
export class DocumentFileStatusModel {
	public statusCode: string;
	@Type(DocumentsBaseTypesProvider.date)
	public dateTime: Date;

	public statusIsReceived() {
		return this.statusCode === DocumentFileStatus.Received || this.statusCode === DocumentFileStatus.QueuedForDigitization;
	}

	public statusIsDigitized() {
		return this.statusCode === DocumentFileStatus.Digitized || this.statusCode === DocumentFileStatus.QueuedForVerification;
	}

	public statusIsQueued() {
		return this.statusCode === DocumentFileStatus.QueuedForDigitization || this.statusCode === DocumentFileStatus.QueuedForVerification;
	}

	static typeProvider() {
		return DocumentFileStatusModel;
	}
}
export class DocumentPageModel {
	@Expose({ name: 'pageEid' })
	public id: string;

	public visible: boolean;

	@Type(DocumentsBaseTypesProvider.date)
	public created: Date;
	@Type(DocumentsBaseTypesProvider.date)
	public changed: Date;

	static typeProvider() {
		return DocumentPageModel;
	}
}

export class DocumentFileBaseModel {
	public id: string;

	@Expose({ name: 'documentDate' })
	public documentDateInt: number;
	@Exclude()
	protected _documentDateEx: Date;
	@Exclude()
	get documentDateEx() {
		if (!this._documentDateEx && this.documentDateInt) {
			this._documentDateEx = intToDate(this.documentDateInt);
		}
		return this._documentDateEx;
	}

	@Exclude()
	private _documentYearMonth: Number;
	@Exclude()
	get documentYearMonth() : number {
		if (!this._documentYearMonth && this.documentDateInt) {
			this._documentYearMonth = Math.floor(this.documentDateInt / 100);
		}
		return this._documentYearMonth.valueOf();
	}

	@Exclude()
	private _scanDocumentYearMonth: Number;
	@Exclude()
	get scanDocumentYearMonth() {
		if (!this._scanDocumentYearMonth && this.created && this.created.dateTime) {
			let dateTimeInt = dateToInt(new Date(this.created.dateTime));
			this._scanDocumentYearMonth = Math.floor(dateTimeInt / 100);
		}
		return this._scanDocumentYearMonth;
	}

	@Exclude()
	private _scanDocumentDateEx: Date;
	@Exclude()
	get scanDocumentDateEx() {
		if (!this._scanDocumentDateEx && this.created && this.created.dateTime) {
			this._scanDocumentDateEx = new Date(this.created.dateTime);
		}
		return this._scanDocumentDateEx;
	}

	public digitizationOutcome: string;

	@Type(DocumentFileStatusModel.typeProvider)
	public status: DocumentFileStatusModel;

	@Type(DocumentFileChangeInfoModel.typeProvider)
	public created: DocumentFileChangeInfoModel;

	@Type(DocumentPageModel.typeProvider)
	public pages: DocumentPageModel[];
	@Expose({ name: 'metadata' })
	public metadataString: string;

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'metadataEx' })
	public metadataEx: any;

	@Exclude()
	public get totalVisiblePages(): number {
		if (this.pages) {
			return this.pages.filter((page) => page.visible).length;
		}
		return this.totalPages;
	}

	public totalPages: number;

	public copy(source: DocumentFileBaseModel) {
		plainToClassFromExist(this, classToPlain(source));

		try {
			this.metadataEx = JSON.parse(this.metadataString);
		}
		catch{
			this.metadataEx = {};
		}
	}
}
