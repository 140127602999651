import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from 'rxjs';
import { EmployeeStatus } from "src/app/payroll/employees/employees.models";
import { EmployeesService } from "src/app/payroll/employees/employees.service";
import { AppMenuService } from "../appmenu/appmenu.service";
import { take } from "rxjs/operators";

@Injectable()
export class PayrollServiceCommon {
    
	private minimumWageEmployeeCount: BehaviorSubject<number>;
	private getMinimumWageInterval: any;

    constructor(public appMenuService: AppMenuService, private employeeService: EmployeesService) {
        this.minimumWageEmployeeCount = new BehaviorSubject<number>(null);
	}

	public minimumWageClearInterval() {
		if (this.getMinimumWageInterval) {
			clearInterval(this.getMinimumWageInterval);
			this.getMinimumWageInterval = null;
		}
	}

	public startMinimumWageEmployeeCount() {
		this.computeMinimumWageEmployeeCount();
		this.minimumWageClearInterval();
		this.getMinimumWageInterval = setInterval(() => {
			this.computeMinimumWageEmployeeCount();
		}, 300000);
	}

	public computeMinimumWageEmployeeCount() {
		this.employeeService.fetchAllEmployeeList().pipe(take(1)).subscribe((rez) => {
			if (rez != null) {
				this.minimumWageEmployeeCount.next(
					rez.data.filter(r => 
						!r.validat ||
						(r.validat && r.statusAngajat.display == EmployeeStatus.Active &&
						(r.modelContract.code.includes('cim') || r.modelContract.code == 'mnd') && r.hasMinimumWage)
					).length);
			}
		});
	}

    public get waitingMinimumWageEmployeeCount(): number {
		if (this.minimumWageEmployeeCount != null && this.minimumWageEmployeeCount.value != null) {
        	return this.minimumWageEmployeeCount.value;
		}
    }
}