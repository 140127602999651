import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, map, catchError, finalize } from 'rxjs/operators';
import gql from 'graphql-tag';

import { RestService } from './rest.service';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from './config.service';
import { CpvCodeModel, MeasureUnitModel, NcCodeModel } from 'src/app/clients/items/items.models';
import { plainToClass } from 'class-transformer';
import { ApolloResult } from './gql-codegen.types';

const coutriesQueryText = gql`
	query countries {
		accounts {
			getCountriesUsingGET {
				countryCode
				countryName
				region
			}
		}
	}
`;

const countiesQueryText = gql`
	query counties($countryCode: String!) {
		accounts {
			getCountiesUsingGET(countryCode: $countryCode) {
				countyCode
				countyName
			}
		}
	}
`;

const citiesQueryText = gql`
	query cities($countryCode: String!, $countyCode: String!) {
		accounts {
			GetCitiesUsingGET(countryCode: $countryCode, countyCode: $countyCode) {
				cityName
			}
		}
	}
`;

const getExchangeRatesQueryText = gql`
	query getExchangeRates($date: Int) {
		accounts {
			getExchangeRatesUsingGET(exchangeRatesdate: $date) {
				currencyCode
				exchangeRate
			}
		}
	}
`;
export const Accounts_GetExchangeRateForDateUsingGetDocument = gql`
	query accounts_getExchangeRateForDateUsingGET($currencyCode: String, $atDate: Int) {
		accounts {
			getExchangeRateForDateUsingGET(currencyCode: $currencyCode, atDate: $atDate) {
				currencyCode
				exchangeRate
				exchangeRateDate
				requestedRateDate
			}
		}
	}
`;
export type Accounts_GetExchangeRateForDateUsingGetQueryVariables = {
	currencyCode?: string;
	atDate?: number;
};
export type Accounts_CurrencyExchangeRateRepresentation = {
	currencyCode?: string;
	exchangeRate?: number;
	exchangeRateDate?: number;
	requestedRateDate?: number;
};
export type Accounts_GetExchangeRateForDateUsingGetQuery = {
	accounts?: {
		getExchangeRateForDateUsingGET?: Accounts_CurrencyExchangeRateRepresentation;
	};
};

const currenciesQueryText = gql`
	query currencies {
		accounts {
			getCurrencysUsingGET {
				currencyCode
				currencyName
			}
		}
	}
`;

const sysIncorporationTypesQueryText = gql`
	query sysIncorporationTypes {
		accounts {
			getSysIncorporationTypesUsingGET {
				code
				name
				isActive
			}
		}
	}
`;

const freshDeskUrlQueryText = gql`
	query freshDeskUrl($clientEid: String!) {
		accounts {
			freshDesk: getFreshdeskSSOUrlUsingGET(clientEid: $clientEid) {
				url
			}
		}
	}
`;

const measureUnitsQueryText = gql`
	query measureUnits {
		accounts {
			getMeasureUnitsUsingGET {
				id
				shortName
				description
				isAdvanced
				shortNameEn
				descriptionEn
				unCode
				displayOrder
			}
		}
	}
`;

const cpvCodeQueryText = gql`
	query cpvCode {
		accounts {
			getCpvCodeUsingGET {
				id
				code
				description
				codeEn
				descriptionEn
				displayOrder
			}
		}
	}
`;

const ncCodeQueryText = gql`
	query ncCode {
		accounts {
			getNcCodeUsingGET {
				id
				code
				description
				isEFactura
				codeEn
				descriptionEn
				displayOrder
			}
		}
	}
`;

const inventoryEnabled = gql`
	query inventoryConfig($clientEid: String!, $filter: String) {
		billing {
			getClientServiceFlavorsUsingGET(clientEid: $clientEid, filter: $filter) {
				data {
					clientEid
					externalId
					isEnabled
					serviceTypeCode
				}
			}
		}
	}
`;

const getIncorporationType = gql`
	query getIncorporationType($clientEid: String!) {
		accounts {
			GetClientUsingGET(clientEid: $clientEid) {
				incorporationType {
					code
				}
			}
		}
	}
`;

export const getContractualStatusQueryText = gql`
query billing_getContractualStatusUsingGET($clientEid: String) {
	billing {
	  getContractualStatusUsingGET(clientEid: $clientEid) {
		isSuspended
		clientChangeRequestProcessStatusRepresentation {
		  clientEid
		  companyName
		  cancellationProcess {
			externalId
		  }
		  dissolutionProcess {
			externalId
		  }
		  expertChangeProcess {
			externalId
		  }
		}
	  }
	}
}
`;

@Injectable()
export class CommonService {
	constructor(private authService: AuthService, private restService: RestService, private configService: ConfigService, private http: HttpClient) {}

	private $countryList: Observable<any[]>;
	public countryList(): Observable<any[]> {
		if (!this.$countryList) {
			this.$countryList = this.restService
				.query({
					query: coutriesQueryText,
				})
				.pipe(
					map((response: any) => {
						return response.data.accounts.getCountriesUsingGET;
					})
				);
		}
		return this.$countryList;
	}

	private $countyList: Observable<any[]>;
	public countyList(countryCode: string): Observable<any[]> {
		if (countryCode) {
			return this.restService
				.query({
					query: countiesQueryText,
					variables: {
						countryCode,
					},
				})
				.pipe(
					map((response: any) => {
						return response.data.accounts.getCountiesUsingGET;
					})
				);
		}
		return of([]);
	}

	public cityList(countryCode: string, countyCode: string): Observable<any[]> {
		if (countryCode && countyCode) {
			return this.restService
				.query({
					query: citiesQueryText,
					variables: {
						countryCode,
						countyCode,
					},
				})
				.pipe(
					map((response: any) => {
						return response.data.accounts.GetCitiesUsingGET;
					})
				);
		}
		return of([]);
	}

	private $currencyList: Observable<any[]>;
	public currencyList(): Observable<any[]> {
		if (!this.$currencyList) {
			this.$currencyList = this.restService
				.query({
					query: currenciesQueryText,
				})
				.pipe(
					map((response: any) => {
						if (response) {
							return response.data.accounts.getCurrencysUsingGET.map((currency: any) => {
								return {
									code: currency.currencyCode,
									name: currency.currencyName,
								};
							});
						}
						return [];
					})
				);
			//this.$currencyList = of([{ code: 'RON', name: 'Leu românesc' }, { code: 'EUR', name: 'Euro' }, { code: 'USD', name: 'Dolari americani' }]);
		}
		return this.$currencyList;
	}

	private $cardTypeList: Observable<any[]>;
	public cardTypeList(): Observable<any[]> {
		if (!this.$cardTypeList) {
			this.$cardTypeList = of(['Credit', 'Debit']);
		}
		return this.$cardTypeList;
	}

	private $cardIssuerList: Observable<any[]>;
	public cardIssuerList(): Observable<any[]> {
		if (!this.$cardIssuerList) {
			this.$cardIssuerList = of(['VISA', 'MasterCard']);
		}
		return this.$cardIssuerList;
	}

	public exchangeRatesStream(date: number, silent: boolean = false): Observable<any[]> {
		return this.restService
			.query(
				{
					query: getExchangeRatesQueryText,
					variables: {
						date,
					},
				},
				{ spinner: !silent }
			)
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.accounts.getExchangeRatesUsingGET;
					}
					return null;
				})
			);
	}
	public exchangeRateStream(currency: string, date: number, silent: boolean = false): Observable<Accounts_CurrencyExchangeRateRepresentation> {
		const variables: Accounts_GetExchangeRateForDateUsingGetQueryVariables = {
			atDate: date,
			currencyCode: currency,
		};
		return this.restService
			.query(
				{
					query: Accounts_GetExchangeRateForDateUsingGetDocument,
					variables: variables,
				},
				{ spinner: !silent }
			)
			.pipe(
				map((response: ApolloResult<Accounts_GetExchangeRateForDateUsingGetQuery>) => {
					if (response) {
						return response.data.accounts.getExchangeRateForDateUsingGET;
					}
					return null;
				})
			);
	}

	public dictionaryStream(eid: any): Observable<any[]> {
		return this.authService.getUser().pipe(
			switchMap((user) => {
				if (user) {
					let headers = new HttpHeaders();
					headers = headers.append('authorization', this.authService.authHeaderValue());
					return <Observable<any[]>>this.http.get(`${this.configService.documentsUrl}/documents/${eid}/file`, {
						headers,
					});
				}
				return of([]);
			})
		);
	}

	private $partnerTypeList: Observable<any[]>;
	public partnerTypeListStream(): Observable<any[]> {
		if (!this.$partnerTypeList) {
			this.$partnerTypeList = of([
				{ code: 'PF', name: 'Persoană fizică' },
				{ code: 'PJ', name: 'Persoană juridică' },
			]);
		}
		return this.$partnerTypeList;
	}

	private $sysIncorporationTypesStream: Observable<any[]>;
	public sysIncorporationTypesStream(): Observable<any[]> {
		if (!this.$sysIncorporationTypesStream) {
			this.$sysIncorporationTypesStream = this.restService
				.query({
					query: sysIncorporationTypesQueryText,
				})
				.pipe(
					map((response: any) => {
						if (response) {
							return response.data.accounts.getSysIncorporationTypesUsingGET;
						}
						return null;
					})
				);
		}
		return this.$sysIncorporationTypesStream;
	}

	public freshdeskUrlStream(): Observable<string> {
		return this.restService
			.query({
				query: freshDeskUrlQueryText,
				fetchPolicy: 'network-only',
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.accounts.freshDesk.url;
					}
					return null;
				})
			);
	}

	public getMeasureUnitsDictionary(): Observable<MeasureUnitModel[]> {
		return this.restService
			.query({
				query: measureUnitsQueryText,
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((res: any) => {
					let measureUnitList: MeasureUnitModel[] = null;
					try {
						measureUnitList = plainToClass<MeasureUnitModel, object>(MeasureUnitModel, res.data.accounts.getMeasureUnitsUsingGET || [], { excludePrefixes: ['__'] });
						measureUnitList.sort((a: MeasureUnitModel, b: MeasureUnitModel) => a.displayOrder - b.displayOrder);
					} catch {
						measureUnitList = null;
					}
					return measureUnitList;
				})
			);
	}

	public getCpvCodeDictionary(): Observable<CpvCodeModel[]> {
		return this.restService
			.query(
				{
					query: cpvCodeQueryText,
					fetchPolicy: 'network-only',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					let cpvCodeList: CpvCodeModel[] = null;
					try {
						cpvCodeList = plainToClass<CpvCodeModel, object>(CpvCodeModel, res.data.accounts.getCpvCodeUsingGET || [], { excludePrefixes: ['__'] });
						cpvCodeList.sort((a: CpvCodeModel, b: CpvCodeModel) => a.displayOrder - b.displayOrder);
					} catch {
						cpvCodeList = null;
					}
					return cpvCodeList;
				})
			);
	}

	public getNcCodeDictionary(): Observable<NcCodeModel[]> {
		return this.restService
			.query(
				{
					query: ncCodeQueryText,
					fetchPolicy: 'network-only',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					let ncCodeList: NcCodeModel[] = null;
					try {
						ncCodeList = plainToClass<NcCodeModel, object>(NcCodeModel, res.data.accounts.getNcCodeUsingGET || [], { excludePrefixes: ['__'] });
						ncCodeList.sort((a: NcCodeModel, b: NcCodeModel) => a.displayOrder - b.displayOrder);
					} catch {
						ncCodeList = null;
					}
					return ncCodeList;
				})
			);
	}

	public getInventoryEnabled(): Observable<boolean> {
		if (this.authService.user.selectedCompanyId == undefined) return;

		return this.restService
			.query(
				{
					query: inventoryEnabled,
					variables: {
						clientEid: this.authService.user.selectedCompanyId,
						filter: 'serviceTypeCode:INVENTORY AND isEnabled:true',
					},
					fetchPolicy: 'network-only',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					let isEnabled: boolean;
					try {
						if (res.data.billing.getClientServiceFlavorsUsingGET.data.length > 0) {
							isEnabled = res.data.billing.getClientServiceFlavorsUsingGET.data[0].isEnabled;
						} else {
							isEnabled = false;
						}
					} catch {
						isEnabled = false;
					}
					return isEnabled;
				})
			);
	}

	public getIncorporationType(): Observable<string> {
		if (this.authService.user.selectedCompanyId == undefined) return;

		return this.restService
			.query(
				{
					query: getIncorporationType,
					variables: {
						clientEid: this.authService.user.selectedCompanyId,
					},
					fetchPolicy: 'network-only',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					return res.data.accounts.GetClientUsingGET.incorporationType.code;
				})
			);
	}

	public getIsClientInCancellationOrDissolutionProcess(): Observable<boolean> {
		if (this.authService.user.selectedCompanyId == undefined) return;
		
		return this.restService
			.query(
				{
					query: getContractualStatusQueryText,
					variables: {
						clientEid: this.authService.user.selectedCompanyId,
					},
					fetchPolicy: 'network-only',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					if (res) {
						let status = res.data.billing.getContractualStatusUsingGET.clientChangeRequestProcessStatusRepresentation;
						if (status != null) {
							return (status.cancellationProcess != null && status.cancellationProcess.externalId != null) ||
								(status.dissolutionProcess  != null && status.dissolutionProcess.externalId != null)
						}
						return false;
					}
					return false;
				})
			);
	}
}
