import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChatComponent } from '@saliente/common-notifications';

import { AuthGuard } from '@saliente/common-auth';
import { CLIENTS_ROUTES } from './clients.routes.constants';
import { SubscriptionModule } from './subscription/subscription.module';

const routes: Routes = [
	{
		path: CLIENTS_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: '',
				children: [
					{
						path: '',
						data: { accessRole: 'DASHBOARD_VIEW', reportName: "Headers", section: 'dashboard', screenName: 'Imaginea afacerii tale', tabName: 'Dashboard'},
						loadChildren: () => import('./reports/financial/financial-reports.module').then(m => m.ClientsFinancialReportsModule)
					},
					{
						path: CLIENTS_ROUTES.ACCOUNTING_HOME,
						data: { accessRole: 'ACCNT_REPORTS_VIEW' },
						loadChildren: (): any => import('./reports/statutory/accounting-statutory-reports.module').then((m) => m.ClientsAccountingStatutoryReportsModule),
					},
					{
						path: CLIENTS_ROUTES.PAYROLL_HOME,
						data: { accessRole: 'PAYROLL_REPORTS_VIEW' },
						loadChildren: (): any => import('./reports/statutory/payroll-statutory-reports.module').then((m) => m.ClientsPayrollStatutoryReportsModule),
					},
				],
			},
			{
				path: CLIENTS_ROUTES.ACC_DOCUMENTS,
				data: { accessRole: 'ACCNT_DOCUMENTS_VIEW_OWN;ACCNT_DOCUMENTS_VIEW' },
				loadChildren: (): any => import('./documents/acc-documents.module').then((m) => m.ClientsAccDocumentsModule),
			},
			{
				path: CLIENTS_ROUTES.PAYROLL_DOCUMENTS,
				data: { accessRole: 'PAYROLL_DOCUMENTS_VIEW' },
				loadChildren: (): any => import('./documents/payroll-documents.module').then((m) => m.ClientsPayrollDocumentsModule),
			},
			/*{
				path: CLIENTS_ROUTES.EMPLOYEES,
				data: { accessRole: 'EMPLOYEE_VIEW' },
				loadChildren: () => import('./employees/employees/employees.module').then(m => m.ClientsEmployeesModule)
				// {
				// 	const modules: any = require('./employees/employees/employees.module');
				// 	return modules.ClientsEmployeesModule;
				// }
			},*/
			{
				path: CLIENTS_ROUTES.TIMESHEET,
				data: { accessRole: 'PAYROLL_TIMESHEET_VIEW' },
				loadChildren: (): any => import('./employees/timesheet/timesheet.module').then((m) => m.ClientsTimesheetModule),
			},
			{
				path: CLIENTS_ROUTES.SUBSCRIPTION,
				data: { accessRole: 'CONFIG_COMPANY_VIEW' },
				loadChildren: (): Promise<typeof SubscriptionModule> => import('./subscription/subscription.module').then((m) => m.SubscriptionModule),
			},
			{
				path: CLIENTS_ROUTES.COMPANY,
				data: { accessRole: 'CONFIG_COMPANY_VIEW' },
				loadChildren: (): any => import('./company/company.module').then((m) => m.ClientsCompanyModule),
			},
			{
				path: CLIENTS_ROUTES.MISC,
				children: [
					{
						path: CLIENTS_ROUTES.VEHICLES,
						loadChildren: (): any => import('./vehicle/vehicles.module').then((m) => m.ClientsVehiclesModule),
					},
					{
						path: CLIENTS_ROUTES.TEMPLATES,
						loadChildren: (): any => import('./templates/templates.module').then((m) => m.ClientTemplatesModule),
					},
				],
			},
			{
				path: CLIENTS_ROUTES.USERS,
				data: { accessRole: 'USERS_VIEW' },
				loadChildren: (): any => import('./users/users/users.module').then((m) => m.ClientsUsersModule),
			},
			{
				path: CLIENTS_ROUTES.AGENTS,
				data: { accessRole: 'USERS_VIEW' },
				loadChildren: (): any => import('./users/agents/agents.module').then((m) => m.ClientsAgentsModule),
			},
			{
				path: CLIENTS_ROUTES.CLIENTPARTNERS,
				data: { accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW' },
				loadChildren: (): any => import('./clientpartners/clientpartners.module').then((m) => m.ClientsClientPartnersModule),
			},
			{
				path: CLIENTS_ROUTES.INVOICES,
				data: { accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW' },
				loadChildren: (): any => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
			},
			{
				path: CLIENTS_ROUTES.ITEMS,
				data: { accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW' },
				loadChildren: (): any => import('./items/items.module').then((m) => m.ItemsModule),
			},
			{
				path: CLIENTS_ROUTES.E_FACTURA,
				loadChildren: (): any => import('../e-factura/e-factura.module').then((m) => m.EFacturaModule),
			},
			{
				path: CLIENTS_ROUTES.BALANCE_SHEET,
				loadChildren: (): any => import('./balance-sheet/expert/balance-sheet.module').then((m) => m.BalanceSheetsModule),
			},
			{
				path: CLIENTS_ROUTES.BALANCE_SHEET_CLIENT,
				loadChildren: (): any => import('./balance-sheet/client/balance-sheet-client.module').then((m) => m.BalanceSheetsClientModule),
			},
			{
				path: CLIENTS_ROUTES.CHAT,
				data: {
					zoneAccessRole: {
						CLIENT: 'CONNECT_KEEZ_ACCESS',
						ACCOUNTING_EXPERT: 'CONNECT_ACCOUNTING_ACCESS',
						PAYROLL_EXPERT: 'CONNECT_PAYROLL_ACCESS',
					},
					helpMenu: 'connect',
				},
				component: ChatComponent,
			},
			{
				path: CLIENTS_ROUTES.CLARIFICATION,
				loadChildren: (): any => import('./clarification/clarification.module').then((m) => m.ClarificationModule),
			},
			{
				path: CLIENTS_ROUTES.CARD_CLARIFICATION,
				loadChildren: (): any => import('./card-clarification/card-clarification.module').then((m) => m.CardClarificationModule),
			},
			{
				path: CLIENTS_ROUTES.IMPORT,
				data: { accessRole: 'IMPORT_OPS' },
				loadChildren: (): any => import('./import/import.module').then((m) => m.ImportModule),
			},
			{
				path: CLIENTS_ROUTES.ACCOUNTING_REPORTS,
				data: { accessRole: 'DASHBOARD_VIEW', reportName: "Headers", section: 'accountingReports', screenName: 'Rapoarte Contabilitate', tabName: 'Rapoarte'},
				loadChildren: () => import('./reports/financial/financial-reports.module').then(m => m.ClientsFinancialReportsModule)
			},
			{
				path: CLIENTS_ROUTES.MISSING_DOCUMENTS,
				loadChildren: (): any => import('./reports/missing-documents/missing-documents.module').then((m) => m.MissingDocumentsReportModule),
			},
			{
				path: CLIENTS_ROUTES.CLIENT_CONTRACT,
				loadChildren: (): any => import('.././client-contract/client-contract.module').then((m) => m.ClientContractModule),
			},
			{
				path: CLIENTS_ROUTES.TAKEOVER,
				data: { accessRole: 'TAKEOVER_VIEW' },
				loadChildren: (): any => import('.././takeover/takeover.module').then((m) => m.TakeoverModule),
			},
			{
				path: CLIENTS_ROUTES.COMPANY_BANK_ACCOUNTS,
				data: { accessRole: 'CONFIG_COMPANY_EDIT' },
				loadChildren: (): any => import('.././bank-accounts/bank-accounts.module').then((m) => m.BankAccountsModule),
			},
			{
				path: CLIENTS_ROUTES.COMPANY_EMPLOYEES_CARDS,
				data: { accessRole: 'CONFIG_COMPANY_EDIT' },
				loadChildren: (): any => import('.././employee-cards/employee-cards.module').then((m) => m.EmployeeCardsModule),
			},
		],
	},
];

// @dynamic
@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
	exports: [RouterModule],
})
export class ClientsRouterModule {}
