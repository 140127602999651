import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Injector, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { LabeledControl } from '../../labeledcontrol';

var moment = require('moment');

export const DATEPICKER_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeMaskedEditDatePickerComponent),
	multi: true
};

export const DATEPICKER_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	multi: true,
	useExisting: forwardRef(() => KeeMaskedEditDatePickerComponent),
}

@Component({
	selector: 'kee-maskededitdatepicker',
	templateUrl: 'maskededitdatepicker.component.html',
	providers: [DATEPICKER_VALUE_ACCESSOR, DATEPICKER_VALIDATORS],
})
export class KeeMaskedEditDatePickerComponent extends LabeledControl<Date> implements OnInit, Validator {

	//#region properties

	//#region placeholder

	@Input() public formatPlaceholder: string;

	public _placeholder: string = null;
	@Input() get placeholder(): string {
		return this._placeholder == null ? " " : this._placeholder;
	}
	set placeholder(value) {
		this._placeholder = value;
	}

	//#endregion

	//#region format

	@Input() public format: string;

	//#endregion


	// cred ca nu mai e nevoie de astea
	private _activeView: string;
	@Input() get activeView(): string {
		return this._activeView || 'month';
	}
	set activeView(value) {
		if (['month', 'year', 'decade', 'century'].indexOf(value) !== -1) {
			this._activeView = value;
		}
		this._activeView = 'month';
	}

	@Input() public bottomView: string;
	@Input() public topView: string;
	@Input() public weekNumber: boolean;

	@Input() public min: Date;
	@Input() public max: Date;

	@Input() public ngModelOptions: any = { updateOn: 'blur' };

	public dateMask = "00.00.0000";

	public constructor(injector: Injector, ) {
		super(injector);
	}

	validate(control: AbstractControl): ValidationErrors {
		if (!this._isValid()) {
			return {'invalidDate': true};
		}
		else if (!this._isLessThanMaxDate()) {
			return {'maxDate': this.max};
		}
		else if (!this._isGraterThanMinDate()) {
			return {'minDate': this.min};
		}

		return null;
	}

	public ngOnInit() {
		super.ngOnInit();
	}

	_getFormatedDate(date: Date) {
		const datePipe = new DatePipe('ro-RO');
		return datePipe.transform(date, "dd.MM.yyyy");
	}

	private _maskedEditDatePickerValue: string; 
	get maskedEditDatePickerValue(): string {
    	return this._maskedEditDatePickerValue;
    }

    set maskedEditDatePickerValue(value) {
        this._maskedEditDatePickerValue = value;
		this._convertStringValueToDate();
    }

	public writeValue(value: Date) {
		super.writeValue(value);
		this._maskedEditDatePickerValue = this._getFormatedDate(this.value);
	}

	_isValid() {
		if (this.maskedEditDatePickerValue != undefined && this.maskedEditDatePickerValue.trim().length == 8) {
			let date = moment(this.maskedEditDatePickerValue, "DDMMYYYY");
			if (date.isValid()) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	_isLessThanMaxDate() {
		if (this.max != undefined && this.maskedEditDatePickerValue != undefined && this.maskedEditDatePickerValue.trim().length == 8) {
			let date = moment(this.maskedEditDatePickerValue, "DDMMYYYY");
			if (date.isValid()) {
				var d = new Date(date.format());
				if (d <= this.max) {
					return true;
				}
				else {
					return false;
				}
			}
		}
		else {
			return true;
		}
	}

	_isGraterThanMinDate() {
		if (this.min != undefined && this.maskedEditDatePickerValue != undefined && this.maskedEditDatePickerValue.trim().length == 8) {
			let date = moment(this.maskedEditDatePickerValue, "DDMMYYYY");
			if (date.isValid()) {
				var d = new Date(date.format());
				if (d >= this.min) {
					return true;
				}
				else {
					return false;
				}
			}
		}
		else {
			return true;
		}
	}
	
	_convertStringValueToDate() {
		if (this.maskedEditDatePickerValue != undefined && this.maskedEditDatePickerValue.trim().length == 8) {
			let date = moment(this.maskedEditDatePickerValue, "DDMMYYYY");
			if (date.isValid()) {
				this.value = new Date(date.format());
			} else {
				this.value = new Date();
			}
		} else {
			this.value = null;
		}
	}

    public _handleTextChange(v: string) {
        this.maskedEditDatePickerValue = v;
        this.touch();
    }
}
