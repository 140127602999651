import * as Types from '../../../graphql';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTakeoversListQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetTakeoversListQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getTakeoversUsingGET?: { __typename?: 'accounts_TakeoverListRepresentation', recordsCount?: number | null, first?: number | null, last?: number | null, pageSize?: number | null, data?: Array<{ __typename?: 'accounts_TakeoverRepresentation', externalId?: string | null, takeoverTypeEid?: string | null, clientEid?: string | null, statusCode?: string | null, createdByUserName?: string | null, createdDate?: string | null, isFinished?: boolean | null, takeoverType?: { __typename?: 'accounts_TakeoverTypeRepresentation', externalId?: string | null, code?: string | null, name?: string | null, description?: string | null, instruction?: string | null, isVisible?: boolean | null, createdByUserName?: string | null } | null, status?: { __typename?: 'accounts_SysTakeoverStatusesRepresentation', code?: string | null, name?: string | null, nameEn?: string | null } | null, takeoverAttachments?: Array<{ __typename?: 'accounts_TakeoverAttachmentRepresentation', fileEid?: string | null, fileName?: string | null, fileSize?: number | null, displayOrder?: number | null, isVisible?: boolean | null }> | null, history?: Array<{ __typename?: 'accounts_TakeoverHistoryRepresentation', externalId?: string | null, description?: string | null, performedDateTime?: string | null, performedUserEid?: string | null, performedUserName?: string | null }> | null }> | null } | null } | null };

export type GetTakeoverQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  takeoverEid: Types.Scalars['String'];
}>;


export type GetTakeoverQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getTakeoverUsingGET?: { __typename?: 'accounts_TakeoverRepresentation', externalId?: string | null, takeoverTypeEid?: string | null, clientEid?: string | null, statusCode?: string | null, createdByUserName?: string | null, createdDate?: string | null, isFinished?: boolean | null, takeoverType?: { __typename?: 'accounts_TakeoverTypeRepresentation', externalId?: string | null, code?: string | null, name?: string | null, description?: string | null, instruction?: string | null, isVisible?: boolean | null, createdByUserName?: string | null } | null, status?: { __typename?: 'accounts_SysTakeoverStatusesRepresentation', code?: string | null, name?: string | null, nameEn?: string | null } | null, takeoverAttachments?: Array<{ __typename?: 'accounts_TakeoverAttachmentRepresentation', fileEid?: string | null, fileName?: string | null, fileSize?: number | null, displayOrder?: number | null, isVisible?: boolean | null }> | null, history?: Array<{ __typename?: 'accounts_TakeoverHistoryRepresentation', externalId?: string | null, description?: string | null, performedDateTime?: string | null, performedUserEid?: string | null, performedUserName?: string | null }> | null } | null } | null };

export type GetTakeoverDashboardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTakeoverDashboardQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getTakeoverDashboardUsingGET?: { __typename?: 'accounts_TakeoverExpertListRepresentation', recordsCount?: number | null, first?: number | null, last?: number | null, pageSize?: number | null, data?: Array<{ __typename?: 'accounts_TakeoverExpertRepresentation', clientEid?: string | null, fiscalNumber?: string | null, companyName?: string | null, accountingTakeoverStatus?: string | null, payrollTakeoverStatus?: string | null, accountingTakeoverEid?: string | null, payrollTakeoverEid?: string | null, hasTakeover?: boolean | null, accountingTakeoverFinished?: boolean | null, payrollTakeoverFinished?: boolean | null, apCode?: string | null, expertName?: string | null, lastClosedMonth?: number | null, serviceStartDate?: number | null }> | null } | null } | null };

export type SaveTakeoverMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Accounts_TakeoverRepresentationInput;
}>;


export type SaveTakeoverMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', upsertTakeoverUsingPUT?: { __typename?: 'accounts_Eid', externalId?: string | null } | null } | null };

export type OpenTakeoverMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Accounts_TakeoverOpDataRepresentationInput;
}>;


export type OpenTakeoverMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', openTakeoverUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type CloseTakeoverMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Accounts_TakeoverOpDataRepresentationInput;
}>;


export type CloseTakeoverMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', closeTakeoverUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type FinishTakeoverMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  fireEvents: Types.Scalars['Boolean'];
  data: Types.Accounts_TakeoverOpDataRepresentationInput;
}>;


export type FinishTakeoverMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', finishTakeoverUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type FinalizeTakeoverMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data: Types.Accounts_TakeoverOpDataRepresentationInput;
}>;


export type FinalizeTakeoverMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', finalizeTakeoverUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type GetTakeoverBadgeStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTakeoverBadgeStatusQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getTakeoverBadgeStatusUsingGET?: { __typename?: 'accounts_TakeoverBadgeStatusRepresentation', count?: number | null } | null } | null };

export const GetTakeoversListDocument = gql`
    query getTakeoversList($clientEid: String!, $filter: String, $order: String, $offset: Int, $count: Int) {
  accounts {
    getTakeoversUsingGET(clientEid: $clientEid, filter: $filter, order: $order, offset: $offset, count: $count) {
      recordsCount
      first
      last
      pageSize
      data {
        externalId
        takeoverTypeEid
        takeoverType {
          externalId
          code
          name
          description
          instruction
          isVisible
          createdByUserName
        }
        clientEid
        status {
          code
          name
          nameEn
        }
        statusCode
        takeoverAttachments {
          fileEid
          fileName
          fileSize
          displayOrder
          isVisible
        }
        history {
          externalId
          description
          performedDateTime
          performedUserEid
          performedUserName
        }
        createdByUserName
        createdDate
        isFinished
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTakeoversListGQL extends Apollo.Query<GetTakeoversListQuery, GetTakeoversListQueryVariables> {
    document = GetTakeoversListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTakeoverDocument = gql`
    query getTakeover($clientEid: String!, $takeoverEid: String!) {
  accounts {
    getTakeoverUsingGET(clientEid: $clientEid, takeoverEid: $takeoverEid) {
      externalId
      takeoverTypeEid
      takeoverType {
        externalId
        code
        name
        description
        instruction
        isVisible
        createdByUserName
      }
      clientEid
      status {
        code
        name
        nameEn
      }
      statusCode
      takeoverAttachments {
        fileEid
        fileName
        fileSize
        displayOrder
        isVisible
      }
      history {
        externalId
        description
        performedDateTime
        performedUserEid
        performedUserName
      }
      createdByUserName
      createdDate
      isFinished
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTakeoverGQL extends Apollo.Query<GetTakeoverQuery, GetTakeoverQueryVariables> {
    document = GetTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTakeoverDashboardDocument = gql`
    query getTakeoverDashboard {
  accounts {
    getTakeoverDashboardUsingGET {
      recordsCount
      first
      last
      pageSize
      data {
        clientEid
        fiscalNumber
        companyName
        accountingTakeoverStatus
        payrollTakeoverStatus
        accountingTakeoverEid
        payrollTakeoverEid
        hasTakeover
        accountingTakeoverFinished
        payrollTakeoverFinished
        apCode
        expertName
        lastClosedMonth
        serviceStartDate
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTakeoverDashboardGQL extends Apollo.Query<GetTakeoverDashboardQuery, GetTakeoverDashboardQueryVariables> {
    document = GetTakeoverDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveTakeoverDocument = gql`
    mutation saveTakeover($clientEid: String!, $data: accounts_TakeoverRepresentationInput!) {
  accounts {
    upsertTakeoverUsingPUT(clientEid: $clientEid, data: $data) {
      externalId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveTakeoverGQL extends Apollo.Mutation<SaveTakeoverMutation, SaveTakeoverMutationVariables> {
    document = SaveTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OpenTakeoverDocument = gql`
    mutation openTakeover($clientEid: String!, $data: accounts_TakeoverOpDataRepresentationInput!) {
  accounts {
    openTakeoverUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OpenTakeoverGQL extends Apollo.Mutation<OpenTakeoverMutation, OpenTakeoverMutationVariables> {
    document = OpenTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseTakeoverDocument = gql`
    mutation closeTakeover($clientEid: String!, $data: accounts_TakeoverOpDataRepresentationInput!) {
  accounts {
    closeTakeoverUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseTakeoverGQL extends Apollo.Mutation<CloseTakeoverMutation, CloseTakeoverMutationVariables> {
    document = CloseTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishTakeoverDocument = gql`
    mutation finishTakeover($clientEid: String!, $fireEvents: Boolean!, $data: accounts_TakeoverOpDataRepresentationInput!) {
  accounts {
    finishTakeoverUsingPOST(clientEid: $clientEid, fireEvents: $fireEvents, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishTakeoverGQL extends Apollo.Mutation<FinishTakeoverMutation, FinishTakeoverMutationVariables> {
    document = FinishTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinalizeTakeoverDocument = gql`
    mutation finalizeTakeover($clientEid: String!, $data: accounts_TakeoverOpDataRepresentationInput!) {
  accounts {
    finalizeTakeoverUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinalizeTakeoverGQL extends Apollo.Mutation<FinalizeTakeoverMutation, FinalizeTakeoverMutationVariables> {
    document = FinalizeTakeoverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTakeoverBadgeStatusDocument = gql`
    query getTakeoverBadgeStatus {
  accounts {
    getTakeoverBadgeStatusUsingGET {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTakeoverBadgeStatusGQL extends Apollo.Query<GetTakeoverBadgeStatusQuery, GetTakeoverBadgeStatusQueryVariables> {
    document = GetTakeoverBadgeStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }