import { Component, Input } from '@angular/core';

const linkify = require('linkify-it')();

@Component({
	selector: 'document-comments',
	templateUrl: 'document-comments-dialog.component.html',
})
export class DocumentCommentsDialog {
	@Input() public comments: any[];

	public parseComment(comment: string) {
		const matches = linkify.match(comment);
		if (matches && matches.length) {
			let commentHtml = comment;
			matches.reverse().forEach((match: any) => {
				commentHtml = commentHtml.substring(0, match.index) +
					`<a href="${match.url}" target="_blank">${match.text}</a>` +
					commentHtml.substring(match.lastIndex);
			});
			return commentHtml;
		}
		return comment;
	}
}