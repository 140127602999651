<section class="m-0">
	<div class="col-24 d-flex justify-content-around cursor-pointer">
		<ngx-stars [wholeStars]="true" [size]="3" [color]="'#5FD321'" [customPadding]="'1.5rem'" (ratingOutput)="onRatingSet($event)"></ngx-stars>
	</div>
	<div class="col-24 d-flex justify-content-around pt-4">
		<button [disabled]="isSendRatingButtonDisabled" class="btn width-200 waves-effect waves-light" type="submit" (click)="sendRating()">Trimite
			<i class="mdi-content-send"></i>
		</button>
	</div>
	<!-- <div *ngIf="showFeedback" class="py-2" style="display: block;">
		<p class="d-flex justify-content-around">Vreți să ne faceți o sugestie de îmbunătățire sau să raportați un bug?</p>
		<textarea rows=3 class="ratingFeedback" [(ngModel)]="feedbackText"></textarea>
		<div style="float: right;">
		<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="saveFeedback()">
			<span i18n="@@Actions$Save"> Trimite</span>
		  </button>
		</div>
	</div> -->
</section>