<div class="form-group materialize {{behaviourClasses}} {{wrapperClass}}">
	<i *ngIf="prefix" class="{{prefix}} prefix" (click)="this.prefixClick.emit($event)"></i>
	<i *ngIf="suffix" class="{{suffix}} suffix" (click)="this.suffixClick.emit($event)"></i>
	<textarea #textarea class="form-control {{controlClass}} {{autoheight ? 'autoheight' : ''}} {{customResize ? 'customResize' : ''}}" [autofocus]="autofocus"
		[disabled]="disabled" [id]="controlId" [readonly]="readonly" [placeholder]="placeholder"
		[spellcheck]="spellcheck" [attr.tabindex]="tabindex" [attr.name]="name" [attr.cols]="cols" [attr.rows]="rows" [maxlength]="maxlength"
		(focus)="_handleFocus($event)" (blur)="_handleBlur($event)" (keypress)="_handleKeyPress($event)"
		[(ngModel)]="value" (change)="_handleChange($event.target.value)" (cut)="delayedResize()"
		(paste)="delayedResize()" (drop)="delayedResize()" (keydown)="delayedResize()">
	</textarea>
	<label [for]="controlId">{{label}}</label>
	<validation-messages *ngIf=" showFailures" [messages]="failures"></validation-messages>
</div>