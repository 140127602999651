import { forwardRef, Component, Input, Output, ViewChild, Injector, EventEmitter, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';
import { LabeledControl } from '../labeledcontrol';

import { Observable } from 'rxjs';

import $ from '../../jquery.plugins';
/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import Inputmask from 'inputmask';

let nextUniqueId = 0;

export const TEXTAREA_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeTextareaComponent),
	multi: true
};

@Component({
	selector: 'kee-textarea',
	templateUrl: './textarea.component.html',
	providers: [TEXTAREA_VALUE_ACCESSOR],
})
export class KeeTextareaComponent extends LabeledControl<string> implements OnInit, AfterViewInit {

	private _textareaEl: ElementRef;
	@ViewChild('textarea') set textareaEl(value: ElementRef) {
		this._textareaEl = value;
		this.delayedResize();
	}

	@Input() public tabindex: number = null;
	@Input() public name: string = null;
	@Input() public rows: string = null;
	@Input() public cols: string = null;
	@Input() maxlength: string = null;

	private _autofocus: boolean = false;
	@Input()
	get autofocus(): boolean {
		return this._autofocus;
	}

	set autofocus(value) {
		this._autofocus = this.coerceBooleanProperty(value);
	}

	private _autoheight: boolean = false;
	@Input()
	get autoheight(): boolean {
		return this._autoheight;
	}

	set autoheight(value) {
		this._autoheight = this.coerceBooleanProperty(value);
	}

	private _customResize: boolean = false;
	@Input()
	get customResize(): boolean {
		return this._customResize;
	}

	set customResize(value) {
		this._customResize = this.coerceBooleanProperty(value);
	}

	private _spellcheck: boolean = false;
	@Input()
	get spellcheck(): boolean {
		return this._spellcheck;
	}

	set spellcheck(value) {
		this._spellcheck = this.coerceBooleanProperty(value);
	}

	@Input() public id: string = `field-input-${nextUniqueId++}`;
	get inputId() {
		return 'input' + this.id;
	}


	private _keyPressEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output('keyPress')
	get keyPress(): Observable<any> {
		return this._keyPressEmitter.asObservable();
	}

	/**
	 * Constructor
	 */
	constructor(private el: ElementRef, injector: Injector, ) {
		super(injector);
		this.placeholder = '';
	}

	/**
	 * Implements onInit event handler.
	 */
	public ngOnInit() {
		//this.el.nativeElement.removeAttribute('name');
		//this.el.nativeElement.removeAttribute('class');
		super.ngOnInit();
	}

	public ngAfterViewInit() {
	}

	public _handleKeyPress(event: any) {
		const args = { original: event, result: true };
		this._keyPressEmitter.emit(args);
		return args.result;
	}


	private delayedResizeTimeout: any;
	public delayedResize() {
		const self = this;
		if (self._autoheight) {
			if (self.delayedResizeTimeout) {
				clearTimeout(self.delayedResizeTimeout);
			}
			self.delayedResizeTimeout = setTimeout(() => { self.resize(); }, 100);
		}
	}

	public resize() {
		if (this._autoheight && this._textareaEl) {
			const $textInput = $(this._textareaEl.nativeElement);
			$textInput.height('auto');
			$textInput.height($textInput.prop('scrollHeight'));
		}
	}

}
