import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@saliente/common-auth';
import { REVISAL_ROUTES } from './revisal.routes.constants';

const routes: Routes = [
  {
    path: REVISAL_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: REVISAL_ROUTES.CLIENTS,
				loadChildren: () => import('./revisal-clients-changes/revisal-clients-changes.module').then(m => m.RevisalClientsWithChangesModule)
			},
			{
				path: REVISAL_ROUTES.REVISAL_REQUESTS,
				loadChildren: () => import('./revisal-requests/revisal-requests.module').then(m => m.RevisalRequestsModule)
			},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RevisalRoutingModule { }
