<div class="d-flex flex-column h-100">
    <kendo-grid [data]="gridView" scrollable="scrollable" [resizable]="true">
        <kendo-grid-command-column [style]="{'text-align': 'center', 'padding-left': '2px', 'padding-right': '2px'}" [width]="75">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <span class="whole-cell">
                    <i i18n-title="@@Actions$Select" title="Selectează" class="mdi-pick-one cursor-pointer font-size-1-5 px-1 cyan-text"
                        [style]="{'font-weight': '600 !important'}"
                        (click)="selectBalanceSheet(dataItem)"></i>
                </span>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column field="definition.description" i18n-title="@@BalanceSheet$DocumentName" title="Denumire"
            [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}">
        </kendo-grid-column>
    </kendo-grid>
</div>