export class PARAMETERS_ROUTES {
	static ROOT: string = '/parameters';
	static ROOT_1: string = 'parameters';

	static COMPANY: string = 'company';
	static INVOICE: string = 'invoice';
	static PAYROLL: string = 'payroll';

	static INVOICE_SERIAL: string = 'invoice-serial';
	static INVOICE_IBAN: string = 'invoice-iban';
}