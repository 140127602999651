<section class="row m-0">
	<form #documentStatusChangeForm="ngForm" class="col-24 px-0">
		<kee-textarea id="txtComment" name="comment" class="col-24" i18n-label="@@DocumentStatusChange$Comment" label="Notă" [required]="commentIsRequired"
		 [(ngModel)]="model.comment"></kee-textarea>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" i18n="@@Actions$Save" (click)="this.save.emit(documentStatusChangeForm)">
				<i class="mdi-content-save"></i>
				Salvează
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" i18n="@@Actions$Cancel" (click)="this.close.emit(documentStatusChangeForm)">
				<i class="mdi-content-clear"></i>
				Renunță
			</button>
		</div>
	</form>
</section>