import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { FinancialDocumentStatusChangeToBookedModel } from '@saliente/common-documents';

@Component({
	selector: 'expert-book-document-with-zero',
	templateUrl: 'book-document-with-zero.component.html'
})
export class ExpertBookDocumentWithZeroComponent implements OnInit {
	@Input() public model: FinancialDocumentStatusChangeToBookedModel;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	constructor() { }

	public ngOnInit() { }
}
