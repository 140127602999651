import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, filter, switchMap, tap } from 'rxjs/operators';

import { AuthService, } from './auth.service';
import { AppMenuService } from '../appmenu/appmenu.service';

@Injectable()
export class HomeGuard implements CanActivate {
	constructor(private authService: AuthService, private appMenuService: AppMenuService, private router: Router) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authService.isDoneLoading$.pipe(
			filter(isDone => isDone),
			switchMap(_ => this.authService.isAuthenticated$),
			tap(isAuthenticated => {
				if (isAuthenticated) {
					// if (route.data) {
						const homePage = this.appMenuService.getHomePage();
						if (!homePage) {
							return true;
						}
						this.router.navigate(homePage);
					// }
				}
				else
					this.authService.login(state.url);
			}),
		);
	}
}
