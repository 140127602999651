import { Component, Input, Output, ElementRef, EventEmitter, OnInit } from '@angular/core';

import { coerceBooleanProperty } from '../../utils';
@Component({
	selector: 'dropdown-button-item',
	templateUrl: 'dropdown-button-item.component.html'
})

export class DropdownButtonItemComponent implements OnInit {

	@Input() public href: string;
	@Input() public class: string = '';


	private _link: boolean = true;
	@Input() get link() {
		return this._link;
	}
	set link(value) {
		this._link = coerceBooleanProperty(value);
	}

	@Output() public itemClick = new EventEmitter<any>();



	constructor(private el: ElementRef) {

	}

	public ngOnInit() {
		this.el.nativeElement.removeAttribute('class');
	}

	public onClick($event: any) {
		this.itemClick.emit($event);
	}
}
