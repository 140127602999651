
import { FilterDescriptor, CompositeFilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';

const isPresent = (value: any) => {
	return value !== null && value !== undefined;
};

var insertDefaultFilter = function (index: number, rootFilter: CompositeFilterDescriptor, filter: FilterDescriptor) {
	rootFilter = (rootFilter || { filters: [], logic: "and" });
	// AM. 01.09.2021. no task. fix invoice bug: only when dataTo and dataFrom are empty, when dataTo has been selected, dataFrom is filled with dataTo value
	// rootFilter.filters[index] = filter;
	rootFilter.filters.splice(index, 0, filter);
	return filter;
};

export const setFilter = function (index: number, filter: CompositeFilterDescriptor, field: string, defaultOperator: string) {
	if (isPresent(filter) && isPresent(filter.filters) && filter.filters.length > index) {
		return filter.filters[index];
	}
	else {
		return insertDefaultFilter(index, filter, {
			field: field,
			operator: defaultOperator
		});
	}
};

export const ensureIntervalFilters = function (filter: CompositeFilterDescriptor, field: string, defaultOperators: string[]) {
	let gteIndex = filter.filters.findIndex((f: any) => f.operator == defaultOperators[0]);
	let lteIndex = filter.filters.findIndex((f: any) => f.operator == defaultOperators[1]);

	if (gteIndex == -1) {
		insertDefaultFilter(0, filter, {
			field: field,
			operator: defaultOperators[0]
		}); 
	}
	
	if (lteIndex == -1) {
		insertDefaultFilter(1, filter, {
			field: field,
			operator: defaultOperators[1]
		}); 
	}
}

/**
 * @hidden
 */
export const flatten = function (filter: CompositeFilterDescriptor): FilterDescriptor[] {
	if (filter && isPresent(filter.filters)) {
		return filter.filters.reduce(function (acc, curr) {
			return acc.concat(isCompositeFilterDescriptor(curr) ? flatten(curr) : [curr]);
		}, []);
	}
	return [];
};
var trimFilterByField = function (filter: CompositeFilterDescriptor, field: string) {
	if (isPresent(filter) && isPresent(filter.filters)) {
		filter.filters = filter.filters.filter(function (x) {
			if (isCompositeFilterDescriptor(x)) {
				trimFilterByField(x, field);
				return x.filters.length;
			}
			else {
				return x.field !== field;
			}
		});
	}
};

/**
 * @hidden
 */
export const filtersByField = function (filter: CompositeFilterDescriptor, field: string) {
	return flatten(filter).filter(function (x) { return x.field === field; });
};
/**
 * @hidden
 */
export const filterByField = function (filter: CompositeFilterDescriptor, field: string) {
	var currentFilter = filtersByField(filter, field)[0];
	return currentFilter;
};
/**
 * @hidden
 */
export const removeFilter = function (filter: CompositeFilterDescriptor, field: string) {
	trimFilterByField(filter, field);
	return filter;
};

export const trimFilters = function (filter: any) {
	filter.filters = filter.filters.filter((f: any) => { return !!f.value && f.value != 0; });
	return filter;
};

const copyObject = function (obj: any) {
	var result: any = {};
	Object.assign(result, obj);
	if (obj.constructor !== Object) {
		var proto_1 = obj.constructor.prototype;
		Object.getOwnPropertyNames(proto_1).forEach(function (property) {
			if (property !== 'constructor' && proto_1.hasOwnProperty(property)) {
				result[property] = obj[property];
			}
		});
	}
	return result;
};
const cloneFilter = function (filter: any) { return copyObject(filter); };

export const cloneFilters = function (filter: any): any {
	if (!filter) {
		return;
	}
	if (isCompositeFilterDescriptor(filter)) {
		return {
			filters: cloneFilters((<CompositeFilterDescriptor>filter).filters),
			logic: filter.logic
		};
	}
	else if (Array.isArray(filter)) {
		return filter.map(cloneFilters);
	}
	return cloneFilter(filter);
};