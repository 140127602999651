import { switchMap, } from 'rxjs/operators';

import { ConfigService, } from '../general/config.service';
import { DownloadService, } from '../general/download.service';

import { DocumentFileBaseModel, DocumentPageModel, } from './documents-base.models';
import { DocumentFileStatus, FinancialDocumentEntryType, FinancialDocumentPaymentType } from './documents.enums';



export class DocumentsBaseService<File extends DocumentFileBaseModel, Page extends DocumentPageModel> {

	constructor(public readonly configService: ConfigService, protected downloadService: DownloadService) { }

	protected processDocumentJson(element: any): any {
		function parseMetadata(element: any) {
			const metadataString = element.metadata;
			if (metadataString) {
				try {
					element.metadataEx = JSON.parse(metadataString);
				} catch (err) { }
			} else {
				element.metadataEx = {};
			}
		}
		if (!element.length) {
			parseMetadata(element);
		} else {
			element.forEach((e: any) => {
				parseMetadata(e);
			});
		}
		return element;
	}

	protected processDocuments(data: any[]): any[] {
		data = JSON.parse(JSON.stringify(data));
		data.forEach((element: any) => {
			this.processDocumentJson(element);
		});
		return data;
	}

	protected receivedStatusName(plural: boolean = false): string {
		return plural ? 'Încărcate' : 'Încărcat';
	}

	protected bookedStatusName(plural: boolean = false): string {
		return plural ? 'Contate' : 'Contat';
	}

	public documentStatusName(statusCode: string, plural: boolean = false): string {
		switch (statusCode) {
			case DocumentFileStatus.Received:
				return this.receivedStatusName(plural);
			case DocumentFileStatus.QueuedForDigitization:
				return 'In coada digitizare';
			case DocumentFileStatus.Digitized:
				return 'Digitizat';
			case DocumentFileStatus.QueuedForVerification:
				return 'În coada verificare';
			case DocumentFileStatus.Verified: return 'Verificat';
			case DocumentFileStatus.NotUsable: return plural ? 'Neclare' : 'Neclar';
			case DocumentFileStatus.SentToExpert: return 'La expert';
			case DocumentFileStatus.Deleted: return 'Șters';
			case DocumentFileStatus.MarkedForDeletion: return 'De șters';
			case DocumentFileStatus.Cancelled: return 'Anulat';
			case DocumentFileStatus.MarkedForCancellation: return 'De anulat';
			case DocumentFileStatus.Booked: return this.bookedStatusName(plural);

			case DocumentFileStatus.NotPayroll: return 'Nu payroll';
			case DocumentFileStatus.NotRevisal: return 'Nu revisal';

			case DocumentFileStatus.UnBooked: return plural ? 'Necontate' : 'Necontat';
		}
		return '';
	}

	public entryTypeDisplayName(type: FinancialDocumentEntryType): string {
		return DocumentsBaseService.entryTypeDisplayName(type);
	}

	public overlayClass(statusCode: string): string {
		switch (statusCode) {
			case DocumentFileStatus.Received:
			case DocumentFileStatus.QueuedForDigitization:
				return 'cyan darken-4';

			case DocumentFileStatus.Digitized:
			case DocumentFileStatus.QueuedForVerification:
			case DocumentFileStatus.NotPayroll:
			case DocumentFileStatus.NotRevisal:
				return 'orange darken-4';
			case DocumentFileStatus.NotUsable: return 'red';
			case DocumentFileStatus.SentToExpert: return 'purple';
			case DocumentFileStatus.MarkedForDeletion: return 'blue-grey';
			case DocumentFileStatus.Cancelled: return 'blue-grey';
			case DocumentFileStatus.MarkedForCancellation: return 'blue-grey';
			case DocumentFileStatus.Booked: return 'light-green';
			default:
				return 'cyan darken-4';
		}
	}

	public extendedOverlayClass(statusCode: string): string {
		switch (statusCode) {
			case DocumentFileStatus.Received:
			case DocumentFileStatus.QueuedForDigitization:
				return 'cyan lighten-4';
			case DocumentFileStatus.NotPayroll:
			case DocumentFileStatus.NotRevisal:
			case DocumentFileStatus.Digitized:
			case DocumentFileStatus.QueuedForVerification:
				return 'orange lighten-5';
			case DocumentFileStatus.Verified: return 'orange lighten-5';
			case DocumentFileStatus.NotUsable: return 'red lighten-4';
			case DocumentFileStatus.SentToExpert: return 'purple lighten-4';
			case DocumentFileStatus.Deleted: return 'blue-grey lighten-4';
			case DocumentFileStatus.MarkedForDeletion: return 'blue-grey lighten-4';
			case DocumentFileStatus.Cancelled: return 'blue-grey lighten-4';
			case DocumentFileStatus.MarkedForCancellation: return 'blue-grey lighten-4';
			case DocumentFileStatus.Booked: return 'light-green lighten-4';
			default:
				return 'cyan lighten-4';
		}
	}

	public downloadDocument(file: File, page?: Page) {
		return this.configService
			.getDocumentsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/documents/${file.id}/file?attachment=true`;
					if (page) {
						url = url + `&page=${page.id}`;
					}
					return this.downloadService.download(url);
				})
			);
	}

	public downloadFullDocument(file: File) {
		return this.configService
			.getDocumentsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/documents/${file.id}/full-document?attachment=true`;
					return this.downloadService.download(url);
				})
			);
	}

	public downloadOriginalDocument(file: File, page?: Page) {
		return this.configService
			.getDocumentsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/documents/${file.id}/original?attachment=true`;
					if (page) {
						url = url + `&page=${page.id}`;
					}
					return this.downloadService.download(url);
				})
			);
	}

	public getDocumentSrc(file: File, page?: Page) {
		if (page) {
			return `${this.configService.documentsUrl}/documents/${file.id}/file?page=${page.id}`;
		}
		return `${this.configService.documentsUrl}/documents/${file.id}/file`;
	}

	public getOriginalDocumentSrc(file: File, page?: Page) {
		if (page) {
			return `${this.configService.documentsUrl}/documents/${file.id}/original?page=${page.id}`;
		}
		return `${this.configService.documentsUrl}/documents/${file.id}/original`;
	}

	public getDocumentThumbnailSrc(file: File) {
		return `${this.configService.documentsUrl}/documents/${file.id}/thumbnail`;
	}

	public getDocumentThumbnailExtSrc(ext: string) {
		return `${this.configService.documentsUrl}/documents/thumbnail-${ext}/thumbnail`;
	}

	static entryTypeDisplayName(type: FinancialDocumentEntryType): string {
		switch (type) {
			case FinancialDocumentEntryType.SaleInvoice: return 'Factură de vânzare';
			case FinancialDocumentEntryType.PurchaseInvoice: return 'Factură de achiziție';
			case FinancialDocumentEntryType.AutoInvoice: return 'Factură de achiziție auto';
			case FinancialDocumentEntryType.SaleFiscalReceipt: return 'Bon fiscal de vânzare';
			case FinancialDocumentEntryType.PurchaseFiscalReceipt: return 'Bon fiscal de achiziție';
			case FinancialDocumentEntryType.GasReceipt: return 'Bon combustibil';
			case FinancialDocumentEntryType.AutoReceipt: return 'Bon Cheltuieli Auto';
			case FinancialDocumentEntryType.CashReceipt: return 'Chitanță primită';
			case FinancialDocumentEntryType.CashIssued: return 'Chitanță emisă';
			case FinancialDocumentEntryType.CashIssuedSales: return 'Chitanță fiscală de vânzare';
			case FinancialDocumentEntryType.ExpenseAccountReceipt: return 'Chitanță decont';
			case FinancialDocumentEntryType.PurchaseFiscalReceiptWithoutTIN: return 'Bon fiscal fară CUI';
			case FinancialDocumentEntryType.ProtocolFiscalReceipt: return 'Bon fiscal protocol';
			case FinancialDocumentEntryType.CashOrder: return 'Dispoziție de plată';
			case FinancialDocumentEntryType.CollectionOrder: return 'Dispoziție de încasare';
			case FinancialDocumentEntryType.StatementOfAccount: return 'Extras de cont';
		}
		return '';
	}

	static paymentTypeDisplayName(type: FinancialDocumentPaymentType): string {
		switch (type) {
			case FinancialDocumentPaymentType.Cash: return 'Numerar Decont(542)';
			case FinancialDocumentPaymentType.Card: return 'Card(Plată POS)';
			case FinancialDocumentPaymentType.Bank: return 'Transfer bancar';
			case FinancialDocumentPaymentType.Receipt: return 'Plată Casă(5311)';
		}
		return '';
	}

}