import { Injectable } from "@angular/core";
import { plainToClass } from 'class-transformer';
import gql from 'graphql-tag';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { RestService } from '../general/rest.service';
import { AuthService } from '../auth/auth.service';
import { ImportListModel, ImportsModel } from "src/app/clients/import/import.models";
import { AppMenuService } from "../appmenu/appmenu.service";


const importListQueryText = gql`
query importList ($clientEid: String!, $filter: String, $type: String){
	imports {
		importList: getImportsList(clientEid: $clientEid, filter: $filter, type: $type) {
			recordsCount
			data {
				status
			}
		}
	}
}`;

@Injectable()
export class ImportServiceCommon {
    
    public importsCount: BehaviorSubject<number>;
	private getWaitingImportsInterval: any;

    constructor(private restService: RestService, private authService: AuthService, public appMenuService: AppMenuService) {
        this.importsCount = new BehaviorSubject<number>(null);
	}

	public waitingImportsClearInterval() {
		if (this.getWaitingImportsInterval) {
			clearInterval(this.getWaitingImportsInterval);
			this.getWaitingImportsInterval = null;
		}
	}

	public startImportCount() {
		this.initializeWaitingImportList();
		this.waitingImportsClearInterval();
		this.getWaitingImportsInterval = setInterval(() => {
			this.initializeWaitingImportList();
		}, 300000);
	}

    public initializeWaitingImportList() {
		this.getWaitingImportList().pipe(take(1)).subscribe((count) => {
			if (count != null) {
				this.importsCount.next(count);
			}
		});
    }

	public getWaitingImportList() {
		return combineLatest([
			this.authService.getSelectedCompanyId(),
		])
			.pipe(
				switchMap(([companyId]) => {
					if (companyId) {
						return this.restService
							.query({
								query: importListQueryText,
								variables: {
									clientEid: companyId,
									filter: "is_waiting: true"
								},
								fetchPolicy: 'network-only'
							}, {
                            	spinner: false, error: false 
                            })
							.pipe(
								map((res: any) => {
									if (res) {
										var importList = plainToClass(ImportsModel, res.data.imports.importList);
										return importList.data.length;
									}
									return null;
								})
							);
					}
					return of(null);
				})
			);
	}

    public get waitingImportsCount(): number {
        // var count;
		// this.getWaitingImportList().subscribe((val) => {
		// 	count = val
		// });
		
		// console.log(count);
		// return count;
        //return this.imports.value.length;
        return this.importsCount.value;

    }
}