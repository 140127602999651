import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SalienteLibrarySharedModule } from "@saliente/library";
import { ClientFormsService } from "../client-forms.service";
import { ActivitateIndependentaComponent } from "../declaratia-unica-pfa-form/activitate-independenta.component";
import { ChirieComponent } from "../declaratia-unica-pfa-form/chirie.component";
import { DeclaratiaUnicaFormComponent } from "../declaratia-unica-pfa-form/declaratia-unica-pfa-form.component";
import { DuPFAKeezFormComponent } from "./du-pfa-keez-form.component";

@NgModule({
    imports: [
		FormsModule, SalienteLibrarySharedModule
	],
	providers: [
		ClientFormsService
	],
	declarations: [
		DuPFAKeezFormComponent, DeclaratiaUnicaFormComponent, ActivitateIndependentaComponent, ChirieComponent
	],
	exports: [
		DuPFAKeezFormComponent, 
	]
})
export class DuPFAKeezSharedModule { }