import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, filter, switchMap, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private authService: AuthService) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authService.isDoneLoading$.pipe(
			filter(isDone => isDone),
			switchMap(_ => this.authService.isAuthenticated$),
			tap(isAuthenticated => {
				if (isAuthenticated) {
					if (route.data) {
						if (route.data.accessRole) {
							return of(this.authService.isInRole(route.data.accessRole));
						}
						if (route.data.zoneAccessRole) {
							const accessRole = route.data.zoneAccessRole[this.authService.selectedZoneCode];
							if (!accessRole) {
								return of(false);
							}
							return of(this.authService.isInRole(accessRole));
						}
					}
				}
				else
					this.authService.login(state.url);
			}),
		);
	}

	public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authService.isDoneLoading$.pipe(
			filter(isDone => isDone),
			switchMap(_ => this.authService.isAuthenticated$),
			tap(isAuthenticated => {
				if (isAuthenticated) {
					if (childRoute.data && childRoute.data.accessRole) {
						return of(this.authService.isInRole(childRoute.data.accessRole));
					}
				}
				else
					this.authService.login(state.url);
			}),
		);
	}
}
