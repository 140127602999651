import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { CaenCodeModel, DeclaratiaUnicaPfaModel, DuActivitatiIndependenteModel, DuChiriiModel, FormStatus, SistemImpozitareActivitatiIndependente } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";
import { round } from "lodash-es";
import { isEmailValid } from "@saliente/library";

@Component({
	selector: 'declaratia-unica-pfa-form',
	templateUrl: 'declaratia-unica-pfa-form.component.html',
    styleUrls: ['declaratia-unica-pfa-form.component.scss']

})
export class DeclaratiaUnicaFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public isDialog: boolean;
    @Input() public isPfa: boolean;
    @Input() public model: DeclaratiaUnicaPfaModel;

    @Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();
	@Output() public invalidate = new EventEmitter();
    @Output() public download = new EventEmitter();

    // view child
	@ViewChild('duPfaForm') public duPfaForm: NgForm;

    //booleans
    public isReadOnly: boolean;
    public isExpert: boolean;

    //models
    public caenCodes: CaenCodeModel[];

    public notEditableFormReason: string = "Declarația nu se poate completa, contactați expertul dumneavoastră în Connect.";

    constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private toastr: ToastrService) {
        this.clientFormsService.getCaenCodeList().pipe(take(1))
            .subscribe((codes: CaenCodeModel[]) => {
                this.caenCodes = codes;
            })
    }

    ngOnInit(): void {
        this.isReadOnly = this.model.formData.status == FormStatus.Submitted;
        this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert && this.authService.isInRole('EXPERT');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.model.firstChange) {
            this.model = changes.model.currentValue;
            this.isReadOnly = this.model.formData.status == FormStatus.Submitted;
            this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert && this.authService.isInRole('EXPERT');
        }
    }

    ngOnDestroy(): void {}

    public get isFormEditable() {
        if (this.isPfa) {
            return true;
        }
        else if (!this.model.data.persoana.areHandicap && !this.model.data.persoana.nerezident) {
            return true;
        }
        return false;
    }

    public get hasActivitatiIndependenteEstimat(): boolean {
        return this.model.data.activitatiIndependenteEstimat != undefined && this.model.data.activitatiIndependenteEstimat.length > 0;
    }

    public onAreHandicapChanged(event: any) {
        if (!this.isPfa && this.model.data.persoana.areHandicap) {
            this.saveForm(this.duPfaForm);
        }
    }

    public onNerezidentChanged(event: any) {
        if (!this.isPfa && this.model.data.persoana.nerezident) {
            this.saveForm(this.duPfaForm);
        }
    }

    public addNewActivitateRealizat() {
        if (this.model.data.activitatiIndependenteRealizat == undefined) {
            this.model.data.activitatiIndependenteRealizat = [];
        }

        let newActivitateRealizat = new DuActivitatiIndependenteModel();
        this.model.data.activitatiIndependenteRealizat = [...this.model.data.activitatiIndependenteRealizat, newActivitateRealizat];
    }

    public addNewActivitateEstimat() {
        if (this.model.data.activitatiIndependenteEstimat == undefined) {
            this.model.data.activitatiIndependenteEstimat = [];
        }

        let newActivitateEstimat = new DuActivitatiIndependenteModel();
        this.model.data.activitatiIndependenteEstimat = [...this.model.data.activitatiIndependenteEstimat, newActivitateEstimat];
    }

    public deleteActivitateRealizat(activitateRealizatIndex: number) {
        this.model.data.activitatiIndependenteRealizat.splice(activitateRealizatIndex, 1);
    }

    public deleteActivitateEstimat(activitatEstimatIndex: number) {
        this.model.data.activitatiIndependenteEstimat.splice(activitatEstimatIndex, 1);
    }

    public addNewChirieRealizat() {
        if (this.model.data.chiriiRealizat == undefined) {
            this.model.data.chiriiRealizat = [];
        }

        let newChirieRealizat = new DuChiriiModel();
        this.model.data.chiriiRealizat = [...this.model.data.chiriiRealizat, newChirieRealizat];
    }

    public addNewChirieEstimat() {
        if (this.model.data.chiriiEstimat == undefined) {
            this.model.data.chiriiEstimat = [];
        }

        let newChirieEstimat = new DuChiriiModel();
        this.model.data.chiriiEstimat = [...this.model.data.chiriiEstimat, newChirieEstimat];
    }

    public deleteChirieRealizat(chirieRealizatIndex: number) {
        this.model.data.chiriiRealizat.splice(chirieRealizatIndex, 1);
    }

    public deleteChirieEstimat(chirieEstimatIndex: number) {
        this.model.data.chiriiEstimat.splice(chirieEstimatIndex, 1);
    }

    public dividendeRealizatOnBlur() {
        if(this.model.data.dividendeRealizat) {
            this.model.data.dividendeRealizat = round(this.model.data.dividendeRealizat);
        }
    }

    public dividendeEstimatOnBlur() {
        if(this.model.data.dividendeRealizat) {
            this.model.data.dividendeRealizat = round(this.model.data.dividendeRealizat);
        }
    }

    public personHelp() {
		window.open("https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_dateContribuabil.html", '_blank', 'noopener noreferrer');
    }

    public activitatiIndependenteHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_venituriActivitatiIndependente.html";
    }

    public chiriiHelp() {
		return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_chirii.html";
    }

    public dividendeHelp() {
       return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_dividende.html";
    }
    
    public sanatateHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_optionalSanatate.html";
    }

    public pensieHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_optionalPensie.html";
    }

    public venituriStrainatateHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_venituriStrainatate.html";
    }

    public cimNedeterminatHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_esteAngajat.html";
    }

    public impozitONGHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_ong.html";
    }

    public comentariiHelp() {
        return "https://app.keez.ro/help/client/antreprenor/declaratia_unica/DU_comentarii.html";
    }

    public validateOptiunePensieValoareEstimat(value: number) {
		if (value && (value < this.model.formDataParameters.limitaPensie)) {
			return `Valoarea trebuie să fie mai mare sau egală cu ${this.model.formDataParameters.limitaPensie} RON`;
		}

		return true;
	}

    public validateEmail(value: string): string | boolean {
		if (value && value.trim() != "") {
			if (!isEmailValid(value.trim())) {
				return "Adresa de e-mail introdusă nu este validă.";
			}
		}

		return true;
	}

	public emailOnBlur(value: string) {
		if (value && value.trim() != "") {
			if (!isEmailValid(value.trim())) {
				this.toastr.error("Adresa de e-mail introdusă nu este validă. Vă rugăm să verificați dacă ați scris corect adresa și să eliminați eventualele spații sau caractere nepermise. Asigurați-vă că adresa de e-mail conține un singur simbol '@' și este formată corect, de exemplu, 'nume@exemplu.com'. Vă mulțumim!");
			}
		}
	}

    public deleteNormaDeVenitFromRealizat() {
        if (this.model.data.activitatiIndependenteRealizat != undefined) {
            this.model.data.activitatiIndependenteRealizat = this.model.data.activitatiIndependenteRealizat
                .filter(aiRealizat => aiRealizat.sistemImpozitare !== SistemImpozitareActivitatiIndependente.NormaDeVenit);
        }
    }

    public saveForm(duPfaForm: NgForm) {
        this.deleteNormaDeVenitFromRealizat();
        this.save.emit({duPfaForm: duPfaForm, doSubmit: false})
    }

    public submitForm(duPfaForm: NgForm) {
        this.save.emit({duPfaForm: duPfaForm, doSubmit: true})
    }

    public invalidateForm() {
        this.invalidate.emit();
    }

    public downloadForm() {
        this.download.emit();
    }
}