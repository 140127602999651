<div *ngIf="model && model.data" class="px-0 col-24">
    <div class="card col-24 px-0 mx-0">
        <div class="col-24 px-0 pb-3 d-flex flex-column">
            <div class="px-3 summary-header d-flex">
                <span class="title pr-1" i18n="@@RegisterSheet$Title">Fișa rol</span>
            </div>
            <div *ngFor="let fiscalSheetData of model.data" class="d-flex flex-wrap">
                <div class="col px-0 mt-3 d-flex align-items-baseline">
                    <div class="col-auto">
                        <label i18n="@@DocumentsSummary$FiscalNumber">Cod fiscal:</label>
                        <span class="pl-2">{{fiscalSheetData.fiscalNumber}}</span>
                    </div>
                    <div class="col-auto">
                        <label i18n="@@RegisterSheet$ToDate">Pentru data:</label>
                        <span class="pl-2">{{fiscalSheetData.toDateEx | date: 'dd.MM.yyyy'}}</span>
                    </div>
                </div>

                <div class="col-auto px-0 d-flex">
                    <a class="col-auto cursor-pointer" target="_blank" (click)="downloadFiscalSheetDataCsv(fiscalSheetData)">
                        <i class="csv-download"></i>
                    </a>
                    <a class="col-auto cursor-pointer" target="_blank" (click)="downloadFiscalSheetDataXls(fiscalSheet)">
                        <i class="xls-download"></i>
                    </a>
                </div>
                <div class="col-24 pl-4 d-flex">
                    <kendo-grid #fiscalSheet class="col-24 px-0 transparent" style="font-size:.8rem" [data]="fiscalSheetData.sections" scrollable="none">
                        <kendo-grid-column field="sectionName" title="Secțiune" headerClass="p-1" class="p-1">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.sectionName}} </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column-group title="Obligație" headerClass="p-1 text-center">
                            <kendo-grid-column field="totalPromiseOfPayment" title="De plată" format="{0:n0}" headerClass="p-1 text-center" class="p-1 text-right" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.totalPromiseOfPayment | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="unpaidPromiseOfPayment" title="Neachitată" format="{0:n0}" headerClass="p-1 text-center" class="p-0 text-right"
                                [style]="{'height': '1px'}" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span
                                        class="px-1 h-100 d-flex align-items-center justify-content-end {{dataItem.isSummary ? 'font-weight-bold' : ''}} {{dataItem.unpaidPromiseOfPayment ? 'red lighten-3' : ''}}">
                                        {{ dataItem.unpaidPromiseOfPayment | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="interestCalculatedNotEstablished" title="Dobândă neinstituită" format="{0:n0}" headerClass="p-1 text-center"
                                class="p-1 text-right" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.interestCalculatedNotEstablished | kendoNumber:'n0'}}
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="penalties" title="Penalizări" format="{0:n0}" headerClass="p-1 text-center" class="p-1 text-right" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.penalties | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid-column-group>
                        <kendo-grid-column-group title="Creditat" headerClass="p-1 text-center">
                            <kendo-grid-column field="creditedAmountIncome" title="Încasări" format="{0:n0}" headerClass="p-1 text-center" class="p-1 text-right" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.creditedAmountIncome | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="creditedAmountRefund" title="De rambursat" format="{0:n0}" headerClass="p-1 text-center" class="p-1 text-right" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.creditedAmountRefund | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="creditedDifference" title="Diferența" format="{0:n0}" headerClass="p-1 text-center" class="p-0 text-right"
                                [style]="{'height': '1px'}" [width]=100>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span
                                        class="px-1 h-100 d-flex align-items-center justify-content-end {{dataItem.isSummary ? 'font-weight-bold' : ''}} {{dataItem.creditedDifference ? 'green lighten-2' : ''}}">
                                        {{ dataItem.creditedDifference | kendoNumber:'n0'}} </span>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid-column-group>
                        <kendo-grid-column field="unpaid" title="Neachitate" format="{0:n0}" headerClass="p-1 align-middle text-center" class="p-1 text-right" [width]=100>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.unpaid | kendoNumber:'n0'}} </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="supersolvency" title="Suprasolvire" format="{0:n0}" headerClass="p-1 align-middle text-center" class="p-1 text-right" [width]=100>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.supersolvency | kendoNumber:'n0'}} </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="unallocated" title="Nealocate" format="{0:n0}" headerClass="p-1 align-middle text-center" class="p-1 text-right" [width]=100>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="{{dataItem.isSummary ? 'font-weight-bold' : ''}}"> {{ dataItem.unallocated | kendoNumber:'n0'}} </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-excel fileName="Fișa rol - {{fiscalSheetData.fiscalNumber}} - {{this.intl.format('{0:MMMM}', fiscalSheetData.toDateEx)}}.xlsx">
                        </kendo-grid-excel>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>