import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MicroProfitFormModel } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";

@Component({
	selector: 'micro-profit-form',
	templateUrl: 'micro-profit-form.component.html',
    styleUrls: ['micro-profit-form.component.scss']

})
export class MicroProfitFormComponent implements OnInit, OnDestroy {
    @Input()
    public isDialog: boolean;
    @Input()
    public clientEidParam: string;
    @Input()
    public instanceParam: string;

    @Output() public close = new EventEmitter();

    //subscriptions
	private microProfitDataSubscription: Subscription;
    
    //models
    public microProfitFormModel: MicroProfitFormModel;

    public clientEid: string;
    public instance: string;

    //booleans
    public isReadOnly: boolean;
    public hasViewRight: boolean;
    public isLoading: boolean;
    public showConfirmationDialog: boolean = false;

    get areConsultanta80Disabled(): boolean {
        return this.microProfitFormModel.data.are1FTE == null || !this.microProfitFormModel.data.are1FTE;
    }

    get are253MicroDisabled(): boolean {
        return this.microProfitFormModel.data.areConsultanta80 == null || this.microProfitFormModel.data.areConsultanta80;
    }

    get pastreazaFirmaMicroDisabled(): boolean {
        return this.microProfitFormModel.data.are253Micro == null || !this.microProfitFormModel.data.are253Micro;
    }

    get saveDisabled(): boolean {
        return this.microProfitFormModel.data.ramaneMicro == null;
    }

    constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
            private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.microProfitDataSubscription = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.clientEid = this.isDialog ? this.clientEidParam : params.get('id');
                    this.instance = this.isDialog ? this.instanceParam : params.get('instance');

                    return this.clientFormsService.getMicroProfitData(this.clientEid, this.instance);
                })
		    )
			.subscribe((microProfitFormData: MicroProfitFormModel) => {
                if (microProfitFormData) {
                    this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || microProfitFormData.formData.status == 'Submitted';

                    this.hasViewRight = this.authService.isInRole('EXPERT') || this.authService.isInRole('ZEUS') 
                        || this.authService.isInRole(microProfitFormData.formData.displayRole);

                    if (this.hasViewRight) {
                        this.microProfitFormModel = microProfitFormData;

                        if (this.microProfitFormModel.formData.status != 'Submitted') {
                            let ramaneMicro = microProfitFormData.data.ramaneMicro;
                            this.computeBooleans();
                            this.microProfitFormModel.data.ramaneMicro = ramaneMicro;
                        }

                        this.getMicroProfitFormConclusion();
                    }
                }
                else {
                    this.microProfitFormModel = null;
                }

                this.isLoading = false;

                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 200);
		});
    }

    ngOnDestroy(): void {
        if (this.microProfitDataSubscription) { this.microProfitDataSubscription.unsubscribe(); this.microProfitDataSubscription = null; }
    }

    public onAre1FTEHelp() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-angajat";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAreConsultanta80() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-venituri-din-consultanta";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAre253Micro() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-actionari-cu-25-din-partile-sociale-la-3-firme";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onRamaneMicroHelp() {
        let url = this.microProfitFormModel.data.ramaneMicro 
            ? "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-venit"
            : "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-profit";

        window.open(url, '_blank', 'noopener noreferrer');   
    }

    public computeBooleans() {
        this.onAre1FTEChange();
        this.onAreConsultanta80Change();
        this.onAre253MicroChange();
        this.onPastreazaFirmaMicroChange();
    }

    public onAre1FTEChange() {
        if (this.microProfitFormModel.data.are1FTE == null) {
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else if (this.microProfitFormModel.data.are1FTE) { // answer: yes - need to answer the following questions
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else { // answer: no - there is no need for the other questions: !ramaneMicro
            this.microProfitFormModel.data.areConsultanta80 = null;
            this.microProfitFormModel.data.are253Micro = null;
            this.microProfitFormModel.data.pastreazaFirmaMicro = null;

            this.microProfitFormModel.data.ramaneMicro = false;
        }
    }

    public onAreConsultanta80Change() {
        if (this.microProfitFormModel.data.areConsultanta80 == null) {
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else if (this.microProfitFormModel.data.areConsultanta80) { // answer: yes - there is no need for the following questions: !ramaneMicro
            this.microProfitFormModel.data.are253Micro = null;
            this.microProfitFormModel.data.pastreazaFirmaMicro = null;
            
            this.microProfitFormModel.data.ramaneMicro = false;
        }
        else { // answer: no - need to answer the following questions
            this.microProfitFormModel.data.ramaneMicro = null;
        }
    }

    public onAre253MicroChange() {
        if (this.microProfitFormModel.data.are253Micro == null) {
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else if (this.microProfitFormModel.data.are253Micro) { // answer: yes - need to answer the following questions
            this.microProfitFormModel.data.pastreazaFirmaMicro = null;
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else { // answer: no - there is no need for the following questions: ramaneMicro
            this.microProfitFormModel.data.ramaneMicro = true;
            this.microProfitFormModel.data.pastreazaFirmaMicro = null;
        }
    }

    public onPastreazaFirmaMicroChange() {
        if (this.microProfitFormModel.data.pastreazaFirmaMicro == null) {
            this.microProfitFormModel.data.ramaneMicro = null;
        }
        else if (this.microProfitFormModel.data.pastreazaFirmaMicro) { // answer: yes - ramaneMicro
            this.microProfitFormModel.data.ramaneMicro = true;
        }
        else { // answer: no - !ramaneMicro
            this.microProfitFormModel.data.ramaneMicro = false;
        }
    }
    
    public getMicroProfitFormConclusion() {
        return this.microProfitFormModel.data.ramaneMicro
            ? 'Firma va rămâne Microîntreprindere - plătitoare de impozit pe venit.' 
            : 'Firma va deveni plătitoare de impozit pe profit.'
    }

    public openConfirmationDialog() {
        this.showConfirmationDialog = true;
    }

    public closeConfirmationDialog() {
        this.showConfirmationDialog = false;
    }

    public async saveMicroProfitForm() {
        let result = await this.clientFormsService.updateClientFormMicroProfitData(this.clientEid, this.instance, this.microProfitFormModel.data);
        if (result) {
            if (!this.isDialog) {
                this.microProfitFormModel.formData.status = "Submitted";
                this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || this.microProfitFormModel.formData.status == 'Submitted';
            }
            
            this.cancelMicroProfitForm();
            this.toastr.success("Formularul a fost salvat cu succes!");
        }
    }

    public cancelMicroProfitForm() {
        this.closeConfirmationDialog();

        if (this.isDialog) {
            this.close.emit();
        }
    }
}
