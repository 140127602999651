import { Directive, Attribute, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { coerceBooleanProperty } from '../utils';

@Directive({
	selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true }]
})
export class EqualValidatorDirective implements Validator {
	@Input('validateEqual') public validateEqual: string;
	@Input('validateEqual-message') public validateEqualMessage: string;

	private _reverse: boolean = false;
	@Input('reverse')
	get reverse() {
		return this._reverse;
	}
	set reverse(value) {
		this._reverse = coerceBooleanProperty(value);
	}

	constructor() { }

	public validate(control: AbstractControl): { [key: string]: any } {
		const controlValue = control.value;
		const compareToControl = control.root.get(this.validateEqual);
		if (compareToControl) {
			const compareToValue = compareToControl.value;
			if (!this.reverse) {
				if (controlValue !== compareToValue) {
					return {
						validateEqual: this.validateEqualMessage || false
					};
				}
			} else {
				if (controlValue === compareToValue) {
					const errors = compareToControl.errors || {};
					delete errors['validateEqual'];
					if (!Object.keys(errors).length) {
						compareToControl.setErrors(null);
					}
				} else {
					const errors = compareToControl.errors || {};
					errors['validateEqual'] = this.validateEqualMessage || false;
					compareToControl.setErrors(errors);
				}
			}
		}
		return null;
	}
}
