<div class="form-group materialize {{behaviourClasses}}">
	<!-- <kendo-datepicker class="form-control" [id]="controlId" [placeholder]="placeholder" [formatPlaceholder]="formatPlaceholder" [format]="format" [activeView]="activeView"
		[bottomView]="bottomView" [topView]="topView" [weekNumber]="weekNumber" [min]="min" [max]="max" [disabled]="disabled" (focus)="_handleFocus($event)"
		(blur)="_handleBlur($event)" [(ngModel)]="value" [ngModelOptions]="ngModelOptions" (valueChange)="_handleChange($event)">
	</kendo-datepicker> -->
	<kendo-maskedtextbox class="form-control" [id]="controlId" [mask]="dateMask"
		[disabled]="disabled" [(ngModel)]="maskedEditDatePickerValue"
		[ngModelOptions]="ngModelOptions"
		(blur)="_handleBlur($event)" (focus)="_handleFocus($event)"
		(valueChange)="_handleTextChange($event)"></kendo-maskedtextbox>
	<label [for]="controlId">{{label}}</label>
	<validation-messages *ngIf="showFailures" [messages]="failures"></validation-messages>
</div>