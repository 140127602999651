<section class="row m-0">
	<form #documentFileCommentForm="ngForm" class="col-24 px-0">
		<kee-datepicker *ngIf="!!date" id="dtpCommentDate" name="date" class="col-24" i18n-label="@@DocumentFileComment$Date" label="{{dateLabel || 'Data'}}"
			[(ngModel)]="model.date" required>
		</kee-datepicker>
		<kee-textarea id="txtComment" name="comment" class="col-24" i18n-label="@@DocumentFileComment$Comment" label="Adnotare" required [(ngModel)]="model.comment"></kee-textarea>
		<span *ngIf="!!note" class="font-size-smaller d-block col-24">{{note}}</span>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<checkbox *ngIf="model.alsoSendInConnect != undefined" name="alsoSendInConnect" i18n-label="@@DocumentStatusChange$AlsoSendInConnect" label="Trimite mesaj în Connect"
			[(ngModel)]="model.alsoSendInConnect">
		</checkbox>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="this.save.emit(documentFileCommentForm)">
				<i class="mdi-content-save"></i>
				<span i18n="@@Actions$Save"> Salvează</span>
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" (click)="this.close.emit(documentFileCommentForm)">
				<i class="mdi-content-clear"></i>
				<span i18n="@@Actions$Cancel">Renunță</span>
			</button>
		</div>
	</form>
</section>