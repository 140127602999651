
import { Type, Expose, Exclude, } from 'class-transformer';
import { dateToInt, intToDate } from '@saliente/library';

export class CompanyFiscalSummaryModel {
    public fiscalNumber: string;
    @Expose({ name: 'dateVATPayerFrom' })
    public dateVATPayerFromInt: number;
    @Exclude()
    protected _dateVATPayerFromEx: Date;
    @Exclude()
    get dateVATPayerFromEx() {
        if (!this._dateVATPayerFromEx) {
            this._dateVATPayerFromEx = intToDate(this.dateVATPayerFromInt);
        }
        return this._dateVATPayerFromEx;
    }

    public inactivationStatus: boolean;
    public isVATPayer: boolean;
    public splitVATStatus: boolean;
    public vatOnCollectionStatus: boolean;

    public caenCode: string;
    public caenDescription: string;

    public specialVat: any;

    static typeProvider() {
        return CompanyFiscalSummaryModel;
    }
}

export class CompanySpecialVatModel {
    public externalId: string;
    public code: string;

    @Expose({ name: 'startDate' })
    public startDateInt: number;
    @Exclude()
    protected _startDateEx: Date;
    @Exclude()
    get startDateEx() {
        if (!this._startDateEx) {
            this._startDateEx = intToDate(this.startDateInt);
        }
        return this._startDateEx;
    }
    set startDateEx(value: Date) {
        if (this._startDateEx != value) {
            this._startDateEx = value;
            this.startDateInt = dateToInt(this._startDateEx);
        }
    }

    @Expose({ name: 'endDate' })
    public endDateInt: number;
    @Exclude()
    protected _endDateEx: Date;
    @Exclude()
    get endDateEx() {
        if (!this._endDateEx) {
            this._endDateEx = intToDate(this.endDateInt);
        }
        return this._endDateEx;
    }
    set endDateEx(value: Date) {
        if (this._endDateEx != value) {
            this._endDateEx = value;
            this.endDateInt = dateToInt(this._endDateEx);
        }
    }
}

export class StatutoryReportTypeRepresentation {
    public code: string;
    public description: string;

    get display() {
        return `${this.code} - ${this.description}`;
    }

    static typeProvider() {
        return StatutoryReportTypeRepresentation;
    }
}

export class AccountingFiscalSheetSectionModel {
    public creditedDifference: number;
    public creditedAmountIncome: number;
    public creditedAmountRefund: number;
    public interestCalculatedNotEstablished: number;
    public isSummary: boolean;
    public sectionName: string;
    public unpaidPromiseOfPayment: number;
    public unpaid: number;
    public totalPromiseOfPayment: number;
    public supersolvency: number;
    public penalties: number;
    public unallocated: number;
    public sortOrder: number;

    static typeProvider() {
        return AccountingFiscalSheetSectionModel;
    }
}

export class AccountingFiscalSheetDataModel {
    public fiscalNumber: string;
    public clientName: string;

    @Expose({ name: 'toDate' })
    public toDateInt: number;
    @Exclude()
    protected _toDateEx: Date;
    @Exclude()
    get toDateEx() {
        if (!this._toDateEx) {
            this._toDateEx = intToDate(this.toDateInt);
        }
        return this._toDateEx;
    }
    set toDateEx(value: Date) {
        if (this._toDateEx != value) {
            this._toDateEx = value;
            this.toDateInt = dateToInt(this._toDateEx);
        }
    }

    @Type(AccountingFiscalSheetSectionModel.typeProvider)
    public sections: AccountingFiscalSheetSectionModel[];

    static typeProvider() {
        return AccountingFiscalSheetDataModel;
    }
}

export class AccountingFiscalSheetModel {
    public found: boolean;

    @Type(AccountingFiscalSheetDataModel.typeProvider)
    public data: AccountingFiscalSheetDataModel[];

    static typeProvider() {
        return AccountingFiscalSheetModel;
    }
}