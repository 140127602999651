import { DatePipe } from '@angular/common';
import { intYYYYMMDDToSimpleMMYYYYString } from '@saliente/library';
import { Exclude } from 'class-transformer';
import { round } from 'lodash-es';

export enum TakeoverStatus {
	Open = 'OPEN',
	Close = 'CLOSE',
}

export enum TakeoverDashboardStatus {
	Open = 'Open',
	Close = 'Close',
}

export class TakeoverListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public pageSize: number;
	public data: TakeoverModel[];
}

export class TakeoverModel {
	public externalId: string;
	public takeoverTypeEid: string;
	public takeoverType: TakeoverTypeModel;
	public clientEid: string;
	public status: SysTakeoverStatusModel;
	public statusCode: string;
	public takeoverAttachments: TakeoverAttachmentModel[];
	public history: TakeoverHistoryModel[];
	public createdByUserName: string;
	public createdDate: string;
	public isFinished: boolean;

	@Exclude()
	get createdDateFormattedString(): string {
		return new DatePipe('ro-RO').transform(this.createdDate, 'dd.MM.yyyy HH:mm:ss');
	}
}

export class TakeoverTypeModel {
	public externalId: string;
	public code: string;
	public name: string;
	public description: string;
	public instruction: string;
	public isVisible: boolean;
	public createdByUserName: string;
}

export class SysTakeoverStatusModel {
	public code: string;
	public name: string;
	public nameEn: string;
}

export class TakeoverAttachmentModel {
	public fileEid: string;
	public fileName: string;
	public fileSize: number;
	public displayOrder: number;
	public isVisible: boolean;

	@Exclude()
	public get convertedFileSize(): number {
		// 1 KB = 1024 bytes; 1 MB = 1024 KB; 1 GB = 1024 MB
		// 1mb = 1 * 1024 * 1024
		if (this.fileSize >= 1048576) {
			// 1024 * 1024
			return round(this.fileSize / 1048576, 2); // = MB
		} else if (this.fileSize >= 1024 && this.fileSize < 1048576) {
			return round(this.fileSize / 1024, 2); // = KB
		} else if (this.fileSize < 1024) {
			return round(this.fileSize, 2); // = B
		} else {
			return round(this.fileSize, 2);
		}
	}

	@Exclude()
	public get convertedFileUM(): string {
		// 1 KB = 1024 bytes; 1 MB = 1024 KB; 1 GB = 1024 MB
		// 1mb = 1 * 1024 * 1024
		if (this.fileSize >= 1048576) {
			// 1024 * 1024
			return 'MB';
		} else if (this.fileSize >= 1024 && this.fileSize < 1048576) {
			return 'KB';
		} else if (this.fileSize < 1024) {
			return 'B';
		} else {
			return 'B';
		}
	}
}

export class TakeoverHistoryModel {
	public externalId: string;
	public description: string;
	public performedDateTime: string;
	public performedUserEid: string;
	public performedUserName: string;

	@Exclude()
	get performedDateTimeFormattedString(): string {
		return new DatePipe('ro-RO').transform(this.performedDateTime, 'dd.MM.yyyy HH:mm:ss');
	}
}

export class TakeoverExpertListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public pageSize: number;
	public data: TakeoverExpertModel[];
	initialize(): void {
		this.data.forEach((data) => data.initialize());
	}
}

export class TakeoverExpertModel {
	public clientEid: string;
	public fiscalNumber: string;
	public companyName: string;
	public accountingTakeoverStatus: string;
	public payrollTakeoverStatus: string;
	public accountingTakeoverEid: string;
	public payrollTakeoverEid: string;
	public hasTakeover: boolean;
	public accountingTakeoverFinished: boolean;
	public payrollTakeoverFinished: boolean;
	public apCode: string;
	public expertName: string;
	public lastClosedMonth: number;
	public serviceStartDate: number;
	public lastClosedMonthString: string;
	public serviceStartDateString: string;
	initialize(): void {
		if (this.lastClosedMonth !== 20171231) {
			this.lastClosedMonthString = intYYYYMMDDToSimpleMMYYYYString(this.lastClosedMonth);
		} else {
			this.lastClosedMonthString = '-';
		}
    this.serviceStartDateString=intYYYYMMDDToSimpleMMYYYYString(this.serviceStartDate);
	}

	@Exclude()
	public accountingTakeoverStatusDisplay: string;
	public payrollTakeoverStatusDisplay: string;
}
