// import { VariableAst } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { RestService } from '@saliente/common';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApolloResult } from '../../general/gql-codegen.types';

export type Accounts_SpecialVatRepresentationInput = {
	code?: string;
	endDate?: number;
	externalId?: string;
	startDate?: number;
};
export type Accounts_GetClientFiscalInfoSummaryUsingGet = {
	caenCode?: string;
	caenDescription?: string;
	clientEid?: string;
	clientName?: string;
	clientNameNa?: string;
	fiscalNumber?: string;
	specialVAT?: Accounts_SpecialVatRepresentationInput;
	hasStatutoryReportsDefinitions?: boolean;
  hasActiveStatutoryReportVatDefinition?: boolean;
  vatPeriodicity?: string;
  vatPayerEndsRquestedMonth?: boolean;
	isInactive?: boolean;
	isVATPayer?: boolean;
	isVATPayerOnCollection?: boolean;
	vatPayerStartDate?: number;
	vatPayerEndDate?: number;
	vatOnCollectionStartDate?: number;
	vatOnCollectionEndDate?: number;
  hasOSS?: boolean;
  hasOSSStartingWithDate?: number;
  lastClosedMonth?: number;
};

export type Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQueryVariables = {
	clientEid: string;
	forMonth: number;
};

export type Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQuery = {
	accounts?: {
		getClientFiscalInfoSummaryForMonthUsingGET?: Accounts_GetClientFiscalInfoSummaryUsingGet;
	};
};
export const Accounts_GetClientFiscalInfoSummaryForMonthUsingGetDocument = gql`
	query accounts_getClientFiscalInfoSummaryUsingGET($clientEid: String, $forMonth: Int) {
		accounts {
			getClientFiscalInfoSummaryForMonthUsingGET(clientEid: $clientEid, forMonth: $forMonth) {
				caenCode
				caenDescription
				clientEid
				clientName
				clientNameNa
				fiscalNumber
				specialVAT {
					externalId
					code
					startDate
					endDate
				}
				hasStatutoryReportsDefinitions
        hasActiveStatutoryReportVatDefinition
        vatPeriodicity
        vatPayerEndsRquestedMonth
				isInactive
				isVATPayer
				isVATPayerOnCollection
				vatPayerStartDate
				vatPayerEndDate
				vatOnCollectionStartDate
				vatOnCollectionEndDate
        hasOSS
        hasOSSStartingWithDate
        lastClosedMonth
			}
		}
	}
`;
@Injectable()
export class CompanyFiscalSummaryService {
	constructor(private restService: RestService) {}

	public getCompanyFiscalSummary(variables: Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQueryVariables): Observable<Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQuery> {
		return this.restService
			.query({
				query: Accounts_GetClientFiscalInfoSummaryForMonthUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloResult<Accounts_GetClientFiscalInfoSummaryForMonthUsingGetQuery>) => {
					if (res) {
						return res.data;
					}
					return null;
				})
			);
	}
}
