<section class="row m-0">
	<form #deleteDocumentForm="ngForm" class="d-flex flex-wrap col-24 px-0">
		<span *ngIf="model.warning" class="col-24 mb-3">{{model.warning}}</span>
		<kee-textarea id="txtComment" name="comment" class="col-24" i18n-label="@@DeleteDocumentFile$Comment" label="Motiv" [(ngModel)]="model.comment"
		 required></kee-textarea>
		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="this.save.emit(deleteDocumentForm)">
				<i class="mdi-action-delete"></i>
				<span i18n="@@Actions$Delete">Șterge</span>
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" (click)="this.close.emit(deleteDocumentForm)">
				<i class="mdi-content-clear"></i>
				<span i18n="@@Actions$Cancel">Renunță</span>
			</button>
		</div>
	</form>
</section>