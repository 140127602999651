import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientFormsRoutingModule } from './client-forms.routes';

@NgModule({
    declarations: [],
    imports: [
      CommonModule,
	    ClientFormsRoutingModule
    ]
  })
  export class ClientFormsModule { }
