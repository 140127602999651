import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';
import { CommonNotificationsSharedModule } from '@saliente/common-notifications';

import { ClientsRouterModule } from './clients.routes';
import { ClientsMenuService } from './menu/menu.service';

@NgModule({
	imports: [
		CommonModule, BrowserModule, FormsModule,
		SalienteLibrarySharedModule, MasterViewModule, CommonNotificationsSharedModule, ClientsRouterModule,
	],
	providers: [ClientsMenuService]
})
export class ClientsModule {
	constructor(private menuService: ClientsMenuService) {
	}
}
