import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { plainToClass } from 'class-transformer';

import { SpinnerService } from '@saliente/library';
import { ConfigService, RestService, DownloadService, UploadService, } from '@saliente/common';
import { AuthService, } from '@saliente/common-auth';
import { DocumentsService, DocumentPageModel, } from '@saliente/common-documents';
import { IClientDocumentsService } from './documents.service';
import { PayrollDocumentFileModel } from './documents.models';


const documentPartialQueryText = `
	created { dateTime, userName }
	documentDate
	eid
	clientEid
	status { statusCode, dateTime }
	metadata
	pages { pageEid, visible }
	totalPages
	comments{ dateTime, comment }
	created { dateTime, userEid, userName }
`;

const payrollDocumentsQueryText = gql`
query payrollDocumentsData($clientEid: String!, $filter: String!, $sort: String, $offset: Int, $count: Int){
	documents{
	  GetPayrollDocumentsUsingGET(clientEid:$clientEid, filter:$filter, sort:$sort, offset:$offset, count:$count){
		${documentPartialQueryText}
	  }
	}
}
`;

const payrollDocumentQueryText = gql`
query payrollDocumentData($docEid: String!) {
  documents {
    GetPayrollDocumentUsingGET(docEid: $docEid) {
	  ${documentPartialQueryText}
    }
  }
}
`;

@Injectable()
export class ClientPayrollDocumentsService extends DocumentsService<PayrollDocumentFileModel, DocumentPageModel> implements IClientDocumentsService {

	constructor(configService: ConfigService, restService: RestService, authService: AuthService,
		downloadService: DownloadService, uploadService: UploadService, spinner: SpinnerService,) {

		super(configService, restService, authService, downloadService, uploadService, spinner);

	}

	protected getDocumentsCategory(): string {
		return 'PayrollDocs';
	}

	protected receivedStatusName(plural: boolean = false): string {
		return plural ? 'Neprocesate' : 'Neprocesat';
	}

	protected bookedStatusName(plural: boolean = false): string {
		return plural ? 'Procesate' : 'Procesat';
	}

	protected getDocumentsQueryText() {
		return payrollDocumentsQueryText;
	}

	protected getDocumentsQueryResponse(response: any) {
		return response.data.documents.GetPayrollDocumentsUsingGET;
	}

	protected getDocumentQueryText() {
		return payrollDocumentQueryText;
	}

	protected getDocumentQueryResponse(response: any) {
		return response.data.documents.GetPayrollDocumentUsingGET;
	}

	protected plainToDocument(data: any): PayrollDocumentFileModel {
		return plainToClass(PayrollDocumentFileModel, data);
	}

	protected plainToDocumentArray(data: any[]): PayrollDocumentFileModel[] {
		return plainToClass(PayrollDocumentFileModel, data);
	}
}
