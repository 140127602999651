import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@saliente/common-auth';
import { ESHOP_ROUTES } from './eshop.routes.constants';

const routes: Routes = [
	{

		path: ESHOP_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: ESHOP_ROUTES.ORDERS,
				data: { accessRole: 'CLIENT_ADMIN' },
				loadChildren: () => import('./eshop-orders/eshop-orders.module').then(m => m.EshopOrdersModule)
			},
			{
				path: ESHOP_ROUTES.PRODUCTS,
				data: { accessRole: 'CLIENT_ADMIN' },
				loadChildren: () => import('./eshop-products/eshop-products.module').then(m => m.EshopProductsModule)
			}
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EshopRoutingModule { }