import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EshopRoutingModule } from './eshop.routes';



@NgModule({
	declarations: [
	],
	imports: [
		CommonModule,
		EshopRoutingModule,
	]
})
export class EshopModule {
}
