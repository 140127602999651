import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAYROLL_ROUTES } from '.';
import { AuthGuard } from '@saliente/common-auth';

const routes: Routes = [
  {
    path: PAYROLL_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
      {
				path: PAYROLL_ROUTES.EMPLOYEES,
				data: { accessRole: 'EMPLOYEE_VIEW' },
				loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule)
			},
      { 
        path: PAYROLL_ROUTES.CALCULATION_ROOT,
        loadChildren: () => import('./calculation/calculation.module').then(m => m.CalculationModule)
      },
      {
				path: PAYROLL_ROUTES.SICK_LEAVE,
				loadChildren: () => import('./sick-leave/sick-leave.module').then(m => m.SickLeaveModule)
			},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollRoutingModule { }
