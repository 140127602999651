import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';

import { AuthRouterModule } from './auth.routes';

//const SERVICES = [];

@NgModule({
	imports: [CommonModule, FormsModule, SalienteLibrarySharedModule, MasterViewModule, AuthRouterModule],
	// providers: [...SERVICES],
})
export class AuthModule {}
