import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, from } from "rxjs";
import { EFacturaStatisticsModel } from "src/app/e-factura/e-factura.models";
import { AuthService } from "../auth/auth.service";
import { RestService } from "./rest.service";
import { GetEFacturaStatisticsGQL } from "src/app/e-factura/adapters/eFactura.generated";
import { plainToClass } from "class-transformer";

@Injectable()
export class EFacturaServiceCommon {
    public eFacturaStatistics: BehaviorSubject<EFacturaStatisticsModel>;
	public eFacturaStatisticsSubscription: Subscription;

    constructor(private authService: AuthService, private restService: RestService) {
		this.eFacturaStatistics = new BehaviorSubject<EFacturaStatisticsModel>(null);
	}

    public async getEFacturaStats(clientEid: string): Promise<EFacturaStatisticsModel> {
		let qry = new GetEFacturaStatisticsGQL(this.restService);
		let queryData  = await qry.fetch(
			{
				clientEid: clientEid,
			}
		).toPromise();

		if (queryData  != null) {
			let result = plainToClass(EFacturaStatisticsModel, queryData.data.accounts.getEFacturaStatisticsUsingGET);
			return result;
		}

		return null;
	}

	public getEFacturaStatistics() {
		if (this.eFacturaStatisticsSubscription) { this.eFacturaStatisticsSubscription.unsubscribe(); this.eFacturaStatisticsSubscription = null; }

		if (this.authService.user && this.authService.user.selectedCompanyId) {
			this.eFacturaStatisticsSubscription = from(this.getEFacturaStats(this.authService.user.selectedCompanyId)).subscribe((statistics) => {
				if (statistics != null) {
					this.eFacturaStatistics.next(statistics);
				}
			})
		}
	}

	public get inboxNotVerifiedCount(): number {
		if (this.eFacturaStatistics != null && this.eFacturaStatistics.value != null) {	
		  return this.eFacturaStatistics.value.inboxNotVerifiedCount;
		}
	}

	public get outboxWithErrorCount(): number {
		if (this.eFacturaStatistics != null && this.eFacturaStatistics.value != null) {
		  return this.eFacturaStatistics.value.outboxWithErrorCount;
		}
	}

}