import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UID } from "@saliente/library";
import { PredefinedUserZone } from "../../auth/auth.models";
import { AuthService } from "../../auth/auth.service";
import { ExpertInfoPersonModel, MeetingHistoryStatus, MeetingRequestDataHistoryModel, MeetingRequestDataModel, MeetingRequestStatus } from "./meetings.model";

const RESCHEDULES_BY_EXPERT: number = 2;

@Component({
    selector: 'meeting-request-dialog',
    templateUrl: 'meeting-request-dialog.html',
  })
export class MeetingRequestDialog implements OnInit {
    @Input() public meetingRequestData: MeetingRequestDataModel;
  	@Input() public expertInfoPersonModel: ExpertInfoPersonModel;

	  @Output() public accept = new EventEmitter<MeetingRequestDataModel>();
	  @Output() public cancel = new EventEmitter<MeetingRequestDataModel>();
	  @Output() public change = new EventEmitter<MeetingRequestDataModel>();
	  @Output() public close = new EventEmitter();

    public isClientAdmin: boolean = false;
    public isExpert: boolean = false;

    public hasProfilePicture: boolean = true;
  	public profileImageGuid: string;

    public showHistoryDialog: boolean = false;
  
    constructor(private authService: AuthService) {
		  this.profileImageGuid = UID();

  		this.isClientAdmin = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
      this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert;
    }

    ngOnInit(): void {}

    public get isExceededTime(): boolean {
      let meetingDateEndTime = this.meetingRequestData.dateEx;
      meetingDateEndTime.setHours(this.meetingRequestData.toTimeHour, this.meetingRequestData.toTimeMinutes);
      return new Date() > meetingDateEndTime;
    }

    public get allowAccept(): boolean {
      return this.meetingRequestData.status == MeetingRequestStatus.Created && !this.isExceededTime &&
          ((this.isExpert && this.meetingRequestData.modifiedByRole == "CLIENT_ADMIN") ||
          (this.isClientAdmin && this.meetingRequestData.modifiedByRole != "CLIENT_ADMIN"));
    }

    public get allowCancel(): boolean {
      return this.isClientAdmin && (this.meetingRequestData.status == MeetingRequestStatus.Created || this.meetingRequestData.status == MeetingRequestStatus.Settled) 
        && !this.isExceededTime;
    }

    public get allowChangeInterval(): boolean {
      return this.meetingRequestData.status == MeetingRequestStatus.Created && !this.isExceededTime;
    }

    public get hasHistory() {
      return this.meetingRequestData.history && this.meetingRequestData.history.length > 0;
    }

    public get meetingHasExpired(): boolean {
      return this.meetingRequestData.status == MeetingRequestStatus.Expired;
    }

    public get getLastHistoryLine(): MeetingRequestDataHistoryModel {
      if (this.hasHistory) {
        return this.meetingRequestData.history[0];
      }
    }

    public get getLastClientHistory(): MeetingRequestDataHistoryModel{
      let client = this.meetingRequestData.history.find((h) => h.role == "CLIENT_ADMIN");
      if (client == undefined) {
        return this.getLastHistoryLine;
      }
      return client;
    }

    public get getLastExpertHistory(): MeetingRequestDataHistoryModel | ExpertInfoPersonModel {
      let expert = this.meetingRequestData.history.find((h) => h.role == "EXPERT");
      if (expert == undefined) {
        return this.expertInfoPersonModel;
      }
      return expert;
    }

    public getProfilePicture() {
      if (this.isClientAdmin) {
        if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.ProposedNewTime) {
          if (this.getLastHistoryLine.userEid == this.authService.user.externalId) {
            return '/users/' + this.getLastExpertHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
          }
          else if (this.getLastHistoryLine.userEid == this.getLastExpertHistory.userEid) {
            return '/users/' + this.getLastExpertHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
          }
          else {
            return '/users/' + this.getLastClientHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
          }
        }
        else {
          return '/users/' + this.getLastExpertHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
        }
      }
      
      if (this.isExpert) {
        return '/users/' + this.getLastClientHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
      }
    }

    public getInitialLetters() {
      if (this.isClientAdmin) {
          if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.ProposedNewTime) {
            if (this.getLastHistoryLine.userEid == this.authService.user.externalId) {
              return this.getLastExpertHistory.initialLetters;
            }
            else if (this.getLastHistoryLine.userEid == this.getLastExpertHistory.userEid) {
              return this.getLastExpertHistory.initialLetters;
            }
            else {
              return this.getLastClientHistory.initialLetters
            }
          }
          else {
            return this.getLastExpertHistory.initialLetters;
          }
      }
      
      if (this.isExpert) {
        return this.getLastClientHistory.initialLetters;
      }
    }

    public getMeetingText() {
      if (this.isClientAdmin) {
        if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.Accepted) {
          return `Întâlnirea cu ${this.getLastExpertHistory.userName} a fost programată.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.Canceled) {
          return `Întâlnirea cu ${this.getLastExpertHistory.userName} a fost anulată.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.AutoAccepted) {
          return `Întâlnirea cu ${this.getLastExpertHistory.userName} a fost programată automat.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.ProposedNewTime) {
          if (this.getLastHistoryLine.userEid == this.authService.user.externalId) {
            return `Ați cerut reprogramarea întâlnirii cu ${this.getLastExpertHistory.userName}:`;
          }
          else if (this.getLastHistoryLine.userEid == this.getLastExpertHistory.userEid) {
            return `${this.getLastExpertHistory.userName} a cerut reprogramarea întâlnirii: `;
          }
          else {
            return `${this.getLastClientHistory.userName} a cerut reprogramarea întâlnirii cu ${this.getLastExpertHistory.userName}: `;
          }
        }
        else {
          if (this.getLastHistoryLine.userEid == this.authService.user.externalId) {
            return `Ați cerut o întâlnire cu ${this.getLastExpertHistory.userName}:`;
          }
          else {
            return `${this.getLastHistoryLine.userName} a cerut o întâlnire cu ${this.getLastExpertHistory.userName}:`
          }
        }
      }

      if (this.isExpert) {
        if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.Accepted) {
          return `Întâlnirea cu ${this.getLastClientHistory.userName} a fost programată.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.Canceled) {
          return `Întâlnirea cu ${this.getLastClientHistory.userName} a fost anulată.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.AutoAccepted) {
          return `Întâlnirea cu ${this.getLastClientHistory.userName} a fost programată automat.`;
        }
        else if (this.getLastHistoryLine.eventType == MeetingHistoryStatus.ProposedNewTime) {
          if (this.getLastHistoryLine.userEid == this.getLastExpertHistory.userEid) {
            return `Ați cerut reprogramarea întâlnirii cu ${this.getLastClientHistory.userName}:`
          }
          else {
            return `${this.getLastClientHistory.userName} a cerut reprogramarea întâlnirii: `;
          }
        }
        else {
          return `${this.getLastClientHistory.userName} a cerut o întâlnire: `;
        }
      }
    }

    public viewHistoryDialog() {
      this.showHistoryDialog = true;
    }

    public closeHistoryDialog() {
      this.showHistoryDialog = false;
    }

    public onProfileImageError(event: any) {
      this.hasProfilePicture = false;
    }

    public acceptMeetingRequest() {
      this.accept.emit(this.meetingRequestData);
    }

    public cancelMeetingRequest() {
      this.cancel.emit(this.meetingRequestData);
    }

    public changeMeetingRequest() {
      this.change.emit(this.meetingRequestData);
    }
}