<form #timeSlotsForm="ngForm">
    <div class="col-24 d-flex flex-wrap px-0 pb-2 align-items-center">
        <div class="col d-flex flex-wrap px-0 align-items-center">
            <img *ngIf="hasProfilePicture" src="{{ getProfilePicture() }}" class="profile-image" 
                (error)="onProfileImageError($event)"/>
            <div *ngIf="!hasProfilePicture" class="d-flex initialLetters">{{ getInitialLetters() }}</div>
            <div class="col pl-4">
                <h4 class="mb-0">{{ getMeetingTitle() }}</h4>
                <div class="col-24 px-0">{{ getLastMeetingInfo() }}</div>
            </div>
        </div>
		<i class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
    </div>
    <div class="col-24 d-flex flex-wrap px-0">
        <div class="col-10 px-0 calendar">
            <kendo-calendar #meetingCalendar type="infinite" [min]="minStartDate" [max]="endDate" [value]="meetingRequest.dateEx" [disabledDates]="disabledDates"
                (navigate)="onCalendarNavigate($event)" (activeViewDateChange)="activeViewChange($event)" (valueChange)="onDateChange($event)">
            </kendo-calendar>
        </div>
        <div class="col-14 px-0">
            <div class="col-24 px-0 font-weight-bold selected-date"> {{ selectedDate(meetingRequest.date) }}</div>
			<div class="row divider mb-2 mr-5" [style]="'margin-top: 0.7rem'"></div>
            <perfect-scrollbar class="col-24 d-flex flex-wrap px-0 justify-content-center max-height-400">
                <div class="col-24 d-flex flex-wrap px-0 justify-content-center max-height-400">
                    <!-- <ng-container *ngIf="meetingTimeSlotsByDate.length == 0">
                        <div class="col-24 d-flex flex-wrap">Data selectată este o zi nelucrătoare!</div>
                    </ng-container> -->
                    <ng-container *ngIf="meetingTimeSlotsByDate.length > 0">
                        <ng-container *ngFor="let slot of meetingTimeSlotsByDate">
                            <div class="col-6 px-0 py-2 mx-2 my-2 slot cursor-pointer" [ngClass]="{'slot-selected': slot.isSelected}" (click)="onSlotSelect(slot)">
                                {{ slot.fromTimeFormat }}
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <div class="col-24 d-flex flex-wrap px-0 pt-2">
        <!-- <div class="col-24 d-flex flex-wrap">Agenda întâlnirii (opțional)</div> -->
        <kee-textarea name="comment" class="col-24 comment no-resize pt-3" i18n-label="@@TimeSlots$Comment" label="Agenda întâlnirii (opțional)" rows="3" maxlength="512"
            [(ngModel)]="meetingRequest.comment" [readonly]="fromProposal">
        </kee-textarea>
    </div>
    <div class="col-24 d-flex flex-wrap align-items-center justify-content-center pt-4">
        <button class="col-7 btn btn-primary waves-effect waves-light" [ngClass]="{'button-disabled': selectedMeetingTimeSlot == null}" [disabled]="selectedMeetingTimeSlot == null"
            (click)="saveInterval(timeSlotsForm)">
          <i class="mdi-action-done mr-2"></i>{{ fromProposal ? 'Confirmă' : 'Cere întâlnire' }}</button>
    </div>
</form>