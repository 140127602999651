import { Injectable } from '@angular/core';

import { map, } from 'rxjs/operators';

import gql from 'graphql-tag';

import { dateToInt, intToDate } from '@saliente/library';
import { RestService, } from '@saliente/common';

const accuracyCheckCountersQueryText = gql`
query accuracyCheckCounters($clientEid: String!, $startDate: Int, $endDate: Int){
	subs{
		documentsCountersUsingGET(clientEid:$clientEid, startDate:$startDate, endDate:$endDate){
			code
			counter
			name
		}
	}
}`;

const accuracyCheckListQueryText = gql`
query accuracyCheckList($clientEid: String!, $startDate: Int!, $endDate: Int!, $type:String!){
	subs{
		documentsByCodeUsingGET(clientEid:$clientEid, startDate:$startDate, endDate:$endDate, code:$type){
			documentDate
			documentEid
			documentNumber
			employeeName
			entryType
			partenerName
			valueLocalCurrency
	    }
	}
  }
`;

@Injectable()
export class ExpertDocumentsAccuracyService {

	public readonly accuracyTypes = [
		{
			name: 'documentsNotExported',
			dataName: 'documentsNotExportedUsingGET',
			displayName: 'Documente în platformă fără corespondență în sistemul contabil'
		},
		{
			name: 'documentsNotPresent',
			dataName: 'documentsNotPresentUsingGET',
			displayName: 'Documente din sistemul contabil fără corespondență în platformă'
		},
	];

	constructor(private restService: RestService) {

	}

	public countersStream(clientEid: string, startDate: Date, endDate: Date) {
		return this.restService
			.query({
				query: accuracyCheckCountersQueryText,
				variables: {
					clientEid,
					startDate: dateToInt(startDate),
					endDate: dateToInt(endDate)
				},
				fetchPolicy: 'network-only'
			}, { spinner: false })
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.subs.documentsCountersUsingGET;
					}
					return [];
				})
			);
	}

	public documentsStream(clientEid: string, type: string, startDate: Date, endDate: Date) {
		return this.restService
			.query({
				query: accuracyCheckListQueryText,
				variables: {
					clientEid,
					type,
					startDate: dateToInt(startDate),
					endDate: dateToInt(endDate)
				},
				fetchPolicy: 'network-only'
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.subs.documentsByCodeUsingGET.map((item: any) => {
							return {
								id: item.id,
								documentEid: item.documentEid,
								entryType: item.entryType,
								employeeName: item.employeeName,
								documentNumber: item.documentNumber,
								documentDate: intToDate(item.documentDate),
								partnerName: item.partenerName,
								value: item.valueLocalCurrency
							}
						});
					}
					return [];
				})
			);
	}
}