import { Component, EventEmitter, Input, Output, OnInit, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { LabeledControl } from '../../labeledcontrol';
import { DropDownListComponent, PopupSettings } from '@progress/kendo-angular-dropdowns';
// const getter: any = require('@progress/kendo-angular-dropdowns/dist/es/util').getter;

export const DROPDOWN_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeDropDownComponent),
	multi: true
};

/*(<any>DropDownListComponent).prototype.componentBlur = function () {
	if (getter(this._previousValue, this.valueField) !== getter(this.dataItem, this.valueField)) {
		this.valueSubject.next(this.dataItem);
	}
	else {
		this.popupOpen = false;
	}
	if (this.isFocused) {
		this.isFocused = false;
		this.onBlur.emit();
		this.onTouchedCallback();
	}
};*/

@Component({
	selector: 'kee-dropdown',
	templateUrl: 'dropdown.component.html',
	providers: [DROPDOWN_VALUE_ACCESSOR],
})
export class KeeDropDownComponent extends LabeledControl<any> implements OnInit {

	@Input() public data: any;
	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public valuePrimitive: boolean;

	@Input() public ngModelOptions: any = { updateOn: 'change' };
	@Input() public popupSettings: PopupSettings = { animate: true };
	@Input() public listHeight: number = 300;
	@Input() public filterable: boolean;

	@Output() public selectionChange = new EventEmitter<any>();

	public constructor(injector: Injector, ) {
		super(injector);
	}

	public ngOnInit() {
		super.ngOnInit();
	}
}