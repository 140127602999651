import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';

import { SubsRouterModule } from './subs.routes';
import { SubsMenuService } from './menu/menu.service';

@NgModule({
	imports: [
		CommonModule, FormsModule,
		SalienteLibrarySharedModule, MasterViewModule, SubsRouterModule
	],
	providers: [SubsMenuService]
})
export class SubcontractorModule {
	constructor(private menuService: SubsMenuService) {
	}
}
