import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@saliente/common-auth';
import { SUBS_ROUTES } from './subs.routes.constants';

const routes: Routes = [
	{
		path: SUBS_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		data: {
			expectedRole: 'subcontractor'
		},
		children: [
			{
				path: '',
				loadChildren: () => import('./home/subs-home.module').then(m => m.SubsHomeModule)
			},
			{
				path: SUBS_ROUTES.DOCUMENTS,
				loadChildren: () => import('./documents/subs-documents.module').then(m => m.SubsDocumentsModule)
			},
			{
				path: SUBS_ROUTES.CASH_OUT,
				loadChildren: () => import('./cash-out/cash-out.module').then(m => m.SubsCashOutModule)
			},
			{
				path: SUBS_ROUTES.REJECTED_DOCUMENTS,
				loadChildren: () => import('./rejected-documents/subs-rejected-documents.module').then(m => m.SubsRejectedDocumentsModule)
			},
		]
	}
];

// @dynamic
@NgModule({
	imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
	exports: [RouterModule]
})
export class SubsRouterModule {

}
