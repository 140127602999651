import { Injectable } from "@angular/core";
import gql from 'graphql-tag';
import { catchError, map } from 'rxjs/operators';
import { RestService } from '../general/rest.service';
import { AuthService } from '../auth/auth.service';
import { AppMenuService } from "../appmenu/appmenu.service";
import { Observable, of, } from "rxjs";
import { UserFeedbackModel, UserRatingModel } from "./rating.models";
import { classToPlain } from "class-transformer";

const ratingNeedQueryText = gql`
  query ratingNeed {
    auth {
      dueRatingUsingGET {
        needRating
      }
    }
  }
`;

const saveRating = gql`
mutation setRating($data: auth_UserRatingRequestRepresentationInput){
	auth {
		setRatingUsingPOST(data:$data){
			Message
		}
	}
}
`;

const saveFeedback = gql`
mutation setFeedback($data: auth_UserFeedbackRequestRepresentationInput){
	auth {
		reportFeedbackUsingPOST(data:$data){
			Message
		}
	}
}
`;
@Injectable()
export class RatingService {

    constructor(private restService: RestService, private authService: AuthService, public appMenuService: AppMenuService) {}

    public checkNeedForRating(): Observable<boolean> {
        return this.restService
        .query({
            query: ratingNeedQueryText,
            fetchPolicy: "network-only",
        }, { spinner: false })
        .pipe(
            map((response: any) => {
                return response.data.auth.dueRatingUsingGET.needRating;
            }),
            catchError((error) => {
                return of(false);
            })
        )
    }

    public saveRating(rating: UserRatingModel): Observable<boolean> {
      return this.restService
        .mutate({
          mutation: saveRating,
          variables: {
            data: classToPlain(rating, { excludePrefixes: ["__"] })
          }
        }, { spinner: false })
        .pipe(
          map((response: any) => {
            if (response) { //response.data.auth.setRatingUsingPOST.Message == ''
              return true;
            }
            return false;
          })
        );
    }

    public saveFeedback(feedback: UserFeedbackModel): Observable<boolean> {
      return this.restService
        .mutate({
          mutation: saveFeedback,
          variables: {
            data: classToPlain(feedback, { excludePrefixes: ["__"] })
          }
        }, { spinner: false })
        .pipe(
          map((response: any) => {
            if (response) {
              return true;
            }
            return false;
          })
        );
    }
}