import { Component } from '@angular/core';
// import { Observable } from '@apollo/client';

import { ConfigService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UnixTimeService } from './common/general/unix-time.service';

@Component({
	selector: 'my-app',
	templateUrl: 'app.component.html',
})
export class AppComponent {
	constructor(public configService: ConfigService, private authService: AuthService, private unixTimeService: UnixTimeService) {

		this.unixTimeService
			.getServerUnixTime()
			.pipe(
				switchMap((res) => {
					if (Math.abs(res.accounts.getServerUnixTimeUsingGET.unixTime - Date.now() / 1000) > 600) {
						/*- more than 5 minutes difference*/
						this.configService.pageUnixTimeError();
						return EMPTY; //stop propagation
					} else {
						return of(true);
					}
				}),
        switchMap(() => {
          return this.configService.getAuthUrl();
        }
			))
			.subscribe((issuer) => {
				this.authService.configure({
					issuer: issuer,
					requireHttps: true,
					redirectUri: window.location.origin + '/',
					silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
					clientId: 'keez.ro',
					scope: 'saliente',
					showDebugInformation: false,
					sessionChecksEnabled: false,
				});

				const accountingPartner = this.configService.accountingPartner;
				if (accountingPartner) {
					this.authService.customQueryParams = {
						AccountingPartner: accountingPartner,
						campaign: this.configService.campaign,
					};
				}
			});

		/* while this works it is not fully correct since this.configService.getAuthUrl().subscribe((issuer) will be called till the getServerUnixTime completes...
    We should have used a pipe wit early cancellation if unixTimes do not match (like returning EMPTY ?)
    */
    /*	
    (async (): Promise<void> => {
			const serverUnixTime = (await this.unixTimeService.getServerUnixTime().toPromise()).accounts.getServerUnixTimeUsingGET.unixTime;
			if (Math.abs(serverUnixTime - Date.now() / 1000) > 600) {
				// more than 5 minutes difference
				this.configService.pageUnixTimeError();
			}
		})();
		this.configService.getAuthUrl().subscribe((issuer) => {
			this.authService.configure({
				issuer: issuer,
				requireHttps: true,
				redirectUri: window.location.origin + '/',
				silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
				clientId: 'keez.ro',
				scope: 'saliente',
				showDebugInformation: false,
				sessionChecksEnabled: false,
			});

			const accountingPartner = this.configService.accountingPartner;
			if (accountingPartner) {
				this.authService.customQueryParams = {
					AccountingPartner: accountingPartner,
					campaign: this.configService.campaign,
				};
			}
		});
    */
	}
}
