<div class="kee-spinner overlay {{visible ? 'show' : ''}}">
	<div class="container">
		<div class="content">
			<div class="rect1"></div>
			<div class="rect2"></div>
			<div class="rect3"></div>
			<div class="rect4"></div>
			<div class="rect5"></div>
		</div>
	</div>
</div>