import { Injectable } from '@angular/core';
import { ConfigService, DownloadService, RestService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import { SpinnerService } from '@saliente/library';
import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Maybe, Scalars, Exact, ApolloResult } from '../general/gql-codegen.types';
import { ApolloQueryResult } from '@apollo/client/core';

export const Subs_GetAnafMessagesUsingGetDocument = gql`
	query subs_getAnafMessagesUsingGET($clientEid: String!) {
		subs {
			getAnafMessagesUsingGET(clientEid: $clientEid) {
				anafRequest {
					anRaportare
					anafDemandDefinition {
						code
						defAnRaportare
						defCuiSecundar
						defIndexRecipisa
						defLunaInceput
						defLunaRaportare
						defLunaSfarsit
						externalId
						isActive
						valLunaRaportare
            useSsn
					}
					confirmationIndex
					cui
					cuiSecundar
					externalId
					indexRecipisa
					lunaInceput
					lunaRaportare
					lunaSfarsit
					momentRequested
					requestedByUserEid
					isRequestedByExpert
					type
				}
				clientEid
				companyName
				companyNameNa
				details
				displayDate
				documentEid
				documentType
				externalId
				fiscalNumber
				id
				isActive
				isClientVisible
				isClientImportant
				isExpertImportant
				isFinalized
				comment
				sortBy
				type
			}
		}
	}
`;

export type Subs_GetAnafMessagesUsingGet_Items = {
	anafRequest?: Maybe<Subs_GetAnafMessagesUsingGet_Items_AnafRequest>;
	clientEid?: Maybe<Scalars['String']>;
	companyName?: Maybe<Scalars['String']>;
	companyNameNa?: Maybe<Scalars['String']>;
	details?: Maybe<Scalars['String']>;
	displayDate?: Maybe<Scalars['String']>;
	documentEid?: Maybe<Scalars['String']>;
	documentType?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	fiscalNumber?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	isActive?: Maybe<Scalars['Boolean']>;
	sortBy?: Maybe<Scalars['Int']>;
	isClientVisible?: Maybe<Scalars['Boolean']>;
	isClientImportant?: Maybe<Scalars['Boolean']>; // visibility rules
	isExpertImportant?: Maybe<Scalars['Boolean']>; // visibility rules
	isFinalized?: Maybe<Scalars['Boolean']>; // somatii/popriri
	comment?: Maybe<Scalars['String']>; // somatii/popriri

	type?: Maybe<Scalars['String']>;
};

export type Subs_GetAnafMessagesUsingGet_Items_AnafRequest = {
	anRaportare?: Maybe<Scalars['Int']>;
	anafDemandDefinition?: Maybe<Subs_GetAnafMessagesUsingGet_Items_AnafRequest_AnafDemandDefinition>;
	confirmationIndex?: Maybe<Scalars['Int']>;
	cui?: Maybe<Scalars['String']>;
	cuiSecundar?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	indexRecipisa?: Maybe<Scalars['String']>;
	lunaInceput?: Maybe<Scalars['Int']>;
	lunaRaportare?: Maybe<Scalars['Int']>;
	lunaSfarsit?: Maybe<Scalars['Int']>;
	momentRequested?: Maybe<Scalars['String']>;
	requestedByUserEid?: Maybe<Scalars['String']>;
	isRequestedByExpert?: Maybe<Scalars['Boolean']>;
	type?: Maybe<Scalars['String']>;
};

export type Subs_GetAnafMessagesUsingGet_Items_AnafRequest_AnafDemandDefinition = {
	code?: Maybe<Scalars['String']>;
	defAnRaportare?: Maybe<Scalars['String']>;
	defCuiSecundar?: Maybe<Scalars['String']>;
	defIndexRecipisa?: Maybe<Scalars['String']>;
	defLunaInceput?: Maybe<Scalars['String']>;
	defLunaRaportare?: Maybe<Scalars['String']>;
	defLunaSfarsit?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	isActive?: Maybe<Scalars['Boolean']>;
	valLunaRaportare?: Maybe<Scalars['Int']>;
	useSsn?: Maybe<Scalars['Boolean']>;
};
export type Subs_GetAnafMessagesUsingGetQuery = {
	subs?: {
		getAnafMessagesUsingGET?: Subs_GetAnafMessagesUsingGet_Items[];
	};
};
/* -----------------Definitions------------------------- */
export type Subs_GetAnafDemandDefinitionsUsingGet_Items = {
	code?: Maybe<Scalars['String']>;
	defAnRaportare?: Maybe<Scalars['String']>;
	defCuiSecundar?: Maybe<Scalars['String']>;
	defIndexRecipisa?: Maybe<Scalars['String']>;
	defLunaInceput?: Maybe<Scalars['String']>;
	defLunaRaportare?: Maybe<Scalars['String']>;
	defLunaSfarsit?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	isActive?: Maybe<Scalars['Boolean']>;
	valLunaRaportare?: Maybe<Scalars['Int']>;
  useSsn?: Maybe<Scalars['Boolean']>;

};

export type Subs_GetAnafDemandDefinitionsUsingGetQuery = {
	subs?: {
		getAnafDemandDefinitionsUsingGET?: Subs_GetAnafDemandDefinitionsUsingGet_Items[];
	};
};

export const Subs_GetAnafDemandDefinitionsUsingGetDocument = gql`
	query subs_getAnafDemandDefinitionsUsingGET {
		subs {
			getAnafDemandDefinitionsUsingGET {
				code
				defAnRaportare
				defCuiSecundar
				defIndexRecipisa
				defLunaInceput
				defLunaRaportare
				defLunaSfarsit
				externalId
				isActive
				valLunaRaportare
        useSsn
			}
		}
	}
`;
/*-----------------Create Requests-------------------------*/
/*---Mutation response------------*/
export type Subs_AnafMessageRepresentation = {
	anafRequest?: Maybe<Subs_AnafRequestRepresentation>;
	clientEid?: Maybe<Scalars['String']>;
	companyName?: Maybe<Scalars['String']>;
	companyNameNa?: Maybe<Scalars['String']>;
	details?: Maybe<Scalars['String']>;
	displayDate?: Maybe<Scalars['String']>;
	documentEid?: Maybe<Scalars['String']>;
	documentType?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	fiscalNumber?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	isActive?: Maybe<Scalars['Boolean']>;
	sortBy?: Maybe<Scalars['Int']>;
	type?: Maybe<Scalars['String']>;
};

export type Subs_AnafRequestRepresentation = {
	anRaportare?: Maybe<Scalars['Int']>;
	anafDemandDefinition?: Maybe<Subs_AnafDemandDefinitionRepresentation>;
	confirmationIndex?: Maybe<Scalars['Int']>;
	cui?: Maybe<Scalars['String']>;
	cuiSecundar?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	indexRecipisa?: Maybe<Scalars['String']>;
	lunaInceput?: Maybe<Scalars['Int']>;
	lunaRaportare?: Maybe<Scalars['Int']>;
	lunaSfarsit?: Maybe<Scalars['Int']>;
	momentRequested?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
};

export type Subs_AnafDemandDefinitionRepresentation = {
	code?: Maybe<Scalars['String']>;
	defAnRaportare?: Maybe<Scalars['String']>;
	defCuiSecundar?: Maybe<Scalars['String']>;
	defIndexRecipisa?: Maybe<Scalars['String']>;
	defLunaInceput?: Maybe<Scalars['String']>;
	defLunaRaportare?: Maybe<Scalars['String']>;
	defLunaSfarsit?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	isActive?: Maybe<Scalars['Boolean']>;
	valLunaRaportare?: Maybe<Scalars['Int']>;
	useSsn?: Maybe<Scalars['Boolean']>;
};
/*---------Mutation request------------*/

export type Subs_AnafRequestCreateRepresentationInput = {
	anRaportare?: Maybe<Scalars['Int']>;
	anafDemandDefinitionEid?: Maybe<Scalars['String']>;
	confirmationIndex?: Maybe<Scalars['Int']>;
	cui?: Maybe<Scalars['String']>;
	cuiSecundar?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	indexRecipisa?: Maybe<Scalars['String']>;
	lunaInceput?: Maybe<Scalars['Int']>;
	lunaRaportare?: Maybe<Scalars['Int']>;
	lunaSfarsit?: Maybe<Scalars['Int']>;
	momentRequested?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
};

export type Subs_CreateAnafMessageRequestUsingPostMutationVariables = Exact<{
	clientEid?: Maybe<Scalars['String']>;
	request?: Maybe<Subs_AnafRequestCreateRepresentationInput>;
}>;
export type Subs_CreateAnafMessageRequestUsingPostMutation = {
	subs?: {
		createAnafMessageRequestUsingPOST?: Subs_AnafMessageRepresentation;
	};
};

export const Subs_CreateAnafMessageRequestUsingPostDocument = gql`
	mutation subs_createAnafMessageRequestUsingPOST($clientEid: String, $request: subs_AnafRequestCreateRepresentationInput) {
		subs {
			createAnafMessageRequestUsingPOST(clientEid: $clientEid, request: $request) {
				anafRequest {
					anRaportare
					anafDemandDefinition {
						code
						defAnRaportare
						defCuiSecundar
						defIndexRecipisa
						defLunaInceput
						defLunaRaportare
						defLunaSfarsit
						externalId
						isActive
						valLunaRaportare
            useSsn
					}
					confirmationIndex
					cui
					cuiSecundar
					externalId
					indexRecipisa
					lunaInceput
					lunaRaportare
					lunaSfarsit
					momentRequested
					requestedByUserEid
					type
				}
				clientEid
				companyName
				companyNameNa
				details
				displayDate
				documentEid
				documentType
				externalId
				fiscalNumber
				id
				isActive
				sortBy
				type
			}
		}
	}
`;

/*------------------------------Refresh Request--------------------------*/

export type Subs_SuccessResponse = {
	Message?: Maybe<Scalars['String']>;
};
export type Subs_CreateAnafRefreshRequestUsingPostMutation = {
	subs?: Subs_SuccessResponse;
};
export type Subs_CreateAnafRefreshRequestUsingPostMutationVariables = Exact<{
	clientEid?: Maybe<Scalars['String']>;
}>;

export const Subs_CreateAnafRefreshRequestUsingPostDocument = gql`
	mutation subs_createAnafRefreshRequestUsingPOST($clientEid: String) {
		subs {
			createAnafRefreshRequestUsingPOST(clientEid: $clientEid) {
				Message
			}
		}
	}
`;
/*----------------------------------Change visibility--------------------------------*/

export type Subs_ChangeAnafMessageClientVisibilityUsingPutMutationVariables = Exact<{
	messageEid?: Maybe<Scalars['String']>;
	newVisibility?: Maybe<Scalars['Boolean']>;
}>;
export type Subs_ChangeAnafMessageClientVisibilityUsingPutMutation = {
	subs?: Subs_SuccessResponse;
};
export const Subs_ChangeAnafMessageClientVisibilityUsingPutDocument = gql`
	mutation subs_changeAnafMessageClientVisibilityUsingPUT($messageEid: String, $newVisibility: Boolean) {
		subs {
			changeAnafMessageClientVisibilityUsingPUT(messageEid: $messageEid, newVisibility: $newVisibility) {
				Message
			}
		}
	}
`;
/*---------------------------------------------------------*/
/*----------------------------------Change finalized--------------------------------*/

export type Subs_ChangeAnafMessageFinalizedUsingPutMutationVariables = Exact<{
	messageEid?: Maybe<Scalars['String']>;
	newFinalized?: Maybe<Scalars['Boolean']>;
}>;
export type Subs_ChangeAnafMessageFinalizedUsingPutMutation = {
	subs?: Subs_SuccessResponse;
};
export const Subs_ChangeAnafMessageFinalizedUsingPutDocument = gql`
	mutation subs_changeAnafMessageClientVisibilityUsingPUT($messageEid: String, $newFinalized: Boolean) {
		subs {
			changeAnafMessageFinalizedUsingPUT(messageEid: $messageEid, newFinalized: $newFinalized) {
				Message
			}
		}
	}
`;
/*---------------------------------------------------------*/
/*----------------------------------Comment--------------------------------*/

export type Subs_AnafMessageUpdateCommentRepresentationInput = {
	comment?: Maybe<Scalars['String']>;
};
export type Subs_UpdateAnafMessageCommentUsingPutMutationVariables = Exact<{
	messageEid?: Maybe<Scalars['String']>;
	request?: Maybe<Subs_AnafMessageUpdateCommentRepresentationInput>;
}>;

export type Subs_UpdateAnafMessageCommentUsingPutMutation = {
	subs?: Subs_SuccessResponse;
};
export const Subs_UpdateAnafMessageCommentUsingPutDocument = gql`
	mutation subs_updateAnafMessageCommentUsingPUT($messageEid: String, $request: subs_AnafMessageUpdateCommentRepresentationInput) {
		subs {
			updateAnafMessageCommentUsingPUT(messageEid: $messageEid, request: $request) {
				Message
			}
		}
	}
`;
/*---------------------------------------------------------*/

/*--------------------AnafMessageNotFinalizedCount---------*/
export const Subs_GetAnafMessageNotFinalizedCountUsingGetDocument = gql`
    query subs_getAnafMessageNotFinalizedCountUsingGET($clientEid: String) {
  subs {
    getAnafMessageNotFinalizedCountUsingGET(clientEid: $clientEid) {
      count
    }
  }
}
`;


export type Subs_GetAnafMessageNotFinalizedCountUsingGetQueryVariables = {
	clientEid: string;
};
export type Subs_AnafMessageNotFinalizedCountRepresentation = {
	count: number;
};
export type Subs_GetAnafMessageNotFinalizedCountUsingGetQuery = {
	subs: {
		getAnafMessageNotFinalizedCountUsingGET: Subs_AnafMessageNotFinalizedCountRepresentation;
	};
};


@Injectable({
  providedIn: 'root',
})
export class AnafMessagesService {
  public somethingChangedBehavior = new BehaviorSubject<boolean>(false);
  public somethingChanged = this.somethingChangedBehavior.asObservable();

	constructor(
		private authService: AuthService,
		private restService: RestService,
	) {
  }

	public getAnafMessages(): Observable<Subs_GetAnafMessagesUsingGetQuery> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.query({
				query: Subs_GetAnafMessagesUsingGetDocument,
				variables: {
					clientEid: clientEid,
				},
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: { data: Subs_GetAnafMessagesUsingGetQuery }) => {
					if (res) {
						try {
              this.somethingChangedBehavior.next(true);
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public requestRefresh(variables: Subs_CreateAnafRefreshRequestUsingPostMutationVariables): Observable<Subs_CreateAnafRefreshRequestUsingPostMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Subs_CreateAnafRefreshRequestUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Subs_CreateAnafRefreshRequestUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public createAnafMessageRequest(variables: Subs_CreateAnafMessageRequestUsingPostMutationVariables): Observable<Subs_CreateAnafMessageRequestUsingPostMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Subs_CreateAnafMessageRequestUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Subs_CreateAnafMessageRequestUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public getAnafDemandDefinitions(): Observable<Subs_GetAnafDemandDefinitionsUsingGetQuery> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.query({
				query: Subs_GetAnafDemandDefinitionsUsingGetDocument,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloResult<Subs_GetAnafDemandDefinitionsUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public changeAnafMessageClientVisibility(
		variables: Subs_ChangeAnafMessageClientVisibilityUsingPutMutationVariables
	): Observable<Subs_ChangeAnafMessageClientVisibilityUsingPutMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Subs_ChangeAnafMessageClientVisibilityUsingPutDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Subs_ChangeAnafMessageClientVisibilityUsingPutMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public changeAnafMessageFinalized(variables: Subs_ChangeAnafMessageFinalizedUsingPutMutationVariables): Observable<Subs_ChangeAnafMessageFinalizedUsingPutMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Subs_ChangeAnafMessageFinalizedUsingPutDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Subs_ChangeAnafMessageFinalizedUsingPutMutation>) => {
					if (res) {
						try {
              this.somethingChangedBehavior.next(true);
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public updateAnafMessageComment(variables: Subs_UpdateAnafMessageCommentUsingPutMutationVariables): Observable<Subs_UpdateAnafMessageCommentUsingPutMutation> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.mutate({
				mutation: Subs_UpdateAnafMessageCommentUsingPutDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Subs_UpdateAnafMessageCommentUsingPutMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

  public getAnafMessageNotFinalizedCount(
		variables: Subs_GetAnafMessageNotFinalizedCountUsingGetQueryVariables
	): Observable<Subs_GetAnafMessageNotFinalizedCountUsingGetQuery> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.query({
				query: Subs_GetAnafMessageNotFinalizedCountUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			}, { spinner: false })
			.pipe(
				map((res: ApolloQueryResult<Subs_GetAnafMessageNotFinalizedCountUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

}
