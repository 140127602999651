import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CalendarComponent, CalendarView } from "@progress/kendo-angular-dateinputs";
import { UID, dateToInt, intToDate, intYYYYMMDDToString } from "@saliente/library";
import { PredefinedUserZone } from "../../auth/auth.models";
import { AuthService } from "../../auth/auth.service";
import { ChatService } from "../chat.service";
import { ExpertInfoPersonModel, MeetingHistoryStatus, MeetingRequestDataHistoryModel, MeetingRequestModel, MeetingRequestStatus, MeetingTimeSlotsModel, TimeSlotStatus } from "./meetings.model";
import { IntlService } from "@progress/kendo-angular-intl";

@Component({
    selector: 'time-slots-dialog',
    templateUrl: 'time-slots-dialog.html',
  })
export class TimeSlotsDialog implements OnInit {
    @Input() public fromProposal: boolean;
    @Input() public meetingRequest: MeetingRequestModel = new MeetingRequestModel();
	@Input() public expertInfoPersonModel: ExpertInfoPersonModel;
    @Input() public meetingInfoLastHistory: MeetingRequestDataHistoryModel[];
 
    @Output() public save = new EventEmitter<{ meetingRequest: MeetingRequestModel, fromProposal: boolean }>();
	@Output() public close = new EventEmitter();
    
    public isClientAdmin: boolean = false;
    public isExpert: boolean = false;

    public minStartDate: Date = new Date();
    public endDate: Date = new Date();
    public meetingTimeSlotsByDate: MeetingTimeSlotsModel[] = [];
    public selectedMeetingTimeSlot: MeetingTimeSlotsModel;

    public disabledDates: Date[];
    public lastMonthDisabledDates: number;

	public profileImageGuid: string;
    public hasProfilePicture: boolean = true;

	@ViewChild('meetingCalendar') private meetingCalendar: CalendarComponent;

    constructor(private chatService: ChatService, private authService: AuthService, private cdr: ChangeDetectorRef, private intl: IntlService) {
		this.profileImageGuid = UID();
        this.isClientAdmin = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
        this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert;
    }

    ngOnInit(): void {
        this.minStartDate = this.meetingRequest.dateEx;
        this.endDate = new Date(this.minStartDate.getFullYear(), this.minStartDate.getMonth() + 1, this.minStartDate.getDate());

        this.onDateChange(this.meetingRequest.dateEx);
        this.getSelectedAndNextMonthWeekendsAndHolidaysDays(this.minStartDate);
    }

    public async getSelectedAndNextMonthWeekendsAndHolidaysDays(startDate: Date) {
        let firstDay = dateToInt(startDate);
        let lastDay = dateToInt(new Date(startDate.getFullYear(), startDate.getMonth() + 2, 0));
        this.lastMonthDisabledDates = intToDate(lastDay).getMonth();

        await this.getWeekendsAndHolidaysDaysList(firstDay, lastDay);
        this.cdr.detectChanges();
    }

    public async getNextMonthWeekendsAndHolidaysDays(startDate: Date) {
        let firstDay = dateToInt(startDate);
        let lastDay = dateToInt(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0));
        this.lastMonthDisabledDates = intToDate(lastDay).getMonth();
        
        await this.getWeekendsAndHolidaysDaysList(firstDay, lastDay);
        this.cdr.detectChanges();
    }

    public async getWeekendsAndHolidaysDaysList(startDate: number, endDate: number) {
        let meetingSlotsListModel = await this.chatService.getMeetingSlots(this.chatService.currentChatRoom.clientEid, startDate, endDate);
        if (meetingSlotsListModel && meetingSlotsListModel.expertList && meetingSlotsListModel.expertList.length > 0) {
            let meetingTimeSlots = meetingSlotsListModel.expertList[0].timeSlots;
            if (meetingTimeSlots) {
                meetingTimeSlots.forEach((s) => {
                    if (s.isWeekend || s.isLegalDay) {
                        if (this.disabledDates == undefined) {
                            this.disabledDates = [];
                        }

                        if (this.disabledDates && this.disabledDates.findIndex((d) => dateToInt(d) == s.date) == -1) {
                            this.disabledDates.push(s.dateEx);
                        }
                    }
                })
            }
            this.meetingCalendar.disabledDates = this.disabledDates;
        }
    }

    public async onDateChange(date: Date) {
        if (date != undefined) {
            this.selectedMeetingTimeSlot = null;
            this.meetingRequest.dateEx = date;

            let meetingSlotsListModel = await this.chatService.getMeetingSlots(this.chatService.currentChatRoom.clientEid, this.meetingRequest.date);
            if (meetingSlotsListModel && meetingSlotsListModel.expertList && meetingSlotsListModel.expertList.length > 0) {
                let meetingTimeSlots = meetingSlotsListModel.expertList[0].timeSlots;
                if (meetingTimeSlots && meetingTimeSlots.length > 0) {
                    if (meetingTimeSlots[0].status == TimeSlotStatus.Blocked) { // isWeekend & isLegalHoliday
                        this.meetingTimeSlotsByDate = [];
                    }
                    else {
				        this.meetingTimeSlotsByDate = meetingTimeSlots.filter((slot) => slot.date == this.meetingRequest.date && slot.status == TimeSlotStatus.Available);
                    }
                }
			}
        }
        else {
            this.meetingTimeSlotsByDate = [];
        }

        this.cdr.detectChanges();
    }

    public onCalendarNavigate(data: { activeView: CalendarView; focusedDate: Date; }) {
        if (data.focusedDate.getMonth() == this.lastMonthDisabledDates) {
            this.getNextMonthWeekendsAndHolidaysDays(new Date(data.focusedDate.getFullYear(), data.focusedDate.getMonth() + 1, 1));
        }
    }

    public activeViewChange(date: any) {
        if (date.getMonth() == this.lastMonthDisabledDates) {
            this.getNextMonthWeekendsAndHolidaysDays(new Date(date.getFullYear(), date.getMonth() + 1, 1));
        }
    }

    public get getLastClientHistory(): MeetingRequestDataHistoryModel{
        let client = this.meetingInfoLastHistory.find((h) => h.role == "CLIENT_ADMIN");
        if (client == undefined) {
          return this.meetingInfoLastHistory[0];
        }
        return client;
    }

    public get getLastCreatedOrProposedMeeting(): MeetingRequestDataHistoryModel {
        if (this.meetingInfoLastHistory) {
            let history = this.meetingInfoLastHistory.find((h) => h.eventType == MeetingHistoryStatus.Created || h.eventType == MeetingHistoryStatus.ProposedNewTime);
            return history;
        }          
    }

    public getProfilePicture() {
        if (!this.fromProposal) {
            return '/users/' + this.expertInfoPersonModel.userEid + '/profile/picture?v=' + this.profileImageGuid;
        }
        else {
            if (this.isClientAdmin) {
                return '/users/' + this.expertInfoPersonModel.userEid + '/profile/picture?v=' + this.profileImageGuid;
            }
            else {
                return '/users/' + this.getLastClientHistory.userEid + '/profile/picture?v=' + this.profileImageGuid;
            }
        }
    }

    public getInitialLetters() {
        if (!this.fromProposal) {
            return this.expertInfoPersonModel.initialLetters;
        }
        else {
            if (this.isClientAdmin) {
                return this.expertInfoPersonModel.initialLetters;
            }
            else {
                return this.getLastClientHistory.initialLetters;
            }
        }
    }

    public getMeetingTitle() {
        if (!this.fromProposal) {
            return `Stabilește o întâlnire de 15 minute cu ${this.expertInfoPersonModel.userName}`;
        }
        else {
            if (this.isClientAdmin) {
                return `Propune alt interval pentru întalnirea de 15 minute cu ${this.expertInfoPersonModel.userName}`;
            }
            else {
                return `Propune alt interval pentru întalnirea de 15 minute cu ${this.getLastClientHistory.userName}`;
            }
        }
    }

    public getLastMeetingInfo() {
        if (this.getLastCreatedOrProposedMeeting) {
            return `programată acum pentru ${this.intl.format('{0:dd.MM.yyyy}', this.getLastCreatedOrProposedMeeting.dateEx)}, ora ${this.getLastCreatedOrProposedMeeting.fromTimeFormat}`;
        }
        else return '';
    }

    public selectedDate(date: number) {
        return intYYYYMMDDToString(date, 'ro-ro');
    }

    public onProfileImageError(event: any) {
        this.hasProfilePicture = false;
	}

    public onSlotSelect(slot: MeetingTimeSlotsModel) {
        this.meetingTimeSlotsByDate.forEach((s) => {
            if (s.fromTime != slot.fromTime) {
                s.isSelected = false;
            }
        })

        slot.isSelected = true;
        this.selectedMeetingTimeSlot = slot;
    }

    public saveInterval(timeSlotsForm: NgForm) {
        if (this.selectedMeetingTimeSlot == null)
            return;

        this.meetingRequest.fromTime = this.selectedMeetingTimeSlot.fromTime;
        if (timeSlotsForm != null && timeSlotsForm.form != null && timeSlotsForm.form.valid) {
            this.save.emit({ meetingRequest: this.meetingRequest, fromProposal: this.fromProposal });
        }
    }
}