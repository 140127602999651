import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'client-unblur-document-file',
	templateUrl: 'unblur-document-file.component.html'
})
export class ClientUnblurDocumentFileComponent implements OnInit {
	@Input() public model: any;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	constructor() { }

	public ngOnInit() { }

}
