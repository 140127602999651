<form #chatCommentForm="ngForm" class="col-24 px-0">
    <kee-textarea id="txtComment" name="comment" class="col-24" label="Comentariu" [(ngModel)]="model.comment" required></kee-textarea>
    <div class="divider col-24 p-0 mt-1 mb-4"></div>
    <div class="col-24 d-flex justify-content-around">
        <button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="this.save.emit(chatCommentForm)">
            <i class="mdi-content-save"></i>
            <span i18n="@@Actions$Save"> Salvează</span>
        </button>
        <button class="btn waves-effect waves-light" type="button" name="action" (click)="this.close.emit(chatCommentForm)">
            <i class="mdi-content-clear"></i>
            <span i18n="@@Actions$Cancel">Renunță</span>
        </button>
    </div>
</form>