import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Maybe } from './gql-codegen.types';
import { RestService } from './rest.service';

export const Accounts_GetServerUnixTimeUsingGetDocument = gql`
	query accounts_getServerUnixTimeUsingGET {
		accounts {
			getServerUnixTimeUsingGET {
				unixTime
			}
		}
	}
`;
export type Accounts_GetServerUnixTimeUsingGetQuery = { accounts?: Maybe<{ getServerUnixTimeUsingGET?: Maybe<{ unixTime?: Maybe<number> }> }> };

@Injectable({
	providedIn: 'root',
})
export class UnixTimeService {
	constructor(private restService: RestService) {}

	public getServerUnixTime(): Observable<Accounts_GetServerUnixTimeUsingGetQuery> {
		return this.restService
			.query({
				query: Accounts_GetServerUnixTimeUsingGetDocument,
				variables: {},
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloQueryResult<Accounts_GetServerUnixTimeUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
}
