import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '@saliente/library';

import { AuthService } from '../auth/auth.service';

const FileSaver = require('file-saver');

@Injectable()
export class DownloadService {
	constructor(private http: HttpClient, private authService: AuthService, private spinner: SpinnerService, private toastr: ToastrService) {}

	public download(fileUrl: string): Observable<string> {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authService.authHeaderValue());
		this.spinner.show();
		return this.http
			.get(fileUrl, {
				headers,
				responseType: 'blob',
				observe: 'response',
			})
			.pipe(
				map((response: any) => {
					return this.saveToFileSystem(fileUrl, response);
				}),
				catchError((err: any) => {
					this.toastr.error('Am întâmpinat o eroare necunoscută. Te rog sa încerci din nou sau să ne contactezi.');
					return of(null);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public downloadUsingPost(fileUrl: string, body: any): Observable<string> {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authService.authHeaderValue());
		this.spinner.show();
		return this.http
			.post(fileUrl, body, {
				headers,
				responseType: 'blob',
				observe: 'response',
			})
			.pipe(
				map((response) => {
					return this.saveToFileSystem(fileUrl, response);
				}),
				catchError((err: any) => {
					/* check if there was an KException thrown on server side; check for Blob since this is what we expect, see responseType: 'blob', */
					const isServerErrorMessage = err.error != null && err.error instanceof Blob && err.error.type != null && err.error.type === 'application/json';
					if (!isServerErrorMessage) {
						this.toastr.error('Am întâmpinat o eroare necunoscută. Te rog sa încerci din nou sau să ne contactezi.');
						return of(null);
					}
					/* in order to get the actual string use text() which returns an promise */
					return from(err.error.text()).pipe(
						map((sjson: any) => {
							const json = JSON.parse(sjson);
							let message = json.Message as string;
							/* apart from the main Message we might have one or more KExceptionDetail */
							if (json.errors != null) {
								message += ':';
								let sep = '';
								for (const error of json.errors) {
									message += ' ' + error.message + sep;
									sep = ';';
								}
							}
							this.toastr.error(message);
							return null;
						})
					);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public tryDownload(fileUrl: string): Observable<string> {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authService.authHeaderValue());
		return this.http
			.get(fileUrl, {
				headers,
				responseType: 'blob',
				observe: 'response',
			})
			.pipe(
				map((response: any) => {
					return this.saveToFileSystem(fileUrl, response);
				})
			);
	}

	/*public load(fileUrl: string): Observable<HttpResponse> {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authService.authHeaderValue());
		return this.http
			.get(fileUrl, {
				headers,
				responseType:'arraybuffer'
			});
	}*/

	public saveToFileSystem(fileUrl: string, response: any): string {
		const contentDispositionHeader: string = response.headers.get('Content-Disposition');
		const contentType: string = response.headers.get('content-type');
		let filename = '';
		if (contentDispositionHeader) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(contentDispositionHeader);
			if (matches != null && matches[1]) {
				filename = matches[1].replace(/['"]/g, '');
			}
		} else {
			filename = fileUrl.replace(/^.*[\\\/]/, '');
		}
		const blob = new Blob([response.body], { type: contentType || 'text/plain' });
		FileSaver.saveAs(blob, filename);
		return filename;
	}
}
