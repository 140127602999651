import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SalienteLibrarySharedModule } from "@saliente/library";
import { ClientAccDocumentsService } from "src/app/clients/documents/acc-documents.service";
import { ClientsDocumentsSharedModule } from "src/app/clients/documents/documents-shared.module";
import { ExpertDocumentsSharedModule } from "src/app/expert/documents/expert-documents-shared.module";
import { ClientDocumentHostComponent } from "./client-document-host.component";
import { DocumentDisplayComponent } from "./document-display.component";
import { DocumentDisplayService } from "./document-display.service";
import { ExpertDocumentHostComponent } from "./expert-document-host.component";
import { PayrollDocumentHostComponent } from "./client-payroll-document-host.component";
import { ClientPayrollDocumentsService } from "src/app/clients/documents/payroll-documents.service";

const COMPONENTS = [ DocumentDisplayComponent, ClientDocumentHostComponent, PayrollDocumentHostComponent, ExpertDocumentHostComponent ];

@NgModule({
	imports: [
	    FormsModule, SalienteLibrarySharedModule, ClientsDocumentsSharedModule, ExpertDocumentsSharedModule, 
	],
	providers: [
		DocumentDisplayService, ClientAccDocumentsService, ClientPayrollDocumentsService 
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class DocumentDisplaySharedModule {
}