import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { Observable, Subscription, BehaviorSubject, combineLatest, of, } from 'rxjs';
import { switchMap, debounceTime, } from 'rxjs/operators';

import { GridComponent } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';

import { AuthService, } from '@saliente/common-auth';
import { DocumentsOpisService, DocumentsBaseService, FinancialDocumentEntryType, } from '@saliente/common-documents';
import { ClientAccDocumentsService } from './acc-documents.service';
import { CLIENTS_ROUTES } from '../clients.routes.constants';

@Component({
	selector: 'client-documents-opis',
	templateUrl: 'documents-opis.component.html',
	styleUrls: ['documents-opis.component.scss']
})
export class ClientDocumentsOpisComponent implements OnInit, OnDestroy {
	private subscription: Subscription;
	public model: any[];

	public showFilters: boolean = true;

	private _startDate: BehaviorSubject<Date> = new BehaviorSubject(new Date());
	public get startDate() {
		return this._startDate.value;
	}
	public set startDate(value) {
		this._startDate.next(value);
	}

	private _endDate: BehaviorSubject<Date> = new BehaviorSubject(new Date());
	public get endDate() {
		return this._endDate.value;
	}
	public set endDate(value) {
		this._endDate.next(value);
	}

	@Input()
	public isCard: boolean = false;
	
	constructor(private opisService: DocumentsOpisService, private authService: AuthService, private router: Router,
		private route: ActivatedRoute, public intl: IntlService, private accDocumentsService: ClientAccDocumentsService) {
		this.fetch();
	}

	public ngOnInit() {
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	private fetch() {
		const self = this;
		this.subscription = self.route.paramMap
			.pipe(
				switchMap((params: ParamMap) => {
					if (params && params.keys.length) {
						const yearmonth = parseInt(params.get('yearmonth'));
						if (!isNaN(yearmonth)) {
							const month = yearmonth % 100,
								year = (yearmonth - month) / 100,
								startDate = new Date(year, month - 1, 1),
								endDate = new Date(year, month, 1);
							endDate.setDate(endDate.getDate() - 1);
							this.showFilters = false;
							return combineLatest(this.authService.getSelectedCompanyId(), of(startDate), of(endDate));
						}
					}
					return combineLatest(this.authService.getSelectedCompanyId(), this._startDate, this._endDate);
				}),
				debounceTime(200),
				switchMap(([clientEid, startDate, endDate]) => {
					return this.opisService.fetch(clientEid, startDate, endDate);
				})
			)
			.subscribe((opis: any[]) => {
				this.model = opis;
			});
	}

	public gotoDocument(document: any) {
		this.router.navigate([])
			.then(result => { window.open(this.router.createUrlTree([CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACC_DOCUMENTS, document.documentEid]).toString(), '_blank'); });;
	}

	public documentStatusName(statusCode: string) {
		return this.accDocumentsService.documentStatusName(statusCode);
	}

	public entryTypeDisplayName(type: FinancialDocumentEntryType) {
		return DocumentsBaseService.entryTypeDisplayName(type)
	}

	public downloadPdf(grid: GridComponent) {
		grid.saveAsPDF();
	}

	public downloadXls(grid: GridComponent): void {
		grid.saveAsExcel();
	}
}
