import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';

import { ExpertRouterModule } from './expert.routes';


import { ExpertDocumentsAccuracyService } from './documents-summary/documents-accuracy/expert-documents-accuracy.service';
import { ExpertStatutoryReportsProcessorsService } from './documents-summary/statutory-reports-processors/expert-statutory-reports-processors.service';
import { ExpertDocumentsSummaryService } from './documents-summary/documents-summary/expert-documents-summary.service';
import { MenuService } from './menu/menu.service';
const SERVICES = [ExpertDocumentsAccuracyService, ExpertStatutoryReportsProcessorsService, ExpertDocumentsSummaryService, MenuService,];

@NgModule({
	imports: [
		CommonModule, FormsModule,
		SalienteLibrarySharedModule, MasterViewModule, ExpertRouterModule
	],
	providers: [
		...SERVICES
	]
})
export class ExpertModule {
	constructor(private menuService: MenuService) {

	}
}
