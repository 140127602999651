export class MessageBoxButton {
	name: string;
	icon: string;
	text: string;

	static ok(): MessageBoxButton {
		const button = new MessageBoxButton();
		button.name = 'ok';
		button.text = 'OK';
		return button;
	}

	static cancel(): MessageBoxButton {
		const button = new MessageBoxButton();
		button.name = 'cancel';
		button.text = 'Anulează';
		return button;
	}

	static yes(): MessageBoxButton {
		const button = new MessageBoxButton();
		button.name = 'yes';
		button.text = 'Da';
		return button;
	}

	static no(): MessageBoxButton {
		const button = new MessageBoxButton();
		button.name = 'no';
		button.text = 'Nu';
		return button;
	}
}

export class Message {
	title: string;
	message: string;
	html?: boolean;
	callback?: Function;
	buttons: MessageBoxButton[];
	defaultButton?: string;
}

export interface IMessageBoxComponent {
	show(message: Message): void;
}
