import { Injectable } from '@angular/core';
import { plainToClass, classToPlain } from 'class-transformer';

import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, first } from 'rxjs/operators';

import gql from 'graphql-tag';

import { dateToInt, intToDate } from '@saliente/library';
import { RestService, ConfigService, DownloadService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import { CompanyFiscalSummaryModel } from '@saliente/common-accounting';

import {
	ExpertStatutoryReportsProcessorsModel,
	ExpertStatutoryReportsProcessorsDefinitionsModel,
	ExpertStatutoryReportProcessorDefinitionModel,
	ExpertStatutoryReportProcessStatusModel,
} from './expert-statutory-reports-processors.models';
import { ApolloResult } from 'src/app/common/general/gql-codegen.types';

const statutoryReportProcessorsExQueryText = gql`
	query statutoryReportProcessors($fiscalNumber: String!, $forMonth: Int, $forRole: String!) {
		subs {
			statutoryReports: getStatutoryReportProcessorsDefinitionExByRoleUsingGET(clientCode: $fiscalNumber, forMonth: $forMonth, forRole: $forRole) {
				definitions {
					code
					extraParameters {
						code
						value
					}
					isPlatformManaged
					name
          statutoryReportConstraint{
            externalId
            isRequired
            isSystemManaged
            isVisible
            periodicity
            statutoryReportDictionary {
              code
              defaultIsRequired
              defaultIsSystemManaged
              defaultPeriodicity
              description
              externalId
              isActive
              forRole
              isPeriodicityChangeable
            }
          }
					processStatusRepresentation {
						externalId
						fiscalNumber
						forMonth
						isRequiredThisMonth
						isVisible
						manuallyMarkedFinalized
						message
						periodicity
						reportCode
						statusCode
						uploadDeadline
						hasWrongPeriodicity
						hasWrongRequired
						messageForHasWrongXXX
					}
				}
				isVatPayer
				periodicityDictionary {
					code
					description
				}
			}
		}
	}
`;

const companyInfoQueryText = gql`
	query companyInfo($clientEid: String!) {
		accounts {
			companyInfo: GetClientUsingGET(clientEid: $clientEid) {
				fiscalNumber
				expertComments
				serviceStartDate
				sysCaenCode {
					code
					description
				}
			}
		}
	}
`;

const refreshQueryText = gql`
	query refresh($fiscalNumber: String!, $forMonth: Int!) {
		subs {
			reportsProcesses: getStatutoryReportProcessesForClientUsingGET(clientCode: $fiscalNumber, forMonth: $forMonth) {
				externalId
				fiscalNumber
				forMonth
				isRequiredThisMonth
				isVisible
				manuallyMarkedFinalized
				periodicity
				message
				reportCode
				statusCode
				uploadDeadline
				hasWrongPeriodicity
				hasWrongRequired
				messageForHasWrongXXX
			}
		}
	}
`;

export type Subs_StatutoryReportProcessStatusUpdateRepresentationInput = {
	isRequiredThisMonth: boolean;
	isVisible: boolean;
	manuallyMarkedFinalized: boolean;
	periodicity: string;
};
export type Subs_UpdateStatutoryReportProcessStatusUsingPutMutationVariables = {
	externalId?: string;
	representation?: Subs_StatutoryReportProcessStatusUpdateRepresentationInput;
};
export type Subs_StatutoryReportProcessStatusRepresentationMutation = {
	subs?: {
		updateStatutoryReportProcessStatusUsingPUT?: Subs_StatutoryReportProcessStatusRepresentation;
	};
};
export type Subs_StatutoryReportProcessStatusRepresentation = {
	externalId?: string;
	fiscalNumber?: string;
	forMonth?: number;
	isRequiredThisMonth?: boolean;
	isVisible?: boolean;
	manuallyMarkedFinalized?: boolean;
	message?: string;
	periodicity?: string;
	reportCode?: string;
	reportFamilyCode?: string;
	statusCode?: string;
	uploadDeadline?: number;
	hasWrongPeriodicity?: boolean;
	hasWrongRequired?: boolean;
};
const Subs_UpdateStatutoryReportProcessStatusUsingPutDocument = gql`
	mutation subs_updateStatutoryReportProcessStatusUsingPUT($externalId: String, $representation: subs_StatutoryReportProcessStatusUpdateRepresentationInput) {
		subs {
			updateStatutoryReportProcessStatusUsingPUT(externalId: $externalId, representation: $representation) {
				externalId
				fiscalNumber
				forMonth
				isRequiredThisMonth
				isVisible
				manuallyMarkedFinalized
				message
				periodicity
				reportCode
				reportFamilyCode
				statusCode
				uploadDeadline
				hasWrongPeriodicity
				hasWrongRequired
				messageForHasWrongXXX
			}
		}
	}
`;
const generateAndValidateMutationText = gql`
	mutation generateAndValidate($fiscalNumber: String!, $forMonth: Int!, $code: String!, $data: subs_StatutoryReportGenerationRequestRepresentationExInput) {
		subs {
			generateAndValidateStatutoryReportExUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code, requestData: $data) {
				message: result
				withSuccess
			}
		}
	}
`;

const declareMutationText = gql`
	mutation declare($fiscalNumber: String!, $forMonth: Int!, $code: String!, $data: subs_StatutoryReportGenerationRequestRepresentationExInput) {
		subs {
			initiateUploadStatutoryReportUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code, requestData: $data) {
				message
				newStatusCode
				uploadDeadline
				withSuccess
			}
		}
	}
`;

const sendMutationText = gql`
	mutation send($fiscalNumber: String!, $forMonth: Int!, $code: String!) {
		subs {
			forceUploadStatutoryReportNowUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code) {
				message
				newStatusCode
				uploadDeadline
				withSuccess
			}
		}
	}
`;

const cancelMutationText = gql`
	mutation cancel($fiscalNumber: String!, $forMonth: Int!, $code: String!) {
		subs {
			cancelUploadStatutoryReportUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code) {
				message
				newStatusCode
				uploadDeadline
				withSuccess
			}
		}
	}
`;

const restartMutationText = gql`
	mutation restart($fiscalNumber: String!, $forMonth: Int!, $code: String!) {
		subs {
			markStatutoryReportProcessIdleUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code) {
				message
				newStatusCode
				uploadDeadline
				withSuccess
			}
		}
	}
`;

const setRequiredMutationText = gql`
	mutation setRequired($fiscalNumber: String!, $forMonth: Int!, $code: String!, $value: Boolean) {
		subs {
			changeStatutoryReportProcessIsRequiredUsingPUT(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code, isRequired: $value) {
				Message
			}
		}
	}
`;

const uploadFileMutationText = gql`
	mutation uploadFile($fiscalNumber: String!, $forMonth: Int!, $code: String!, $file: subs_StatutoryReportProcessExternalReportRequestRepresentationInput) {
		subs {
			processExternalStatutoryReportUsingPOST(clientCode: $fiscalNumber, forMonth: $forMonth, code: $code, request: $file) {
				message
				newStatusCode
				uploadDeadline
				withSuccess
			}
		}
	}
`;
export type Subs_GetStatutoryReportConstraintsUsingGetQueryVariables = {
	clientCode: string;
};
export type Subs_StatutoryReportConstraintRepresentation = {
	externalId?: string;
	isRequired?: boolean;
	isSystemManaged?: boolean;
	isVisible?: boolean;
	periodicity?: string;
	statutoryReportDictionary?: Subs_StatutoryReportDictionaryRepresentation;
};

export type Subs_StatutoryReportDictionaryRepresentation = {
	code?: string;
	defaultIsRequired?: boolean;
	defaultIsSystemManaged?: boolean;
	defaultPeriodicity?: string;
	isPeriodicityChangeable?: boolean;
	description?: string;
	externalId?: string;
	isActive?: boolean;
	forRole?: string;
};

export type Subs_GetStatutoryReportConstraintsUsingGetQuery = {
	subs?: {
		getStatutoryReportConstraintsUsingGET?: Subs_StatutoryReportConstraintRepresentation[];
	};
};

const Subs_GetStatutoryReportConstraintsUsingGetDocument = gql`
	query subs_getStatutoryReportConstraintsUsingGET($clientCode: String) {
		subs {
			getStatutoryReportConstraintsUsingGET(clientCode: $clientCode) {
				externalId
				isRequired
				isSystemManaged
				isVisible
				periodicity
				statutoryReportDictionary {
					code
					defaultIsRequired
					defaultIsSystemManaged
					defaultPeriodicity
					description
					externalId
					isActive
					forRole
          isPeriodicityChangeable
				}
			}
		}
	}
`;
export type Subs_StatutoryReportConstraintUpdateRepresentationInput = {
	isRequired: boolean;
	isVisible: boolean;
	periodicity: string;
};
export type Subs_UpdateStatutoryReporConstraintUsingPutMutationVariables = {
	clientCode: string;
	externalId: string;
	request: Subs_StatutoryReportConstraintUpdateRepresentationInput;
};
export const Subs_UpdateStatutoryReporConstraintUsingPutDocument = gql`
	mutation subs_updateStatutoryReporConstraintUsingPUT($clientCode: String, $externalId: String, $request: subs_StatutoryReportConstraintUpdateRepresentationInput) {
		subs {
			updateStatutoryReporConstraintUsingPUT(clientCode: $clientCode, externalId: $externalId, request: $request) {
				externalId
				isRequired
				isSystemManaged
				isVisible
				periodicity
				statutoryReportDictionary {
					code
					defaultIsRequired
					defaultIsSystemManaged
					defaultPeriodicity
					description
					externalId
					isActive
				}
			}
		}
	}
`;

@Injectable()
export class ExpertStatutoryReportsProcessorsService {
	public _date: BehaviorSubject<Date> = new BehaviorSubject(null);
	public get date(): Date {
		return this._date.value;
	}
	public set date(value) {
		const oldValue = this._date.value;
		value = this.firstDayOfMonth(value);
		if (value && oldValue) {
			if (value.getTime() !== oldValue.getTime()) {
				this._date.next(value);
			}
		} else {
			this._date.next(value);
		}
	}

	constructor(private restService: RestService, private authService: AuthService, private configService: ConfigService, private downloadService: DownloadService) {}

	private firstDayOfMonth(date: Date): Date {
		if (date && date.getTime) {
			return new Date(date.getFullYear(), date.getMonth(), 1);
		}
		return null;
	}

	public fetch(forRole: string = 'EXPERT'): Observable<ExpertStatutoryReportsProcessorsModel> {
		return this.authService.getSelectedCompanyId().pipe(
			switchMap((clientEid) => {
				return this.restService
					.query({
						query: companyInfoQueryText,
						fetchPolicy: 'network-only',
						variables: {
							clientEid: clientEid,
						},
					})
					.pipe(
						map((response: any) => {
							if (response) {
								const companyInfo = response.data.accounts.companyInfo;
								const model: ExpertStatutoryReportsProcessorsModel = plainToClass(ExpertStatutoryReportsProcessorsModel, companyInfo);
								const caen = companyInfo.sysCaenCode;
								if (caen) {
									model.caenCode = caen.code;
									model.caenDescription = caen.description;
								}
								return model;
							}
						})
					);
			}),
			switchMap((model: ExpertStatutoryReportsProcessorsModel) => {
				if (!this._date.value) {
					const startDate = this.firstDayOfMonth(new Date());
					if (model.serviceStartDateEx < startDate) {
						startDate.setMonth(startDate.getMonth() - 1);
					}
					this._date.next(startDate);
				}
				return this._date.pipe(
					switchMap((date) => {
						return this.restService
							.query({
								query: statutoryReportProcessorsExQueryText,
								variables: {
									fiscalNumber: model.fiscalNumber,
									forMonth: Math.floor(dateToInt(date) / 100),
									forRole,
								},
								fetchPolicy: 'network-only',
							})
							.pipe(
								map((response: any) => {
									if (response) {
										model.statutoryReportsProcessors = plainToClass(ExpertStatutoryReportsProcessorsDefinitionsModel, response.data.subs.statutoryReports);
										model.statutoryReportsProcessors.definitions = model.statutoryReportsProcessors.definitions.sort((a, b) => {
											return a.code.localeCompare(b.code);
										});
										return model;
									}
									return null;
								})
							);
					})
				);
			})
		);
	}

	public refreshStream(model: ExpertStatutoryReportsProcessorsModel, date: Date): Observable<ExpertStatutoryReportsProcessorsModel> {
		return this.restService
			.query({
				query: refreshQueryText,
				variables: {
					fiscalNumber: model.fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
				},
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((response: any) => {
					if (response) {
						// eslint-disable-next-line @typescript-eslint/ban-types
						const reportsProcesses = plainToClass<ExpertStatutoryReportProcessStatusModel, object>(ExpertStatutoryReportProcessStatusModel, response.data.subs.reportsProcesses);
						const reportsDefinitions = model.statutoryReportsProcessors.definitions;
						reportsProcesses.forEach((reportProcess) => {
							const reportDefinition = reportsDefinitions.find((def) => def.code === reportProcess.reportCode);
							if (reportDefinition) {
								reportDefinition.processStatus = reportProcess;
							}
						});
					}
					return model;
				}),
				first()
			);
	}

	public generateAndValidate(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: generateAndValidateMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
					data: {
						format: 'HTML',
						values: classToPlain(
							definition.extraParameters.filter((p) => !!p.value),
							{ excludePrefixes: ['__'] }
						),
					},
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return response.data.subs.generateAndValidateStatutoryReportExUsingPOST;
					}
					return false;
				})
			);
	}

	public generateAndDownload(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel, format: string): Observable<any> {
		const forMonth = Math.floor(dateToInt(date) / 100);
		return this.configService.getSubsDownloadUrl().pipe(
			switchMap((url) => {
				url = url + `statutory-reports/ex/clients/${fiscalNumber}/months/${forMonth}/reports/${definition.code}/generations-downloads`;
				return this.downloadService.downloadUsingPost(url, {
					format,
					values: classToPlain(
						definition.extraParameters.filter((p) => !!p.value),
						{ excludePrefixes: ['__'] }
					),
				});
			})
		);
	}

	public declareStream(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: declareMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
					data: {
						format: 'XML',
						values: classToPlain(
							definition.extraParameters.filter((p) => !!p.value),
							{ excludePrefixes: ['__'] }
						),
					},
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						response = response.data.subs.initiateUploadStatutoryReportUsingPOST;
						if (response.withSuccess) {
							definition.processStatus.statusCode = response.newStatusCode;
							definition.processStatus.uploadDeadlineEx = intToDate(response.uploadDeadline);
						}
						return response;
					}
					return false;
				}),
				first()
			);
	}

	public sendStream(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: sendMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						response = response.data.subs.forceUploadStatutoryReportNowUsingPOST;
						if (response.withSuccess) {
							definition.processStatus.statusCode = response.newStatusCode;
							definition.processStatus.uploadDeadlineEx = intToDate(response.uploadDeadline);
						}
						return response;
					}
					return false;
				}),
				first()
			);
	}

	public cancelStream(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: cancelMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						response = response.data.subs.cancelUploadStatutoryReportUsingPOST;
						if (response.withSuccess) {
							definition.processStatus.statusCode = response.newStatusCode;
							definition.processStatus.uploadDeadlineEx = intToDate(response.uploadDeadline);
						}
						return response;
					}
					return false;
				}),
				first()
			);
	}

	public restartStream(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: restartMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						response = response.data.subs.markStatutoryReportProcessIdleUsingPOST;
						if (response.withSuccess) {
							definition.processStatus.statusCode = response.newStatusCode;
							definition.processStatus.uploadDeadlineEx = intToDate(response.uploadDeadline);
						}
						return response;
					}
					return false;
				}),
				first()
			);
	}

	public uploadFileStream(fiscalNumber: string, date: Date, definition: ExpertStatutoryReportProcessorDefinitionModel, content: string): Observable<any> {
		return this.restService
			.mutate({
				mutation: uploadFileMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
					file: { content },
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						response = response.data.subs.processExternalStatutoryReportUsingPOST;
						if (response.withSuccess) {
							definition.processStatus.statusCode = response.newStatusCode;
							definition.processStatus.uploadDeadlineEx = intToDate(response.uploadDeadline);
						}
						return response;
					}
					return false;
				}),
				first()
			);
	}

	public setRequiredStream(fiscalNumber: string, date: Date, value: boolean, definition: ExpertStatutoryReportProcessorDefinitionModel): Observable<any> {
		return this.restService
			.mutate({
				mutation: setRequiredMutationText,
				variables: {
					fiscalNumber,
					forMonth: Math.floor(dateToInt(date) / 100),
					code: definition.code,
					value,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				}),
				first()
			);
	}
	public updateStatus(variables: Subs_UpdateStatutoryReportProcessStatusUsingPutMutationVariables): Observable<Subs_StatutoryReportProcessStatusRepresentationMutation> {
		return this.restService
			.mutate({
				mutation: Subs_UpdateStatutoryReportProcessStatusUsingPutDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloResult<Subs_StatutoryReportProcessStatusRepresentationMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public getConstraints(variables: Subs_GetStatutoryReportConstraintsUsingGetQueryVariables): Observable<Subs_GetStatutoryReportConstraintsUsingGetQuery> {
		return this.restService
			.query({
				query: Subs_GetStatutoryReportConstraintsUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloResult<Subs_GetStatutoryReportConstraintsUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public updateConstraint(variables: Subs_UpdateStatutoryReporConstraintUsingPutMutationVariables): Observable<Subs_StatutoryReportConstraintRepresentation> {
		return this.restService

			.mutate({
				mutation: Subs_UpdateStatutoryReporConstraintUsingPutDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloResult<Subs_StatutoryReportConstraintRepresentation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
}
