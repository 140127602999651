import { IClientDocumentsService } from "src/app/clients/documents/documents.service";
import { DocumentFileModel } from "../documents/documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";

export abstract class IClientDocumentItemParent {
    public documentsService: IClientDocumentsService;
	public hasMarkUsableRight: boolean;
	public hasDeleteRight: boolean;
	public hasDeleteOwnRight: boolean;
	public hasAdnotateRight: boolean;
	public hasAdnotateOwnRight: boolean;
	public hasEFacturaDeleteRight: boolean;

    public getRouteComponent(): string {
		return null;
	}

    public initLazy(): void {
		return null;
	}
}