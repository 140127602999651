import { Component, Input, ViewChild, ViewChildren, ElementRef, OnInit, AfterViewInit, OnDestroy, QueryList, Renderer2 } from '@angular/core';

import { debounceTime } from 'rxjs/operators';

import $ from '../../jquery.plugins';

let Viewer: any = require('./.dist/viewer.js');
let ResizeSensor: any = require('css-element-queries/src/ResizeSensor');

export interface GalleryImage {
	alt?: string;
	src?: string;
	dataSrc?: string;
	class?: string;
}

function coerceBooleanProperty(value: any): boolean {
	return value != null && `${value}` !== 'false';
}
@Component({
	selector: 'kee-gallery',
	templateUrl: 'gallery.component.html',
	styleUrls: ['gallery.component.scss',]
})
export class KeeGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('container') private containerEl: ElementRef;
	@ViewChildren('imgs') imagesList: QueryList<ElementRef>;

	@Input() public class: string = '';
	@Input('gallery-options') public options: any;

	private _images : GalleryImage[];
	@Input('gallery-images') public get images() {
		return this._images;
	}
	public set images(value: GalleryImage[]) {
		this._images = value;
	}

	private _lazy: boolean = false;
	@Input() get lazy() {
		return this._lazy;
	}
	set lazy(value) {
		this._lazy = coerceBooleanProperty(value);
	}

	private resizeSensor: any;
	private resizeHandler: any;
	private viewer: any;

	constructor(private el: ElementRef) {
		this.resizeHandler = this.onContainerResize.bind(this);
	}

	public ngOnInit() {
		this.el.nativeElement.removeAttribute('class');
	}

	public ngAfterViewInit() {
		this.imagesList
			.changes
			.pipe(
				debounceTime(300) /* add an additional debounce time.. */
			)
			.subscribe(images => {
				this.createViewer();
			});

		this.createViewer();
		this.resizeSensor = new ResizeSensor($(this.el.nativeElement).parent(), this.resizeHandler);
		this.initLazy();
	}

	private onContainerResize() {
		if (this.viewer && this.viewer.ready) {
			this.viewer.resize();
		}
	}

	private createViewer() {
		if (this.viewer) {
			this.viewer.destroy();
			this.viewer = null;
		}
		if (this.images && this.images.length) {
			this.viewer = new Viewer(this.containerEl.nativeElement, this.options);
			this.initLazy();
			setTimeout(() => { this.viewer.update() }, 200);
		}
	}

	private initLazy() {
		let self = this;
		if (this.lazy) {
			$(this.containerEl.nativeElement).find('img.lazy').Lazy({
				visibleOnly: true,
				threshold: 2000,
				combined: true,
				load: function (element: any) {
					element.attr('src', element.data('src'));
				},
				onError: function (element: any) { //AM. 23.11.2022. task 5107: when there is no thumbnail
					if (!element.data('thumbnail') && self.options.thumbnailExtSrc) {
						element.data('thumbnail', true);
						element.attr('src', self.options.thumbnailExtSrc);
					}
				}
			});
		}
	}

	public ngOnDestroy() {
		if (this.viewer) {
			this.viewer.destroy();
		}
		if (this.resizeSensor) {
			this.resizeSensor.detach(this.resizeHandler);
			this.resizeSensor = null;
		}
	}
}