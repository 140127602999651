export class EXPERT_ROUTES {
	static ROOT = '/experts';
	static ROOT_1 = 'experts';
	static DASHBOARD = 'dashboard';
	static STATUTORY_REPORTS_DASHBOARD = 'stautory_reports_dashboard';
	static DOCUMENTS = 'documents';
	static DOCUMENTS_ACCURACY = 'accuracy';
	static DOCUMENTS_SUMMARY = 'summary';
	static DOCUMENTS_CHECK = 'checks';
	static DOCUMENTS_TASK = 'tasks';
	static ACC_NOTES = 'accnotes';
	static CLOSING_AUDIT = 'closing_audit';
	static STATUTORY_REPORTS = 'statutory_reports';
	static ANAF_MESSAGES = 'anaf_messages';
	static BALANCE_SHEET_DASHBOARD = 'balance_sheet_dashboard';
	static FISA_ROL_SUMAR = 'fisa_rol_sumar';
	static CHART_OF_ACCOUNTS = 'chart_of_accounts';
	static PAYMENT_ALLOCATION = 'paymentallocation';
	static OPERATIONAL_DASHBOARD = 'operational-dashboard';
	static TAKEOVER_DASHBOARD = 'takeover_dashboard';

}
