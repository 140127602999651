import { Type, Expose, Exclude } from 'class-transformer';
import { DocumentFileModel } from '@saliente/common-documents';

export class AccDocumentFileModel extends DocumentFileModel {
	@Expose({ name: 'eid' })
	public id: string;

	@Expose({ name: 'clientEid' })
	public clientEid: string;

	public costCenter: string;

	@Exclude()
	public accMetadata: any[];

	// CM moved to base class
	// @Exclude()
	// get isInvoice(): boolean {
	// 	return !this.metadataEx || this.metadataEx.sourceModule == 'InvoiceModule';
	// }

	public copy(src: AccDocumentFileModel) {
		super.copy(src);
	}
}

export class PayrollDocumentFileModel extends DocumentFileModel {
	@Expose({ name: 'eid' })
	public id: string;

	@Expose({ name: 'clientEid' })
	public clientEid: string;

	public copy(src: PayrollDocumentFileModel) {
		super.copy(src);
	}
}