import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametersRoutingModule } from './parameters.routes';



@NgModule({
  imports: [
    CommonModule,
    ParametersRoutingModule
  ],
  declarations: [
  ]
})
export class ParametersModule { }
