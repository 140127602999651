import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { STOCK_ROUTES } from '.';
import { AuthGuard } from '@saliente/common-auth';

const routes: Routes = [
  {
    path: STOCK_ROUTES.ROOT_1,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
      	// {
		// 	path: STOCK_ROUTES.WAREHOUSES,
		// 	loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule)
		// },
		{
			path: STOCK_ROUTES.INVENTORY_MANAGEMENT,
			loadChildren: () => import('./inventoryManagement/inventoryManagement.module').then(m => m.InventoryManagementModule)
		},
		{
			path: STOCK_ROUTES.INVENTORY_REPORTS,
			data: { reportName: "OperationalHeaders", section: 'Inventory', screenName: 'Rapoarte de stoc', tabName: 'Rapoarte'},
			loadChildren: () => import('../clients/reports/financial/financial-reports.module').then(m => m.ClientsFinancialReportsModule)
		},
		{
			path: STOCK_ROUTES.INVENTORY_CONFIG,
			loadChildren: () => import('./inventoryConfig/inventoryConfig.module').then(m => m.InventoryConfigModule)
		},
		{
			path: STOCK_ROUTES.INVENTORY_PERIODS,
			loadChildren: () => import('./inventoryPeriods/inventoryPeriods.module').then(m => m.InventoryPeriodsModule)
		},
		{
			path: STOCK_ROUTES.ITEMS,
			data: { accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW', isStockable: true },
			loadChildren: () => import('../clients/items/items.module').then(m => m.ItemsModule)
		},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StockRoutingModule { }
