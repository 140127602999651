<div class="form-group materialize {{behaviourClasses}}">
	<div class="d-flex">
		<kendo-combobox #comboBox class="form-control no-clear-value" [id]="controlId" [data]="sortedData" [valueField]="valueField" [textField]="textField"
			[placeholder]="placeholder" [filterable]="filterable" [valuePrimitive]="valuePrimitive" [allowCustom]="allowCustom" [suggest]="suggest"
			[valueNormalizer]="valueNormalizer" [loading]="loading" [virtual]="virtual" [popupSettings]="popupSettings" [disabled]="disabled" (focus)="_handleFocus($event)"
			(blur)="_handleBlur($event)" (filterChange)="filterChange.emit($event)" (selectionChange)="selectionChange.emit($event)"
			(open)="open.emit($event)" (close)="close.emit($event)" [(ngModel)]="value" (valueChange)="_handleChange($event)">
		</kendo-combobox>
		<i *ngIf="showClearButton" class="comboboxClearButton col-auto p-0 ml-n2 align-self-center mdi-content-clear cursor-pointer pink-text text-accent-2 align-self-end"
			style="z-index: 10;" (click)="clearValue()">
		</i>
	</div>
	<label [for]="controlId">{{label}}</label>
	<validation-messages *ngIf="showFailures" [messages]="failures"></validation-messages>
</div>
