import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';

import { OAuthModule } from 'angular-oauth2-oidc';
// import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
// import { ApolloLink, concat } from 'apollo-link';
// import { HttpLink } from 'apollo-angular/http';
// import { InMemoryCache} from '@apollo/client/core';

import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';

import { SalienteLibrarySharedModule, AuthHeaderProvider } from '@saliente/library';
import { CommonRouterModule } from './common.routes';

import { AccountingPeriodService } from './accounting/accounting-period.service';
import { AppMenuService } from './appmenu/appmenu.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService, } from './auth/auth.service';
import { HomeGuard } from './auth/home.guard';
import { DocumentsOpisService, } from './documents/documents-opis.service';
import { CommonService, } from './general/common.service';
import { ConfigService, } from './general/config.service';
import { DownloadService, } from './general/download.service';
import { PayUService, } from './general/payu.service';
import { RestService, } from './general/rest.service';
import { UploadService, } from './general/upload.service';
import { ChatService, } from './notifications/chat.service';
import { PartnersService, } from './partners/partners.service';
import { ImportServiceCommon } from './general/import.service';
import { RatingService } from './rating/rating.service';
import { DocumentStatisticsCommon } from './general/documentStatistics.service';
import { ClientAccDocumentsService } from '../clients/documents/acc-documents.service';
import { ExpertDocumentsService } from '../expert/documents/expert-documents.service';
import { ExpertDocumentsLiteService } from '../expert/documents/expert-documents-lite.service';
import { PayrollServiceCommon } from './general/payroll.service';
import { EFacturaServiceCommon } from './general/e-factura.service';
import { PayrollCommonService } from '../payroll/common/payroll.service';

const SERVICES = [
	AppMenuService, AuthGuard, AuthService, HomeGuard, DownloadService, UploadService, RestService,
	DocumentsOpisService,
	AccountingPeriodService, ChatService, CommonService, ConfigService, PartnersService, PayUService,
	ImportServiceCommon, RatingService, DocumentStatisticsCommon, ClientAccDocumentsService, ExpertDocumentsService, ExpertDocumentsLiteService,
	PayrollServiceCommon, EFacturaServiceCommon, PayrollCommonService 
];

@NgModule({
	imports: [
		AngularCommonModule, BrowserModule, HttpClientModule, RouterModule, FormsModule,
		OAuthModule.forRoot(), SalienteLibrarySharedModule, CommonRouterModule
	],
	providers: [
		{ provide: AuthHeaderProvider, useClass: AuthService },
		...SERVICES,
		{ provide: APP_INITIALIZER, useFactory: ConfigService.appInitializerFactory, deps: [ConfigService], multi: true }
	]
})

export class CommonModule {
	constructor(apollo: Apollo, httpLink: HttpLink, authService: AuthService) {
		const http = httpLink.create({ uri: '/graphql' });

		const authMiddleware = new ApolloLink((operation, forward) => {
			const authorizationToken = authService.authHeaderValue();
			if (authorizationToken) {
				// add the authorization to the headers
				operation.setContext({
					headers: new HttpHeaders().set('Authorization', authorizationToken)
				});
			} else if (operation.variables && operation.variables.__auth__) {
				authService.login().subscribe();
				return;
			}
			return forward(operation);
		});

		apollo.create({
			link: ApolloLink.from([authMiddleware, http]), //concat(authMiddleware, http),
			cache: new InMemoryCache({
				typePolicies: {
					accounts: {
						keyFields:[]
					},
					documents: {
						keyFields:[]
					},
					auth: {
						keyFields:[]
					},
					subs: {
						keyFields:[]
					},
					reports: {
						keyFields:[]
					},
					payU: {
						keyFields:[]
					},
					billing: {
						keyFields:[]
					},
					notifications: {
						keyFields:[]
					},
					imports: {
						keyFields:[]
					}
				}
			}),
			queryDeduplication: false,
			defaultOptions: {
				watchQuery: {
					errorPolicy: 'none'
				}
			}
		});
	}
}
