import { Directive, OnInit, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kee-autofocus]'
})
export class KeeAutofocusDirective implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    //const ne = this.elementRef.nativeElement.children[0].children[0];
    //ne.focus();
    this.searchAndSetFocus(this.elementRef.nativeElement);
  };

  ngOnInit(): void {
    //   this.elementRef.nativeElement.focus();
  }
  /*
  We might have applied the directive to a component that encapsulate (at an arbitrary deep level) an input or textarea.
  So look recursively after a potential target
  */
  searchAndSetFocus(root: any): boolean {
    if (root.localName === 'textarea' || root.localName === 'input') {
      root.focus();
      return true;
    }
    for (const child of root.children) {
      if (this.searchAndSetFocus(child)) {
        return true;
      }
    }
    return false;

  }

}