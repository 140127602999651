import { Injectable } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';
import { KMutationOptions, RestService } from '../common/general/rest.service';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import {
	CloseTakeoverGQL,
	FinalizeTakeoverGQL,
	FinishTakeoverGQL,
	GetTakeoverBadgeStatusGQL,
	GetTakeoverBadgeStatusQuery,
	GetTakeoverDashboardGQL,
	GetTakeoverGQL,
	GetTakeoversListDocument,
	GetTakeoversListQuery,
	OpenTakeoverGQL,
	SaveTakeoverGQL,
} from './adapters/takeover.generated';
import { ApolloQueryResult } from '@apollo/client';
import {
	TakeoverAttachmentModel,
	TakeoverDashboardStatus,
	TakeoverExpertListModel,
	TakeoverExpertModel,
	TakeoverHistoryModel,
	TakeoverListModel,
	TakeoverModel,
	TakeoverStatus,
} from './takeover.models';
import { classToPlain, plainToClass } from 'class-transformer';
import { ConfigService } from '../common/general/config.service';
import { UploadService } from '../common/general/upload.service';
import { SpinnerService } from '../library/services';
import { DownloadService } from '../common/general/download.service';
import { UID } from '../library/utils';
import { Accounts_TakeoverOpDataRepresentationInput, Accounts_TakeoverRepresentationInput } from 'src/graphql';

@Injectable({
	providedIn: 'root',
})
export class TakeoverService {
	// BehaviorSubject
	private sortStream = new BehaviorSubject<string>(null);
	private filtersStream = new BehaviorSubject<string>(null);
	private pageSizeStream = new BehaviorSubject<number>(20);
	private pageIndexStream = new BehaviorSubject<number>(0);

	public somethingChangedBehavior = new BehaviorSubject<boolean>(false);
	public somethingChanged = this.somethingChangedBehavior.asObservable();

	//navigation
	public currentTakeoverListModel: TakeoverListModel;

	constructor(
		private authService: AuthService,
		private restService: RestService,
		private configService: ConfigService,
		private downloadService: DownloadService,
		private spinner: SpinnerService,
		private uploadService: UploadService
	) {}

	get pageSize() {
		return this.pageSizeStream.value;
	}
	set pageSize(value: number) {
		this.pageSizeStream.next(value);
	}

	get pageIndex() {
		return this.pageIndexStream.value;
	}
	set pageIndex(value: number) {
		this.pageIndexStream.next(value);
	}

	public filterInbox(filter: string) {
		const self = this;
		self.pageIndexStream.next(0);
		self.filtersStream.next(filter);
	}

	public sortInbox(sort: string) {
		if (this.sortStream.value != sort) {
			this.sortStream.next(sort);
		}
	}

	public getTakeoversList(debounce: number = 100, showSpinner: boolean = true) {
		return combineLatest([this.authService.getSelectedCompanyId(), this.filtersStream, this.sortStream, this.pageSizeStream, this.pageIndexStream]).pipe(
			debounceTime(debounce),
			switchMap(([clientEid, filter, sort, pageSize, pageIndex]) => {
				if (clientEid) {
					return this.restService
						.query(
							{
								query: GetTakeoversListDocument,
								variables: {
									clientEid: clientEid,
									filter: filter,
									order: sort,
									offset: pageIndex * pageSize,
									count: pageSize,
								},
								fetchPolicy: 'network-only',
							},
							{ spinner: showSpinner }
						)
						.pipe(
							map((response: ApolloQueryResult<GetTakeoversListQuery>) => {
								let result: TakeoverListModel = null;
								try {
									result = plainToClass(TakeoverListModel, response.data.accounts.getTakeoversUsingGET);
									result.data = plainToClass<TakeoverModel, object>(TakeoverModel, result.data || []);
									result.data.forEach((data) => {
										data.takeoverAttachments = plainToClass<TakeoverAttachmentModel, object>(TakeoverAttachmentModel, data.takeoverAttachments || []);
										data.history = plainToClass<TakeoverHistoryModel, object>(TakeoverHistoryModel, data.history || []);
									});
								} catch {
									result = null;
								}

								this.currentTakeoverListModel = result;
								return result;
							})
						);
				}
				return of(null);
			})
		);
	}

	public async getTakeover(clientEid: string, takeoverEid: string) {
		let qry = new GetTakeoverGQL(this.restService);
		let queryData = await qry
			.fetch({
				clientEid: clientEid,
				takeoverEid: takeoverEid,
			})
			.toPromise();

		if (queryData != null) {
			let result = plainToClass(TakeoverModel, queryData.data.accounts.getTakeoverUsingGET);
			result.takeoverAttachments = plainToClass<TakeoverAttachmentModel, object>(TakeoverAttachmentModel, result.takeoverAttachments || []);
			result.history = plainToClass<TakeoverHistoryModel, object>(TakeoverHistoryModel, result.history || []);
			return result;
		}

		return null;
	}

	public uploadFileStream(file: File, clientEid: string, takeoverEid: string): Observable<TakeoverAttachmentModel> {
		return this.uploadFile(file, clientEid, takeoverEid).pipe(
			map((fileEid: string) => {
				if (fileEid) {
					let newAttachment = this.createAttachment(fileEid, file);
					return newAttachment;
				}
				return null;
			})
		);
	}

	public uploadFile(file: File, clientEid: string, takeoverEid: string, spinner: boolean = true): Observable<string> {
		spinner && this.spinner.show();

		let fileEid = UID();
		const meta = {
			clientEid: clientEid,
			source: 'takeover',
			category: 'takeoverattachment',
			documentType: 'DOCUMENT',
			fileEid,
			takeoverEid: takeoverEid,
		};
		return this.configService.getDocumentsUploadUrl().pipe(
			switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
			filter((data) => !!data.done),
			map((data) => {
				return fileEid;
			}),
			finalize(() => {
				spinner && this.spinner.hide();
			})
		);
	}

	private createAttachment(fileEid: string, file: File) {
		let newAttachment = new TakeoverAttachmentModel();
		newAttachment.fileEid = fileEid;
		newAttachment.fileName = file.name;
		newAttachment.fileSize = file.size;

		return newAttachment;
	}

	public downloadFile(fileEid: string) {
		return this.configService
			.getDocumentsDownloadUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/storage/${fileEid}`;
					return this.downloadService.download(url);
				}),
				take(1)
			)
			.subscribe();
	}

	public downloadAllFilesArchive(clientEid: string, takeoverId: string) {
		return this.configService
			.getAccountsUrl()
			.pipe(
				switchMap((url) => {
					url = url + `/${clientEid}/takeovers/${takeoverId}/attachments?format=zip`;
					return this.downloadService.download(url);
				}),
				take(1)
			)
			.subscribe();
	}

	public async saveTakeover(clientEid: string, takeover: TakeoverModel): Promise<boolean> {
		let qry = new SaveTakeoverGQL(this.restService);
		let data: Accounts_TakeoverRepresentationInput = {
			clientEid: clientEid,
			externalId: takeover.externalId,
			takeoverTypeEid: takeover.takeoverType.externalId,
			statusCode: takeover.status.code,
			takeoverAttachments: [],
		};

		takeover.takeoverAttachments.forEach((attachment) => {
			data.takeoverAttachments.push({
				displayOrder: attachment.displayOrder,
				fileEid: attachment.fileEid,
				fileName: attachment.fileName,
				fileSize: attachment.fileSize,
				isVisible: attachment.isVisible,
			});
		});

		const result = await qry
			.mutate(
				{
					clientEid: clientEid,
					data: data,
				},
				{ error: false } as KMutationOptions
			)
			.toPromise();
		this.somethingChangedBehavior.next(true);
		if (result) {
			return true;
		}

		return false;
	}

	public async openTakeover(clientEid: string, takeoverEid: string, comment: string): Promise<boolean> {
		let qry = new OpenTakeoverGQL(this.restService);
		let data: Accounts_TakeoverOpDataRepresentationInput = {
			takeoverEid: takeoverEid,
			comment: comment,
		};

		let result = await qry
			.mutate(
				{
					clientEid: clientEid,
					data: data,
				},
				{ error: false } as KMutationOptions
			)
			.toPromise();
		this.somethingChangedBehavior.next(true);
		if (result) {
			return true;
		}

		return false;
	}

	public async closeTakeover(clientEid: string, takeoverEid: string, comment: string): Promise<boolean> {
		let qry = new CloseTakeoverGQL(this.restService);
		let data: Accounts_TakeoverOpDataRepresentationInput = {
			takeoverEid: takeoverEid,
			comment: comment,
		};

		let result = await qry
			.mutate(
				{
					clientEid: clientEid,
					data: data,
				},
				{ error: false } as KMutationOptions
			)
			.toPromise();
		this.somethingChangedBehavior.next(true);
		if (result) {
			return true;
		}

		return false;
	}

	public async finishTakeover(clientEid: string, takeoverEid: string, comment: string): Promise<boolean> {
		let qry = new FinishTakeoverGQL(this.restService);
		let data: Accounts_TakeoverOpDataRepresentationInput = {
			takeoverEid: takeoverEid,
			comment: comment,
		};

		let result = await qry
			.mutate(
				{
					clientEid: clientEid,
					fireEvents: true,
					data: data,
				},
				{ error: false } as KMutationOptions
			)
			.toPromise();
		this.somethingChangedBehavior.next(true);
		if (result) {
			return true;
		}

		return false;
	}

	public async getTakeoverDashboard() {
		let qry = new GetTakeoverDashboardGQL(this.restService);
		let queryData = await qry.fetch().toPromise();

		if (queryData != null) {
			let result = plainToClass(TakeoverExpertListModel, queryData.data.accounts.getTakeoverDashboardUsingGET);
			result.data = plainToClass<TakeoverExpertModel, object>(TakeoverExpertModel, result.data || []);
			result.data.forEach((d) => {
				d.accountingTakeoverStatusDisplay = this.getDisplayStatus(d.accountingTakeoverStatus);
				d.payrollTakeoverStatusDisplay = this.getDisplayStatus(d.payrollTakeoverStatus);
			});
			return result;
		}

		return null;
	}

	public getDisplayStatus(status: string) {
		if (status == null) {
			return 'Nu necesită preluare';
		}

		switch (status) {
			case TakeoverDashboardStatus.Open:
				return 'Deschis';
			case TakeoverDashboardStatus.Close:
				return 'Inchis';
			default:
				return '';
		}
	}

	public async finalizeTakeover(clientEid: string, takeoverEid: string, comment: string): Promise<boolean> {
		let qry = new FinalizeTakeoverGQL(this.restService);
		let data: Accounts_TakeoverOpDataRepresentationInput = {
			takeoverEid: takeoverEid,
			comment: comment,
		};

		let result = await qry
			.mutate(
				{
					clientEid: clientEid,
					data: data,
				},
				{ error: false } as KMutationOptions
			)
			.toPromise();
		this.somethingChangedBehavior.next(true);
		if (result) {
			return true;
		}

		return false;
	}

	public getTakeoverBadgeStatusObservable(): Observable<GetTakeoverBadgeStatusQuery> {
		const qry = new GetTakeoverBadgeStatusGQL(this.restService);
		return qry.fetch().pipe(switchMap((rez) => of(rez.data)));
	}
}
