import * as Types from '../../../../graphql';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ClientPartnersListQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ClientPartnersListQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getClientPartnersUsingGET?: { __typename?: 'accounts_PartnerClientGridListRepresentation', data?: Array<{ __typename?: 'accounts_PartnerClientListRepresentation', externalId?: string | null, fiscalNumber?: string | null, partnerExternalId?: string | null, partnerName?: string | null, partnerClientContacts?: Array<{ __typename?: 'accounts_PartnerClientContactRepresentation', email?: string | null, firstName?: string | null, isActive?: boolean | null, lastName?: string | null, personTitleCode?: string | null, personTitleName?: string | null, phoneNumber?: string | null }> | null }> | null } | null } | null };

export type CheckBatchStatusQueryVariables = Types.Exact<{
  batchExternalId: Types.Scalars['String'];
}>;


export type CheckBatchStatusQuery = { __typename?: 'Query', reports?: { __typename?: 'reports', checkBatchStatusUsingGET?: { __typename?: 'reports_BatchExecutionRepresentation', externalId?: string | null, clientEid?: string | null, status?: string | null, type?: string | null, description?: string | null, info?: any | null, result?: string | null, errorMessage?: string | null } | null } | null };

export type RunUploadMutationVariables = Types.Exact<{
  data: Types.Reports_RunUploadRepresentationInput;
}>;


export type RunUploadMutation = { __typename?: 'Mutation', reports?: { __typename?: 'reports_mutation', runUploadUsingPOST?: { __typename?: 'reports_Eid', externalId?: string | null } | null } | null };

export const ClientPartnersListDocument = gql`
    query clientPartnersList($clientEid: String!, $filter: String, $order: String, $offset: Int, $count: Int) {
  accounts {
    getClientPartnersUsingGET(clientExternalId: $clientEid, filter: $filter, order: $order, offset: $offset, count: $count) {
      data {
        externalId
        fiscalNumber
        partnerExternalId
        partnerName
        partnerClientContacts {
          email
          firstName
          isActive
          lastName
          personTitleCode
          personTitleName
          phoneNumber
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientPartnersListGQL extends Apollo.Query<ClientPartnersListQuery, ClientPartnersListQueryVariables> {
    document = ClientPartnersListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckBatchStatusDocument = gql`
    query checkBatchStatus($batchExternalId: String!) {
  reports {
    checkBatchStatusUsingGET(batchExternalId: $batchExternalId) {
      externalId
      clientEid
      status
      type
      description
      info
      result
      errorMessage
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckBatchStatusGQL extends Apollo.Query<CheckBatchStatusQuery, CheckBatchStatusQueryVariables> {
    document = CheckBatchStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RunUploadDocument = gql`
    mutation runUpload($data: reports_RunUploadRepresentationInput!) {
  reports {
    runUploadUsingPOST(data: $data) {
      externalId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RunUploadGQL extends Apollo.Mutation<RunUploadMutation, RunUploadMutationVariables> {
    document = RunUploadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }