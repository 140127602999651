import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalienteLibrarySharedModule } from '@saliente/library';
import { CommonDocumentsSharedModule } from '@saliente/common-documents';
import { ClientDocumentItemComponent, } from './document-item.component';
import { ClientDocumentsOpisComponent, } from './documents-opis.component';
import { ClientUnblurDocumentFileComponent, } from './unblur-document-file.component';
import { DeleteDocumentFileSharedModule } from './delete-document-file-shared.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DocumentDisplayService } from 'src/app/common/document-display';

const COMPONENTS = [ ClientDocumentItemComponent, ClientDocumentsOpisComponent, ClientUnblurDocumentFileComponent ];


@NgModule({
	imports: [
		CommonModule, FormsModule, SalienteLibrarySharedModule, CommonDocumentsSharedModule, DeleteDocumentFileSharedModule, PerfectScrollbarModule
	],
	providers: [ 
		DocumentDisplayService
	],
	declarations: [
		...COMPONENTS
	],
	exports: [
		...COMPONENTS
	]
})
export class ClientsDocumentsSharedModule {
}
