<kendo-dialog class="helpVideoModal" *ngIf="showModalBox" (close)="closeModal()" [minWidth]="250" [width]="1000">
  <iframe
    width="1000"
    height="596"
    [src]="currentHelpVideo.url"
    title="{{ currentHelpVideo.title }}"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</kendo-dialog>

<kendo-dialog *ngIf="showHelpConfirmationDialog" title="Ascunde afisarea clipurilor Help" [minWidth]="350" [width]="450" (close)="closeHideHelpConfirmationDialog()">
  <p style="font-size: 15px; margin-bottom: 30px">Puteți oricând să reactivați afișarea clipurilor din meniul Help.</p>
  <div class="container d-flex justify-content-around">
    <button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="closeHideHelpConfirmationDialog()">
      <!-- <i class="mdi-content-save"></i> -->
      <span i18n="@@Actions$Save"> Ok</span>
    </button>
  </div>
</kendo-dialog>

<kendo-dialog
  [ngClass]="{ showRatingDialogCloseButton: showRatingDialogCloseButton }"
  *ngIf="showRatingDialog"
  title="Ce părere aveți despre serviciile Keez?"
  [minWidth]="250"
  [width]="500"
  (close)="closeRatingDialog(showRatingDialogCloseButton)"
>
  <rating-dialog [source]="ratingSource" [showFeedback]="showFeedback" (onRatingSave)="onRatingSave($event)"></rating-dialog>
</kendo-dialog>

<kee-spinner></kee-spinner>
<kee-messagebox class="kee-messagebox"></kee-messagebox>
<div class="master-container masterview {{ !showSideBar ? 'no-sidebar' : '' }} {{ !showHeaderBar ? 'no-headerbar' : '' }} {{ showHelpVideos ? 'help-videos' : '' }}">
  <!-- <default-header *ngIf="showHeaderBar && !authService.isLoggedIn"></default-header>
  <client-header *ngIf="showHeaderBar && authService.isLoggedIn && authService.selectedZoneCode === PredefinedUserZone.Administrator"> </client-header>
  <expert-header *ngIf="showHeaderBar && authService.isLoggedIn && authService.selectedZoneCode === PredefinedUserZone.Expert"> </expert-header>
  <hr-expert-header *ngIf="showHeaderBar && authService.isLoggedIn && authService.selectedZoneCode === PredefinedUserZone.HrExpert"> </hr-expert-header>
  <subcontractor-header *ngIf="showHeaderBar && authService.isLoggedIn && authService.selectedZoneCode === PredefinedUserZone.Subcontractor"> </subcontractor-header> -->
  <masterview-header *ngIf="showHeaderBar"></masterview-header>
  <div class="master-container">
    <div *ngIf="showHelpVideos" class="col-24 pt-4 masterview-videos">
      <div *ngFor="let helpVideo of helpVideos" class="px-1 d-flex flex-column" style="max-width: 400px; padding-top: 30px">
        <img width="370" style="padding-left: 10px; padding-right: 10px; cursor: pointer" src="{{ helpVideo.thumbnail }}" (click)="openModalWithVideo(helpVideo)" />
        <div class="overlayVideoTitle" (click)="openModalWithVideo(helpVideo)">
          <p>{{ helpVideo.title }}</p>
        </div>
        <div class="text-truncate" style="padding-left: 10px; padding-top: 2px">
          <a href="{{ helpVideo.full_content_link }}" target="_blank">Mai multe informații</a>
        </div>
      </div>
      <div class="position-absolute p-4 red-text text-darken-3 cursor-pointer" style="top: 0; right: 0" (click)="hideHelpVideos()">
        <i class="mdi-navigation-close"></i>
        <span i18n="@@Actions$Hide">Ascunde</span>
      </div>
    </div>
    <div class="master-container masterview-content">
      <aside *ngIf="showSideBar" class="sidebar d-flex flex-column {{ sideBarVisible ? 'show' : '' }} left-sidebar-nav">
        <!-- <ul *ngIf="!authService.isLoggedIn">
          <li class="bold">
            <a (click)="authService.login().subscribe()" class="waves-effect waves-cyan"> <i class="mdi-action-dashboard"></i> Intră </a>
          </li>
        </ul> -->
        <!-- AM, 26.11.2021, task 5442: moved to masterview header -->
        <!-- <ul *ngIf="authService.isLoggedIn" class="col-auto my-0 px-0">
          <li *ngIf="true" class="user-details">
            <div class="row m-0">
              <div class="col-24">
                <dropdown-button class="btn-flat dropdown-button waves-effect waves-light white-text profile-btn" list-class="profile-dropdown">
                  <span>{{ authService.userName }}</span>
                  <i class="mdi-navigation-arrow-drop-down"></i>
                  <dropdown-button-item (itemClick)="gotoProfile()"> <i class="mdi-action-face-unlock"></i> Profil </dropdown-button-item>
                  <dropdown-button-item (itemClick)="gotoContact()"> <i class="mdi-communication-live-help"></i> Ajutor </dropdown-button-item>
                  <dropdown-button-item class="divider"></dropdown-button-item>
                  <dropdown-button-item (itemClick)="authService.logout()"> <i class="mdi-hardware-keyboard-tab"></i> Ieșire </dropdown-button-item>
                </dropdown-button>
                <div *ngIf="authService.displayZones.length != 1 || authService.selectedZoneCode != PredefinedUserZone.Administrator" class="user-role-container">
                  <dropdown-button *ngIf="authService.displayZones.length > 1"
                    class="btn-flat dropdown-button waves-effect waves-light white-text user-role"
                    list-class="role-dropdown">
                      <span>{{ authService.selectedZoneDisplayName }}</span>
                      <i class="mdi-navigation-arrow-drop-down"></i>
                      <dropdown-button-item *ngIf="authService.isInZone(PredefinedUserZone.Administrator)" (itemClick)="switchZone(PredefinedUserZone.Administrator)">
                        <span>{{ authService.getZoneDisplayName(PredefinedUserZone.Administrator) }}</span>
                      </dropdown-button-item>
                      <dropdown-button-item *ngIf="authService.isInZone(PredefinedUserZone.Expert)" (itemClick)="switchZone(PredefinedUserZone.Expert)">
                        <span>{{ authService.getZoneDisplayName(PredefinedUserZone.Expert) }}</span>
                      </dropdown-button-item>
                      <dropdown-button-item *ngIf="authService.isInZone(PredefinedUserZone.HrExpert)" (itemClick)="switchZone(PredefinedUserZone.HrExpert)">
                        <span>{{ authService.getZoneDisplayName(PredefinedUserZone.HrExpert) }}</span>
                      </dropdown-button-item>
                      <dropdown-button-item *ngIf="authService.isInZone(PredefinedUserZone.Subcontractor)" (itemClick)="switchZone(PredefinedUserZone.Subcontractor)">
                        <span>{{ authService.getZoneDisplayName(PredefinedUserZone.Subcontractor) }}</span>
                      </dropdown-button-item>
                    </dropdown-button>
                    <span *ngIf="authService.displayZones.length == 1" class="user-role">{{ authService.selectedZoneDisplayName }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul> -->
        <perfect-scrollbar>
          <ul *ngIf="authService.isLoggedIn" class="min-height-300 my-0 py-3 overflow-y-auto">
            <li>
              <ul class="collapsible collapsible-accordion">
                <!-- parents -->
                <!-- canExpand = is collapsible -->
                <ng-template ngFor let-parent [ngForOf]="appMenuService.getMenus()">
                <li #parentMenu id="{{parent.text}}" *ngIf="parent.isVisible"
                  routerLinkActive="{{ (parent.canExpand || parent.alwaysExpanded) ? '' : 'selected' }}"
                  [routerLinkActiveOptions]="{ exact: !parent.leafLink }"
                  class="{{ parent.canExpand ? 'canExpand' : parent.alwaysExpanded ? 'alwaysExpanded' : '' }}">
                    <a *ngIf="!parent.disabled" [routerLink]="parent.link" title="{{ parent.text }}"
                      class="{{ parent.alwaysExpanded ? '' : 'collapsible-header'}} {{ parent.isAtFirstExpanded ? 'active' : ''}} waves-effect no-waves main-menu-item {{ parent.class }}">
                      <i *ngIf="parent.icon && !parent.canExpand" class="{{ parent.icon }}"></i>
                      <!-- do not show badges on collapsible parent menu header, show instead arrows --> <!-- {{ parentMenu.canExpand ? parentMenu.text : '' }} -->
                      <!-- badges for parents -->
                      <div class="main-menu-item-text d-flex flex-nowrap">
                        <div class="col px-0">{{ parent.text }}</div>
                        <div *ngIf="parent.isChat && !!unseenMessages" class="col-auto general-menu-badge">{{ unseenMessages }}</div>
                        <div *ngIf="parent.isPortofolioMenu && portofolioTotalCount>0" class="col-auto general-menu-badge">{{ portofolioTotalCount }}</div>
                        <div *ngIf="parent.isAccountingMenu && !!accountingTotalCount && accountingTotalCount > 0" class="col-auto general-menu-badge">{{ accountingTotalCount }}</div>
                        <div *ngIf="parent.isPayrollMainMenu && !!payrollTotalCount && payrollTotalCount > 0" class="col-auto general-menu-badge">{{ payrollTotalCount }}</div>
                        <div *ngIf="parent.isInvoiceMenu && !!invoiceTotalCount && invoiceTotalCount > 0" class="col-auto general-menu-badge">{{ invoiceTotalCount }}</div>
                        </div>
                    </a>
                    <!-- children -->
                    <div *ngIf="parent.childrenMenu != null && parent.childrenMenu.length > 0" class="collapsible-body">
                      <ng-template ngFor let-child [ngForOf]="parent.childrenMenu" let-i="index">
                        <li #childMenu *ngIf="child.isVisible" routerLinkActive="selected" [routerLinkActiveOptions]="{ exact: !child.leafLink }">
                          <a *ngIf="!child.disabled" class="waves-effect no-waves main-menu-item {{ child.class }}"
                            [routerLink]="child.link" title="{{ child.text }}">
                            <i *ngIf="child.icon" class="{{ child.icon }}"></i>
                            <!-- badges for children -->
                            <div class="main-menu-item-text d-flex flex-nowrap">
                              <div class="col px-0">{{ child.text }}</div>
                              <div *ngIf="child.isImportMenu && !!waitingImportList && waitingImportList > 0" class="col-auto general-menu-badge">{{ waitingImportList }}</div>
                              <div *ngIf="child.isAnafComm && anafMessageNotFinalizedCount > 0" class="col-auto general-menu-badge">{{ anafMessageNotFinalizedCount }}</div>
                              <div *ngIf="child.isFinancialDocuments && !!documentsCount && documentsCount > 0" class="col-auto general-menu-badge">{{ documentsCount }}</div>
                              <div *ngIf="child.isClarificationMenu && !!clarificationCount && clarificationCount > 0" class="col-auto general-menu-badge">{{clarificationCount}}</div>
                              <div *ngIf="child.isCardClarificationMenu && !!cardClarificationCount && cardClarificationCount > 0" class="col-auto general-menu-badge">{{cardClarificationCount}}</div>
                              <div *ngIf="child.isReconciliationMenu && !!reconciliationCount && reconciliationCount > 0" class="col-auto general-menu-badge">{{reconciliationCount}}</div>
                              <div *ngIf="child.isMissingDocsMenu && !!missingDocumentsCount && missingDocumentsCount > 0" class="col-auto general-menu-badge">{{missingDocumentsCount}}</div>
                              <div *ngIf="child.isBalanceSheet && !!balanceSheetWorkInProgressCount && balanceSheetWorkInProgressCount > 0" class="col-auto general-menu-badge">{{ balanceSheetWorkInProgressCount }}</div>
                              <div *ngIf="child.isSalariatiMenu && !!minimumWageEmployeesCount && minimumWageEmployeesCount > 0" class="col-auto general-menu-badge">{{ minimumWageEmployeesCount }}</div>
                              <div *ngIf="child.isEFacturaMenu && !!eFacturaCount && eFacturaCount > 0" class="col-auto general-menu-badge">{{ eFacturaCount }}</div>
                              <div *ngIf="child.isTakeover && takeoverBadgeCount > 0" class="col-auto general-menu-badge">{{ takeoverBadgeCount }}</div>
                            </div>
                          </a>
                          <!-- if child menu is disabled -->
                          <span *ngIf="child.disabled" title="{{ child.text }}"
                            class="a-disabled d-block main-menu-item {{ child.disabled ? 'grey-text text-ligthen-2' : '' }} {{ child.class }}">
                            <i *ngIf="child.icon" class="{{ child.icon }} "></i>
                            <span class="main-menu-item-text">{{ child.text }}</span>
                          </span>
                        </li>
                      </ng-template>
                    </div>
                    <div class="menu-divider"></div>
                    <!-- if parent menu is disabled -->
                    <span *ngIf="parent.disabled" title="{{ parent.text }}"
                      class="a-disabled d-block main-menu-item {{ parent.disabled ? 'grey-text text-ligthen-2' : '' }} {{ parent.class }}">
                      <i *ngIf="parent.icon" class="{{ parent.icon }} "></i>
                      <span class="main-menu-item-text">{{ parent.text }}</span>
                    </span>
                </li>
              </ng-template>
              </ul>
            </li>
          </ul>
        </perfect-scrollbar>

        <!-- <perfect-scrollbar>
          <ul *ngIf="authService.isLoggedIn" class="min-height-300 my-0 py-3 overflow-y-auto ">
            <li *ngFor="let menu of appMenuService.getMenus()" routerLinkActive="active " [routerLinkActiveOptions]="{ exact: !menu.leafLink }"
              class="bold {{ menu.expanded ? 'active' : '' }} {{ menu.disabled || !menu.link ? 'no-hover' : '' }}">
              <a *ngIf="!menu.disabled && menu.link" [routerLink]="menu.link" title="{{ menu.text }}" class="waves-effect waves-cyan main-menu-item {{ menu.class }}">
                <i *ngIf="menu.icon" class="{{ menu.icon }} "></i>
                <div class="main-menu-item-text d-flex flex-nowrap">
                  <div class="col px-0">{{ menu.text }}</div>
                  <div *ngIf="menu.isChat && !!unseenMessages" class="col-auto chat-unseen-messages">{{ unseenMessages }}</div>
                  <div *ngIf="menu.isImportMenu && !!waitingImportList && waitingImportList > 0" class="col-auto general-menu-badge">{{ waitingImportList }}</div>
                  <div *ngIf="menu.isAnafComm && anafMessageNotFinalizedCount > 0" class="col-auto chat-unseen-messages">{{ anafMessageNotFinalizedCount }}</div>
                  <div *ngIf="menu.isBalanceSheet && balanceSheetExpertWorkInProgressCount > 0" class="col-auto chat-unseen-messages">{{ balanceSheetExpertWorkInProgressCount }}</div>
                  <div *ngIf="menu.isMissingDocsMenu && !!missingDocumentsCount && missingDocumentsCount > 0" class="col-auto general-menu-badge">{{missingDocumentsCount}}</div>
                  <div *ngIf="menu.isClarificationMenu && !!clarificationCount && clarificationCount > 0" class="col-auto general-menu-badge">{{clarificationCount}}</div>
                  <div *ngIf="menu.isReconciliationMenu && !!reconciliationCount && reconciliationCount > 0" class="col-auto general-menu-badge">{{reconciliationCount}}</div>
                </div>
              </a>
              <span *ngIf="menu.disabled || !menu.link" title="{{ menu.text }}"
                class="a-disabled d-block  main-menu-item {{ menu.disabled ? 'grey-text text-ligthen-2' : '' }} {{ menu.class }}">
                <i *ngIf="menu.icon" class="{{ menu.icon }} "></i>
                <span class="main-menu-item-text">{{ menu.text }}</span>
              </span>
            </li>
          </ul>
        </perfect-scrollbar> -->
      </aside>
      <div class="page-content d-flex flex-column container-fluid">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
