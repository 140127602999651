import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MonacoEditorModule } from 'ngx-monaco-editor';

import localeRo from '@angular/common/locales/ro';
import localeRoExtra from '@angular/common/locales/extra/ro';
registerLocaleData(localeRo, 'ro-RO', localeRoExtra);

import { IntlModule } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { GridModule, FilterMenuModule, RowFilterModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
// import { SparklineModule } from '@progress/kendo-angular-charts';
import { LayoutModule, SplitterModule, TabStripModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from "@progress/kendo-angular-tooltip";
const KENDO_MODULES = [IntlModule, GridModule, FilterMenuModule, RowFilterModule, PDFModule, ExcelModule, InputsModule, DatePickerModule, DropDownsModule, DialogModule, WindowModule, ButtonsModule,
	/*SparklineModule,*/ SplitterModule, PopupModule, TabStripModule, TooltipModule, LayoutModule];
// Load all required data for the ro locale
import '@progress/kendo-angular-intl/locales/ro/all';

import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// import 'ngx-toastr/toastr.css';
import 'hammerjs';

import {
	InputFieldComponent,
	DropdownButtonComponent, DropdownButtonItemComponent,
	FlowStepsComponent, FlowStepComponent, CheckboxComponent, /*PdfPreviewComponent, PdfViewerComponent,*/ ValidationMessagesComponent,
	SpinnerComponent
} from './components';
import { COMPONENTS } from './components';

import { SERVICES, KendoMessageService } from './services';
import { EqualValidatorDirective, CustomValidatorDirective, CheckedValidatorDirective, FileDropDirective, OwlCarouselDirective, WowDirective, WowContainerDirective,KeeAutofocusDirective } from './directives';

@NgModule({
	imports: [
		CommonModule, FormsModule, ...KENDO_MODULES, InfiniteScrollModule, MonacoEditorModule.forRoot(),
	],
	declarations: [
		InputFieldComponent,

		DropdownButtonComponent, DropdownButtonItemComponent,
		FlowStepsComponent, FlowStepComponent, CheckboxComponent, /*PdfPreviewComponent, PdfViewerComponent,*/ ValidationMessagesComponent,
		SpinnerComponent,
		...COMPONENTS,

		EqualValidatorDirective, CustomValidatorDirective, CheckedValidatorDirective, FileDropDirective, OwlCarouselDirective, WowDirective, WowContainerDirective, KeeAutofocusDirective,
	],
	exports: [
		...KENDO_MODULES, InfiniteScrollModule, MonacoEditorModule,
		InputFieldComponent,

		DropdownButtonComponent, DropdownButtonItemComponent,
		FlowStepsComponent, FlowStepComponent, CheckboxComponent, /*PdfPreviewComponent, PdfViewerComponent,*/ ValidationMessagesComponent,
		SpinnerComponent,
		...COMPONENTS,

		EqualValidatorDirective, CustomValidatorDirective, CheckedValidatorDirective, FileDropDirective, OwlCarouselDirective, WowDirective, WowContainerDirective, KeeAutofocusDirective
	]
})
export class SalienteLibrarySharedModule {
}

@NgModule({
	imports: [
		SalienteLibrarySharedModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'ro-RO' },
		{ provide: MessageService, useClass: KendoMessageService },
		...SERVICES,
		EqualValidatorDirective, CustomValidatorDirective, CheckedValidatorDirective, FileDropDirective, OwlCarouselDirective, WowDirective, WowContainerDirective, KeeAutofocusDirective
	],
	exports: [SalienteLibrarySharedModule]
})
export class SalienteLibraryModule {
}
