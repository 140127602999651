import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';

import { PredefinedUserZone, } from '@saliente/common-auth';
import { AppMenuService, AppMenuModel, } from '@saliente/common-appmenu';

import { SUBS_ROUTES } from '../subs.routes.constants';

@Injectable()
export class SubsMenuService {

	constructor(private appMenuService: AppMenuService) {
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			home: true,
			zone: PredefinedUserZone.Subcontractor,
			text: 'Acasă',
			icon: 'mdi-action-dashboard',
			link: [SUBS_ROUTES.ROOT]
		}));
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			accessRole: 'SUB_INCORRECT_DIGITIZATION_VIEW',
			zone: PredefinedUserZone.Subcontractor,
			text: 'Documente invalidate',
			icon: 'mdi-content-content-copy',
			link: [SUBS_ROUTES.ROOT, SUBS_ROUTES.REJECTED_DOCUMENTS]
		}));
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Subcontractor,
			text: 'Cash out',
			icon: 'mdi-editor-attach-money',
			link: [SUBS_ROUTES.ROOT, SUBS_ROUTES.CASH_OUT]
		}));
	}
}
