import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RevisalRoutingModule } from "./revisal.routes";

@NgModule({
	imports: [
		CommonModule, RevisalRoutingModule
	]
})
export class RevisalModule {
}
