import { forwardRef, Component, Input, Output, Injector, ViewChild, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { Control } from '../control';

let nextUniqueId = 0;

export const CHECKBOX_FIELD_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CheckboxComponent),
	multi: true
};

@Component({
	selector: 'checkbox',
	templateUrl: 'checkbox.component.html',
	providers: [CHECKBOX_FIELD_VALUE_ACCESSOR],
})
export class CheckboxComponent extends Control<boolean> implements OnInit {

	@ViewChild('checkbox') public checkboxEl: ElementRef;

	@Input() public label: string = '';

	private _disabled: boolean = false;
	@Input() get disabled() {
		return this._disabled;
	}
	set disabled(value) {
		this._disabled = this.coerceBooleanProperty(value);
	}

	private _indeterminate: boolean = false;
	@Input() get indeterminate() {
		return this._indeterminate;
	}
	set indeterminate(value) {
		value = this.coerceBooleanProperty(value);
		if (value !== this._indeterminate) {
			this._indeterminate = value;
			this.checkboxEl.nativeElement.indeterminate = value;
		}
	}

	@Input() public id: string = `checkbox-${nextUniqueId++}`;
	get inputId() {
		return 'input' + this.id;
	}

	constructor(private el: ElementRef, injector: Injector, ) {
		super(injector);
	}

	public ngOnInit() {
		if (this.el.nativeElement.hasAttribute('checked')) {
			this.value = true;
		}
		super.ngOnInit();
	}
}
