import { Injectable } from '@angular/core';

import { IMessageBoxComponent, Message } from '.';

@Injectable()
export class MessageBoxService {
	private component: IMessageBoxComponent;

	constructor() {
	}

	public register(component: IMessageBoxComponent) {
		this.component = component;
	}

	public show(message: Message) {
		if (this.component) {
			this.component.show(message);
		}
	}
}
